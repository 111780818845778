import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    setLocations(state, action) {
      state = action.payload;
      return state;
    },
  },
});

export const { setLocations } = locationsSlice.actions;

export const reducer = locationsSlice.reducer;
