import { format } from "date-fns";
import styled from "styled-components";
import EventIcon from "../eventIcon";

const EventCard = ({ icon, iconUrl, title, message, variant, timestamp }) => {
  return (
    <EventCardWrapper className={`event-item-card variant-${variant}`}>
      <span className="event-type-icon">
        <EventIcon icon={icon} iconUrl={iconUrl} />
      </span>
      <div className="event-item-content">
        <div className="title">{title}</div>
        <div className="message">{message}</div>
      </div>
      <span className="event-fire-time">
        {format(new Date(timestamp * 1000), "hh:mm aa")}
      </span>
    </EventCardWrapper>
  );
};

export default EventCard;

const EventCardWrapper = styled.div`
  min-height: 60px;
  background: ${({ theme }) => theme.happenings.card.background};
  padding: 5px;
  display: flex;
  margin: 5px 0px;
  border-radius: 10px;
  align-items: center;
  .event-type-icon {
    color: #0000004d;
    display: flex;
    background: rgb(177, 177, 177);
    height: 40px;
    width: 50px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin: 0px 5px;
  }
  .event-item-content {
    height: 100%;
    width: 100%;
    .title {
      position: relative;
      top: 2px;
      font-size: 12px;
    }
    .message {
      position: relative;
      bottom: 2px;
      font-size: 16px;
    }
  }
  .event-fire-time {
    font-size: 10px;
    display: flex;
    width: 75px !important;
    height: 100%;
    transform: translateY(-18px);
  }
  &.variant-positive {
    .event-type-icon {
      color: ${({ theme }) => theme.happenings.positive.icon.color};
      background: ${({ theme }) => theme.happenings.positive.icon.background};
    }
    .event-item-content {
      .title {
        color: ${({ theme }) => theme.happenings.positive.title};
      }
      .message {
        color: ${({ theme }) => theme.happenings.positive.message};
      }
    }
    .event-fire-time {
      color: ${({ theme }) => theme.happenings.positive.time};
    }
  }
  &.variant-neutral {
    .event-type-icon {
      color: ${({ theme }) => theme.happenings.neutral.icon.color};
      background: ${({ theme }) => theme.happenings.neutral.icon.background};
    }
    .event-item-content {
      .title {
        color: ${({ theme }) => theme.happenings.neutral.title};
      }
      .message {
        color: ${({ theme }) => theme.happenings.neutral.message};
      }
    }
    .event-fire-time {
      color: ${({ theme }) => theme.happenings.neutral.time};
    }
  }
  &.variant-negative {
    .event-type-icon {
      color: ${({ theme }) => theme.happenings.negative.icon.color};
      background: ${({ theme }) => theme.happenings.negative.icon.background};
    }
    .event-item-content {
      .title {
        color: ${({ theme }) => theme.happenings.negative.title};
      }
      .message {
        color: ${({ theme }) => theme.happenings.negative.message};
      }
    }
    .event-fire-time {
      color: ${({ theme }) => theme.happenings.negative.time};
    }
  }
`;
