import { useEffect, useState } from "react";
import type { EChartsOption } from "echarts";
import BaseChart from "../../baseChart";
import { numFormatter } from "../../../helpers/utils";

export type CandleStickConfiguration = {
  config: {
    selector: string;
    name: string;
    value_formatter: any;
  };
  data: {
    series: { 
      category: string;
      open: number;
      high:number;
      low: number;
      close: number;
      
    }[];
  };
};

interface ICandleStickVisualProps {
  width: number;
  height: number;
  data: CandleStickConfiguration;
}

const CandleStickVisual = (props: ICandleStickVisualProps) => {
  const [option, setOption] = useState<EChartsOption>({
    tooltip: {
      show: true,
      axisPointer: {
        type: "cross",
      },
    },
    xAxis: {
      data: []
    },
    yAxis: {},
    series: { 
      type: "candlestick",
      data: []
    }
  });

  useEffect(() => {
    if (props.data) {
      setOption((o) => {
        let updatedOption = { ...o };
        updatedOption.xAxis = {
          data: props.data.data.series.map((d) => d.category),
        };
        updatedOption.yAxis = {
          type: "value",
          name: props.data.config.name,
          axisLabel: {
            formatter: function (value, index) {
              return `${numFormatter(value)} ${
                props.data.config.value_formatter
              }`;
            },
          },
        }; 
        updatedOption.series = [
          {
            type: "candlestick",
            data: props.data.data.series.map((d) => [d.open, d.high, d.low ,d.close]),
          },
        ];
        return updatedOption;
      });
    }
  }, [props.data]);

  const handleClickEvent = (e) => {
    // TODO
  };

  return (
    <BaseChart
      height={props.height}
      width={props.width}
      options={option}
      chartType="candlestick"
      onClick={handleClickEvent}
    />
  );
};

export default CandleStickVisual;
