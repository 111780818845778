import { useSelector } from "react-redux";
import { ToastContainer, type ToastOptions, toast, Flip } from "react-toastify";
import { RootState } from "../../state/store";
import { ToastContext } from "../../helpers/contexts/toastContext";
import { useState } from "react";

const ToastProvider = ({ children }) => {
  const theme = useSelector((state: RootState) => state.theme.mode);

  const showToast = ({
    message,
    type = "info",
    config = {},
  }: {
    message: string;
    type?: ToastOptions["type"];
    config?: ToastOptions;
  }) => {
    toast[type](message, config);
  };

  return (
    <ToastContext.Provider
      value={{
        showToast,
      }}>
      <ToastContainer
        autoClose={5000}
        hideProgressBar
        pauseOnHover
        toastStyle={{
          fontSize: "14px",
          fontFamily:
            "ibm-plex-sans, Helvetica Neue, Helvetica, Arial, sans-serif",
        }}
        transition={Flip}
        theme={theme}
      />
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
