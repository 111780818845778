import Draggable from "react-draggable";
import styled, { keyframes } from "styled-components";
import { useExtendedAContext } from "../../hooks/useExtendedAContext";
import CloseButton from "../modalCloseButton";
import { BsFillTrashFill } from "react-icons/bs";

const SavedViewsPopup = () => {
  const { views, loadView, getCurrentViewSnapshot } = useExtendedAContext();
  const { hideSavedViews, savedViews, updateView, deleteView } = views;

  return (
    <Popover>
      <Draggable
        defaultClassName="draggable-popup"
        defaultClassNameDragging="draggable-popup-dragging"
        defaultClassNameDragged="draggable-popup-dragged"
        handle=".draggable-handle-modal"
        positionOffset={{ x: "5vw", y: "10vh" }}>
        <div className="popup-content">
          <div className="draggable-handle-modal"></div>
          <CloseButton onClick={hideSavedViews} />
          <div className="header">
            <span className="title">Load View</span>
          </div>
          <div className="content">
            {savedViews.length === 0 && (
              <div className="center-section">
                <span className="item-name">No saved views</span>
              </div>
            )}
            {savedViews.map((view: { id: number; name: string; data: any }) => (
              <div key={view.id} className="item">
                <span className="item-name">{view.name}</span>
                <span
                  className="item-action item-action-load"
                  onClick={() => loadView(view.data)}>
                  Load
                </span>
                <span
                  className="item-action item-action-update"
                  onClick={() =>
                    updateView({
                      id: view.id,
                      name: view.name,
                      snapshot: getCurrentViewSnapshot(),
                    })
                  }>
                  Overwrite
                </span>
                <span
                  className="item-action-delete"
                  onClick={() => deleteView({ id: view.id })}>
                  <BsFillTrashFill />
                </span>
              </div>
            ))}
          </div>
        </div>
      </Draggable>
    </Popover>
  );
};

export default SavedViewsPopup;

const contentShow = keyframes({
  "0%": {
    opacity: 0,
    transform: "translate(5vw, 9vh) scale(0.95)",
  },
  "100%": {
    opacity: 1,
    transform: "translate(5vw, 10vh) scale(1)",
  },
});

const Popover = styled.div`
  .popup-content {
    position: fixed;
    top: 10%;
    left: 25%;
    transform: translate(5vw, 10vh);
    z-index: 1610;
    background-color: ${({ theme }) => theme.pivot_table.background_color};
    border: 1px solid ${({ theme }) => theme.pivot_table.table_props.border_color};
    border-radius: 25px;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
      hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    width: 578px;
    height: 512px;
    padding: 25px;
    animation: ${contentShow} 600ms cubic-bezier(0.16, 1, 0.3, 1);
    .draggable-handle-modal {
      cursor: move;
      position: absolute;
      width: 100%;
      top: 1px;
      left: 1px;
      height: 30px;
      border-radius: 20px 20px 0 0;
    }
    .header {
      margin-bottom: 20px;
      .title {
        font-size: 25px;
        font-weight: 700;
        line-height: 36.4px;
        text-align: left;
      }
    }
    .content {
      .center-section {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70px;
      }
      .item {
        height: 70px;
        padding: 10px;
        display: flex;
        align-items: center;
        border-top: 1px solid #d0cccc;
        border-bottom: 1px solid #d0cccc;
        .item-name {
          flex: 1;
          font-size: 24px;
          font-weight: 500;
          line-height: 31.2px;
          text-align: left;
          margin-right: 10px;
        }
        .item-action {
          cursor: pointer;
          margin-right: 10px;
          border: 1px solid;
          text-align: center;
          display: flex;
          width: 112px;
          justify-content: center;
          height: 45px;
          border-radius: 4px;
          color: #ffffff;
          font-size: 19px;
          font-weight: 400;
          align-items: center;
          line-height: 22.7px;
          &.item-action-load {
            background: #565656;
          }
          &.item-action-update {
            background: #ed7846;
          }
        }
        .item-action-delete {
          cursor: pointer;
          display: flex;
          color: #fff;
          background: #d42a2a;
          justify-content: center;
          align-items: center;
          height: 45px;
          width: 45px;
          border-radius: 4px;
        }
      }
    }
  }
`;
