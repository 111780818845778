import { useContext, useMemo } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useElementSize } from "usehooks-ts";
import Widget from "../../baseWidget";
import { WidgetContext } from "../../widgetContext";
import RadialPolarBarChart from "./radialPolarBar";
import { addWidgetToFilterList } from "../../../state/features/analytics/actions";
import { RootState } from "../../../state/store";

interface IRadialPolarBarVisualProps {}

const PolarBarChartWidget = (props: IRadialPolarBarVisualProps) => {
  const [widgetData, setWidgetData] = useState<any>({});
  const [chartContainerRef, chartDimensions] = useElementSize();
  const dispatch = useDispatch();

  const widget = useContext(WidgetContext);

  const { widgets: widgetFilters } = useSelector(
    (state: RootState) => state.filters
  );

  useEffect(() => {
    if (widget.widgetData.widget_data) {
      setWidgetData(widget.widgetData);
      const widgetFilterExists = widgetFilters.find(
        (i) => i.key == widget?.widgetFilterConfig?.widgetFilterKey
      );
      if (widget?.widgetFilterConfig.enableFilters && !widgetFilterExists) {
        dispatch(
          addWidgetToFilterList({
            name: widget.widgetFilterConfig.filterTitle,
            filterKey: widget.widgetFilterConfig.widgetFilterKey,
            options: widget.widgetData.widget_data.data.series.map((d) => ({
              id: d.id,
              name: d.name,
            })),
          })
        );
      }
    }
  }, [widget.widgetData]);

  const widgetSeriesData = useMemo(() => {
    if (widgetData && widgetData?.widget_data) {
      return widgetData.widget_data.data.series;
    }
    return [];
  }, [JSON.stringify(widgetData)]);

  const handleWidgetOnClick = () => {
    if (widget.widgetOnClick) {
      widget.widgetOnClick();
    }
  };

  return (
    <Widget ref={chartContainerRef}>
      <Widget.Header>
        <Widget.Title onClick={handleWidgetOnClick}>
          {widgetData.title}
        </Widget.Title>
        <Widget.Controls>
          {widgetData.widget_data ? (
            <>
              <Widget.ExtendedAReportCta />
            </>
          ) : null }
        </Widget.Controls>
      </Widget.Header>
      <Widget.Content>
        { widgetSeriesData.length ? (
          <RadialPolarBarChart
            data={widgetData.widget_data}
            width={chartDimensions.width - 50}
            height={chartDimensions.height - 90}
          />
        ): (
          <Widget.EmptyData
            width={chartDimensions.width - 50}
            height={chartDimensions.height - 90}
          />
        )}
      </Widget.Content>
    </Widget>
  );
};

export default PolarBarChartWidget;
