import {
  addDays,
  addWeeks,
  differenceInHours,
  differenceInMonths,
  differenceInQuarters,
  differenceInWeeks,
  differenceInYears,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  getWeekOfMonth,
  lastDayOfMonth,
  parse,
  parseISO,
  setHours,
  setMinutes,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from "date-fns";
import { COMPARISON_MODE, COMPARISON_OPTION } from "../../types/comparison";

type DateFormatString = "yyyy-MM-dd" | "dd-MM-yyyy" | "MM-dd-yyyy";

/**
 * Creates a function for formatting dates with provided string format
 * @returns function to format Date to string
 * */
export const createDateFormatter =
  (formatString: DateFormatString) => (date: Date) =>
    format(date, formatString);

/**
 * Creates a function for parsing dates with provided string format
 * @returns function to format Date string to Date
 * */
export const createDateParser =
  (formatString: DateFormatString) => (dateString: string) =>
    parse(dateString, formatString, new Date());

export const timeParser = (formatString: DateFormatString) => {
  return format(parseISO(formatString.split("+")[0]), "hh:mm a");
};

const getComparisonMode = ({
  startDate,
  endDate,
  startTime,
  endTime,
}: {
  startDate: string;
  endDate: string;
  startTime: [number, number];
  endTime: [number, number];
}) => {
  let startDateTime = setMinutes(
    setHours(parse(startDate, "yyyy-MM-dd", new Date()), startTime[0]),
    startTime[1]
  );
  let endDateTime = setMinutes(
    setHours(parse(endDate, "yyyy-MM-dd", new Date()), endTime[0]),
    endTime[1]
  );
  let deltaHours = differenceInHours(endDateTime, startDateTime);
  let formatDate = createDateFormatter("dd-MM-yyyy");
  let startDateOfYear = formatDate(startOfYear(startDateTime));
  let startDateOfNextYear = formatDate(startOfYear(endDateTime));
  let isSelectedRangeYear =
    startDateOfYear === formatDate(startDateTime) &&
    startDateOfNextYear === formatDate(endDateTime) &&
    JSON.stringify(startTime) == JSON.stringify(endTime);
  let startDateOfQuarter = formatDate(startOfQuarter(startDateTime));
  let startDateOfNextQuarter = formatDate(startOfQuarter(endDateTime));
  let isSelectedRangeQuarter =
    startDateOfQuarter === formatDate(startDateTime) &&
    startDateOfNextQuarter === formatDate(endDateTime) &&
    JSON.stringify(startTime) == JSON.stringify(endTime);
  let startDateOfMonth = formatDate(startOfMonth(startDateTime));
  let startDateOfNextMonth = formatDate(startOfMonth(endDateTime));
  let isSelectedRangeMonth =
    startDateOfMonth === formatDate(startDateTime) &&
    startDateOfNextMonth === formatDate(endDateTime) &&
    JSON.stringify(startTime) == JSON.stringify(endTime);

  let startDateOfWeek = formatDate(startOfWeek(startDateTime));
  let startDateOfNextWeek = formatDate(startOfWeek(endDateTime));
  let isSelectedRangeWeek =
    startDateOfWeek === formatDate(startDateTime) &&
    startDateOfNextWeek === formatDate(endDateTime) &&
    JSON.stringify(startTime) == JSON.stringify(endTime);
  if (isSelectedRangeYear) {
    return "year";
  }
  if (isSelectedRangeQuarter) {
    return "quarter";
  }
  if (isSelectedRangeMonth) {
    return "month";
  }
  if (isSelectedRangeWeek) {
    return "week";
  }
  if (deltaHours == 24) {
    return "day";
  }
  return null;
};

interface Params {
  baseDateRange: {
    startDate: Date;
    endDate: Date;
  };
  mode: COMPARISON_MODE;
  option: string;
  config: any;
}

const buildCompareDateRangeFromOption = ({
  baseDateRange,
  mode,
  option,
  config,
}: Params) => {
  const formatDate = createDateFormatter("yyyy-MM-dd");

  switch (mode) {
    case COMPARISON_MODE.YEAR:
      switch (option) {
        case COMPARISON_OPTION.SAME_RANGE_PREVIOUS: {
          return [
            formatDate(subYears(baseDateRange.startDate, 1)),
            formatDate(subYears(baseDateRange.endDate, 1)),
          ];
        }
        // sameRangeLastRangeType not available for 'year' comparison mode
        case COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE: {
          let { count } = config;
          /** creates 2D array of n years date ranges `[[startDate, endDate]]` */
          let LastNYears = Array(count)
            .fill([])
            .map((d, i) => [
              formatDate(subYears(baseDateRange.startDate, ++i)),
              formatDate(subYears(baseDateRange.endDate, i)),
            ]);
          /** pick only the first and last date of the n years
           * `[startDateOfNYears, endDateOfNYears]` */
          let lastNYearsRange = [LastNYears[count - 1][0], LastNYears[0][1]];
          return lastNYearsRange;
        }
        case COMPARISON_OPTION.CUSTOM: {
          let { customDate } = config;
          let customRange = [
            formatDate(startOfYear(customDate)),
            formatDate(addDays(endOfYear(customDate), 1)),
          ];
          return customRange;
        }
        default:
          return ["", ""];
      }

    case COMPARISON_MODE.QUARTER:
      switch (option) {
        case COMPARISON_OPTION.SAME_RANGE_PREVIOUS: {
          return [
            formatDate(subQuarters(baseDateRange.startDate, 1)),
            formatDate(subQuarters(baseDateRange.endDate, 1)),
          ];
        }
        case COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE: {
          // Same quarter last year
          return [
            formatDate(subYears(baseDateRange.startDate, 1)),
            formatDate(subYears(baseDateRange.endDate, 1)),
          ];
        }
        case COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE: {
          let { count } = config;
          let lastNQuarters = Array(count)
            .fill([])
            .map((d, i) => [
              formatDate(subQuarters(baseDateRange.startDate, ++i)),
              formatDate(subQuarters(baseDateRange.endDate, i)),
            ]);
          let lastNQuartersRange = [
            lastNQuarters[count - 1][0],
            lastNQuarters[0][1],
          ];
          return lastNQuartersRange;
        }
        case COMPARISON_OPTION.CUSTOM: {
          let { customDate } = config;
          let customRange = [
            formatDate(startOfQuarter(customDate)),
            formatDate(addDays(endOfQuarter(customDate), 1)),
          ];
          return customRange;
        }
        default:
          return ["", ""];
      }

    case COMPARISON_MODE.MONTH:
      switch (option) {
        case COMPARISON_OPTION.SAME_RANGE_PREVIOUS: {
          let previousMonthRange = [
            formatDate(subMonths(baseDateRange.startDate, 1)),
            formatDate(subMonths(baseDateRange.endDate, 1)),
          ];
          return previousMonthRange;
        }
        case COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE: {
          // Same month last year
          let sameMonthLastYearRange = [
            formatDate(subYears(baseDateRange.startDate, 1)),
            formatDate(subYears(baseDateRange.endDate, 1)),
          ];
          return sameMonthLastYearRange;
        }
        case COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE: {
          let { count } = config;
          let lastNMonths = Array(count)
            .fill([])
            .map((d, i) => [
              formatDate(subMonths(baseDateRange.startDate, ++i)),
              formatDate(subMonths(baseDateRange.endDate, i)),
            ]);
          let lastNMonthsRange = [lastNMonths[count - 1][0], lastNMonths[0][1]];
          return lastNMonthsRange;
        }
        case COMPARISON_OPTION.CUSTOM: {
          // TODO - Recheck again
          let { customDate } = config;
          let customRange = [
            formatDate(customDate),
            formatDate(addDays(endOfMonth(customDate), 1)),
          ];
          return customRange;
        }
        default:
          return ["", ""];
      }

    case COMPARISON_MODE.WEEK:
      switch (option) {
        case COMPARISON_OPTION.SAME_RANGE_PREVIOUS: {
          return [
            formatDate(subDays(baseDateRange.startDate, 7)),
            formatDate(subDays(baseDateRange.endDate, 7)),
          ];
        }
        case COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE: {
          // Same week last month only
          let weekOfMonth = getWeekOfMonth(baseDateRange.startDate);
          let previousMonthNumber = baseDateRange.startDate.getMonth() - 1;
          let previousMonthYear = baseDateRange.startDate.getFullYear();
          let previousMonthDate = new Date(
            previousMonthYear,
            previousMonthNumber,
            1
          );
          let previousMonthWeekRange = [
            formatDate(
              startOfWeek(addWeeks(previousMonthDate, weekOfMonth - 1))
            ),
            formatDate(
              addDays(
                endOfWeek(addWeeks(previousMonthDate, weekOfMonth - 1)),
                1
              )
            ),
          ];
          return previousMonthWeekRange;
        }
        case COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE: {
          let { count } = config;
          let lastNWeeks = Array(count)
            .fill([])
            .map((d, i) => [
              formatDate(subWeeks(baseDateRange.startDate, ++i)),
              formatDate(subWeeks(baseDateRange.endDate, i)),
            ]);
          let lastNWeeksRange = [lastNWeeks[count - 1][0], lastNWeeks[0][1]];
          return lastNWeeksRange;
        }
        case COMPARISON_OPTION.CUSTOM: {
          // TODO
          return ["", ""];
        }
        default:
          return ["", ""];
      }

    case COMPARISON_MODE.DAY:
      switch (option) {
        case COMPARISON_OPTION.SAME_RANGE_PREVIOUS: {
          return [
            formatDate(subDays(baseDateRange.startDate, 1)),
            formatDate(subDays(baseDateRange.endDate, 1)),
          ];
        }
        case COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE: {
          let { subRange } = config;
          switch (subRange) {
            case "week": {
              // Same day last week
              return [
                formatDate(subDays(baseDateRange.startDate, 7)),
                formatDate(subDays(baseDateRange.endDate, 7)),
              ];
            }
            case "month": {
              // Same day last month
              return [
                formatDate(subMonths(baseDateRange.startDate, 1)),
                formatDate(subMonths(baseDateRange.endDate, 1)),
              ];
            }
            case "year": {
              // Same day last year
              return [
                formatDate(subYears(baseDateRange.startDate, 1)),
                formatDate(subYears(baseDateRange.endDate, 1)),
              ];
            }
            default:
              // Same day last week
              return [
                formatDate(subDays(baseDateRange.startDate, 7)),
                formatDate(subDays(baseDateRange.endDate, 7)),
              ];
          }
        }
        case COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE: {
          let { count } = config;
          let lastNDays = Array(count)
            .fill([])
            .map((d, i) => [
              formatDate(subDays(baseDateRange.startDate, ++i)),
              formatDate(subDays(baseDateRange.endDate, i)),
            ]);
          return [lastNDays[count - 1][0], lastNDays[0][0]];
        }
        case COMPARISON_OPTION.CUSTOM: {
          let { selectedDate } = config;
          let customRange = [
            formatDate(selectedDate),
            formatDate(addDays(selectedDate, 1)),
          ];
          return customRange;
        }
        default:
          return ["", ""];
      }
    default:
      return ["", ""];
  }
};

const filterRowData = (data: any[], accessorKey: string) => {
  let rowData: any[] = [];
  if (data.length) {
    rowData = data.map((d) => {
      return d[accessorKey];
    });
  }
  return rowData;
};

export { getComparisonMode, buildCompareDateRangeFromOption, filterRowData };
