import { useContext } from "react";
import { AnalyticsGroupWidgetContext } from "../contexts/analyticsGroupWidgetContext";
import { AnalyticsGroupWidgetContextType } from "../../types/widgets";

const useAnalyticsGroupWidgetContext = () => {
  const context = useContext(
    AnalyticsGroupWidgetContext
  ) as AnalyticsGroupWidgetContextType;
  return context;
};

export { useAnalyticsGroupWidgetContext };
