import { useContext } from "react";
import styled from "styled-components";
import { useElementSize } from "usehooks-ts";
import { WidgetContext } from "../../widgetContext";
import GaugeChart from "./gaugeChart";

const GaugeChartWidget = () => {
  const [chartContainerRef, chartDimensions] = useElementSize();
  const widget = useContext(WidgetContext);

  const handleWidgetOnClick = () => {
    if (widget.widgetOnClick) {
      widget.widgetOnClick();
    }
  };

  return (
    <Wrapper ref={chartContainerRef} onClick={handleWidgetOnClick}>
      <div
        className="overlay"
        style={{
          width: chartDimensions.width - 50,
          height: chartDimensions.height - 50,
          zIndex: 2,
          position: "absolute",
        }}
      ></div>
      <div className="widget-header">
        <span className="widget-title">{widget.widgetData.title}</span>
      </div>
      <div className="widget-content">
        <GaugeChart
          data={widget.widgetData.data}
          width={chartDimensions.width - 50}
          height={chartDimensions.height - 90}
        />
      </div>
    </Wrapper>
  );
};

export default GaugeChartWidget;

const Wrapper = styled.div`
  border-radius: 20px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.widget.background};
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.05));
  width: 100%;
  height: 100%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  .widget-header {
    text-align: center;
    .widget-title {
      font-size: 1.3rem;
      font-weight: 500;
    }
  }
`;
