import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";

const useAnalyticsFilters = () => {
  const [analyticsFilterUrls, setAnalyticsFilterUrls] = useState<string[]>([]);
  const { filterList } = useSelector((state: RootState) => state.analytics);
  const widgetFilters = useSelector(
    (state: RootState) => state.filters.widgets
  );

  const analyticsFiltersToFetch = useMemo(() => {
    if (analyticsFilterUrls.length > 0) {
      return analyticsFilterUrls.filter(
        (d) =>
          !filterList.map((c) => c.filterKey).includes(d) &&
          !widgetFilters.map((c) => c.key).includes(d)
      );
    }
    return [];
  }, [JSON.stringify(analyticsFilterUrls), JSON.stringify(filterList)]);

  return {
    analyticsFiltersToFetch,
    setAnalyticsFilterUrls,
    shouldFetchFilterData: Boolean(analyticsFiltersToFetch.length),
  };
};

export { useAnalyticsFilters };
