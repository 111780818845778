import Card from "react-bootstrap/Card";
import mapIcon from "../../../images/icons/map.svg";
import styled from "styled-components";

function DisabledMapUI() {
  return (
    <Wrapper>
      <Card className="card">
        <div className="icon">
          <img src={mapIcon} alt="map-no-data" />
        </div>
        <div className="text-area">
          <p className="text">Data not available for selected date</p>
        </div>
      </Card>
    </Wrapper>
  );
}

export default DisabledMapUI;

const Wrapper = styled.div`
  .card {
    background-color: ${({ theme }) => theme.widget.background};
    text-align: center;
    width: 320px;
    height: 214px;
    padding: 50px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    .icon {
      color: ${({ theme }) => theme.text};
    }
    .text-area {
      margin-top: 15px;

      .text {
        margin: 0;
        font-size: 18px;
        font-color: ${({ theme }) => theme.text};
        line-height: 23px;
      }
    }
  }
`;
