import axios, { AxiosRequestConfig } from "axios";
import { getEnvValueFor } from "../helpers/utils";
import { authOperations } from "./features/auth";

export const rmsApi = axios.create({
  baseURL: "",
  responseType: "json",
  headers: {
    Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    "Release-Version": getEnvValueFor("RELEASE_VERSION"),
  },
});

export const authmsApi = axios.create({
  baseURL: getEnvValueFor("AUTH_MS_BASE_URL"),
  responseType: "json",
});

export const fetcher = (url, options = {}) =>
  rmsApi(url, options).then((response) => response.data);

rmsApi.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response && err.response.status === 401) {
      authOperations.setSessionExpired();
    }
    return Promise.reject(err);
  }
);

export type AxiosRequestAction = (param?: any) => {
  payload: { request: AxiosRequestConfig };
};
