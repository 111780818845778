import { registerLicense, validateLicense } from "@syncfusion/ej2-base";
/**
 * Syncfusion - React Pivot Table Library
 * https://ej2.syncfusion.com/react/documentation/pivotview/getting-started
 */
const registerSyncfusionLicenseKey = (licenseKey: string) => {
  let isLicenseValid = validateLicense(licenseKey);
  if (!isLicenseValid) {
    console.error(
      "%c[EXTENDED-ANALYTICS]%c - Syncfusion License Invalid",
      "font-weight:900;color:red;",
      `color: default`
    );
  }
  registerLicense(licenseKey);
};
export { registerSyncfusionLicenseKey };
