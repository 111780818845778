import { useEffect, useState } from "react";
import styled from "styled-components";

interface ICounterSelectProps {
  min: number;
  max: number;
  default: number;
  showControls: boolean;
  setCount: (count: number) => any;
}

const CounterSelect = (props: ICounterSelectProps) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(props.default);
  }, []);

  useEffect(() => {
    props.setCount(count);
  }, [count]);

  const incrementCount = () => {
    if (count < props.max) {
      setCount((c) => c + 1);
    }
  };

  const decrementCount = () => {
    if (count > props.min) {
      setCount((c) => c - 1);
    }
  };

  return (
    <CounterSelectWrapper showControls={props.showControls}>
      <div className="button-container">
        <span className="minus-btn control" onClick={decrementCount}>
          -
        </span>
        <div className="count">{count}</div>
        <span className="plus-btn control" onClick={incrementCount}>
          +
        </span>
      </div>
    </CounterSelectWrapper>
  );
};
export default CounterSelect;

const CounterSelectWrapper = styled.div<{ showControls: boolean }>`
  display: inline;
  .button-container {
    display: inline-flex;
    flex-direction: row;
    .control {
      display: ${(props: any) => (props.showControls ? "inline" : "none")};
      border-radius: 5px;
      color: #fff;
      background-color: ${({ theme }) => theme.button.primary_color};
      cursor: pointer;
      &:first-child {
        margin-right: 5px;
      }
      &:last-child {
        margin-left: 5px;
      }
      width: 20px;
      text-align: center;
    }
  }
`;
