import { useEffect, useState } from "react";
import { numFormatter } from "../../../helpers/utils";
import BaseChart from "../../baseChart";

const DemandForecastChart = ({ data, height, width }) => {
  const [option, setOption] = useState({});

  useEffect(() => {
    if (data) {
      const {
        xAxisLabels,
        currentValues,
        predictedValues,
        predictedLowerboundValues,
        predictedUpperboundValues,
      } = transformData(data);

      const commonSeriesConfigs = {
        type: "line",
        smooth: true,
      };
      setOption((o) => ({
        ...o,
        legend: {
          top: "bottom",
          data: [
            {
              name: data.config.labels.current_data,
              color: "#5a73be",
            },
            {
              name: data.config.labels.predicted_data,
              color: "#929292",
            },
            {
              name: data.config.labels.predicted_data_upperbound,
              color: "#3ECD89",
            },
            {
              name: data.config.labels.predicted_data_lowerbound,
              color: "#E41212",
            },
          ],
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            show: true,
            type: "cross",
          },
        },
        dataZoom: {
          type: "inside",
        },
        xAxis: {
          type: "category",
          data: xAxisLabels,
        },
        yAxis: [
          {
            type: "value",
            name: "",
            axisLabel: {
              formatter: function (value, index) {
                return `${numFormatter(value)} ${data.config.value_formatter}`;
              },
            },
          },
          {
            type: "value",
            name: "",
            axisLabel: {
              formatter: function (value, index) {
                return `${numFormatter(value)} ${data.config.value_formatter}`;
              },
            },
          },
          {
            type: "value",
            name: "",
            axisLabel: {
              formatter: function (value, index) {
                return `${numFormatter(value)} ${data.config.value_formatter}`;
              },
            },
          },
          {
            type: "value",
            name: "",
            axisLabel: {
              formatter: function (value, index) {
                return `${numFormatter(value)} ${data.config.value_formatter}`;
              },
            },
          },
        ],
        series: [
          {
            ...commonSeriesConfigs,
            name: data.config.labels.current_data,
            data: currentValues,
            itemStyle: {
              color: "#2DB6f5",
            },
            lineStyle: {
              color: "#2DB6f5",
            },
          },
          {
            ...commonSeriesConfigs,
            name: data.config.labels.predicted_data,
            data: predictedValues,
            itemStyle: {
              color: "#F5C324",
            },
            lineStyle: {
              color: "#F5C324",
              type: "dotted",
            },
          },
          // {
          //   ...commonSeriesConfigs,
          //   name: data.config.labels.predicted_data_upperbound,
          //   data: predictedUpperboundValues,
          //   itemStyle: {
          //     color: "#3ECD89",
          //   },
          //   lineStyle: {
          //     color: "#3ECD89",
          //     type: "dotted",
          //   },
          // },
          // {
          //   ...commonSeriesConfigs,
          //   name: data.config.labels.predicted_data_lowerbound,
          //   data: predictedLowerboundValues,
          //   itemStyle: {
          //     color: "#E41212",
          //   },
          //   lineStyle: {
          //     color: "#E41212",
          //     type: "dotted",
          //   },
          // },
        ],
      }));
    }
  }, [data]);

  const transformData = (chartData) => ({
    xAxisLabels: chartData.data.series.map((d) => d.date),
    currentValues: chartData.data.series.map((d) => d.value),
    predictedValues: chartData.data.series.map((d) => d.predicted_value),
    predictedUpperboundValues: chartData.data.series.map(
      (d) => d.predicted_value_high
    ),
    predictedLowerboundValues: chartData.data.series.map(
      (d) => d.predicted_value_low
    ),
  });

  return (
    <BaseChart
      width={width}
      height={height}
      chartType="line"
      options={option}
    />
  );
};

export default DemandForecastChart;
