import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { useFetchAnalyticsFilterData } from "../../../helpers/hooks/useFetchAnalyticsFilterData";
import { useEffect } from "react";
import { addWidgetToFilterList } from "../../../state/features/analytics/actions";

const LoadAnalyticsFilterData = ({
  dashboard = "",
  widget = "",
  filterUrl = "",
  shouldFetchFilterData = false,
}) => {
  const { filters } = useSelector((state: RootState) => state);
  const { data } = useFetchAnalyticsFilterData({
    dashboard,
    widget,
    filterUrl,
    filters: { ...filters, widgets: [] },
    shouldFetchFilterData,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      dispatch(
        addWidgetToFilterList({
          name: data?.filter_data?.label,
          filterKey: data?.filter_data?.url,
          options: data?.filter_data?.values.map((v) => ({
            id: v.value,
            name: v.label,
          })),
        })
      );
    }
  }, [data]);

  return <></>;
};

export default LoadAnalyticsFilterData;
