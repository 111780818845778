import { IoIosCloseCircle } from "react-icons/io";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../../../state/store";

const CloseButton = ({ onClick }) => {
  const theme = useSelector((state: RootState) => state.theme.mode);
  return (
    <Wrapper
      className={`modal-close-btn ${theme != "light" ? "dark" : ""}`}
      onClick={onClick}>
      <IoIosCloseCircle
        fontSize="45px"
        fill={theme !== "light" ? "#dfdcdc" : "#000"}
      />
    </Wrapper>
  );
};

export default CloseButton;

const Wrapper = styled.span`
  cursor: pointer;
  position: absolute;
  top: -23px;
  right: -23px;
  background: #ffffff;
  width: auto;
  border-radius: 25px;
  &.dark {
    background: #333333;
  }
`;
