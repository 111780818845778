import useSWR from "swr";
import offThreadQueryResponse from "../../../helpers/middleware/offThreadQueryResponse";
import { configureUrlWithParams } from "../../../helpers/utils";
import { useEffect, useMemo, useState } from "react";
import { useToast } from "../../../helpers/hooks/useToast";
import { useSelector } from "react-redux";

const useFetchAiSuggestions = ({
  dashboard,
  dashboardWidget,
  widgetUrl,
  filters,
}) => {
  const { company } = useSelector((state: RootState) => state);
  const timezoneOffset = company?.company_details?.time_zone_offset;
  const { showToast } = useToast();
  const apiUrl = useMemo(() => {
    if (dashboard && dashboardWidget && widgetUrl) {
      return configureUrlWithParams(
        `/api/v1/dar/${dashboard}/${dashboardWidget}/${widgetUrl}/ai`,
        { ...filters, timezoneOffset, shouldUseEpocForTimeRange: company.shouldUseEpocForTimeRange }
      );
    }
    return null;
  }, [dashboard, dashboardWidget, widgetUrl, JSON.stringify(filters)]);
  
  const [url, setUrl] = useState(apiUrl);

  useEffect(() => {
    if (url) {
      setUrl(null);
    }
  }, [apiUrl]);

  const { data, isLoading, error, mutate } = useSWR(url, {
    use: [offThreadQueryResponse],
    revalidateOnMount: false,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    onError() {
      showToast({
        message: "Oops! Unable to load AI suggestions. Please try again later",
        type: "error",
      });
    }
  });

  const reloadData = () => {
    setUrl(apiUrl);
    mutate(undefined, true);
  };

  const resetData = () => {
    mutate(undefined, false);
    setUrl(null);
  };

  return { data, isLoading, error, reloadData, resetData };
};

export { useFetchAiSuggestions };
