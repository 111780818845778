import { useMemo } from "react";
import useSWR from "swr";

type Params = {
  onSuccess?(data?: any, key?: string, config?: any): void;
};

const useFetchDashboardList = ({ onSuccess }: Params) => {
  const {
    data: dashboardsListData,
    error: dashboardListDataFetchError,
    isValidating,
    mutate: fetchDashboardList,
  } = useSWR("/api/v1/dar/dashboards", { revalidateOnMount: false, onSuccess });

  const isDashboardListDataLoading = useMemo(
    () => !dashboardsListData && isValidating,
    []
  );

  return {
    dashboardsListData,
    dashboardListDataFetchError,
    isDashboardListDataLoading,
    fetchDashboardList,
  };
};

export { useFetchDashboardList };
