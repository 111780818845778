import useSWR from "swr";

const useFetchBSWidgets = () => {
  const {
    data,
    error,
    isValidating: isLoading,
  } = useSWR(`/api/v1/dar/business-summary/dashboard`, null, {
    revalidateOnFocus: false,
  });
  return { data, error, isLoading };
};

export { useFetchBSWidgets };
