import { format } from "date-fns";
import ReactDatePicker from "react-datepicker";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import styled from "styled-components";

interface Props {
  selectedDate: { year: number; month: number; day: number };
  onMonthChange: (date: Date) => void;
  onShowYearView: () => void;
}

const MonthPickView = ({
  selectedDate,
  onMonthChange,
  onShowYearView,
}: Props) => {
  const handleMonthSelect = (mdate) => {
    onMonthChange(mdate);
  };
  return (
    <Wrapper>
      <ReactDatePicker
        selected={
          new Date(selectedDate.year, selectedDate.month, selectedDate.day)
        }
        inline
        showMonthYearPicker
        onChange={onMonthChange}
        onMonthChange={onMonthChange}
        onSelect={handleMonthSelect}
        highlightDates={[]}
        fixedHeight
        calendarClassName="date-default-view"
        renderCustomHeader={({ date, increaseYear, decreaseYear }) => {
          return (
            <>
              <span
                className="cal-header nav nav-left"
                onClick={() => decreaseYear()}>
                <FaChevronLeft fontSize={"20px"} />
              </span>
              <span className="cal-header title" onClick={onShowYearView}>
                {format(date, "yyyy")}
              </span>
              <span
                className="cal-header nav nav-right"
                onClick={() => increaseYear()}>
                <FaChevronRight fontSize={"20px"} />
              </span>
            </>
          );
        }}
      />
    </Wrapper>
  );
};

export default MonthPickView;

const Wrapper = styled.div`
  > div {
    > .date-default-view {
      border: 1px solid ${({ theme }) => theme.border_color};
      border-radius: 11px;
      width: 275px;
      background-color: ${({ theme }) => theme.widget.background};
      > .react-datepicker__month-container {
        background-color: ${({ theme }) => theme.widget.background};
        float: none;
        .react-datepicker__header--custom {
          border-bottom: 0;
          background-color: ${({ theme }) => theme.widget.background};
          color: ${({ theme }) => theme.text};
          padding-top: 19px;
          padding-left: 20px;
          padding-right: 20px;
          > .nav {
            display: inline;
            cursor: pointer;
            user-select: none;
          }
          & .nav-left {
            float: left;
          }
          & .nav-right {
            float: right;
          }
          > .cal-header.title {
            cursor: pointer;
            font-size: 20px;
            font-weight: 700;
            line-height: 26px;
            user-select: none;
          }
        }
        .react-datepicker__monthPicker {
          height: 188px;
          .react-datepicker__header--custom {
            background-color: ${({ theme }) => theme.widget.background};
          }
          .react-datepicker__month-text {
            width: 75px;
            height: 40px;
            padding: 7px;
            margin: 2px;
            font-size: 16px;
            border-radius: 5px;
            color: ${({ theme }) => theme.text};
            background-color: ${({ theme }) => theme.body};

            &.react-datepicker__month-text--keyboard-selected {
              background-color: #3aa98d;
              border: 1px solid #3aa98d;
            }
            &.react-datepicker__month-text--today {
              font-weight: 400;
            }
          }
        }
      }
    }
  }
`;
