import { format } from "date-fns";
import { useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import calendarAltIcon from "../../images/icons/calendar-alt.svg";
import styled, { keyframes } from "styled-components";

const CustomYearPicker = ({ onYearSelect: handleYearSelect }) => {
  const [year, setYear] = useState(new Date());

  const dropdownRef: React.RefObject<HTMLButtonElement> = useRef(null);

  const onYearSelect = (date) => {
    setYear(new Date(date));
    handleYearSelect(new Date(date));
    if (dropdownRef.current) {
      dropdownRef.current.click();
    }
  };

  return (
    <Wrapper>
      <Dropdown>
        <Dropdown.Toggle className="date-select-btn" ref={dropdownRef}>
          {format(new Date(year), "yyyy")}
          <img src={calendarAltIcon} alt="calendar-icon" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="date-select-menu">
          <ReactDatePicker
            inline
            isClearable
            showYearPicker
            selected={year}
            onChange={onYearSelect}
            dateFormat="yyyy"
          />
        </Dropdown.Menu>
      </Dropdown>
    </Wrapper>
  );
};

export default CustomYearPicker;

const fadeInTop = keyframes`
  0% {
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-50);
            transform: translateY(-50);
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  .date-select-btn {
    width: 100%;
    height: 43px;
    border: 1px solid #000;
    outline: none;
    background-color: white;
    color: #000;
    text-align: left;
    > img {
      float: right;
    }
    &.btn-primary {
      color: #000;
      background-color: white;
      border: 1px solid #000;
    }
  }
  .date-select-menu {
    padding: 0;
    animation: ${fadeInTop} 0.2s;
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border: 1px solid #dedede;
    > div {
      > .react-datepicker {
        border-radius: 10px;
        width: 269px;
        background-color: ${({ theme }) => theme.widget.background};
        border-radius: 10px;
        .react-datepicker__year--container {
          background-color: ${({ theme }) => theme.widget.background};
          border-radius: 10px;
          .react-datepicker__header {
            font-size: 20px;
            font-weight: 700;
            line-height: 26px;
            background-color: ${({ theme }) => theme.widget.background};
            border-bottom: none;
            color: ${({ theme }) => theme.text};
            border-radius: 10px;
          }
          .react-datepicker__year {
            .react-datepicker__year-wrapper {
              width: 100%;
              max-width: 100%;
              justify-content: space-evenly;
              .react-datepicker__year-text {
                padding: 5px;
                height: 33px;
                color: ${({ theme }) => theme.text};
                background-color: ${({ theme }) => theme.body};
                border: ${({ theme }) => theme.widget.background};
                border-radius: 10px;
                &.react-datepicker__year-text--selected {
                  background-color: rgb(13, 138, 106);
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
`;
