import { useContext } from "react";
import styled from "styled-components";
import { SubRDataContext } from "../dataProvider";
import { Spinner } from "react-bootstrap";

export default function LoyaltyDetails() {
  const data = useContext<any>(SubRDataContext);

  return (
    <Container>
      {data.isOrderSummaryDataLoading && !data.itemsData ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <>
          {data.customer || data.customer_contact ? (
            <div className="details">
              <span className="name">
                {data.customer ? data.customer : "-"}
              </span>
              <span className="number">
                {data.customer_contact ? data.customer_contact : "-"}
              </span>
            </div>
          ) : (
            <div className="no-data">No customer details available</div>
          )}
        </>
      )}
      {data.loyalty ? <div className="loyalty">{data.loyalty}</div> : null}
    </Container>
  );
}

const Container = styled.div`
  color: #fff;
  background: linear-gradient(87deg, #f2b927 15.63%, #e2da22 92%);
  height: 80px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 0.5rem;
  padding: 0 1rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 0.5rem;
  filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 4px 4px);
  .details {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    .name {
      font-size: 18px;
      font-weight: 600;
      line-height: normal;
    }
    .number {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    height: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
  }
  .loyalty {
    padding: 0.5rem 1rem;
    border-radius: 999px 0 0 999px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(29, 29, 29, 0.3);
    backdrop-filter: blur(2px);
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
  }
`;
