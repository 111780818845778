import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { RiArrowDownWideLine, RiArrowUpWideLine } from "react-icons/ri";
import styled from "styled-components";
import { padNumberWithZero } from "../../helpers/utils";

const TimePickView = ({ hour, minute, onHourChange, onMinuteChange }) => {
  const [hourValue, setHourValue] = useState(hour.toString().padStart(2, "0"));
  const [minuteValue, setMinuteValue] = useState(
    minute.toString().padStart(2, "0")
  );
  const hourInputRef = useRef<HTMLInputElement | null>(null);
  const minuteInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setHourValue(hour);
    setMinuteValue(minute);
  }, []);

  const minuteControl = {
    onMinuteChange(e) {
      let value = e.target.value;
      if (value.length > 2) {
        value = value.slice(-2);
      }
      if (value.length <= 2) {
        if (["0", "00", "30"].includes(value)) {
          onMinuteChange(value);
        }
        setMinuteValue(padNumberWithZero(parseInt(value)));
      }
    },
    updateMinute(ref) {
      let currentValue = ref.current?.value;
      if (currentValue == "00") {
        setMinuteValue("30");
        onMinuteChange(30);
      } else {
        setMinuteValue("00");
        onMinuteChange(0);
      }
    },
  };

  const hourControl = {
    onHourChange(e) {
      const value = e.target.value;
      if (/^\d{0,2}$/.test(value)) {
        if (value.length === 2) {
          const numValue = parseInt(value, 10);
          if (numValue >= 0 && numValue <= 23) {
            setHourValue(value.padStart(2, "0"));
            onHourChange(value);
          }
        } else {
          setHourValue(value);
        }
      }
    },
    increaseHour(ref) {
      const value = ref.current?.value;
      if (!value) {
        setHourValue("00");
      } else {
        let numValue = parseInt(value);
        if (numValue >= 0 && numValue <= 23) {
          if (numValue == 23) {
            setHourValue("00");
            onHourChange("00");
          } else {
            let newValue = (numValue + 1).toString().padStart(2, "0");
            setHourValue(newValue);
            onHourChange(newValue);
          }
        }
      }
    },
    decreaseHour(ref) {
      const value = ref.current?.value;
      if (!value) {
        setHourValue("00");
      } else {
        let numValue = parseInt(value);
        if (numValue >= 0 && numValue <= 23) {
          if (numValue == 0) {
            setHourValue("23");
            onHourChange("23");
          } else {
            let newValue = (numValue - 1).toString().padStart(2, "0");
            setHourValue(newValue);
            onHourChange(newValue);
          }
        }
      }
    },
  };

  return (
    <Wrapper>
      <h4 className="heading">Select a time</h4>
      <div className="time-select-control">
        <div className="select-control-container hour-select">
          <button
            className="btn control increase-control"
            onClick={() => hourControl.increaseHour(hourInputRef)}>
            <RiArrowUpWideLine className="control-icon" />
          </button>
          <NoSpinnerInput
            aria-label="Hour Input"
            maxLength={2}
            type="number"
            name="hour"
            ref={hourInputRef}
            value={hourValue}
            onChange={hourControl.onHourChange}
          />
          <button
            className="btn control decrease-control"
            onClick={() => hourControl.decreaseHour(hourInputRef)}>
            <RiArrowDownWideLine className="control-icon" />
          </button>
        </div>
        <div className="colon">:</div>
        <div className="select-control-container minute-select">
          <button
            className="btn control increase-control"
            onClick={() => minuteControl.updateMinute(minuteInputRef)}>
            <RiArrowUpWideLine className="control-icon" />
          </button>
          <NoSpinnerInput
            aria-label="Minute Input"
            maxLength={2}
            type="number"
            name="minute"
            ref={minuteInputRef}
            value={minuteValue}
            onChange={minuteControl.onMinuteChange}
          />
          <button
            className="btn control decrease-control"
            onClick={() => minuteControl.updateMinute(minuteInputRef)}>
            <RiArrowDownWideLine className="control-icon" />
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default TimePickView;

const NoSpinnerInput = styled(Form.Control)`
  -moz-appearance: textfield;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.date_range_picker.custom.background};
`;

const Wrapper = styled.div`
  .heading {
    text-align: center !important;
  }
  height: 256px;
  width: 268px;
  padding: 30px 20px 40px;
  .selected-date-header {
    display: flex;
    justify-content: space-between;
    .selected-date {
      cursor: pointer;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      user-select: none;
    }
    .nav-btn {
      cursor: pointer;
      user-select: none;
    }
  }
  .time-select-control {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    padding: 20px;
    .colon {
      text-align: center;
      padding: 30px 0px;
      font-size: 26px;
      font-weight: bolder;
    }
    .select-control-container {
      .form-control {
        margin-top: 2px;
        width: 46px;
      }
      .control {
        background: #99beeb;
        height: 26px;
        .control-icon {
          font-size: 20px;
          font-weight: 900;
          color: rgb(76, 100, 129);
          margin-bottom: 10px;
        }
      }
    }
  }
`;
