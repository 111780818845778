import styled from "styled-components";
import LoadingAnimation from "../../../../components/loadingAnimation";

const BSWidgetDataLoading = () => {
  return (
    <Wrapper>
      <LoadingAnimation />
      <span>Loading Data</span>
    </Wrapper>
  );
};

export default BSWidgetDataLoading;

const Wrapper = styled.div`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.widget.background};
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.05));
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  span {
    font-size: 14px;
    color: #91a1ac;
    font-weight: 500;
  }
`;
