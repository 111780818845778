import styled from "styled-components";

const LastUpdatedFooter = ({
  date = "unknown date",
  time = "unknown time",
}) => {
  return (
    <FooterWrapper>
      Current data as of {date} at {time}
    </FooterWrapper>
  );
};

export default LastUpdatedFooter;

const FooterWrapper = styled.span`
  position: absolute;
  bottom: 15px;
  left: 25px;
  font-size: 11px;
  font-weight: 300;
  line-height: 11.7px;
  text-align: left;
`;
