import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useElementSize } from "usehooks-ts";
import { CustomDispatch, RootState } from "../../../state/store";
import Widget from "../../baseWidget";
import SwitchToggle from "../../switchToggle/switchToggle";
import { WidgetContext } from "../../widgetContext";
import MultiSetBarChart from "../multiSetBarChartWidget/multiSetBarChart";
import { patchSeriesCategories } from "../multiSetBarChartWidget/utils";

const MultisetBarChartSwitchWidget = () => {
  const [viewIndex, setViewIndex] = useState(0);
  const [widgetTitle, setWidgetTitle] = useState("");
  const [widgetData, setWidgetData] = useState<any>([]);
  const [visualData, setVisualData] = useState<any>({});
  const [chartContainerRef, chartDimensions] = useElementSize();

  const widget = useContext(WidgetContext);
  const dispatch: CustomDispatch = useDispatch();

  // const { widgets: widgetFilters } = useSelector(
  //   (state: RootState) => state.filters
  // );

  useEffect(() => {
    if (widget.widgetData.widget_data) {
      setWidgetTitle(widget.widgetData.title);
      setWidgetData(widget.widgetData.widget_data);
      setVisualData({
        data: patchSeriesCategories(widget.widgetData.widget_data[viewIndex]),
      });
      // const widgetFilterExists = widgetFilters.find(
      //   (i) => i.key == widget?.widgetFilterConfig?.widgetFilterKey
      // );
      // if (widget.widgetFilterConfig.enableFilters && !widgetFilterExists) {
      // dispatch(
      //   addWidgetToFilterList({
      //     name: widget.widgetFilterConfig.filterTitle,
      //     filterKey: widget.widgetFilterConfig.widgetFilterKey,
      //     options: widget.widgetData.widget_data[0].data.series.map((d) => ({
      //       ...d,
      //       name: d.category,
      //     })),
      //   })
      // );
      // }
    }
  }, [widget.widgetData]);

  const handleOnClick = () => {
    //TODO
  };

  const handleFlipData = () => {
    let updatedIndex = viewIndex === 0 ? 1 : 0;
    setViewIndex(updatedIndex);
    if (widgetData) {
      setVisualData({ data: patchSeriesCategories(widgetData[updatedIndex]) });
    }
  };

  const widgetSeriesData = useMemo(() => {
    if (
      widgetData?.widget_data &&
      widgetData?.widget_data[0].data.series.length
    ) {
      return widgetData.widget_data[0].data.series;
    } else if (widgetData.length && widgetData[0]?.data?.series) {
      return widgetData[0]?.data.series;
    }
    return [];
  }, [JSON.stringify(widgetData)]);

  return (
    <Widget ref={chartContainerRef}>
      <Widget.Header>
        <Widget.Title onClick={handleOnClick}>{widgetTitle}</Widget.Title>
        <Widget.Controls>
          {widgetSeriesData.length ? (
            <SwitchToggle
              widgetData={widgetData}
              handleFlipData={handleFlipData}
            />
          ) : null}
        </Widget.Controls>
      </Widget.Header>
      <Widget.Content>
        {visualData && visualData.data?.data?.series.length ? (
          <MultiSetBarChart
            width={chartDimensions.width - 50}
            height={chartDimensions.height - 90}
            data={visualData.data}
            //handleDataPointClick={handleOnClick}
          />
        ) : (
          <Widget.EmptyData
            width={chartDimensions.width - 50}
            height={chartDimensions.height - 90}
          />
        )}
      </Widget.Content>
    </Widget>
  );
};

export default MultisetBarChartSwitchWidget;
