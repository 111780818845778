import { useContext, useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useElementSize } from "usehooks-ts";
import { addWidgetToFilterList } from "../../../state/features/analytics/actions";
import { WidgetContext } from "../../widgetContext";
import WidgetEmptyData from "../../widgetEmptyData";
import StackedBarChart from "./stackedBarChart";
import Widget from "../../baseWidget";
import { getFilterOptionsFromCategories } from "./utils";
import { RootState } from "../../../state/store";

interface IStackedBarChartWidgetProps {}

const StackedBarChartWidget = (props: IStackedBarChartWidgetProps) => {
  const [widgetData, setWidgetData] = useState<any>({});
  const [widgetRef, contentDimensions] = useElementSize();

  const widget = useContext(WidgetContext);
  const dispatch = useDispatch();

  const { widgets: widgetFilters } = useSelector(
    (state: RootState) => state.filters
  );

  const widgetSeriesData = useMemo(() => {
    if (widgetData?.widget_data) {
      return widgetData.widget_data.data.series;
    }
    return [];
  }, [JSON.stringify(widgetData)]);

  const handleDataPointClick = () => {
    // TODO
  };

  useEffect(() => {
    if (widget.widgetData.widget_data) {
      setWidgetData(widget.widgetData);
      const widgetFilterExists = widgetFilters.find(
        (i) => i.key == widget?.widgetFilterConfig?.widgetFilterKey
      );
      if (
        widget.widgetFilterConfig.enableFilters &&
        !widgetFilterExists
      ) {
        let filterConfig = {
          name: widget.widgetFilterConfig.filterTitle,
          filterKey: widget.widgetFilterConfig.widgetFilterKey,
          options: getFilterOptionsFromCategories(
            widget.widgetData.widget_data,
            widget?.widgetData?.widget_data?.config?.filter_by
          ),
        };
        dispatch(addWidgetToFilterList(filterConfig));
      }
    }
  }, [widget.widgetData]);

  const dimensions = useMemo(() => {
    let chart = { width: 0, height: 0 };
    let hPadding = 50;
    let vPadding = 90;
    if (contentDimensions.height > 0) {
      chart.width = contentDimensions.width - hPadding;
      chart.height = contentDimensions.height - vPadding;
    }
    return { chart };
  }, [JSON.stringify(contentDimensions)]);

  return (
    <Widget ref={widgetRef}>
      <Widget.Header>
        <Widget.Title>{widgetData?.title}</Widget.Title>
        <Widget.Controls>
          {widget.widgetData.widget_data && widgetSeriesData.length > 0 ? (
            <Widget.ExtendedAReportCta />
          ) : null}
        </Widget.Controls>
      </Widget.Header>
      <Widget.Content>
        {widgetSeriesData.length > 0 ? (
          <StackedBarChart
            data={widgetData.widget_data}
            height={dimensions.chart.height}
            width={dimensions.chart.width}
            handleDataPointClick={handleDataPointClick}
            limitBar={5}
          />
        ) : (
          <WidgetEmptyData
            width={contentDimensions.width - 50}
            height={contentDimensions.height - 90}
          />
        )}
      </Widget.Content>
    </Widget>
  );
};

export default StackedBarChartWidget;
