import { removeDuplicateArrayItems } from "../../../helpers/utils";

export const getFilterOptionsFromCategories = (
  widgetData,
  filterBy = "series"
) => {
  if (filterBy === "stack") {
    return widgetData.data.series.map((d) => ({
      id: d.id,
      name: d.name,
    }));
  } else {
    return removeDuplicateArrayItems(
      widgetData.data.series
        .map((d) => d.values)
        .flat()
        .map((d) => ({ id: d.id, name: d.category }))
    );
  }
};
