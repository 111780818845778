import styled from "styled-components";

export default function Logo() {
  return (
    <LogoContainer>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1008.3 287.5"
        xmlSpace="preserve"
      >
        <g>
          <g>
            <g>
              <path
                className="st0"
                d="M372.2,238.4h-8.6l-39.6-83h11l33,69l32.8-69h11L372.2,238.4z"
              />
              <path
                className="st0"
                d="M493.8,238.4l-13.5-28.5h-40l-13.1,28.6h-10.9l39.6-83h10l38.9,83h-11V238.4z M460.7,167.3l-15.5,32.9H476
				L460.7,167.3z"
              />
              <path
                className="st0"
                d="M587.7,238.4l-54.1-67.6v67.6h-9.8v-83h9.8l54.1,68.5v-68.5h9.9v83H587.7L587.7,238.4z"
              />
              <path
                className="st0"
                d="M661.2,165.2v73.3h-9.8v-73.3h-30.3v-9.8h70.6v9.8H661.2z"
              />
              <path
                className="st0"
                d="M749.8,155.4h-10l-39.6,83h10.9l13.1-28.6h40l13.6,28.6h10.9L749.8,155.4z M729.2,200.2l15.6-32.9l15.2,32.9
				L729.2,200.2L729.2,200.2z"
              />
              <path
                className="st0"
                d="M863,238.4c-5,1.4-10.1,2.1-15.3,2.1c-25.5,0-46-17.2-46-43.5c0-26,20.9-43.3,46-43.3
				c14.8,0,25,5.2,35.3,15.5l-6.7,7c-8.8-8.1-16.6-12.7-28.9-12.7c-19.6,0-36.1,13.4-36.1,33.7c0,20.6,16.7,33.6,36.3,33.6
				c3.3,0,6.8-0.3,10.1-1c7.2-1.6,17.1-6.1,17.1-14.9v-11.7h-28.7v-9.8h38.5v22.1C884.7,227.6,873.4,235.3,863,238.4z"
              />
              <path
                className="st0"
                d="M908.6,238.4v-83h68.5v9.8h-58.6v25.3h30.8v9.8h-30.8v28.3h61v9.8L908.6,238.4L908.6,238.4z"
              />
            </g>
            <g id="The_Logo_00000060747477188631321820000009262275770520599727_">
              <g>
                <path
                  className="st1"
                  d="M509.9,86.1c0,0.3,0,0.6,0,0.9c-0.8-7.3-7.1-13.1-14.9-13.1c-2.9,0-5.5,0.8-7.8,2.1
					c-1.3,0.8-2.5,1.8-3.5,2.9c-0.1-0.7-0.2-1.4-0.4-2c-1.7-6.2-8.3-11.1-16.6-12.4c-0.2,0-0.4-0.1-0.6-0.1
					c-0.3-0.7-0.7-1.5-1.1-2.2c-5.1-9.5-14.8-15.9-26-15.9c-13.5,0-24.8,9.4-28.5,22.3c-0.1,0.5-0.3,1.1-0.4,1.6
					c-0.2,1-0.4,2.1-0.6,3.2c-5.3-3.8-11.9-6.1-19-6.1c-14.5,0-26.7,9.4-30,22.1c0.5-15.8,13.6-28.5,29.8-28.5
					c5.5,0,10.7,1.5,15.1,4c4.4-12.7,17-21.6,31.3-21.6c13.3,0,25.2,7.5,30.4,19c9.3,0.5,17,5.3,19.5,11.7c2.4-1.6,5.2-2.5,8.3-2.5
					C503.2,71.5,509.9,78,509.9,86.1z"
                />
                <g>
                  <g>
                    <path
                      className="st1"
                      d="M539.9,63.4c0-0.8-0.3-0.9-1-0.9c-1.9,0-3.8,0.1-5.7,0c-1-0.1-1.2,0.3-1.2,1.2c0,7,0,14.1,0.1,21.2
							c-0.5-0.1-0.6-0.5-0.8-0.7c-1.4-1.7-3.2-3-5.3-3.6c-7.9-2-12.9,2.7-15.3,9.2c-2,5.5-2.1,11.1-1.7,16.8
							c0.3,4.2,1.4,8.3,3.8,11.8c4.4,6.5,14.8,6.9,19.3,0.2c0.1-0.2,0.2-0.6,0.7-0.6c0.2,1.3,0.4,2.5,0.6,3.8c0.1,0.6,0.4,0.9,1,0.9
							c1.6-0.1,3.2-0.1,4.9,0c0.7,0,0.9-0.2,0.9-0.9C539.9,102.3,539.9,82.9,539.9,63.4z M532.1,94.2c-0.2,2.6,0,5.2,0,7.9
							c0,2.7,0,5.5,0,8.2c0,3-2.2,6-5.2,6.8c-3.6,1-6.7-0.3-8.4-3.7c-1.3-2.6-1.7-5.3-1.9-8.2c-0.2-4.3-0.1-8.6,1.3-12.8
							c0.4-1.3,1-2.6,1.8-3.7c2.2-2.8,6.4-3.3,9.2-1C530.9,89.4,532.2,91.4,532.1,94.2z"
                    />
                    <path
                      className="st1"
                      d="M408.9,85.9c0.4-0.5,0.6-0.8,0.8-1.1c4.6-6,13.5-6.1,18.2-0.1c2.4,3,3.6,6.5,4.2,10.3
							c0.9,5.8,1,11.7-0.5,17.4c-0.8,3-2.2,5.7-4.3,7.9c-4.4,4.6-13.5,4.6-17.5-0.7c-0.1-0.1-0.2-0.2-0.3-0.4
							c-0.4,0.4-0.2,0.7-0.2,1c0,5.9,0,11.7,0,17.6c0,0.9-0.2,1.1-1.1,1.1c-2-0.1-4-0.1-6,0c-0.7,0-0.9-0.2-0.9-0.9
							c0-18.7,0-37.4-0.1-56c0-0.7,0.2-1,0.9-0.9c1.7,0.1,3.4,0,5.1,0c0.6,0,0.8,0.1,0.9,0.7C408.4,83.1,408.7,84.4,408.9,85.9z
							 M424.9,102.8c0-0.4,0-0.9,0-1.4c-0.1-3.8-0.6-7.6-2.3-11c-1.5-3.1-3.8-4.3-7.1-3.9c-3.3,0.4-6.1,3.6-6.1,6.9
							c0,6.1,0,12.2,0,18.4c0,0.4,0,0.9,0.1,1.3c1.4,3.1,4.7,4.9,8.1,4.5c3.2-0.4,4.9-2.6,5.9-5.4
							C424.7,109.2,424.9,106,424.9,102.8z"
                    />
                    <path
                      className="st1"
                      d="M355.1,83c-0.9,0-1.9-0.1-2.8,0c-0.8,0.1-1-0.2-1-1c-0.1-2.7-0.9-5.1-2.7-7.1c-2.6-2.9-6.9-3.8-10.6-2.2
							c-3.2,1.3-5.2,5-4.9,9c0.2,2.8,1.4,4.9,3.7,6.5c2.3,1.6,4.9,2.7,7.6,3.7c3.9,1.3,7.5,3.1,10.6,5.8c6.3,5.5,7,19.6-4,24.4
							c-6.4,2.8-16.1,2.5-22.4-3.4c-3.3-3.1-4.5-7.1-4.5-11.5c0-0.6,0.3-0.7,0.8-0.7c2.1,0,4.1,0,6.2,0c0.6,0,0.8,0.2,0.8,0.8
							c0,1.1,0.1,2.1,0.4,3.1c1.3,5,6.1,8.1,11.5,7.3c4-0.6,6.6-2.7,7.5-6.2c1.1-4.1-0.2-8-3.3-10.4c-2-1.5-4.2-2.5-6.5-3.2
							c-3.4-1.1-6.7-2.5-9.7-4.5c-5.8-3.9-7.5-9.4-6.1-15.9c1.4-6.2,5.7-9.8,11.7-11.1c5.5-1.2,10.8-0.7,15.5,2.8
							c4.2,3.2,6.2,7.6,6.2,12.9c0,0.6-0.2,0.9-0.8,0.9C357.2,82.9,356.1,83,355.1,83z"
                    />
                    <path
                      className="st1"
                      d="M504.1,122.8c-2.5,0-5,0-7.4,0c-0.6,0-0.6-0.3-0.8-0.8c-0.4-1.7-0.8-3.4-0.9-5.1c-0.5,0-0.5,0.5-0.7,0.8
							c-2,2.8-4.3,5.1-7.8,5.7c-7.6,1.2-12.8-2.8-13.6-10.2c-0.4-3.9,0.2-7.5,3.1-10.4c2.4-2.5,5.5-3.6,8.9-4.1c3-0.4,6-0.2,9-0.2
							c0.7,0,1-0.1,0.9-0.9c-0.1-2.1,0.2-4.3-0.3-6.4c-0.7-3.1-2.8-4.9-6-5c-2.9-0.1-5.2,1.5-6.1,4.2c-0.1,0.2-0.1,0.4-0.1,0.6
							c-0.4,1.8-0.4,1.8-2.1,1.8c-1.6,0-3.2,0-4.8,0c-0.7,0-0.9-0.2-0.9-0.9c0.1-3.5,1.7-6.2,4.3-8.4c5.3-4.5,14.5-4.5,19.8-0.1
							c2.7,2.3,3.9,5.3,4.2,8.7c0.4,5.4,0.1,10.7,0.2,16.1c0.1,3.8-0.2,7.7,0.5,11.5C503.6,120.7,503.9,121.7,504.1,122.8z
							 M490.9,103.4L490.9,103.4c-1.4,0.1-2.7-0.1-4,0.1c-4.7,0.8-7.3,5.9-5.6,10.6c0.8,2.2,2.5,3.4,4.9,3.4c3.7,0,7.1-2.4,8.5-5.8
							c0.1-0.3,0.3-0.6,0.3-1c0-2.2,0-4.4,0-6.7c0-0.5-0.1-0.6-0.6-0.6C493.1,103.4,492,103.4,490.9,103.4z"
                    />
                    <path
                      className="st1"
                      d="M386.3,116.7c-1,1.7-2,3-3.2,4c-3.7,3.4-7.9,3.7-12.3,2.1c-4.2-1.5-6-5.1-6.5-9.3
							c-0.4-3.3-0.1-6.6,2.1-9.5c2.5-3.3,5.9-4.7,9.9-5.2c3-0.4,6-0.2,9-0.2c0.8,0,1.1-0.1,1-1c-0.1-2.1,0.2-4.3-0.3-6.4
							c-0.8-3.3-3.1-5.1-6.5-4.9c-3.2,0.1-5.3,2.1-5.8,5.5c-0.1,0.8-0.3,1.2-1.2,1.1c-1.9-0.1-3.8,0-5.7,0c-0.8,0-0.8-0.5-0.8-1
							c0.1-3.5,1.7-6.2,4.3-8.3c4.8-3.8,10.3-4.2,15.9-2.2c3.9,1.4,6.4,4.2,7.5,8.2c0.4,1.7,0.6,3.3,0.6,5c0,7,0,14.1,0.1,21.1
							c0,2.2,0.4,4.3,1,6.3c0.2,0.7,0.1,0.9-0.6,0.9c-2.2,0-4.3,0-6.4,0c-0.6,0-0.8-0.2-1-0.8C386.8,120.4,386.4,118.8,386.3,116.7z
							 M382.1,103.5C382.1,103.5,382.1,103.6,382.1,103.5c-1.3,0.1-2.7-0.1-3.9,0.1c-4.6,0.7-7.3,5.7-5.7,10.4
							c0.9,2.5,2.7,3.7,5.3,3.5c3.6-0.2,6.1-2.1,7.8-5.1c0.3-0.6,0.6-1.2,0.6-1.8c0-2.2,0-4.3,0-6.4c0-0.5-0.2-0.7-0.7-0.7
							C384.3,103.5,383.2,103.5,382.1,103.5z"
                    />
                    <path
                      className="st1"
                      d="M468.5,122.8c-2.6,0-5.1,0-7.5,0c-0.6,0-0.6-0.4-0.6-0.7c-0.5-1.7-0.7-3.4-1-5.3c-1,1.4-1.8,2.6-2.9,3.6
							c-3.6,3.5-7.8,3.9-12.2,2.4c-4.3-1.4-6.2-4.9-6.8-9.1c-0.3-1.8-0.3-3.7,0.1-5.5c1.1-5,4.6-7.7,9.3-9.1
							c3.2-0.9,6.5-0.7,9.9-0.7c2.6,0,2.6,0,2.6-2.6c0-1.7,0.1-3.4-0.4-5c-0.9-3.2-3.4-4.9-6.8-4.6c-3,0.3-5.1,2.4-5.4,5.7
							c-0.1,0.6-0.2,0.9-0.9,0.9c-2,0-4,0-6,0.1c-0.7,0-0.8-0.3-0.8-0.9c0-3.2,1.4-5.8,3.7-7.9c5.6-5.4,16.5-5.1,21.5,0.7
							c2.3,2.7,3,5.8,3,9.2c0,3-0.1,6,0,9C467.3,109.6,466.5,116.2,468.5,122.8z M455.1,103.4L455.1,103.4c-1.4,0.1-2.7-0.1-4,0.1
							c-5.3,0.9-7.3,6.9-5.3,11.1c0.9,2,2.6,2.9,4.8,2.8c2.5-0.1,4.6-1,6.3-2.8c1.1-1.2,2.2-2.5,2.3-4.2c0.1-2.1,0-4.3,0.1-6.4
							c0-0.6-0.2-0.7-0.8-0.7C457.3,103.4,456.2,103.4,455.1,103.4z"
                    />
                  </g>
                </g>
              </g>
              <g>
                <path
                  className="st2"
                  d="M549.8,119.3c0,0.5-0.1,0.9-0.2,1.3c-0.2,0.4-0.4,0.8-0.7,1c-0.3,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.3,0.3
					s-0.9-0.1-1.3-0.3c-0.4-0.2-0.7-0.4-1-0.7s-0.5-0.7-0.7-1.1s-0.3-0.8-0.3-1.3s0.1-0.9,0.3-1.3s0.4-0.8,0.7-1
					c0.3-0.3,0.6-0.5,1-0.7s0.8-0.3,1.3-0.3s0.9,0.1,1.3,0.3c0.4,0.2,0.7,0.4,1,0.7c0.3,0.3,0.5,0.6,0.7,1
					C549.7,118.4,549.8,118.9,549.8,119.3z M549.2,119.3c0-0.4-0.1-0.7-0.2-1.1c-0.1-0.3-0.3-0.6-0.6-0.9c-0.2-0.3-0.5-0.5-0.8-0.6
					s-0.7-0.2-1.1-0.2c-0.4,0-0.7,0.1-1,0.2s-0.6,0.4-0.8,0.6c-0.2,0.3-0.4,0.6-0.6,0.9c-0.1,0.3-0.2,0.7-0.2,1.1s0.1,0.7,0.2,1.1
					c0.1,0.3,0.3,0.6,0.6,0.9c0.2,0.3,0.5,0.5,0.8,0.6c0.3,0.2,0.7,0.2,1.1,0.2c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.6-0.4,0.8-0.6
					s0.4-0.6,0.6-0.9C549.1,120.1,549.2,119.7,549.2,119.3z M547.4,121.2c-0.1-0.1-0.2-0.4-0.3-1c-0.1-0.4-0.3-0.6-0.8-0.6h-0.4v1.6
					h-0.6v-3.7c0.4-0.1,0.8-0.1,1-0.1c0.5,0,0.8,0.1,1.1,0.2c0.3,0.2,0.4,0.4,0.4,0.8c0,0.5-0.2,0.7-0.7,0.9l0,0
					c0.2,0,0.3,0.1,0.4,0.3c0.1,0.2,0.2,0.3,0.2,0.5c0.1,0.2,0.1,0.4,0.1,0.6s0.1,0.3,0.1,0.4L547.4,121.2L547.4,121.2z
					 M547.2,118.6c0-0.5-0.3-0.7-0.9-0.7c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0v1.3h0.4C546.9,119.2,547.2,119,547.2,118.6z"
                />
              </g>
            </g>
          </g>
          <polygon
            className="st3"
            points="754.2,196.3 734.8,196.3 744.6,175.6 	"
          />
          <polygon
            className="st3"
            points="754.2,196.3 734.8,196.3 744.6,175.6 	"
          />
          <g>
            <path
              className="st4"
              d="M81.6,259.3l-45.1-38.7c-13.2-11.4-5.2-37.1-1.4-47.5L98,47c8.9-16.2,18.1-28.4,33.2-20.4l48.1,38.7
			c9.1,13.1,7.3,31.8-1.8,50.8l-58.8,117.3C114.6,246.5,95.2,269.5,81.6,259.3z"
            />
            <path
              className="st5"
              d="M135.3,260.9l-45.1-38.7c-13.2-11.4-5.2-37.1-1.4-47.5l62.8-126.1c8.9-16.2,18.1-28.4,33.2-20.4L233,66.9
			c9.1,13.1,7.3,31.8-1.8,50.8L172.4,235C168.3,248.1,148.9,271.1,135.3,260.9z"
            />
            <path
              className="st6"
              d="M189.8,259.3l-45.1-38.7c-13.2-11.4-5.2-37.1-1.4-47.5L206.1,47c8.9-16.2,18.1-28.4,33.2-20.4l48.1,38.7
			c9.1,13.1,7.3,31.8-1.8,50.8l-58.8,117.3C222.7,246.5,203.3,269.4,189.8,259.3z"
            />
          </g>
        </g>
      </svg>
    </LogoContainer>
  );
}

const LogoContainer = styled.div`
  width: 100%;
  svg {
    width: 147px;
    enable-background: new 0 0 1008.3 287.5;
    .st0 {
      fill: #e3eaf3;
    }
    .st1 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #b5bec6;
    }
    .st2 {
      fill: #b5bec6;
    }
    .st3 {
      fill: #68b42e;
    }
    .st4 {
      fill: #57646d;
    }
    .st5 {
      fill: #929ea7;
    }
    .st6 {
      fill: #c7ced6;
    }
  }
`;
