import styled, { keyframes } from "styled-components";
import { useFetchBSAiSuggestions } from "../../hooks/useFetchBSAiSuggestions";
import RenderMarkdownContent from "../renderMarkdownContent";

const BSAiCommentaryView = () => {
  const { data, isError, isLoading, retry } = useFetchBSAiSuggestions();

  return (
    <Wrapper>
      {isLoading ? (
        <div className="loading-section">
          <span className="banner-title">
            Analyzing<span className="dots"></span>
          </span>
          <span className="info">
            Please wait while we generate your report
          </span>
        </div>
      ) : isError ? (
        <div className="loading-section">
          <span className="banner-title">Request Failed</span>
          <span className="info">Please try again later</span>
        </div>
      ) : data?.result ? (
        <div className="result-section">
          <span className="banner-title">Analysis</span>
          <div className="result">
            <RenderMarkdownContent content={data?.result} />
          </div>
        </div>
      ) : null}
    </Wrapper>
  );
};

const dotAnimation = keyframes`
  to {
    clip-path: inset(0 -34% 0 0)
  }
`;

const Wrapper = styled.div`
  .section {
    transform: translate(0px, 50%);
  }
  .loading-section {
    transform: translate(0px, 20%);
    .dots {
      transform: translate(3px, 6px);
      width: 16px;
      aspect-ratio: 4;
      background: radial-gradient(circle closest-side, #ffffff 90%, #0000) 0 /
        calc(100% / 3) 100% space;
      clip-path: inset(0 100% 0 0);
      animation: ${dotAnimation} 1s steps(4) infinite;
    }
  }
  .section,
  .loading-section {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    flex-direction: column;
    .banner-title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
      .cta {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .info {
      color: rgb(255 255 255 / 68%);
      font-size: 14px;
    }
  }
  .result-section {
    transition: all 0.4s linear 0s;
    padding: 40px;
    .banner-title {
      font-size: 30px;
      font-weight: 700;
      line-height: 39px;
      text-align: left;
      color: #fff;
    }
    .result {
      font-size: 17px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: rgb(255, 255, 255);
    }
  }
`;

export default BSAiCommentaryView;
