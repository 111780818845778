import { EChartsOption } from "echarts";
import { useEffect, useState } from "react";
import { numFormatter } from "../../helpers/utils";
import BaseChart from "../baseChart";

interface IComparisonBarChartProps {
  chartData: any;
  width: number;
}

const ComparisonBarChart = (props: IComparisonBarChartProps) => {
  const [option, setOption] = useState<EChartsOption>({
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: {},
    yAxis: {},
    series: [{ type: "bar", data: [1, 2, 3, 4, 5] }],
  });

  useEffect(() => {
    if (props.chartData.data) {
      setOption((data) => {
        return {
          ...data,
          grid: {
            left: props.width < 650 ? "20%" : "10%",
            right: "5%",
          },
          yAxis: [
            {
              type: "value",
              name: props.chartData.config.bar.name,
              axisPointer: {
                type: "line",
                show: true,
                triggerTooltip: false,
                label: {
                  show: true
                }
              },
              axisLabel: {
                formatter: function (value, index) {
                  return `${numFormatter(value)} ${
                    props.chartData.config.bar.value_formatter
                  }`;
                },
              },
              axisLine: {
                lineStyle: {
                  color: "#58585E",
                },
              },
            },
            {
              type: "value",
              name: props.chartData.config.line.name,
              axisPointer: {
                type: "line",
                show: true,
                triggerTooltip: false,
                label: {
                  show: true
                }
              },    
              axisLabel: {
                formatter: function (value, index) {
                  return `${numFormatter(value)} ${
                    props.chartData.config.bar.value_formatter
                  }`;
                },
              },
              axisLine: {
                lineStyle: {
                  color: "#3ECD89",
                },
              },
            },
          ],
          xAxis: [
            {
              type: "category",
              data: props.chartData.data.series.map((d) => d.category),
              axisPointer: {
                type: "shadow",
                show: true,
                label: {
                  show: true
                }
              }
            },
          ],
          series: [
            {
              name: props.chartData.config.bar.name,
              type: "bar",
              itemStyle: {
                color: "#58585E",
              },
              tooltip: {
                formatter: "{b0}",
                valueFormatter: function (value) {
                  return `${formatNumber(value)} ${
                    props.chartData.config.bar.value_formatter
                  }`;
                },
              },
              data: props.chartData.data.series.map((d) => d.bar_value),
            },
            {
              name: props.chartData.config.line.name,
              type: "line",
              itemStyle: {
                color: "#3ECD89",
              },
              yAxisIndex: 1,
              tooltip: props.chartData.config.line.name ? {
                formatter: "{b1}",
                valueFormatter: function (value) {
                  return `${formatNumber(value)} ${
                    props.chartData.config.line.value_formatter
                  }`;
                },
              } : {},
              data: props.chartData.config.line.name
              ? props.chartData.data.series.map((d) => d.line_value)
              : [],
            },
          ],
        };
      });
    }
  }, [props.chartData, props.width]);
  const handleClickEvent = () => {};
  return (
    <BaseChart
      height={500}
      width={props.width}
      options={option}
      chartType="bar"
      onClick={handleClickEvent}
    />
  );
};

export default ComparisonBarChart;
