import styled from "styled-components";
import { useBSWidgetContext } from "../../hooks/useBSWidgetContext";
import DataGridView from "../dataGridView";
import BSWidgetDataLoading from "../bsWidgetDataLoading";
import BSWidgetDataFetchError from "../bsWidgetDataFetchErr";

const BSTableWidget = () => {
  const { widgetData, dataFetchError, isFetchingData, reload } =
    useBSWidgetContext();

  return (
    <Wrapper>
      {isFetchingData ? (
        <BSWidgetDataLoading />
      ) : dataFetchError ? (
        <BSWidgetDataFetchError reload={reload} />
      ) : widgetData ? (
        <DataGridView data={widgetData.widget} />
      ) : null}
    </Wrapper>
  );
};

export default BSTableWidget;

const Wrapper = styled.div`
  border-radius: 25px !important;
  background-color: ${({ theme }) => theme.body};
  width: 100%;
  height: 100%;
  justify-content: center;
  overflow-y: scroll;
`;
