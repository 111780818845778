import styled from "styled-components";
import OrderTimeline from "./orderTimeline";
import { useState } from "react";
import Order from "./order";
import History from "./history";
import Modification from "./modification";

export default function OrderDetails() {
  const [page, setPage] = useState("order");

  return (
    <Container>
      <div className="header">
        <span
          className={`order ${page == "order" ? "selected" : ""}`}
          onClick={() => setPage("order")}
        >
          Order
        </span>
        <span
          className={`history ${page == "history" ? "selected" : ""}`}
          onClick={() => setPage("history")}
        >
          History
        </span>
      </div>
      {page == "order" && (
        <div className="body">
          <Order />
        </div>
      )}
      {page == "history" && (
        <div className="history-body">
          <div className="body">
            <History />
            <Modification />
          </div>
          <OrderTimeline />
        </div>
      )}
    </Container>
  );
}

const Container = styled.div`
  color: ${({ theme }) => theme.text};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.body};
  padding: 0.5rem;
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    span {
      padding: 0.5rem 1.5rem;
      background: transparent;
      border-radius: 10px 10px 0 0;
      color: #1a1f28;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      line-height: normal;
      min-width: 100px;
      cursor: pointer;
      color: ${({ theme }) => theme.text};
    }
    .selected {
      background: ${({ theme }) => theme.widget.background};
      border: 1px solid #ede7e7;
      border-bottom: 0;
    }
  }
  .history-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
`;
