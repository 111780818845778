import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { EChartsOption } from "echarts";
import BaseChart from "../../baseChart";
import { numFormatter } from "../../../helpers/utils";
import { convertTimestampToTimeZone } from "./utils";

export type MultiSeriesLineChartConfiguration = {
  config: {
    selector: string;
    value_formatter: { prefix: string; postfix: string };
  };
  data: {
    series: {
      name: string;
      series_data: { id: number; category: string; value: number }[];
    }[];
  };
};

interface IMultiSeriesLineChartProps {
  width: number;
  height: number;
  data: MultiSeriesLineChartConfiguration;
}

const MultiSeriesLineChart = ({
  data,
  width,
  height,
}: IMultiSeriesLineChartProps) => {
  const theme = useSelector((state: RootState) => state.theme);

  const option = useMemo<EChartsOption>(() => {
    let updatedOption: EChartsOption = {};
    if (data) {
      updatedOption.legend = {
        show: true,
        top: "center",
        right: "10px",
        type: "scroll",
        orient: "vertical",
        data: data.data.series.map((s) => s.name),
      };
      if (width < 650) {
        updatedOption.legend = {
          ...updatedOption.legend,
          top: "10px",
          right: 0,
          type: "scroll",
          orient: "horizontal",
        };
      } else {
        updatedOption.grid = {
          right: "160px",
        };
      }
      updatedOption.xAxis = {
        type: "category",
        data: data.data?.series[0]?.series_data?.map((sd) => {
          if (sd.offset) {
            return convertTimestampToTimeZone(
              sd.category,
              sd.offset,
              "yyyy-MM-dd hh:mm:ss a"
            );
          }
          return sd.category;
        }) ?? [],
        axisPointer: {
          show: true,
          type: "shadow",
        },
      };
      updatedOption.yAxis = {
        type: "value",
        axisLabel: {
          formatter: (value) =>
            `${numFormatter(value)} ${data.config.value_formatter.postfix}`,
        },
        axisPointer: {
          show: true,
          type: "line",
        },
      };
      updatedOption.dataZoom = {
        type: "slider",
        height: 10,
        startValue: 0,
        endValue: 9,
        backgroundColor: "#F1EDED",
        fillerColor: "#717171",
        brushSelect: false,
        moveHandleSize: 0,
        moveHandleIcon: "diamond",
        handleIcon: "circle",
        handleSize: 12,
      };
      updatedOption.tooltip = {
        trigger: "axis",
        valueFormatter: (value) => `${value} ${data.config.value_formatter.postfix}`,
        axisPointer: {
          show: true,
          type: "cross",
        },
      };
      updatedOption.series = data.data.series.map((sd) => ({
        name: sd.name,
        type: "line",
        data: sd.series_data.map((d) => d.value),
        selectedMode: "multiple",
      }));
    }
    return updatedOption;
  }, [data, width]);

  const handleClickEvent = (e) => {
    // TODO
  };

  return (
    <BaseChart
      height={height + 20}
      width={width}
      options={option}
      echartsTheme={theme.mode}
      chartType="line"
      onClick={handleClickEvent}
    />
  );
};

export default MultiSeriesLineChart;
