import useSWR from "swr";
import offThreadQueryResponse from "../middleware/offThreadQueryResponse";
import { configureUrlWithParams } from "../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";

const useFetchAnalyticsFilterData = ({
  dashboard,
  widget,
  filterUrl,
  filters,
  shouldFetchFilterData,
}) => {
  const { company } = useSelector((state: RootState) => state);
  const timezoneOffset = company?.company_details?.time_zone_offset;
  const { data, error, isLoading } = useSWR(
    filterUrl && shouldFetchFilterData
      ? configureUrlWithParams(
          `/api/v1/dar/${dashboard}/${widget}/dropdown-filters/${filterUrl}`,
          {
            ...filters,
            shouldUseEpocForTimeRange: company.shouldUseEpocForTimeRange,
            timezoneOffset,
          }
        )
      : null,
    { use: [offThreadQueryResponse], revalidateOnFocus: false }
  );
  return { data, error, isLoading };
};

export { useFetchAnalyticsFilterData };
