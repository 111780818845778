import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";

const StockMovementsIcon = () => {
  const currentTheme = useSelector((state: RootState) => state.theme.mode);
  const fillColor = currentTheme == "light" ? "#1D1D1B" : "#ffffff";
  return (
    <svg
      width="145"
      height="110"
      viewBox="0 0 145 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5305_2812)">
        <path
          d="M123.223 43.7723V36.5808C123.223 32.293 120.749 30.5139 115.451 28.8176L78.5677 16.9213C77.5637 16.9213 76.6035 17.1118 75.7231 17.4586C72.8378 18.5926 70.7954 21.3996 70.7954 24.6829V85.3171C70.7954 88.602 72.8378 91.4089 75.7231 92.543C76.6035 92.8897 77.5637 93.0803 78.5677 93.0803L115.451 81.1824C120.438 79.533 123.223 77.707 123.223 73.4208V62.4071L131.115 59.886V81.6058C131.115 87.7976 127.092 90.4342 119.889 92.8179L66.6168 110C66.2603 110 65.9068 109.983 65.5581 109.95C65.2093 109.983 64.8559 110 64.4978 110L11.2253 92.8179C4.02222 90.4342 0 87.7976 0 81.6058V28.3958C0 22.2024 3.57339 19.6329 11.2253 17.1837L22.1566 13.6582V40.0797C22.1566 43.5583 22.926 45.6295 25.3625 46.3793L38.3753 47.7476C41.5812 48.149 43.7815 45.8169 43.7815 40.9341V6.68229L64.4978 0C64.8559 0 65.2093 0.0171821 65.5581 0.0499844C65.9068 0.0171821 66.2603 0 66.6168 0L119.889 17.1837C127.541 19.6329 131.115 22.2024 131.115 28.3958V45.8513L123.223 43.7723Z"
          fill={fillColor}
        />
        <path
          d="M136.017 62.255V57.3972C136.017 57.1926 135.828 57.041 135.628 57.0848L134.263 57.3831L131.115 58.0704L123.223 59.7964L120.099 60.479L101.963 64.9807C99.2027 65.5837 96.5911 63.4843 96.5911 60.6633V45.1073C96.5911 42.2582 99.2512 40.1541 102.029 40.807L120.817 45.6868L123.223 46.2538L131.115 48.1095L134.263 48.8514L135.625 49.1716C135.825 49.2185 136.017 49.067 136.017 48.8608V43.8717C136.017 43.5312 136.436 43.3672 136.668 43.6171L144.876 52.4909C145.039 52.6674 145.044 52.9376 144.884 53.1188L136.658 62.494C136.436 62.7471 136.019 62.5909 136.019 62.2535L136.017 62.255Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_5305_2812">
          <rect width="145" height="110" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { StockMovementsIcon };
