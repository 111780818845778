import { ExtendedAContext } from "../../contexts/extendedAContext";
import { useExtendedAViews } from "../../hooks/useExtendedAViews";
import { useParams } from "react-router-dom";
import { useFetchExtendedAData } from "../../hooks/useFetchExtendedAData";
import { RootState } from "../../../../state/store";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import type { PivotViewComponent } from "@syncfusion/ej2-react-pivotview";
import ExtendedAReportDialog from "../reportDialog";

const ExtendedAProvider = ({ children }) => {
  const [showExtendedAReport, setShowExtendedAReport] = useState(false);
  const moreFilters = useRef([]);
  const [widgetUrl, setWidgetUrl] = useState("");
  const { filters } = useSelector((state: RootState) => state);
  const routeMatch = useParams();
  const theme = useSelector((state: RootState) => state.theme.mode);

  const pivotTableInstance = useRef<PivotViewComponent | null>(null);
  const pivotTableRef = (refProps) => {
    pivotTableInstance.current = refProps;
  };
  const getPivotTableInstance = () => {
    return pivotTableInstance.current;
  };

  useEffect(() => {
    let lightThemeCssElem = document.getElementById(
      "syncfusion-light-theme"
    ) as HTMLLinkElement;
    let darkThemeCssElem = document.getElementById(
      "syncfusion-dark-theme"
    ) as HTMLLinkElement;

    if (lightThemeCssElem && darkThemeCssElem) {
      const isLightTheme = theme == "light";
      lightThemeCssElem!.disabled = !isLightTheme;
      darkThemeCssElem!.disabled = isLightTheme;
    }
  }, [theme]);

  const views = useExtendedAViews({
    dashboard: routeMatch.dashboard,
    dashboardWidget: routeMatch.widget,
    widgetUrl,
    onViewSaveSuccess: () => {
      views.fetchViews();
      views.hideSaveCurrentView();
    },
    onViewUpdateSuccess: () => {
      views.fetchViews();
      views.hideSavedViews();
    },
    onViewDeleteSuccess: () => {
      views.fetchViews();
    },
  });

  const {
    data,
    isLoading,
    error,
    retry,
    downloadProgress: reportDownloadProgress,
  } = useFetchExtendedAData({
    dashboard: routeMatch.dashboard,
    dashboardWidget: routeMatch.widget,
    widgetUrl,
    additionalFilters: moreFilters.current,
    filters,
    shouldFetch: showExtendedAReport && widgetUrl,
  });

  useEffect(() => {
    if (widgetUrl) {
      setShowExtendedAReport(true);
    } else {
      setShowExtendedAReport(false);
    }
  }, [widgetUrl]);

  useEffect(() => {
    if (showExtendedAReport) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [showExtendedAReport]);

  const handleShowExtendedAReport = ({ widgetUrl, addFilters }) => {
    moreFilters.current = addFilters;
    setWidgetUrl(widgetUrl);
  };

  const hideReport = () => {
    setWidgetUrl("");
  };

  const reloadData = () => {
    retry(undefined, { revalidate: true });
  };

  const getCurrentViewSnapshot = () => {
    try {
      if (pivotTableInstance.current) {
        let tableSnapshot = JSON.parse(
          pivotTableInstance.current?.getPersistData()
        );
        let viewData = views.removeDataSourceFromSnapshot(tableSnapshot);
        return viewData;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const loadView = (snapShotData) => {
    /* merge current extended-a data into snapshotData
      (snapshot data does not contain raw data) */
    const updatedSnapshot = views.mergeDataSourceToViewData(
      data?.extended_data?.data,
      snapShotData
    );
    // Apply the snapshot to pivot table instance
    if (pivotTableInstance.current) {
      try {
        pivotTableInstance.current.loadPersistData(
          JSON.stringify(updatedSnapshot)
        );
        // table view or chart view
        pivotTableInstance.current.currentView =
          updatedSnapshot.displayOption.primary;
      } catch (e) {
        console.error(e);
      }
    }
  };

  const saveCurrentView = ({ name }) => {
    const snapshot = getCurrentViewSnapshot();
    if (snapshot) {
      views.saveNewView({ name, snapshot });
    }
  };

  return (
    <ExtendedAContext.Provider
      value={{
        views,
        showReport: showExtendedAReport,
        hideReport,
        extendedAData: data,
        isFetchingReportData: isLoading,
        isFetchReportError: Boolean(error),
        externalLinkChunkError:
          Boolean(error) &&
          error.message ===
            "External link with multiple chunks is not supported",
        refetchReportData: reloadData,
        pivotTableRef,
        getPivotTableInstance,
        saveCurrentView,
        loadView,
        getCurrentViewSnapshot,
        handleShowExtendedAReport,
        reportDownloadProgress,
      }}
    >
      <ExtendedAReportDialog />
      {children}
    </ExtendedAContext.Provider>
  );
};

export default ExtendedAProvider;
