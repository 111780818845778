import styled from "styled-components"
import DatePicker from "./datePicker"

const DateTimePickerSection = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  startHour,
  setStartHour,
  startMinute,
  setStartMinute,
  endHour,
  setEndHour,
  endMinute,
  setEndMinute,
}) => {
  return (
    <Wrapper>
      <DatePicker
        key="start"
        pickType="start"
        currDate={startDate}
        setDate={setStartDate}
        hour={startHour}
        setHour={setStartHour}
        minute={startMinute}
        setMinute={setStartMinute}
      />
      <DatePicker
        key="end"
        pickType="end"
        currDate={endDate}
        setDate={setEndDate}
        hour={endHour}
        setHour={setEndHour}
        minute={endMinute}
        setMinute={setEndMinute}
      />
    </Wrapper>
  );
}

export default DateTimePickerSection

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px; /* Adjust the gap between the grid items as needed */
  padding: 16px; /* Adjust the padding as needed */
`;