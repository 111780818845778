import styled from "styled-components";
import monitorIcon from "../../images/icons/monitor.svg";
import { ErrorBoundary } from "react-error-boundary";
import { useState } from "react";
import { reportExceptionToSentry } from "../../services/sentry/utils";

const FallbackUiOnError = ({ error, resetErrorBoundary, type, url }) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const toggleErrorDescription = () => {
    setShowErrorMessage((toggleShow) => !toggleShow);
  };

  return (
    <WidgetErrorWrapper>
      <div className="container">
        <div className="icon">
          <img src={monitorIcon} alt="monitor-disconnected" />
        </div>
        <div className="text-area" onClick={toggleErrorDescription}>
          <p className="text">Widget Error</p>
          <p className="text">Please try again.</p>
          <p className="text">URL: {url}</p>
          <p className="text">TYPE: {type}</p>
        </div>
        {showErrorMessage && (
          <div className="error-description" onClick={toggleErrorDescription}>
            <code>{error.message}</code>
          </div>
        )}
        <div>
          <span className="link-text" onClick={resetErrorBoundary}>
            Reload Widget
          </span>
        </div>
      </div>
    </WidgetErrorWrapper>
  );
};

/**
 * Catches all JS errors inside the widget and show a fallback UI.
 */
const WidgetErrorBoundary = ({ children, url, type }) => {
  const handleOnExceptionCaught = (error: Error) => {
    reportExceptionToSentry({
      title: "Error rendering Analytics widget",
      message: `${error.name}: ${error.message}`,
      tags: { url },
      stack: error.stack,
    });
  };

  return (
    <ErrorBoundary
      onError={handleOnExceptionCaught}
      FallbackComponent={({ error, resetErrorBoundary }) => (
        <FallbackUiOnError
          error={error}
          resetErrorBoundary={resetErrorBoundary}
          url={url}
          type={type}
        />
      )}
      onReset={() => {}}>
      {children}
    </ErrorBoundary>
  );
};

export default WidgetErrorBoundary;

const WidgetErrorWrapper = styled.div`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.body.background};
  border: 1px solid #f1eded;
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 100%;
  padding: 3em;
  .container {
    text-align: center;
    .text-area {
      margin-top: 15px;
      .text {
        margin: 0;
        font-size: 18px;
        color: ${({ theme }) => theme.text};
        line-height: 23px;
      }
    }
    .error-description {
      text-align: left;
      font-size: 15px;
      max-height: 200px;
    }
    .link-text {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      align-items: center;
      text-align: center;
      cursor: pointer;
      color: #2b7bc5;
    }
  }
`;
