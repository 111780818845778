import { useEffect, useState } from "react";

type Params = {
  startLoadingOnMount: boolean;
  duration: number;
};

const useSimulateLoader = ({
  startLoadingOnMount = true,
  duration = 3,
}: Params) => {
  const [startLoader, setStartLoader] = useState(startLoadingOnMount);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let timer;
    if (startLoader) {
      setIsLoaded(false);
      setIsLoading(true);
      timer = setTimeout(() => {
        setIsLoaded(true);
        setIsLoading(false);
        setStartLoader(false);
      }, duration * 1000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [startLoader]);

  const reload = () => {
    setStartLoader(true);
  };

  return { isLoaded, isLoading, reload };
};

export { useSimulateLoader };
