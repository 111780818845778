import { GiEmptyHourglass } from "react-icons/gi";
import styled from "styled-components";

const EmptyStateFallback = () => {
  return (
    <Wrapper>
      <div className="icon">
        <GiEmptyHourglass />
      </div>
      <div className="label">Nothing here yet</div>
    </Wrapper>
  );
};

export default EmptyStateFallback;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  color: #9fbba4;
  .icon {
    font-size: 26px;
  }
  .label {
    font-size: 16px;
  }
`;
