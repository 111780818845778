import styled from "styled-components";

export default function Resizer() {
  return (
    <ResizeWrapper>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1417.3 1417.3"
        xmlSpace="preserve"
      >
        <path
          className="st0"
          d="M1111.1,5.1c0,149.4-29.2,294.2-86.8,430.4c-27.7,65.5-62,128.6-101.9,187.7c-39.6,58.6-85.1,113.8-135.2,163.9
	s-105.3,95.6-163.9,135.2c-59.1,39.9-122.3,74.2-187.7,101.9c-136.2,57.6-281,86.8-430.4,86.8v301.1c777.1,0,1407-630,1407-1407
	H1111.1z"
        />
      </svg>
    </ResizeWrapper>
  );
}

const ResizeWrapper = styled.div`
  position: absolute;
  right: 5px;
  bottom: 0;
  width: 15px;
  svg {
    enable-background: new 0 0 1417.3 1417.3;
    .st0 {
      opacity: 0.5;
      fill: #8c8c8c;
    }
  }
`;
