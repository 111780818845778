import { AnyAction, configureStore } from "@reduxjs/toolkit";
import { loadState, saveState } from "../helpers/localStorage";
import * as reducers from "./features";
import thunk from "redux-thunk";

const persistedState = loadState();

export const store = configureStore({
  preloadedState: persistedState,
  reducer: reducers,
  middleware: [thunk],
});

store.subscribe(() => {
  saveState({
    theme: store.getState().theme,
  });
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type CustomDispatch = (dispatch: any) => Promise<any> | AnyAction;
export const dispatchAction: AppDispatch = store.dispatch.bind(this);
