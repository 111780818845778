import { createReducer } from "@reduxjs/toolkit";
import * as actions from "./actions";

const initialState = {
  filterList: [] as any[],
  focusFilter: {
    location: "",
    widget: "",
  },
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(actions.addWidgetToFilterList, (state, action) => {
    const filterItemIndex = state.filterList.findIndex(
      (d) => action.payload.filterKey === d.filterKey
    );
    if (filterItemIndex !== -1) {
      state.filterList[filterItemIndex] = action.payload;
    } else {
      state.filterList.push(action.payload);
    }
  });
  builder.addCase(actions.clearFilterList, (state, action) => {
    state.filterList = [];
  });
  builder.addCase(actions.focusSecondaryFilter, (state, action) => {
    state.focusFilter.widget = action.payload;
  });
  builder.addCase(actions.clearSecondaryFilterFocus, (state, action) => {
    state.focusFilter.widget = "";
  });
  builder.addCase(actions.focusLocationFilter, (state, action) => {
    state.focusFilter.location = action.payload;
  });
  builder.addCase(actions.clearLocationFilterFocus, (state, actions) => {
    state.focusFilter.location = "";
  });
});
