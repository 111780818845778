import { format } from "date-fns";
import { removeDuplicateArrayItems } from "../../../helpers/utils";

function convertTimestampToTimeZone(
  timestampS,
  timeZoneOffset,
  formatString = "yyyy-MM-dd HH:mm:ss"
) {
  const [, sign, offsetHours, offsetMinutes] = timeZoneOffset.match(
    /([+-])(\d{2}):(\d{2})/
  );
  const totalOffsetInSeconds =
    (parseInt(offsetHours, 10) * 60 * 60 + parseInt(offsetMinutes, 10) * 60) *
    (sign === "-" ? -1 : 1);
  const timestampTZ = parseInt(timestampS) + totalOffsetInSeconds;
  const absoluteDate = new Date(timestampTZ * 1000);
  const weekDay = absoluteDate.getUTCDay();
  const day = absoluteDate.getUTCDate();
  const month = absoluteDate.getUTCMonth();
  const year = absoluteDate.getUTCFullYear();
  const hours = absoluteDate.getUTCHours();
  const minutes = absoluteDate.getUTCMinutes();
  const seconds = absoluteDate.getUTCSeconds();
  const milliseconds = absoluteDate.getUTCMilliseconds();

  const convertedDate = new Date(
    year,
    month,
    day,
    hours,
    minutes,
    seconds,
    milliseconds
  );
  return format(convertedDate, formatString);
}

const patchSeriesCategories = (widgetData) => {
  const allCategories = removeDuplicateArrayItems(
    widgetData.data.series
      .map((d) => d.data)
      .flat()
      .map((d) => ({ ...d, value: 0 })),
    "category"
  );
  const patchedWidgetData = {
    ...widgetData,
    data: {
      ...widgetData.data,
      series: widgetData.data.series.map((d) => {
        let existingSeriesData = d.data;
        d.data = allCategories.map((c) => {
          let existingCat = existingSeriesData.find(
            (e) => e.category === c.category
          );
          if (existingCat) {
            return existingCat;
          }
          return c;
        });
        return d;
      }),
    },
  };
  return patchedWidgetData;
};

export { convertTimestampToTimeZone, patchSeriesCategories };
