import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import useSWR from "swr";

interface Params {
  /** dashboard name */
  dashboard: string | undefined;
  /** true if date-time selection is today */
  isLive: boolean;
  /** refresh interval in milliseconds */
  refreshInterval?: number | undefined;
}

const useFetchHappeningsData = ({
  dashboard = "",
  isLive = false,
  refreshInterval,
}: Params) => {
  const locationFilters = useSelector(
    (state: RootState) => state.filters.locations
  );
  const locationsIdsString = `${locationFilters.join(",")}`
  const queryParams = `dashboard=${dashboard}&live=${isLive}&locations=${locationsIdsString}`
  const apiUrl = `/api/v1/happenings?${queryParams}`
  const { data, error, isLoading, mutate } = useSWR(apiUrl, {
    refreshInterval,
  });

  return { data, error, isLoading, mutate };
};

export { useFetchHappeningsData };
