import styled from "styled-components";
import CustomModal from "../../customModal";

const ExtendedAMarketingModal = ({ show, closeModal, url }) => {
  return (
    <CustomModal show={show} size="lg">
      <CustomModal.CloseButton onClick={closeModal} />
      <CustomModal.Content>
        <ModalContent src={url} width={"100%"} height={"100%"} />
      </CustomModal.Content>
    </CustomModal>
  );
};

export { ExtendedAMarketingModal };

const ModalContent = styled.iframe`
  width: 100%;
  height: 100%;
`;
