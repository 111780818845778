import { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useElementSize } from "usehooks-ts";
import { addWidgetToFilterList } from "../../../state/features/analytics/actions";
import Widget from "../../baseWidget";
import WidgetEmptyData from "../../widgetEmptyData";
import SankeyChart from "./sankeyChart";
import { useWidgetContext } from "../../../helpers/hooks/useWidgetContext";
import SankeyTable from "./sankeyTable";
import { RootState } from "../../../state/store";

const SankeyChartWidget = () => {
  const [widgetData, setWidgetData] = useState<any>(null);
  const [widgetRef, contentDimensions] = useElementSize();

  const widget = useWidgetContext();
  const dispatch = useDispatch();

  // const { widgets: widgetFilters } = useSelector(
  //   (state: RootState) => state.filters
  // );

  const widgetSeriesData = useMemo(() => {
    if (widgetData?.widget_data) {
      return widgetData.widget_data.data.series;
    }
    return [];
  }, [JSON.stringify(widgetData)]);

  const dimensions = useMemo(() => {
    let chart = { width: 0, height: 0 };
    let table = { width: 0, height: 0 };
    let maxChartHeight = 300;
    let hPadding = 50;
    let vPadding = 90;
    if (contentDimensions.height > 0) {
      chart.width = contentDimensions.width - hPadding;
      chart.height = 0.4 * (contentDimensions.height - vPadding);
      table.width = contentDimensions.width - hPadding;
      table.height = 0.6 * (contentDimensions.height - vPadding);
      if (contentDimensions.height > 600) {
        chart.height = maxChartHeight;
        table.height = contentDimensions.height - (maxChartHeight + vPadding);
      }
    }
    return { chart, table };
  }, [JSON.stringify(contentDimensions)]);

  useEffect(() => {
    if (widget.widgetData.widget_data) {
      setWidgetData(widget.widgetData);
      // const widgetFilterExists = widgetFilters.find(
      //   (i) => i.key == widget?.widgetFilterConfig?.widgetFilterKey
      // );
      // if (widget.widgetFilterConfig.enableFilters && !widgetFilterExists) {
        // dispatch(
        //   addWidgetToFilterList({
        //     name: widget.widgetFilterConfig.filterTitle,
        //     filterKey: widget.widgetFilterConfig.widgetFilterKey
        //   })
        // );
      // }
    }
  }, [widget.widgetData]);

  return (
    <Widget ref={widgetRef}>
      <Widget.Header>
        <Widget.Title>{widgetData?.title}</Widget.Title>
        <Widget.Controls></Widget.Controls>
      </Widget.Header>
      <Widget.Content>
        {widgetSeriesData.length > 0 ? (
          <>
            <SankeyChart
              data={widgetData.widget_data}
              width={dimensions.chart.width}
              height={dimensions.chart.height}
            />
            <SankeyTable
              data={widgetData.widget_data}
              width={dimensions.table.width}
              height={dimensions.table.height}
            />
          </>
        ) : (
          <WidgetEmptyData
            width={contentDimensions.width - 50}
            height={contentDimensions.height - 90}
          />
        )}
      </Widget.Content>
    </Widget>
  );
};

export default SankeyChartWidget;
