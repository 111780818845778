import { Dropdown as BSDropdown } from "react-bootstrap";
import { BsChevronDown } from "react-icons/bs";
import styled, { keyframes } from "styled-components";

type Option = { key: string; name: string }
interface IValueSelectDropdownProps {
  options: Option[];
  selectedOption: Option;
  onChange: (selectedOption: Option) => void;
}
const ValueSelectDropdown = ({
  options,
  selectedOption,
  onChange,
}: IValueSelectDropdownProps) => {
  const handleOnChange = (option) => {
    onChange(option);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle as={"button"}>
        {selectedOption?.name ?? ""} <BsChevronDown />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {options.map((d) => (
          <Dropdown.Item key={d.key} onClick={() => handleOnChange(d)}>
            {d.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const fadeInTop = keyframes`
  0% {
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-50);
            transform: translateY(-50);
    opacity: 1;
  }
`;

const Dropdown = styled(BSDropdown)`
  .dropdown-toggle {
    font-size: 16px;
    font-weight: 500;
    padding: 5px 15px;
    border-radius: 18px;
    background-color: ${({ theme }) => theme.widget.background};
    color: ${({ theme }) => theme.text};
    border: 1.5px solid ${({ theme }) => theme.text};
    &:hover {
      color: ${({ theme }) => theme.button_plain.hover_text};
      background: ${({ theme }) => theme.button_plain.hover};
    }
    &::after {
      content: none;
    }
  }
  .dropdown-menu {
    animation: ${fadeInTop} 0.2s;
    border: 1px solid #dedede;
    border-radius: 10px;
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
    .dropdown-item {
      background-color: ${({ theme }) => theme.widget.background};
      color: ${({ theme }) => theme.text};
      &:hover {
      color: ${({ theme }) => theme.button_plain.hover_text};
      background: ${({ theme }) => theme.button_plain.hover};
    }
    }
  }
`;

export default ValueSelectDropdown;
