import { WIDGET_TYPE } from "../../types/widgets";

export const transformToWidgetsList = (data) => {
  return Object.entries(data).map((d) => ({
    ...d[1],
    widget_name: d[0],
  }));
};

export const filterTickerDataFromWidgets = (widgetsList) => {
  let tickerData = [];
  let updatedWidgetsList = widgetsList.filter((d) => {
    if (d.widget.type === WIDGET_TYPE.TICKER) {
      // Show tickers that have only visibility permission
      if (d.widget_visibility === "visible") {
        tickerData.push(d?.widget);
      }
    }
    return d.widget.type !== WIDGET_TYPE.TICKER;
  });

  return { updatedWidgetsList, tickerData };
};
