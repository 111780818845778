import { format } from "date-fns";

export function convertTimestampToTimeZone(
  timestampS,
  timeZoneOffset,
  formatString = "yyyy-MM-dd HH:mm:ss"
) {
  const [, sign, offsetHours, offsetMinutes] = timeZoneOffset.match(
    /([+-])(\d{2}):(\d{2})/
  );
  const totalOffsetInSeconds =
    (parseInt(offsetHours, 10) * 60 * 60 + parseInt(offsetMinutes, 10) * 60) *
    (sign === "-" ? -1 : 1);
  const timestampTZ = parseInt(timestampS) + totalOffsetInSeconds;
  const absoluteDate = new Date(timestampTZ * 1000);
  const weekDay = absoluteDate.getUTCDay();
  const day = absoluteDate.getUTCDate();
  const month = absoluteDate.getUTCMonth();
  const year = absoluteDate.getUTCFullYear();
  const hours = absoluteDate.getUTCHours();
  const minutes = absoluteDate.getUTCMinutes();
  const seconds = absoluteDate.getUTCSeconds();
  const milliseconds = absoluteDate.getUTCMilliseconds();

  const convertedDate = new Date(
    year,
    month,
    day,
    hours,
    minutes,
    seconds,
    milliseconds
  );
  return format(convertedDate, formatString);
}
