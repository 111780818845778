import { Spinner } from "react-bootstrap";
import styled from "styled-components";

const ComparisonLoaderModal = () => {
  return (
    <ComparisonLoaderWrapper>
      <div className="content">
        <Spinner animation="border" className="spinner" />
        <h4>Loading Data</h4>
      </div>
    </ComparisonLoaderWrapper>
  );
};

export default ComparisonLoaderModal;

const ComparisonLoaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.body};
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    position: absolute;
    margin-top: -100px;
    width: 576px;
    height: 324px;
    background-color: ${({ theme }) => theme.widget.background};
    padding: 6rem 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .spinner {
      margin-bottom: 10px;
    }
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
  }
`; 

