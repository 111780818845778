import { useParams } from "react-router-dom";
import { useFilterNavigate } from "../../../helpers/hooks/useFilterNavigate";
import { WidgetProvider } from "../../widgetContext";
import WidgetErrorBoundary from "../../widgetErrorBoundary";
import SelectWidget from "../selectWidget";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";

interface Props {
  type: string;
  widgetData: any;
  url: string;
  childWidgets: any[];
  isTimelessWidget: boolean;
}

const DashboardWidgetLoader = ({
  type: widgetType,
  widgetData,
  url: widgetUrl,
  childWidgets,
  isTimelessWidget,
}: Props) => {
  const routeMatch = useParams();
  const navigateTo = useFilterNavigate();
  const [showTimelessUI, setShowTimelessUI] = useState(false);
  const [lastUpdatedTime, setLastUpdatedTime] = useState(new Date());

  const dateFilters = useSelector((state: RootState) => state.filters.dates);

  useEffect(() => {
    if (widgetData) {
      let today = format(new Date(), "yyyy-MM-dd");
      let selectedDate = dateFilters[0];
      setShowTimelessUI(today !== selectedDate);
      setLastUpdatedTime(new Date());
    }
  }, [JSON.stringify(widgetData), dateFilters[0]]);

  const handleWidgetOnClick = () => {
    if (childWidgets.length > 1) {
      navigateTo(`/${routeMatch.dashboard}/${widgetUrl}`);
    }
  };

  return (
    <WidgetErrorBoundary>
      <WidgetProvider
        widgetType={widgetType}
        widgetData={widgetData}
        widgetUrl={widgetUrl}
        widgetOnClick={handleWidgetOnClick}
        childWidgets={childWidgets}
        isTimelessWidget={isTimelessWidget && showTimelessUI}
        lastUpdatedTime={lastUpdatedTime}>
        <SelectWidget widgetType={widgetType} />
      </WidgetProvider>
    </WidgetErrorBoundary>
  );
};

export default DashboardWidgetLoader;
