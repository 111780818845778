import styled from "styled-components";

export const ToggleSwitch = styled.div`
  align-content: center;
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      &::before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 3px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        z-index: 1;
      }
      > .theme-icon {
        position: absolute;
        z-index: 10;
        top: 7px;
        transition: all 400ms cubic-bezier(0.115, 1.195, 0.245, 1.195);
        &.sun{
          left: 7px;
        }
        &.moon{
          right: 5px;
        }
      }
    }
    input {
      opacity: 0;
      width: 0;
      height: 0;
      & + span {
        > .moon{
          color: #5A6371;
          transform: scale(0.8);
        }
        > .sun{
          transform: scale(1);
        }
      }
      &:checked + span{
        > .sun{
          color: #2a3546;;
          transform: scale(0.8);
        }
        > .moon{
          color: #fff;
          transform: scale(1);
        }
      }
    }
  }
  input:checked + .slider {
    background-color: #7c8694;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  .slider {
    &.round {
      border-radius: 34px;
      border: 1px solid #2c2c30;
      box-shadow: inset 0 2px 2px rgba(0,0,0,0.5);
      transition: all 400ms cubic-bezier(0.115, 1.195, 0.245, 1.195);
    }
    &.round::before {
      border-radius: 50%;
      background: #42454b;
    }
  }
`;
