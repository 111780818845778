import { createAction } from "@reduxjs/toolkit";
import * as types from "./types";

export const addWidgetToFilterList = createAction(
  types.FILTER_LIST.ADD_WIDGET,
  (data) => ({ payload: data })
);

export const clearFilterList = createAction(types.FILTER_LIST.CLEAR_FILTERS);

export const focusSecondaryFilter = createAction(
  types.FILTER_FOCUS.SECONDARY,
  (data) => ({
    payload: data,
  })
);

export const clearSecondaryFilterFocus = createAction(
  types.CLEAR_FILTER_FOCUS.SECONDARY
);

export const focusLocationFilter = createAction(
  types.FILTER_FOCUS.LOCATION,
  (data) => ({
    payload: data,
  })
);

export const clearLocationFilterFocus = createAction(
  types.CLEAR_FILTER_FOCUS.LOCATION
);
