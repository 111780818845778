import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { filterOperations } from "../../state/features/filters";
import type { RootState } from "../../state/store";

const ActiveFilterSection = () => {
  const widgetFilters = useSelector(
    (state: RootState) => state.filters.widgets
  );
  const dispatch = useDispatch();

  const removeWidgetFilter = (widgetKey, id) => {
    dispatch(filterOperations.removeWidgetFilter({ key: widgetKey, id }));
  };

  const resetFilters = () => {
    dispatch(filterOperations.clearWidgetFilter());
  };

  return (
    <Wrapper>
      {widgetFilters.length > 0 && (
        <div className="data-filter-section">
          <div className="header-section">
            <p className="header">Filtering data by:</p>
            <button className="reset-button" onClick={resetFilters}>
              Reset
            </button>
          </div>
          <div className="widget-filter-container">
            {widgetFilters.map((widget, idx) => (
              <div className="widget-filter" key={idx}>
                <p className="widget-filter_name">{widget.name}:</p>
                <div className="filter-list">
                  {widget.data.map((data, idx) => (
                    <div className="filter-option" key={idx}>
                      <p className="option-name">{data.name}</p>
                      <div
                        className="close-button"
                        onClick={() => removeWidgetFilter(widget.key, data.id)}>
                        &#215;
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default ActiveFilterSection;

const Wrapper = styled.div`
  margin-left: 50px;
  margin-right: 50px;
  span {
    font-size: 18px;
  }
  p {
    margin: 0;
  }
  .data-filter-section {
    background-color: ${({ theme }) => theme.widget.background};
    border-radius: 20px;
    overflow-x: scroll;
    margin-top: 30px;
    .header-section {
      display: flex;
      justify-content: space-between;
      padding: 22px 16px 10px;
      position: sticky;
      top: 0;
      left: 0;
      .header {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        color: ${({ theme }) => theme.text};
      }
      .reset-button {
        background-color: #a2d198;
        color: #fff;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        border: none;
        border-radius: 20px;
        padding: 10px 15px;
      }
    }
    .widget-filter-container {
      display: flex;
      border-top: 1px solid #f7f7f7;
      margin: 0 16px 22px;
      width: max-content;
      min-width: -webkit-fill-available;
      .widget-filter {
        padding: 10px 15px;
        :not(:last-child) {
          border-right: 1px solid #f7f7f7;
        }
        .widget-filter_name {
          font-weight: 500;
          font-size: 11px;
          line-height: 15px;
          color: ${({ theme }) => theme.text};
        }
        .filter-list {
          .filter-option {
            display: flex;
            background-color: ${({ theme }) => theme.filter.background};
            border-radius: 20px;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: ${({ theme }) => theme.modal.background};
            margin: 6px 0;
            overflow: hidden;
            .option-name {
              padding: 0.5rem 1.2rem;
              flex: 1;
              background-color: ${({ theme }) => theme.filter.background};
            }
            .close-button {
              background: ${({ theme }) => theme.filter.color};
              display: flex;
              justify-content: center;
              align-items: baseline;
              padding: 4px 7px;
              color: ${({ theme }) => theme.modal.background};
              font-size: 25px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
`;
