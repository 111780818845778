import { createSlice } from "@reduxjs/toolkit";
import { JWT_REFRESH_TIMER_THRESHOLD_SECONDS } from "../../../helpers/constants";

const initialState = {
  authorize: false,
  tokenExpiry: null,
  jwtData: null,
  isTokenExpired: false,
  initiateTicker: false,
  userCompanies: [] as number[],
  enableCompanySwitch: false,
  showCompanySwitcher: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authorizeUser(state, action) {
      state.authorize = action.payload;
    },
    setJwtData(state, action) {
      let tokenExpiry = action.payload.exp;
      let jwtData = action.payload;
      let updatedState = { ...state, tokenExpiry, jwtData };
      let timeDiff = tokenExpiry - new Date().getTime() / 1000;
      if (timeDiff <= JWT_REFRESH_TIMER_THRESHOLD_SECONDS) {
        return { ...updatedState, isTokenExpired: true, initiateTicker: false };
      }
      if ("authorized_companies" in jwtData) {
        let userCompanies: number[] = jwtData.authorized_companies;
        let enableCompanySwitch = userCompanies.length > 1;
        updatedState = { ...updatedState, userCompanies, enableCompanySwitch };
      }
      return {
        ...updatedState,
        authorize: true,
        isTokenExpired: false,
        initiateTicker: true,
      };
    },
    setInitiateTicker(state, action) {
      state.initiateTicker = action.payload;
    },
    setSessionExpired(state, action) {
      state.authorize = false;
    },
    toggleCompanySwitcher(state, action) {
      state.showCompanySwitcher = action?.payload ?? false;
    },
  },
});

export const {
  authorizeUser,
  setInitiateTicker,
  setJwtData,
  setSessionExpired,
  toggleCompanySwitcher,
} = authSlice.actions;

export const reducer = authSlice.reducer;
