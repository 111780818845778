import { TbUnlink } from "react-icons/tb";
import styled from "styled-components";

interface IWidgetErrorFallbackProps {
  height: number;
  width: number;
  widgetUrl: string;
  widgetType: string;
  retry(): void;
}

const WidgetErrorFallback = ({
  height,
  width,
  widgetUrl,
  widgetType,
  retry,
}: IWidgetErrorFallbackProps) => {
  return (
    <Wrapper style={{ height, width }}>
      <TbUnlink fontSize={"60px"} className="icon" />
      <p className="error-title">Widget Error. Cannot connect to the server.</p>
      <p className="reload-cta" onClick={() => retry()}>
        Reload Widget
      </p>
    </Wrapper>
  );
};

export default WidgetErrorFallback;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .icon {
    text-align: center;
  }
  .error-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 23.4px;
    text-align: center;
  }
  .reload-cta {
    color: #2b7bc5;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
  }
`;
