import { useContext, useEffect, useMemo, useState } from "react";
import { useElementSize } from "usehooks-ts";
import MultiSeriesComboChart from "./multiSeriesComboChart";
import { useDispatch, useSelector } from "react-redux";
import { CustomDispatch, RootState } from "../../../state/store";
import { addWidgetToFilterList } from "../../../state/features/analytics/actions";
import { WidgetContext } from "../../widgetContext";
import WidgetEmptyData from "../../widgetEmptyData";
import Widget from "../../baseWidget";
import { patchSeriesCategories } from "./utils";

interface IMultiSeriesComboWidgetProps {}

const MultiSeriesComboWidget = (props: IMultiSeriesComboWidgetProps) => {
  const [widgetData, setWidgetData] = useState<any>({});
  const [widgetDataCopy, setWidgetDataCopy] = useState({});
  const [widgetRef, contentDimensions] = useElementSize();
  const [widgetFilterOption, setWidgetFilterOption] = useState("");

  const widget = useContext(WidgetContext);

  const dispatch: CustomDispatch = useDispatch();

  const { widgets: widgetFilters } = useSelector(
    (state: RootState) => state.filters
  );

  useEffect(() => {
    if (widget.widgetData.widget_data) {
      let patchedWidgetData = patchSeriesCategories(
        widget.widgetData.widget_data
      );
      setWidgetData({
        ...widget.widgetData,
        widget_data: patchedWidgetData,
      });
      const widgetFilterExists = widgetFilters.find(
        (i) => i.key == widget?.widgetFilterConfig?.widgetFilterKey
      );
      if (
        widget.widgetFilterConfig.enableFilters &&
        widget.widgetData.widget_data.data.series.length &&
        !widgetFilterExists
      ) {
        dispatch(
          addWidgetToFilterList({
            name: widget.widgetFilterConfig.filterTitle,
            filterKey: widget.widgetFilterConfig.widgetFilterKey,
            options: widget.widgetData.widget_data.data.series[0].data.map(
              (d) => ({
                id: d.id,
                name: d.category,
              })
            ),
          })
        );
      }
    }
  }, [widget.widgetData]);

  const widgetSeriesData = useMemo(() => {
    if (widgetData.widget_data) {
      let series_data = widgetData.widget_data.data.series.filter(
        (i) => i.data.filter((j) => j.value).length
      );
      if (series_data.length) {
        return widgetData.widget_data.data.series;
      }
    }
    return [];
  }, [JSON.stringify(widgetData)]);

  const dimensions = useMemo(() => {
    let chart = { width: 0, height: 0 };
    let hPadding = 50;
    let vPadding = 90;
    if (contentDimensions.height > 0) {
      chart.width = contentDimensions.width - hPadding;
      chart.height = contentDimensions.height - vPadding;
    }
    return { chart };
  }, [JSON.stringify(contentDimensions)]);

  const handleDataPointClick = () => {
    // TODO
  };

  return (
    <Widget ref={widgetRef}>
      <Widget.Header>
        <Widget.Title>{widgetData?.title}</Widget.Title>
        <Widget.Controls>
          {widgetSeriesData.length > 0 ? <Widget.ExtendedAReportCta /> : null}
        </Widget.Controls>
      </Widget.Header>
      <Widget.Content>
        {widgetSeriesData.length > 0 ? (
          <MultiSeriesComboChart
            data={widgetData.widget_data}
            height={dimensions.chart.height}
            width={dimensions.chart.width}
            handleDataPointClick={handleDataPointClick}
            limitBar={5}
          />
        ) : (
          <WidgetEmptyData
            width={contentDimensions.width - 50}
            height={contentDimensions.height - 90}
          />
        )}
      </Widget.Content>
    </Widget>
  );
};

export default MultiSeriesComboWidget;
