import { useEffect, useState } from "react";
import type { EChartsOption } from "echarts";
import BaseChart from "../../baseChart";

export type ScatterPlotChartConfiguration = {
  config: {
    value_formatter: string;
    x_axis: {
      name: string;
      type: any;
    };
    y_axis: {
      name: string;
      type: any;
    }
  },
  data: {
    series: {
      x_axis_value: number;
      y_axis_value: number;
      scatter_size: number;
      scatter_name: string;
      identity_name: string;
    }[];
  }
};

interface IScatterPlotChartVisual {
  width: number;
  height: number;
  data: ScatterPlotChartConfiguration;
}

const ScatterPlotChartVisual = (props: IScatterPlotChartVisual) => {
  const [option, setOption] = useState<EChartsOption>({
    tooltip: {
      show: true,
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    config: {
      xAxis: {
        name: "",
        value: "type"
      },
      yAxis: {
        name: "",
        value: "type"
      }
    },
    data: {
      series: []
    }
  });

  const [identityColour, setIdentityColours] = useState<any>({});
  const [data, setData] = useState<any>([]);

  const stringToColour = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = "#";
    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
  }
  
  const generateRandomColours = (series) => {
    let identityNames: string[] = [];
    let lookup = {};
    let identityColours = {};
    //Set unique location names
    series.forEach((dat) => {
      if (!(dat.identity_name in lookup)) {
        lookup[dat.identity_name] = 1;
        identityNames.push(dat.identity_name);
      }
    });
    //Set unique location colours
    identityNames.forEach((name) => {
      identityColours[name] = stringToColour(name);
    });
    
    return identityColours;
  }

  useEffect(() => {
    if (props.data) {
      setData(props.data);
      setIdentityColours(generateRandomColours(props.data.data.series));
      console.log(identityColour);
      setOption({
        xAxis: {
          name: props.data.config.x_axis.name,
          type: props.data.config.x_axis.type,
        },
        yAxis: {
          name: props.data.config.y_axis.name,
          type: props.data.config.y_axis.type,
        },
        legend: {
          show: true,
          type: "scroll",
          tooltip: {
            show: true,
          },
        },
        series: props.data.data.series.map((dat) => {
          return {
            symbolSize: dat.scatter_size,
            data: [dat.x_axis_value, dat.y_axis_value],
            type: "scatter",
            name: dat.scatter_name,
            color: identityColour[dat.identity_name],
            label: {
              show: false,
              formatter: `{a} ${props.data.config.value_formatter}`,
            },
          }
        }),
      });
    }
  }, [props.data]);

  const handleClickEvent = (e) => {
    // TODO
  };

  return (
    <BaseChart
      height={props.height}
      width={props.width}
      options={option}
      chartType="scatter"
      onClick={handleClickEvent}
    />
  );
};

export default ScatterPlotChartVisual;
