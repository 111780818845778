import AnalyticsGroupWidgetLoader from "../../../components/widgetLoader/analyticsGroupWidgetLoader";
import AnalyticsWidgetLoader from "../../../components/widgetLoader/analyticsWidgetLoader";
import Resizer from "./resizer";
import { MdControlCamera } from "react-icons/md";

const WidgetLoader = ({ widget, dashboard, dashboardWidget }) => {
  return widget.render_type === "group" ? (
    <>
      <AnalyticsGroupWidgetLoader widgetMeta={widget} />
      <div className="resizer">
        <Resizer />
      </div>
      <div className="drag-handle">
        <MdControlCamera />
      </div>
    </>
  ) : (
    <>
      <AnalyticsWidgetLoader
        dashboard={dashboard}
        dashboardWidget={dashboardWidget}
        widget={widget}
      />
      <div className="resizer">
        <Resizer />
      </div>
      <div className="drag-handle">
        <MdControlCamera />
      </div>
    </>
  );
};

export default WidgetLoader;
