import { useMemo } from "react";
import { useEffect, useState } from "react";
import { useElementSize } from "usehooks-ts";
import BarChart from "./barChart";
import { useDispatch, useSelector } from "react-redux";
import { addWidgetToFilterList } from "../../../state/features/analytics/actions";
import WidgetHeaderSection from "../../widgetHeaderSection";
import Widget from "../../baseWidget";
import { RootState } from "../../../state/store";
import { useWidgetContext } from "../../../helpers/hooks/useWidgetContext";

interface IBarChartWidgetProps {}

const BarChartWidget = (props: IBarChartWidgetProps) => {
  const [widgetData, setWidgetData] = useState<any>({});
  const [widgetRef, contentDimensions] = useElementSize();
  const [widgetDataCopy, setWidgetDataCopy] = useState<any>({});
  const [sortAscending, setSortAscending] = useState(false);
  const [widgetFilterOption, setWidgetFilterOption] = useState("");
  const [isDataFilteringEnabled, setEnableDataFiltering] = useState(false);
  const [isDataSortingEnabled, setEnableDataSorting] = useState(false);

  const widget = useWidgetContext();

  const { widgets: widgetFilters } = useSelector(
    (state: RootState) => state.filters
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (widget.widgetData.widget_data) {
      if (widget.widgetData.widget_data.config.enable_data_filtering) {
        setWidgetData(
          filterWidgetData("10", sortWidgetData(widget.widgetData))
        );
        setWidgetFilterOption("10");
        setWidgetDataCopy(widget.widgetData);
        setEnableDataFiltering(true);
        setEnableDataSorting(true);
      } else {
        setWidgetData(
          filterWidgetData("All", sortWidgetData(widget.widgetData))
        );
        setWidgetFilterOption("All");
        setEnableDataFiltering(false);
        setEnableDataSorting(false);
      }
      const widgetFilterExists = widgetFilters.find(
        (i) => i.key == widget?.widgetFilterConfig?.widgetFilterKey
      );
      if (
        widget.widgetFilterConfig &&
        widget.widgetFilterConfig.enableFilters &&
        !widgetFilterExists
      ) {
        dispatch(
          addWidgetToFilterList({
            name: widget.widgetFilterConfig.filterTitle,
            filterKey: widget.widgetFilterConfig.widgetFilterKey,
            options: widget.widgetData.widget_data.data.series.map((d) => ({
              id: d.id,
              name: d.category,
            })),
          })
        );
      }
    }
  }, [JSON.stringify(widget.widgetData)]);

  const widgetSeriesData = useMemo(() => {
    if (widget.widgetData.widget_data) {
      return widget.widgetData.widget_data.data.series;
    }
    return [];
  }, [JSON.stringify(widget.widgetData)]);

  const handleDataPointClick = () => {
    // TODO
  };

  const handleWidgetOnClick = () => {
    if (widget.widgetOnClick) {
      widget.widgetOnClick();
    }
  };

  const sortWidgetData = (widgetDataObj, ascending = sortAscending) => {
    let updatedSeriesData = widgetSeriesData.length > 1 ? widgetSeriesData.sort(
      (a, b) => {
        if (!ascending) {
          return b.value - a.value;
        }
        return a.value - b.value;
      }
    ) : widgetSeriesData ;
    return {
      ...widgetDataObj,
      widget_data: {
        ...widgetDataObj.widget_data,
        data: { ...widgetDataObj.widget_data.data, series: updatedSeriesData },
      },
    };
  };

  const handleWidgetDataSorting = (sortAscending) => {
    setSortAscending(sortAscending);
    let sortedData = filterWidgetData(
      widgetFilterOption,
      sortWidgetData(widgetData, sortAscending)
    );
    setWidgetData(sortedData);
  };

  const filterWidgetData = (option, widgetDataObject) => {
    let updatedSeriesData = [...widgetDataObject.widget_data.data.series];
    if (option === "All") {
      updatedSeriesData = [...widgetDataObject.widget_data.data.series];
    } else {
      updatedSeriesData = widgetDataObject.widget_data.data.series.filter(
        (dat, idx) => idx <= parseInt(option) - 1
      );
    }
    return {
      ...widgetDataObject,
      widget_data: {
        ...widgetDataObject.widget_data,
        data: {
          ...widgetDataObject.widget_data.data, series: updatedSeriesData,
        },
      },
    };
  };

  const handleWidgetDataFiltering = (option) => {
    setWidgetFilterOption(option);
    setWidgetData(filterWidgetData(option, sortWidgetData(widgetDataCopy)));
  };

  return (
    <Widget ref={widgetRef}>
      <WidgetHeaderSection
        widgetTitle={widgetData.title}
        clickHandler={handleWidgetOnClick}
        enableFiltering={false}
        handleWidgetDataFiltering={handleWidgetDataFiltering}
        enableSorting={widgetSeriesData.length > 0}
        handleWidgetDataSorting={handleWidgetDataSorting}
        enableExtendedAReport={
          widget.enableExtendedAReport && widgetSeriesData.length > 0
        }
        handleExtendedAAction={widget.onExtendedAClick}
        isTimelessWidget={widget?.isTimelessWidget}
        lastUpdatedTime={widget?.lastUpdatedTime}
        aiAdvisor={{
          isAiSuggestionEnabled: widget?.isAiSuggestionEnabled,
          isAiSuggestionLoading: widget?.isAiSuggestionLoading,
          isAiSuggestionLoadError: widget?.isAiSuggestionLoadError,
          getAiSuggestion: widget?.getAiSuggestion,
        }}
      />
      <Widget.Content>
        {widgetSeriesData.length > 0 ? (
          <BarChart
            data={widgetData.widget_data}
            width={contentDimensions.width - 50}
            height={contentDimensions.height - 75}
            handleDataPointClick={handleDataPointClick}
          />
        ) : (
          <Widget.EmptyData
            width={contentDimensions.width - 50}
            height={contentDimensions.height - 75}
          />
        )}
      </Widget.Content>
    </Widget>
  );
};

export default BarChartWidget;
