import { useMemo } from "react";
import type { EChartsOption } from "echarts";
import BaseChart from "../../baseChart";

export type SankeyChartConfiguration = {
  config: {
    table_configs: {
      column_names: {
        source: string;
        destination: string;
        item: string;
        value: string;
      };
    };
    value_formatter: string;
  };
  data: {
    series: {
      source: string;
      destination: string;
      item: string;
      value: number;
    }[];
  };
};

interface ISankeyChartProps {
  width: number;
  height: number;
  data: SankeyChartConfiguration;
}

const SankeyChart = ({ data, height, width }: ISankeyChartProps) => {
  const option = useMemo<EChartsOption>(() => {
    if (data) {
      const sources = data.data.series.map((d) => d.source);
      const destinations = data.data.series.map((d) => d.destination);
      const nodes = [...new Set(sources.concat(destinations))];
      return {
        tooltip: {
          show: true,
        },
        series: {
          type: "sankey",
          layout: "none",
          emphasis: {
            focus: "adjacency",
          },
          data: nodes.map((n) => ({ name: n })),
          links: data.data.series.map((s) => ({
            source: s.source,
            target: s.destination,
            value: s.value,
            name: s.item,
          })),
        },
      };
    }
    return {
      tooltip: {
        show: true,
      },
      series: {
        type: "sankey",
        layout: "none",
        data: [],
        links: [],
      },
    };
  }, []);

  return (
    <BaseChart
      chartType="sankey"
      options={option}
      height={height}
      width={width}
    />
  );
};

export default SankeyChart;
