import { useContext, useMemo } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useElementSize } from "usehooks-ts";
import { addWidgetToFilterList } from "../../../state/features/analytics/actions";
import { WidgetContext } from "../../widgetContext";
import WidgetLoading from "../../widgetLoading";
import ScatterPlotChartVisual from "./scatterPlotChart";
import { RootState } from "../../../state/store";

interface IScatterPlotChartWidgetProps {};

const ScatterPlotChartWidget = (props: IScatterPlotChartWidgetProps) => {
  const [widgetData, setWidgetData] = useState<any>({});
  const [chartContainerRef, chartDimensions] = useElementSize();

  const widget = useContext(WidgetContext);
  const dispatch = useDispatch();

  const { widgets: widgetFilters } = useSelector(
    (state: RootState) => state.filters
  );

  useEffect(() => {
    if (widget.widgetData.widget_data) {
      setWidgetData(widget.widgetData);
      const widgetFilterExists = widgetFilters.find(
        (i) => i.key == widget?.widgetFilterConfig?.widgetFilterKey
      );
      if (widget.widgetFilterConfig.enableFilters && !widgetFilterExists) {
        dispatch(
          addWidgetToFilterList({
            name: widget.widgetFilterConfig.filterTitle,
            filterKey: widget.widgetFilterConfig.widgetFilterKey,
            options: widget.widgetData.widget_data.data.series.map((d) => ({
              ...d,
              name: d.category,
            })),
          })
        );
      }
    }
  }, [widget.widgetData]);

  const scatterChart = useMemo(
    () => (
      <ScatterPlotChartVisual
        data={widgetData.widget_data}
        width={chartDimensions.width - 50}
        height={chartDimensions.height - 90}
      />
    ),
    [widgetData, chartDimensions]
  );

  const handleWidgetOnClick = () => {
    if (widget.widgetOnClick) {
      widget.widgetOnClick();
    }
  };

  return (
    <WidgetWrapper ref={chartContainerRef}>
      <div className="widget-title" onClick={handleWidgetOnClick}>
        {widgetData.title}
      </div>
      <div className="widget-content">{scatterChart}</div>
    </WidgetWrapper>
  );
};

export default ScatterPlotChartWidget;

const WidgetWrapper = styled.div`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.widget.background};
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.05));
  width: 100%;
  height: 100%;
  padding: 25px;
  .widget-title {
    font-size: 1.3rem;
    text-decoration: underline;
  }
`;
