import styled from "styled-components";
import LoadingAnimation from "../loadingAnimation";

const CompanyLoadingScreen = ({ statusText }) => {
  return (
    <LoaderWrapper>
      <div className="content">
        <LoadingAnimation />
        <h4>Application Loading</h4>
        <p className="status-text">{statusText}</p>
      </div>
    </LoaderWrapper>
  );
};

export default CompanyLoadingScreen;

const LoaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.background};
  width: 100vw;
  height: 100vh;
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 576px;
    height: 324px;
    background-color: ${({ theme }) => theme.widget.background};
    padding: 6rem 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .status-text {
      margin-top: 10px;
      font-weight: 300;
      font-size: 16px;
    }
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
  }
`;
