import { removeDuplicateArrayItems } from "../../../helpers/utils";
import { format } from "date-fns";

const patchSeriesData = (seriesData) => {
  let allCategoryData = removeDuplicateArrayItems(
    seriesData
      .map((d) => d.series_data)
      .flat()
      .map((d) => ({ ...d, value: 0 }))
  );
  let modifiedSeriesData = seriesData.map((d) => {
    let existingCatSeries = d.series_data;
    let normalizeCatData = allCategoryData.map((c) => {
      let existCat = existingCatSeries.find((e) => e.id === c.id);
      if (existCat) {
        return existCat;
      }
      return c;
    });
    return { ...d, series_data: normalizeCatData };
  });
  return modifiedSeriesData;
};

export const patchWidgetData = (widgetData) => {
  return {
    ...widgetData,
    widget_data: {
      ...widgetData.widget_data,
      data: {
        ...widgetData.widget_data.data,
        series: patchSeriesData(widgetData.widget_data.data.series),
      },
    },
  };
};

export const getFilterOptionsFromCategories = (
  widgetData,
  filterBy = "series"
) => {
  return filterBy === "value"
    ? widgetData.widget_data.data.series
        .filter((d) => d.series_data.length > 0)
        .map((d) => {
          return {
            id: d.series_data[0].id,
            name: d.name,
          };
        })
    : widgetData.widget_data.data.series[0].series_data.map((d) => ({
        id: d.id,
        name: d.category,
        value: d.value,
      }));
};

export function convertTimestampToTimeZone(
  timestampS,
  timeZoneOffset,
  formatString = "yyyy-MM-dd HH:mm:ss"
) {
  const [, sign, offsetHours, offsetMinutes] = timeZoneOffset.match(
    /([+-])(\d{2}):(\d{2})/
  );
  const totalOffsetInSeconds =
    (parseInt(offsetHours, 10) * 60 * 60 + parseInt(offsetMinutes, 10) * 60) *
    (sign === "-" ? -1 : 1);
  const timestampTZ = parseInt(timestampS) + totalOffsetInSeconds;
  const absoluteDate = new Date(timestampTZ * 1000);
  const weekDay = absoluteDate.getUTCDay();
  const day = absoluteDate.getUTCDate();
  const month = absoluteDate.getUTCMonth();
  const year = absoluteDate.getUTCFullYear();
  const hours = absoluteDate.getUTCHours();
  const minutes = absoluteDate.getUTCMinutes();
  const seconds = absoluteDate.getUTCSeconds();
  const milliseconds = absoluteDate.getUTCMilliseconds();

  const convertedDate = new Date(
    year,
    month,
    day,
    hours,
    minutes,
    seconds,
    milliseconds
  );
  return format(convertedDate, formatString);
}
