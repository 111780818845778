import { useContext, useEffect, useState } from "react";
import { useElementSize } from "usehooks-ts";
import BubbleMap from "./bubbleMap";
import styled from "styled-components";
import { WidgetContext } from "../../widgetContext";
import DisabledMapUI from "./disabledMapUI";
import { FaMagnifyingGlassArrowRight } from "react-icons/fa6";
import Widget from "../../baseWidget";

const MapBubbleWidget = () => {
  const [widgetData, setWidgetData] = useState<any>({});
  const [chartContainerRef, chartDimensions] = useElementSize();

  const widget = useContext(WidgetContext);

  useEffect(() => {
    if (widget.widgetData.widget_data) {
      setWidgetData(widget.widgetData);
    }
  }, [widget.widgetData]);

  return (
    <Widget ref={chartContainerRef}>
      <WidgetHeaderWrapper>
        <div className="widget-title">
          <span>{widgetData.title}</span>
          <span>
            {widget.enableExtendedAReport &&
            widgetData.widget_data &&
            widgetData.widget_data.length > 0 ? (
              <div
                className="extended-report-option"
                onClick={() => widget.onExtendedAClick()}>
                <FaMagnifyingGlassArrowRight fontSize={"18px"} />
              </div>
            ) : null}
          </span>
        </div>
      </WidgetHeaderWrapper>
      <WidgetContentWrapper>
        {widgetData.widget_data && widgetData.widget_data.length > 0 ? (
          <BubbleMap
            data={widgetData.widget_data}
            width={chartDimensions.width - 50}
            height={chartDimensions.height - 50}
            type={widgetData.type}
          />
        ) : (
          <div className="widget-error-content">
            <DisabledMapUI />
          </div>
        )}
      </WidgetContentWrapper>
    </Widget>
  );
};

export default MapBubbleWidget;

const WidgetHeaderWrapper = styled.div`
  .widget-title {
    font-size: 1.3rem;
    position: absolute;
    text-decoration: underline;
    background-color: ${({ theme }) => theme.widget.background};
    padding: 15px;
    border-radius: 0 0 30px 0;
    top: 20px;
    left: 25px;
    z-index: 1;
    > span {
      padding: 10px;
    }
  }
  .extended-report-option {
    cursor: pointer;
    position: absolute;
    top: 16px;
    left: 120px;
    height: 30px;
    width: 30px;
    padding: 0px 5px 1px;
    box-sizing: border-box;
    border-radius: 8px;
  }
`;

const WidgetContentWrapper = styled.div`
  height: 100%;
  .widget-error-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mapboxgl-map {
    .mapboxgl-popup {
      .mapboxgl-popup-tip {
        border-bottom-color: ${({ theme }) => theme.widget.background};
      }
      .mapboxgl-popup-content {
        border-radius: 20px;
        background-color: ${({ theme }) => theme.widget.background};
        color: ${({ theme }) => theme.text};
        .mapboxgl-popup-close-button {
          right: 3px;
          top: 2px;
        }
      }
    }
  }
`;
