import WidgetLockedUnauthorized from "../../../components/widgetLockedUnauthorized";
import { ENTITY_LOCK_TYPE } from "../../../types/visibility";
import DashboardLockedMarketingContent from "./dashboardLockedMarketingContent";

const DashboardStatusContainer = ({
  shouldLoadDashboard,
  children,
  dashboardAuthError,
}) => {
  const noErrors = !dashboardAuthError.isEnabled;

  if (dashboardAuthError.isEnabled) {
    if (dashboardAuthError.config.lockType === ENTITY_LOCK_TYPE.UNAUTHORIZED) {
      return <WidgetLockedUnauthorized type="d_level" />;
    }
    if (
      dashboardAuthError.config.lockType === ENTITY_LOCK_TYPE.MARKETING_MESSAGE
    ) {
      return (
        <DashboardLockedMarketingContent
          url={dashboardAuthError.config.marketingHtmlUrl}
        />
      );
    }
  }
  if (noErrors && shouldLoadDashboard) {
    return <>{children}</>;
  }
  return <>...</>;
};

export { DashboardStatusContainer };
