import { createContext } from "react";

export const EnvContext = createContext({} as any);

interface IEnvProviderProps {
  envObject: any;
  children: any;
}

export const EnvProvider = (props: IEnvProviderProps) => {
  return (
    <EnvContext.Provider value={props.envObject}>
      {props.children}
    </EnvContext.Provider>
  );
};
