import useSWR from "swr";
import offThreadQueryResponse from "../middleware/offThreadQueryResponse";

export const useFetchReportData = ({ path }: { path: string | null }) => {
  const { data, isValidating, error, mutate } = useSWR(path, {
    use: [offThreadQueryResponse],
    revalidateOnFocus: false,
  });

  return {
    data: data?.report_data,
    isValidating: isValidating,
    error,
    mutate,
  };
};
