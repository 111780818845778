import { useEffect, useMemo, useRef, useState } from "react";
import { Dropdown, Form, Button, Spinner } from "react-bootstrap";
import { BsFilterCircle } from "react-icons/bs";
import ReactSelect, { components } from "react-select";
import styled, { keyframes } from "styled-components";
import { BiCheckbox, BiCheckboxSquare } from "react-icons/bi";
import type { MenuProps, OptionProps } from "react-select";
import useFetchColumnFilterData from "../../../helpers/hooks/useFetchColumnFilterData";

const Option = ({ children, ...props }: OptionProps) => {
  return (
    <components.Option {...props}>
      <div>
        {props.selectProps.value ? (
          props.selectProps.value
            .map((v) => v.value)
            .includes(props.data.value) ? (
            <BiCheckboxSquare fontSize="30px" />
          ) : (
            <BiCheckbox fontSize="30px" />
          )
        ) : (
          <BiCheckbox fontSize="30px" />
        )}
        <span>{props.label}</span>
      </div>
    </components.Option>
  );
};

const Menu = ({
  children,
  submitFilterSelection,
  disableSubmitButton,
  ...props
}: MenuProps) => {
  return (
    <components.Menu {...props}>
      {children}
      <Button
        className="submit-btn"
        onClick={submitFilterSelection}
        disabled={disableSubmitButton}
      >
        Submit
      </Button>
    </components.Menu>
  );
};

const ColumnFilterDropdown = ({
  columnFilterKey,
  headerTitle,
  appliedFilters,
  setAppliedFilters,
}) => {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const { data: filterData, isLoading } = useFetchColumnFilterData({
    filterKey: columnFilterKey,
  });

  useEffect(() => {
    setDisableSubmit(selectedFilters.length === 0);
  }, [selectedFilters]);

  useEffect(() => {
    resetFilters();
  }, [JSON.stringify(appliedFilters), filterData, isLoading]);

  const multiSelectRef = useRef(null);
  const dropdownToggleRef = useRef(null);
  const handleItemSelect = (data) => {
    setSelectedFilters(data); // Remove
  };

  const handleSubmit = (e) => {
    if (filterData) {
      setAppliedFilters(headerTitle, filterData.url, selectedFilters);
    }
    if (multiSelectRef.current) {
      multiSelectRef.current?.clearValue();
    }
    if (dropdownToggleRef.current) {
      dropdownToggleRef.current?.click();
    }
  };

  const resetFilters = () => {
    if (!isLoading && filterData) {
      let filter = appliedFilters.find((i) => i.filterKey == filterData.url);
      if (filter?.options?.length) {
        setSelectedFilters(filter.options);
      }
    }
  };

  return (
    <DropdownWrapper>
      <Dropdown
        as="span"
        className="column-filter-dropdown"
        onToggle={resetFilters}
      >
        {!isLoading ? (
          !filterData ? (
            <span className="column-filter-dropdown">{headerTitle}</span>
          ) : (
            <>
              <Dropdown.Toggle as="span" ref={dropdownToggleRef}>
                {headerTitle} <BsFilterCircle style={{ marginLeft: "5px" }} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <ReactSelect
                  classNamePrefix="custom-filter"
                  components={{
                    Option,
                    Menu: (props) => (
                      <Menu
                        submitFilterSelection={handleSubmit}
                        disableSubmitButton={disableSubmit}
                        {...props}
                      />
                    ),
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  options={
                    filterData
                      ? filterData.values.map((v) => ({
                          ...v,
                          label: v.heading,
                        }))
                      : []
                  }
                  isMulti
                  onChange={handleItemSelect}
                  ref={multiSelectRef}
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  menuIsOpen
                  value={selectedFilters}
                />
              </Dropdown.Menu>
            </>
          )
        ) : (
          <>
            <span className="column-filter-dropdown">{headerTitle}</span>{" "}
            <Spinner animation="border" size="sm" />
          </>
        )}
      </Dropdown>
    </DropdownWrapper>
  );
};

export default ColumnFilterDropdown;

const fadeInTop = keyframes`
  0% {
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-50);
    transform: translateY(-50);
    opacity: 1;
  }
`;

const DropdownWrapper = styled.span`
  .column-filter-dropdown {
    cursor: pointer;
    .dropdown-menu {
      min-width: 300px;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
      padding: 0.5rem;
      border: 1px solid rgb(222, 222, 222);
      border-radius: 10px;
      animation: ${fadeInTop} 0.2s;
      .custom-filter__menu {
        position: static;
        box-shadow: none;
      }
      .custom-filter__control {
        background: ${({ theme }) => theme.dropdown.background_color};
        box-shadow: none;
        margin-top: 0.5rem;
        &.custom-filter__control--is-focused {
          border-color: #0d8a6a;
        }
        .custom-filter__value-container {
          cursor: text;
          font-weight: 400;
        }
        .custom-filter__indicators {
          cursor: pointer;
        }
      }
      .custom-filter__menu {
        border-radius: 0 0 10px 10px;
        background: ${({ theme }) => theme.dropdown.background_color};
        .submit-btn {
          float: right;
          margin-right: 0.5rem;
          margin-bottom: 0.5rem;
          font-size: 14px;
          height: 40px;
          border: none;
          background-color: #0d8a6a;
        }
        .custom-filter__menu-list {
          background: ${({ theme }) => theme.dropdown.background_color};
          margin-bottom: 0.5rem;
          .custom-filter__option,
          .custom-filter__option--is-selected {
            cursor: pointer;
            background: ${({ theme }) => theme.dropdown.background_color};
            color: ${({ theme }) => theme.text};
            &:hover {
              color: #fff;
              background-color: #3aa98d;
            }
            div {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
`;
