import { CacheContext } from "../../helpers/contexts/cacheContext";

const CacheProvider = ({ children }) => {
  return (
    <CacheContext.Provider value={{}}>
      {children}
    </CacheContext.Provider>
  );
};

export default CacheProvider;
