import { useContext, useMemo, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { GiFiles } from "react-icons/gi";
import styled, { keyframes } from "styled-components";
import { ComparisonContext } from "../comparison/comparisonProvider";
import ComparisonDayOptions from "./comparisonOptions/dayOptions";
import ComparisonMonthOptions from "./comparisonOptions/monthOptions";
import ComparisonQuarterOptions from "./comparisonOptions/quarterOptions";
import ComparisonWeekOptions from "./comparisonOptions/weekOptions";
import ComparisonYearOptions from "./comparisonOptions/yearOptions";
import { TooltipHelper } from "../tooltipHelper";
import { filterOperations } from "../../state/features/filters";
import { useDispatch } from "react-redux";

const SelectComparisonOptions = ({ mode }) => {
  switch (mode) {
    case "day":
      return <ComparisonDayOptions />;
    case "week":
      return <ComparisonWeekOptions />;
    case "month":
      return <ComparisonMonthOptions />;
    case "quarter":
      return <ComparisonQuarterOptions />;
    case "year":
      return <ComparisonYearOptions />;
    default:
      return <>INVALID SELECTION</>;
  }
};

const ComparisonSelect = () => {
  const comparison = useContext(ComparisonContext);
  const [showMenu, setShowMenu] = useState(false);

  const disableButton = useMemo(() => {
    if (comparison && comparison.comparisonMode) {
      return false;
    }
    return true;
  }, [comparison]);

  const dispatch = useDispatch();

  const resetFilters = () => {
    dispatch(filterOperations.clearWidgetFilter());
  };

  const handleExitComparison = () => {
    resetFilters();
    comparison.onExitComparison();
  };

  return (
    <ComparisonSelectContainer>
      {!comparison.showComparisonResults ? (
        <>
          {comparison?.comparisonMarketingContent ? (
            <Button
              className="marketing-content-button"
              onClick={() => comparison?.onShowComparisonMarketingContent()}>
              <GiFiles fontSize={"30px"} className="icon" />
            </Button>
          ) : (
            <Dropdown className="comparison-container">
              <TooltipHelper
                placement="bottom"
                tooltipText={
                  disableButton
                    ? "Choose time range to compare."
                    : "Compare selected date."
                }>
                <span>
                  <Dropdown.Toggle disabled={disableButton}>
                    <GiFiles fontSize={"30px"} className="icon" />
                  </Dropdown.Toggle>
                </span>
              </TooltipHelper>
              <Dropdown.Menu>
                <div className="dropdown-container">
                  <div className="title">Compare Selected date to: </div>
                  <SelectComparisonOptions mode={comparison.comparisonMode} />
                  <button
                    className="btn btn-primary"
                    disabled={comparison.submitDataLoading}
                    onClick={comparison.onComparisonApply}>
                    {comparison.submitDataLoading ? (
                      <Spinner animation="border" />
                    ) : (
                      "Apply"
                    )}
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </>
      ) : (
        <Button onClick={handleExitComparison} className="exit-comparison">
          <span>
            <GiFiles fontSize={"28px"} className="icon" />
          </span>
          <span>EXIT COMPARISON</span>
        </Button>
      )}
    </ComparisonSelectContainer>
  );
};

export default ComparisonSelect;

const fadeInTop = keyframes`
  0% {
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-50);
            transform: translateY(-50);
    opacity: 1;
  }
`;

const ComparisonSelectContainer = styled.div`
  margin-left: 15px;
  .marketing-content-button {
    border-color: ${({ theme }) => theme.widget.background};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    background-color: ${({ theme }) => theme.widget.background} !important;
    color: #0d8a6a;
    padding: 5px 10px;
  }
  .exit-comparison {
    text-align: left;
    font-size: 17px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: #ffffff;
      font-style: normal;
      font-weight: 700;
      margin-right: 10px;
      .icon path {
        font-size: 19px;
        stroke: #ffffff;
      }
      &:last-child {
        margin-right: 0;
        font-weight: 500;
      }
    }
    background-color: #c62828 !important;
    border-color: #c62828;
    > p {
      color: ${({ theme }) => theme.button.primary_border_color};
      margin: 0;
      margin-left: 20px;
      line-height: 25px;
    }
  }
  .comparison-container {
    .icon path {
      stroke: ${({ theme }) => theme.text};
    }
    > span {
      > .dropdown-toggle {
        &::after {
          content: none;
        }
        border-color: ${({ theme }) => theme.widget.background};
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        background-color: ${({ theme }) => theme.widget.background} !important;
        color: #0d8a6a;
        padding: 5px 10px;
        > p {
          color: ${({ theme }) => theme.button.primary_border_color};
          margin: 0;
          margin-left: 20px;
          font-style: normal;
          font-weight: 700;
          font-size: 19px;
          line-height: 25px;
          color: #ffffff;
        }
        > svg {
          width: 25px;
          height: 25px;
          path {
            stroke: #0d8a6a;
          }
        }
      }
    }
    > .dropdown-menu {
      animation: ${fadeInTop} 0.2s;
      min-width: 300px;
      padding: 20px;
      box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      border: 1px solid #dedede;
    }
    .dropdown-container {
      width: 100%;
      .title {
        font-size: 30px;
        font-weight: 500;
        line-height: 39px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 23px;
      }
      .option-list-container {
        p {
          margin: 0;
        }
        .list-item {
          display: block;
          padding: 10px;
          cursor: pointer;
          &:hover {
            background-color: ${({ theme }) => theme.body};
          }
          &.selected {
            background-color: transparent;
            border: 3px solid #82b884;
            border-radius: 8px;
          }
          &.days {
            width: 100%;
            > .custom-date-select {
              padding-top: 10px;
              padding-right: 10px;
              .dropdown-toggle {
                width: 100%;
                &::after {
                  content: none;
                }
              }
            }
          }
        }
      }
      > button {
        margin-top: 34px;
        width: 100%;
        height: 40px;
        border: none;
        background-color: rgb(13, 138, 106);
      }
    }
  }
`;
