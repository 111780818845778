import { useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { BsCalendar3 } from "react-icons/bs";
import styled, { keyframes } from "styled-components";
import InlineDatepicker from "../inlineDatepicker";
import { format } from "date-fns";
import { padNumberWithZero } from "../../helpers/utils";

const DatePicker = ({
  pickType,
  currDate,
  setDate,
  hour,
  setHour,
  minute,
  setMinute,
}) => {
  const datepickDropdownToggleRef = useRef(null);

  const handleToggleDropdown = () => {
    if (datepickDropdownToggleRef.current) {
      datepickDropdownToggleRef.current.click();
    }
  };
  return (
    <Wrapper>
      <p className="heading">{pickType == "start" ? "From: " : "To: "}</p>
      <div className="date-pick-wrapper">
        <input
          type="text"
          readOnly
          onClick={handleToggleDropdown}
          value={`${format(currDate, "dd MMMM yyyy")} - ${padNumberWithZero(
            hour
          )}:${padNumberWithZero(minute)}`}
        />
        <Dropdown>
          <Dropdown.Toggle ref={datepickDropdownToggleRef}>
            <BsCalendar3 style={{ fontSize: "14px", marginBottom: "5px" }} />
          </Dropdown.Toggle>
          <Dropdown.Menu className="date-pick-dropdown">
            <InlineDatepicker
              pickType={pickType}
              currDate={currDate}
              setDate={setDate}
              hour={hour}
              setHour={setHour}
              minute={minute}
              setMinute={setMinute}
            />
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Wrapper>
  );
};

export default DatePicker;

const fadeInTop = keyframes`
  0% {
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-50);
            transform: translateY(-50);
    opacity: 1;
  }
`;


const Wrapper = styled.div`
  .heading {
    font-size: 23px;
    font-weight: 400;
    line-height: 29.9px;
    text-align: left;
  }
  .date-pick-wrapper {
    display: flex;
    width: 300px;
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    > input[type="text"] {
      width: 100%;
      border: none;
      border-radius: 10px 0px 0px 10px;
      padding: 10px;
      border: ${({ theme }) => theme.date_range_picker.custom.border};
      color: ${({ theme }) => theme.date_range_picker.custom.color};
      background: ${({ theme }) => theme.date_range_picker.custom.background};
    }
    .dropdown-toggle::after {
      content: none;
    }
    .dropdown {
      .dropdown-toggle {
        border: none;
        border-radius: 0px 10px 10px 0px;
        height: 100%;
        color: ${({ theme }) => theme.text};
        background-color: ${({ theme }) => theme.date_range_picker.custom.background};
      }
      .date-pick-dropdown {
        animation: ${fadeInTop} 0.2s;
        min-width: 250px;
        padding: 0px;
        border: 1px solid ${({ theme }) => theme.border_color};
        border-radius: 10px;
        box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
        .inline-date-picker {
          margin: 0px;
        }
      }
    }
  }
`;
