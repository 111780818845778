import styled from "styled-components";

export const ExceedLimitIcon = () => (
  <StyledSvg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    version="1.1"
    viewBox="0 0 100 100"
  >
    <defs>
      <style></style>
    </defs>
    <path
      className="st2"
      d="M18.4,88.3l-14.8-12.7c-4.3-3.7-2.4-11.4-.5-15.6L23.7,18.7c2.9-5.3,6-9.8,10.8-6.7l15.7,12.7c4.9,4.4,2.4,10.4-.6,16.7l-19.2,38.4c-2.5,4.5-7.6,11.8-12,8.5Z"
    />
    <path
      className="st1"
      d="M38.8,88.3l-14.8-12.7c-4.3-3.7-2.4-11.4-.5-15.6l20.5-41.3c2.9-5.3,6-9.8,10.8-6.7l15.7,12.7c4.9,4.4,2.4,10.4-.6,16.7l-19.2,38.4c-2.5,4.5-7.6,11.8-12,8.5Z"
    />
    <path
      className="st0"
      d="M59.1,88.3l-14.7-12.7c-4.3-3.7-2.4-11.4-.5-15.6l20.5-41.3c2.9-5.3,6-9.8,10.8-6.7l15.7,12.7c4.9,4.4,2.4,10.4-.6,16.7l-19.2,38.4c-2.5,4.6-7.7,11.8-12.1,8.5Z"
    />
    <path
      className="st3"
      d="M91.3,56.9c-.1-.1-.3-.2-.5-.3-.2,0-.4-.1-.6-.1h-11.6c-.2,0-.4,0-.6.1-.2,0-.3.2-.5.3l-7.3,7.3c-.1.1-.2.3-.3.5s-.1.4-.1.6v11.6c0,.4.2.8.4,1l7.3,7.3c.1.1.3.2.5.3.2,0,.4.1.6.1h11.6c.4,0,.8-.2,1-.4l7.3-7.3c.1-.1.2-.3.3-.5,0-.2.1-.4.1-.6v-11.6c0-.2,0-.4-.1-.6s-.2-.3-.3-.5l-7.3-7.3ZM85.9,78.3h-2.9v-2.9h2.9v2.9ZM85.9,72.5h-2.9v-8.7h2.9v8.7Z"
    />
  </StyledSvg>
);

const StyledSvg = styled.svg`
  width: 60px;
  height: 60px;
  .st0 {
    fill: #ffb0b2;
  }

  .st1 {
    fill: #fa9fa1;
  }

  .st2 {
    fill: #f78e90;
  }

  .st3 {
    fill: #ee060a;
  }
`;
