import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { authOperations } from "../../../state/features/auth";
import CloseButton from "./modalCloseButton";
import { useFetchCompanies } from "../../../helpers/hooks/useFetchCompanies";
import { getEnvValueFor } from "../../../helpers/utils";
import styled from "styled-components";
import { useState } from "react";
import LoadingAnimation from "../../../components/loadingAnimation";

const CompanySwitchDialog = () => {
  const { data: authorizedCompanies } = useFetchCompanies();
  const { showCompanySwitcher, enableCompanySwitch } = useSelector(
    (state: RootState) => state.auth
  );
  const [redirectCompany, setRedirectCompany] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const userId = useSelector(
    (state: RootState) => state.company.company_details?.user_id
  );
  const companyId = useSelector(
    (state: RootState) => state.company.company_details?.company_id
  );
  const { toggleCompanySwitcher } = authOperations;

  if (!enableCompanySwitch) {
    return null;
  }

  const handleSelection = (companyData: any) => {
    setRedirectCompany(companyData)
    window.location.href = `${getEnvValueFor(
      "AUTH_MS_BASE_URL"
    )}/brand-switch/users/${userId}/companies/${
      companyData.id
    }/apps/vantage?jwt=${localStorage.getItem("jwt")}`;
  };

  return (
    <Modal show={showCompanySwitcher} dialogClassName="company-switch-dialog">
      <CloseButton onClick={() => toggleCompanySwitcher(false)} />
      <Modal.Body>
        <ModalContent>
          {redirectCompany ? (
            <div className="switching-wrapper">
              <div className="loading-wrapper">
                <LoadingAnimation />
              </div>
              <h4 className="heading">Switching to {redirectCompany?.name}</h4>
            </div>
          ) : (
            <>
              <div className="header-section">
                <h4 className="title">Switch brands</h4>
                <p className="subtitle">
                  Select a brand to explore its data and insights
                </p>
              </div>
              <div className="list-section">
                {authorizedCompanies &&
                  authorizedCompanies
                    .filter((d) => d.id !== companyId)
                    .map((c) => (
                      <p
                        key={c.id}
                        onClick={() => handleSelection(c)}
                        className="option">
                        {c.name}
                      </p>
                    ))}
              </div>
            </>
          )}
        </ModalContent>
      </Modal.Body>
    </Modal>
  );
};

export default CompanySwitchDialog;

const ModalContent = styled.div`
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.modal.background};
  .switching-wrapper {
    height: 200px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .header-section {
    padding: 10px 50px;
    .title {
      font-size: 23px;
      font-weight: 600;
      line-height: 29.9px;
      text-align: center;
      color: #609954;
    }
    .subtitle {
      font-size: 16px;
      font-weight: 400;
      line-height: 20.8px;
      text-align: center;
      color: #91ba88;
    }
  }
  .list-section {
    padding: 10px 10px;
    .option {
      &:hover {
        background-color: ${({ theme }) => theme.modal.hover};
      }
      cursor: pointer;
      font-size: 26px;
      font-weight: 500;
      line-height: 41.6px;
      text-align: center;
      border-bottom: 1px solid;
      border-image-source: linear-gradient(
        90deg,
        #ffffff 0%,
        #262626 50%,
        #ffffff 100%
      );
    }
  }
`;
