import { useContext } from "react";
import styled from "styled-components";
import { SubRDataContext } from "../dataProvider";
import { ORDER_TYPES } from "../utils";
import ContentLoader from "react-content-loader";
import { useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import { Spinner } from "react-bootstrap";

export default function OtherDetails() {
  const data = useContext<any>(SubRDataContext);
  const theme = useSelector((state: RootState) => state.theme.mode);

  return (
    <Container>
      <div className="details">
        {data.order_type == ORDER_TYPES.DINE_IN && (
          <>
            <div className="table-details">
              <span className="header">Table Number</span>
              <span className="data">
                {data.isOrderSummaryDataLoading && !data.itemsData ? (
                  <Spinner animation="border" size="sm" />
                ) : data.table_no ? (
                  data.table_no
                ) : (
                  "-"
                )}
              </span>
            </div>
            <div className="line"></div>
            <div className="cover-details">
              <span className="header">Covers</span>
              <span className="data">
                {data.isOrderSummaryDataLoading && !data.itemsData ? (
                  <Spinner animation="border" size="sm" />
                ) : data.cover ? (
                  data.cover
                ) : (
                  "-"
                )}
              </span>
            </div>
            <div className="line"></div>
          </>
        )}
        <div className="staff-details">
          <span className="header">Staff</span>
          <span className="data">{data.staff ? data.staff : "-"}</span>
        </div>
      </div>
      {data.isOrderSummaryDataLoading && !data.timelineData ? (
        <div className="loader-container">
          <ContentLoader
            height={180}
            speed={3}
            backgroundColor={theme === "light" ? "#f3f3f3" : "#2a2a2a"}
            foregroundColor={theme === "light" ? "#ecebeb" : "#1e1e1e"}
            style={{ width: "100%" }}
          >
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
          </ContentLoader>
        </div>
      ) : (
        <div className="order-notes">
          <span className="notes-header">Order Notes</span>
          {data?.timelineData?.order_notes ? (
            <span className="notes">
              <span>{data?.timelineData?.order_notes}</span>
            </span>
          ) : (
            <div className="no-data">No data available</div>
          )}
        </div>
      )}
    </Container>
  );
}

const Container = styled.div`
  background: ${({ theme }) => theme.widget.background};
  color: ${({ theme }) => theme.text};
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid rgb(255, 255, 255);
  filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 4px 4px);
  border-radius: 0.5rem;
  .details {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #e7e7e7;
    .line {
      height: 50px;
      border-right: 1px solid #a6a6a650;
      align-self: center;
    }
    .table-details,
    .cover-details,
    .staff-details {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      padding: 0 0.5rem;
      .header {
        opacity: 0.3;
        font-size: 11px;
        font-weight: 500;
        line-height: normal;
        text-align: center;
      }
      .data {
        font-size: 24px;
        font-weight: 600;
        line-height: normal;
      }
    }
    .staff-details {
      align-items: flex-start;
    }
  }
  .order-notes {
    padding-top: 0.5rem;
    display: flex;
    flex-direction: column;
    .notes-header {
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      opacity: 0.5;
      color: #828282;
    }
    .notes {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      font-size: 15px;
      font-weight: 400;
      line-height: normal;
      height: 150px;
      overflow: scroll;
    }
    .no-data {
      font-size: 16px;
      opacity: 0.5;
      height: 150px;
      margin-top: 0.5rem;
      display: flex;
      border: 1px solid #e6dfdf;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem;
    }
  }
`;
