import { useContext, useMemo } from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useElementSize } from "usehooks-ts";
import { WidgetContext } from "../../widgetContext";
import FunnelChart from "./funnelChart";
import WidgetEmptyData from "../../widgetEmptyData";
import Widget from "../../baseWidget";

interface IFunnelChartWidgetProps {}

const FunnelChartWidget = (props: IFunnelChartWidgetProps) => {
  const [widgetData, setWidgetData] = useState<any>({});
  const [widgetRef, contentDimensions] = useElementSize();
  const dispatch = useDispatch();

  const widget = useContext(WidgetContext);

  const widgetSeriesData = useMemo(() => {
    if (widgetData?.widget_data) {
      return widgetData.widget_data.data.series;
    }
    return [];
  }, [JSON.stringify(widgetData)]);

  useEffect(() => {
    if (widget.widgetData.widget_data) {
      setWidgetData(widget.widgetData);
    }
  }, [widget.widgetData]);

  const dimensions = useMemo(() => {
    let chart = { width: 0, height: 0 };
    let hPadding = 50;
    let vPadding = 90;
    if (contentDimensions.height > 0) {
      chart.width = contentDimensions.width - hPadding;
      chart.height = contentDimensions.height - vPadding;
    }
    return { chart };
  }, [JSON.stringify(contentDimensions)]);

  return (
    <Widget ref={widgetRef}>
      <Widget.Header>
        <Widget.Title>{widgetData?.title}</Widget.Title>
        <Widget.Controls></Widget.Controls>
      </Widget.Header>
      <Widget.Content>
        {widgetSeriesData.length > 0 ? (
          <FunnelChart
            data={widgetData.widget_data}
            height={dimensions.chart.height}
            width={dimensions.chart.width}
          />
        ) : (
          <WidgetEmptyData
            width={contentDimensions.width - 50}
            height={contentDimensions.height - 90}
          />
        )}
      </Widget.Content>
    </Widget>
  );
};

export default FunnelChartWidget;
