import { BiTrendingDown, BiTrendingUp } from "react-icons/bi";

export const renderIndicator = (indication: string) => {
  if (indication == "up") {
    return (
      <span className="up">
        <BiTrendingUp size={25} />
      </span>
    );
  }
  if (indication == "down") {
    return (
      <span className="down">
        <BiTrendingDown size={25} />
      </span>
    );
  }
  return null;
};
