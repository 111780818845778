import { useEffect, useMemo, useState } from "react";
import { useElementSize } from "usehooks-ts";
import MultiSeriesLineChart from "./multiSeriesLineChart";
import Widget from "../../baseWidget";
import { useWidgetContext } from "../../../helpers/hooks/useWidgetContext";
import { useDispatch, useSelector } from "react-redux";
import { addWidgetToFilterList } from "../../../state/features/analytics/actions";
import { getFilterOptionsFromCategories } from "./utils";
import { RootState } from "../../../state/store";

const MultiSeriesLineChartWidget = () => {
  const [widgetData, setWidgetData] = useState<any>();
  const [widgetRef, contentDimensions] = useElementSize();

  const widget = useWidgetContext();
  const dispatch = useDispatch();

  const { widgets: widgetFilters } = useSelector(
    (state: RootState) => state.filters
  );

  const widgetSeriesData = useMemo(() => {
    if (widgetData?.widget_data) {
      let series_data = widgetData.widget_data.data.series.filter(
        (i) => i.series_data.filter((j) => j.value)?.length
      );
      if (series_data.length) {
        return widgetData.widget_data.data.series;
      }
    }
    return [];
  }, [JSON.stringify(widgetData)]);

  useEffect(() => {
    if (widget.widgetData.widget_data) {
      setWidgetData(widget.widgetData);
      const widgetFilterExists = widgetFilters.find(
        (i) => i.key == widget?.widgetFilterConfig?.widgetFilterKey
      );
      if (widget?.widgetFilterConfig.enableFilters && !widgetFilterExists) {
        let filterConfig = {
          name: widget.widgetFilterConfig.filterTitle,
          filterKey: widget.widgetFilterConfig.widgetFilterKey,
          options: getFilterOptionsFromCategories(
            widget.widgetData,
            widget?.widgetData?.widget_data?.config?.filter_by ?? "series"
          ),
        };
        dispatch(addWidgetToFilterList(filterConfig));
      }
    }
  }, [widget.widgetData]);

  return (
    <Widget ref={widgetRef}>
      <Widget.Header>
        <Widget.Title>{widgetData?.title}</Widget.Title>
        <Widget.Controls>
          {widgetSeriesData.length > 0 ? <Widget.ExtendedAReportCta /> : null}
        </Widget.Controls>
      </Widget.Header>
      <Widget.Content>
        {widgetSeriesData.length > 0 ? (
          <MultiSeriesLineChart
            data={widgetData.widget_data}
            width={contentDimensions.width - 50}
            height={contentDimensions.height - 90}
          />
        ) : (
          <Widget.EmptyData
            width={contentDimensions.width - 50}
            height={contentDimensions.height - 90}
          />
        )}
      </Widget.Content>
    </Widget>
  );
};

export default MultiSeriesLineChartWidget;
