import useSWR from "swr";

type Params = {
  onSuccess?(data?: any, key?: string, config?: any): void;
};

const useFetchLocationsData = ({ onSuccess }: Params) => {
  const {
    data: locationData,
    isValidating: isLocationDataLoading,
    error: locationDataLoadError,
    mutate: fetchLocations,
  } = useSWR("/api/v1/locations/list", {
    revalidateOnMount: false,
    onSuccess: onSuccess,
  });

  return {
    locationData,
    isLocationDataLoading,
    locationDataLoadError,
    fetchLocations,
  };
};

export { useFetchLocationsData };
