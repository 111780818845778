import styled, { keyframes } from "styled-components";

export default function LoadingAnimation() {
  return (
    <LoadingWrapper>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 183.1 183.1"
        xmlSpace="preserve"
      >
        <g id="Layer_2">
          <path
            className="c4"
            d="M34.4,147.1L13,128.6c-6.3-5.4-3.5-16.6-0.7-22.6l29.9-60c4.2-7.7,8.8-14.3,15.8-9.7l22.9,18.4
		c7.2,6.4,3.5,15.1-0.9,24.2l-28,55.8C48.3,141.4,40.8,151.9,34.4,147.1z"
          />
          <path
            className="c3"
            d="M64.1,147.1l-21.5-18.4c-6.3-5.4-3.5-16.6-0.7-22.6l29.9-60c4.2-7.7,8.8-14.3,15.8-9.7l22.9,18.4
		c7.2,6.4,3.5,15.1-0.9,24.2l-28,55.8C77.9,141.4,70.5,151.9,64.1,147.1z"
          />
          <path
            className="c2"
            d="M93.8,147.1l-21.5-18.4c-6.3-5.4-3.5-16.6-0.7-22.6l29.9-60c4.2-7.7,8.8-14.3,15.8-9.7l22.9,18.4
		c7.2,6.4,3.5,15.1-0.9,24.2l-28,55.8C107.6,141.4,100.2,151.9,93.8,147.1z"
          />
          <path
            className="c1"
            d="M123.4,147.1L102,128.6c-6.3-5.4-3.5-16.6-0.7-22.6l29.9-60c4.2-7.7,8.8-14.3,15.8-9.7l22.9,18.4
		c7.2,6.4,3.5,15.1-0.9,24.2l-28,55.8C137.3,141.4,129.8,151.9,123.4,147.1z"
          />
        </g>
      </svg>
    </LoadingWrapper>
  );
}

const c1_animation = keyframes`
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(80px) scale(0.7);
        opacity: 0;
    }
`;

const c2_animation = keyframes`
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(30px);
        fill: #91A1AC;
    }
`;

const c3_animation = keyframes`
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(30px);
        fill: #6E7E88;
    }
`;

const c4_animation = keyframes`
    from{
        opacity: 0;
        transform: translateX(0) translateY(25px) scale(0.7);
    }
    to{
        opacity: 1;
        transform: translateX(30px);
        fill: #57666D;
    }
`;

const LoadingWrapper = styled.div`
  width: 80px;
  svg {
    enable-background: new 0 0 183.1 183.1;
    .c1 {
      animation-name: ${c1_animation};
      animation-duration: 0.8s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      fill: #91a1ac;
    }
    .c2 {
      animation-name: ${c2_animation};
      animation-duration: 0.8s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      fill: #6e7e88;
    }
    .c3 {
      animation-name: ${c3_animation};
      animation-duration: 0.8s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      fill: #57666d;
    }
    .c4 {
      animation-name: ${c4_animation};
      animation-duration: 0.8s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      transform: translateX(0) scale(0.9);
      opacity: 0;
      fill: #57666d;
    }
  }
`;
