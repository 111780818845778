import { useEffect, useMemo, useState } from "react";
import Widget from "../../baseWidget";
import { useWidgetContext } from "../../../helpers/hooks/useWidgetContext";
import { useElementSize } from "usehooks-ts";
import WaterfallChart from "./waterfallChart";
import WidgetEmptyData from "../../widgetEmptyData";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";

const WaterfallChartWidget = () => {
  const [widgetData, setWidgetData] = useState<any>(null);
  const [widgetRef, contentDimensions] = useElementSize();

  const widget = useWidgetContext();

  // const { widgets: widgetFilters } = useSelector(
  //   (state: RootState) => state.filters
  // );

  const widgetSeriesData = useMemo(() => {
    if (widgetData?.data.length) {
      return widgetData.data;
    }
    return [];
  }, [JSON.stringify(widgetData)]);

  useEffect(() => {
    if (widget.widgetData.data) {
      setWidgetData(widget.widgetData);
      // const widgetFilterExists = widgetFilters.find(
      //   (i) => i.key == widget?.widgetFilterConfig?.widgetFilterKey
      // );
      // if (widget.widgetFilterConfig.enableFilters && !widgetFilterExists) {
        // dispatch(
        //   addWidgetToFilterList({
        //     name: widget.widgetFilterConfig.filterTitle,
        //     filterKey: widget.widgetFilterConfig.widgetFilterKey
        //   })
        // );
      // }
    }
  }, [widget.widgetData]);

  const dimensions = useMemo(() => {
    let chart = { width: 0, height: 0 };
    let hPadding = 50;
    let vPadding = 90;
    if (contentDimensions.height > 0) {
      chart.width = contentDimensions.width - hPadding;
      chart.height = contentDimensions.height - vPadding;
    }
    return { chart };
  }, [JSON.stringify(contentDimensions)]);

  return (
    <Widget ref={widgetRef}>
      <Widget.Header>
        <Widget.Title>{widgetData?.title}</Widget.Title>
        <Widget.Controls></Widget.Controls>
      </Widget.Header>
      <Widget.Content>
        {widgetSeriesData.length > 0 ? (
          <WaterfallChart
            data={widgetData}
            height={dimensions.chart.height}
            width={dimensions.chart.width}
          />
        ) : (
          <WidgetEmptyData
            width={contentDimensions.width - 50}
            height={contentDimensions.height - 90}
          />
        )}
      </Widget.Content>
    </Widget>
  );
};

export default WaterfallChartWidget;
