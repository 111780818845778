import { useEffect, useState } from "react";
import { EChartsOption } from "echarts";
import BaseChart from "../../baseChart";

export type DonutChartConfiguration = {
  config: {
    selector: string;
    value_formatter: {
      prefix: string;
      postfix: string;
    };
  };
  data: {
    series: { category: string; value: number; id: number | string }[];
  };
};
interface DonutChartProps {
  width: number;
  height: number;
  data: DonutChartConfiguration;
}

const DonutChart = (props: DonutChartProps) => {
  const [option, setOption] = useState<EChartsOption>({
    tooltip: {
      trigger: "item",
    },
    legend: {
      type: "scroll",
      orient: "vertical",
      left: "60%",
    },
    series: [],
  });

  let itemsArray = props.data.data.series
    .sort((a, b) => parseFloat(b.value) - parseFloat(a.value))
    .slice(0, 10);

  useEffect(() => {
    if (props.data) {
      setOption((o) => {
        let updatedOption = { ...o };
        updatedOption.tooltip = {
          ...updatedOption.tooltip,
          formatter: (params) => `
            <div>
              <h5>${params.percent} %</h5>
              <b>${params.name}</b> - ${
            props.data.config.value_formatter.prefix
          } ${formatNumber(params.value)} ${
            props.data.config.value_formatter.postfix
          }
            </div>
          `,
        };
        updatedOption.series = [
          {
            type: "pie",
            name: props.data.config.name,
            radius: ["50%", "85%"],
            right: "50%",
            width: "auto",
            height: "auto",
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "center",
              formatter: "{a}",
              fontWeight: "bold",
              fontSize: 15,
            },
            labelLine: {
              show: false,
            },
            data: itemsArray.map((d) => ({
              name: d.category,
              value: d.value,
              id: d.id,
            })),
          },
        ];
        return updatedOption;
      });
    }
  }, [props.data]);

  return (
    <BaseChart
      height={props.height}
      width={props.width}
      options={option}
      chartType="donut"
    />
  );
};

export default DonutChart;
