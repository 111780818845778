import { useEChartsOption } from "../../../helpers/hooks/useEchartsOption";
import { dataZoomSliderConfig, numFormatter } from "../../../helpers/utils";
import BaseChart from "../../baseChart";

interface IStackedChart {
  height: number;
  width: number;
  data: any;
  limitBars?: number;
}

const StackedBarChart = ({ height, width, data, limitBars=40 }) => {
  const option = useEChartsOption(() => {
    if (data) {
      let updatedOption: any = {};
      let categories = data?.data?.series[0]?.values.map((c) => c.category) ?? [];
      updatedOption.legend = {
        ...updatedOption.legend,
        show: true,
        top: "center",
        right: "10px",
        type: "scroll",
        orient: "vertical",
        data: data.data.series.map((s) => s.name),
      };
      if (width < 650) {
        updatedOption.legend = {
          ...updatedOption.legend,
          top: "10px",
          right: 0,
          type: "scroll",
          orient: "horizontal",
        };
        updatedOption.grid = {
          left: "20%",
          right: "5%",
        };
      } else {
        updatedOption.grid = {
          left: "10%",
          right: "160px",
        };
      }
      if (data.data?.series.length && data.data.series[0].values.length > limitBars) {
        updatedOption.dataZoom = dataZoomSliderConfig(limitBars-1);
      }
      updatedOption.tooltip = {
        show: true,
        trigger: "axis",
        axisPointer: {
          show: true,
          type: "shadow",
        },
        formatter: (params) => {
          let nonZeroValues = params.filter((d) => d.value !== 0);
          let list = nonZeroValues
            .map(
              (d) => `
            <div style="margin-bottom:5px;">
            ${d.marker}
            <span style="display:inline-block; margin-right:10px;">${
              d.seriesName
            }</span>
            <span style="display:inline-block; float:right; font-weight:900;">${formatNumber(d.value)} ${
                data.config.value_formatter
              }</span>
            </div>
            `
            )
            .join("");
          return `<div style="max-width:500px;">
          <h6>${params[0].axisValue}</h6>
          ${list}</div>`;
        },
      };
      let axisLabel = {
        formatter: (value) =>
          `${numFormatter(value)} ${data.config.value_formatter}`,
      };
      if (data.config.direction === "horizontal") {
        updatedOption.xAxis = { 
          type: "value",
          axisPointer: {
            type: "line",
            show: true,
            triggerTooltip: false,
            label: {
              show: true,
            }
        }};
        updatedOption.yAxis = { 
          type: "category", 
          data: categories, 
          axisLabel,
          axisPointer: {
            type: "shadow",
            show: true,
            label: {
              show: true
            }
        }};
      } else {
        updatedOption.yAxis = { 
          type: "value", 
          axisLabel,
          axisPointer: {
            type: "line",
            show: true,
            triggerTooltip: false,
            label: {
              show: true,
            }
        }};
        updatedOption.xAxis = { 
          type: "category", 
          data: categories,
          axisPointer: {
            type: "shadow",
            show: true,
            label: {
              show: true
            }
        }};
      }
      updatedOption.series = data.data.series.map((d) => ({
        type: "bar",
        name: d.name,
        stack: "total",
        label: {
          show: false,
        },
        data:
          d.values.length && d.values[0].percentage_value
            ? d.values.map((v) => v.percentage_value)
            : d.values.map((v) => v.value),
      }));
      return updatedOption;
    }
    return {};
  }, [data, width]);

  return (
    <BaseChart
      height={height}
      width={width}
      options={option}
      chartType={"bar"}
    />
  );
};

export default StackedBarChart;
