import { useContext } from "react";
import styled from "styled-components";
import { SubRDataContext } from "./dataProvider";

export default function LoadingErrorScreen() {
  const data = useContext(SubRDataContext);

  return (
    <Wrapper>
      <div className="error-container">
        <b> An Error has occured </b>
        <div>
          <button className=" btn btn-primary" onClick={data.reloadData}>
            Reload
          </button>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  b {
    font-size: 20px;
  }
  .error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .btn {
    margin-top: 1rem;
  }
`;
