import { ErrorBoundary } from "react-error-boundary";
import ApplicationErrorScreen from "./applicationErrorScreen";
import { reportExceptionToSentry } from "../../services/sentry/utils";

const AppErrorBoundary = ({ children }) => {
  const handleOnExceptionCaught = (error: Error) => {
    reportExceptionToSentry({
      title: `Application Error`,
      message: `${error.name}: ${error.message}`,
      stack: error.stack,
    });
  }
  return (
    <ErrorBoundary
      FallbackComponent={ApplicationErrorScreen}
      onError={handleOnExceptionCaught}>
      {children}
    </ErrorBoundary>
  );
};

export default AppErrorBoundary;
