import { useEffect, useMemo, useState } from "react";
import { useElementSize } from "usehooks-ts";
import BarLineComboChart from "./barComboChart";
import { useDispatch, useSelector } from "react-redux";
import { CustomDispatch, RootState } from "../../../state/store";
import { addWidgetToFilterList } from "../../../state/features/analytics/actions";
import WidgetEmptyData from "../../widgetEmptyData";
import Widget from "../../baseWidget";
import { useWidgetContext } from "../../../helpers/hooks/useWidgetContext";

const BarLineComboWidget = () => {
  const [widgetData, setWidgetData] = useState<any>({});
  const [containerRef, containerDimensions] = useElementSize();

  const widget = useWidgetContext();

  const { widgets: widgetFilters } = useSelector(
    (state: RootState) => state.filters
  );

  const dispatch: CustomDispatch = useDispatch();

  useEffect(() => {
    if (widget.widgetData.widget_data) {
      setWidgetData(widget.widgetData);
      const widgetFilterExists = widgetFilters.find(
        (i) => i.key == widget?.widgetFilterConfig?.widgetFilterKey
      );
      if (widget.widgetFilterConfig.enableFilters && !widgetFilterExists) {
        dispatch(
          addWidgetToFilterList({
            name: widget.widgetFilterConfig.filterTitle,
            filterKey: widget.widgetFilterConfig.widgetFilterKey,
            options: widget.widgetData.widget_data.data.series.map((d) => ({
              id: d.bar_id,
              name: d.category,
            })),
          })
        );
      }
    }
  }, [widget.widgetData]);

  const widgetSeriesData = useMemo(() => {
    if (widgetData.widget_data) {
      return widgetData.widget_data.data.series;
    }
    return [];
  }, [JSON.stringify(widgetData)]);

  const updateSeriesData = (modifiedSeries) => {
    setWidgetData(() => {
      let data = widget.widgetData;
      return {
        ...data,
        widget_data: {
          ...widgetData.widget_data,
          data: {
            series: modifiedSeries,
          },
        },
      };
    });
  };

  const handleWidgetOnClick = () => {
    if (widget.widgetOnClick) {
      widget.widgetOnClick();
    }
  };

  return (
    <Widget ref={containerRef}>
      <Widget.Header>
        <Widget.Title onClick={handleWidgetOnClick}>
          {widgetData.title}
        </Widget.Title>
        <Widget.Controls>
          {widget.widgetData.widget_data && widgetSeriesData.length > 0 ? (
            <>
              <Widget.ExtendedAReportCta />
              <Widget.DataFilterControl
                seriesData={widget.widgetData.widget_data.data.series}
                sortKey={widget.widgetData.widget_data.config.sort_by}
                updateSeriesData={updateSeriesData}
                enableSorting={Boolean(
                  widget.widgetData.widget_data.config.sort_by
                )}
                enableFiltering={false}
              />
              <Widget.AiSuggestionCta />
            </>
          ) : null}
        </Widget.Controls>
      </Widget.Header>
      <Widget.Content>
        {widgetSeriesData.length > 0 ? (
          <BarLineComboChart
            limitBars={5}
            data={widgetData.widget_data}
            width={containerDimensions.width - 50}
            height={containerDimensions.height - 90}
          />
        ) : (
          <WidgetEmptyData
            width={containerDimensions.width - 50}
            height={containerDimensions.height - 90}
          />
        )}
      </Widget.Content>
    </Widget>
  );
};

export default BarLineComboWidget;
