import { rmsApi } from "../../../state/axios";


const saveNewView = async (payload) => {
  try {
    let result = await rmsApi.post(
      `/api/v1/dar/${payload.dashboard}/${payload.widget}/${payload.url}/extended/views`,
      payload
    );
    return Promise.resolve(result);
  } catch (e) {
    return Promise.reject(e);
  }
};

const updateView = async (
  id: string,
  payload: { name: string; payload: any; dashboard: string; widget: string; url: string }
) => {
  try {
    let result = await rmsApi.patch(
      `/api/v1/dar/${payload.dashboard}/${payload.widget}/${payload.url}/extended/views/${id}`,
      payload
    );
    return Promise.resolve(result);
  } catch (e) {
    return Promise.reject(e);
  }  
};

const deleteView = async (
  id,
  payload: { dashboard: string; widget: string; url: string }
) => {
  try {
    let result = await rmsApi.delete(
      `/api/v1/dar/${payload.dashboard}/${payload.widget}/${payload.url}/extended/views/${id}`
    );
    return Promise.resolve(result);
  } catch (e) {
    return Promise.reject(e);
  }
};

const removeDataSourceFromSnapshot = (snapshot) => {
  return {
    ...snapshot,
    dataSourceSettings: {
      ...snapshot.dataSourceSettings,
      dataSource: [],
    },
  };
};

const mergeDataSourceToViewData = (data, viewData) => {
  return {
    ...viewData,
    dataSourceSettings: {
      ...viewData.dataSourceSettings,
      dataSource: data,
    },
  };
};

export {
  saveNewView,
  updateView,
  deleteView,
  removeDataSourceFromSnapshot,
  mergeDataSourceToViewData,
};
