import { useEffect } from "react";
import WidgetErrorBoundary from "../../../../components/widgetErrorBoundary";
import SelectWidget from "../../../../components/widgetLoader/selectWidget";
import { BSWidgetContext } from "../../contexts/bsWidgetContext";
import { useBSDashboardContext } from "../../hooks/useBSDashboardContext";
import { useFetchBSWidgetData } from "../../hooks/useFetchBSWidgetData";

const BSWidgetLoader = ({ widget }) => {
  const { data, error, isLoading, mutate } = useFetchBSWidgetData({
    url: widget.url,
  });

  const { addWidgetDataForPrint } = useBSDashboardContext();

  useEffect(() => {
    if (data) {
      addWidgetDataForPrint({ [widget.url]: data.widget });
    }
  }, [data]);

  const reloadData = () => {
    mutate(undefined, { revalidate: true });
  };

  return (
    <WidgetErrorBoundary type={widget.type} url={widget.url}>
      <BSWidgetContext.Provider
        value={{
          widgetData: data,
          dataFetchError: error,
          isFetchingData: isLoading,
          reload: reloadData,
        }}>
        <SelectWidget widgetType={widget.type} />
      </BSWidgetContext.Provider>
    </WidgetErrorBoundary>
  );
};

export default BSWidgetLoader;
