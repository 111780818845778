import { useState } from "react";
import { useEventListener } from "usehooks-ts";

const DashboardMarketingContentView = ({ url = "https://sapaad.com" }) => {
  const [contentDims, setContentDims] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEventListener("resize", () => {
    setContentDims({ width: window.innerWidth, height: window.innerHeight });
  });
  return (
    <iframe
      src={url}
      height={contentDims.height}
      width={contentDims.width}></iframe>
  );
};

export default DashboardMarketingContentView;
