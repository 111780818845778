import ReactDOM from "react-dom/client";
import { Provider as StoreProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import AppErrorBoundary from "../components/appErrorBoundary";
import { EnvProvider } from "../components/envContext";
import App from "../containers/app";
import { store } from "../state/store";
import { getEnvValueFor } from "../helpers/utils";
import PusherProvider from "../components/pusherProvider";
import DashboardEvents from "../components/dashboardEvents";
import CacheProvider from "../components/cacheProvider";
import PendingResultWaitEvents from "../components/pendingResultWaitEvents";
import { registerSyncfusionLicenseKey } from "../features/extendedAnalytics/helpers/validateLicense";
import initializeSentryErrorReporting from "../services/sentry/init";

const rootElement = document.getElementById("root") as HTMLDivElement;
const root = ReactDOM.createRoot(rootElement);

initializeSentryErrorReporting();
registerSyncfusionLicenseKey(getEnvValueFor("SYNCFUSION_LICENSE_KEY"));

root.render(
  <AppErrorBoundary>
    <EnvProvider envObject={rootElement.dataset}>
      <PusherProvider>
        <CacheProvider>
          <DashboardEvents>
            <PendingResultWaitEvents>
              <StoreProvider store={store}>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </StoreProvider>
            </PendingResultWaitEvents>
          </DashboardEvents>
        </CacheProvider>
      </PusherProvider>
    </EnvProvider>
  </AppErrorBoundary>
);
