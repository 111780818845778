import * as actions from "./actions";

const setLocationFilter = actions.setLocationFilter;
const setTimeRangeFilter = actions.setTimeRangeFilter;
const setWidgetFilter = actions.setWidgetFilter;
const clearWidgetFilter = actions.clearWidgetFilter;
const removeWidgetFilter = actions.removeWidgetFilter;
const setDropdownFilters = actions.setDropdownFilters;
const setDateRangeWithLocations = actions.setDateRangeWithLocations;

export default {
  setLocationFilter,
  setTimeRangeFilter,
  setWidgetFilter,
  clearWidgetFilter,
  removeWidgetFilter,
  setDropdownFilters,
  setDateRangeWithLocations,
};
