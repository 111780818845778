import { useState } from "react";
import * as viewOperations from "../helpers/viewOperations";
import Swal from "sweetalert2";
import { useFetchExtendedAViews } from "./useFetchExtendedAViews";

const useExtendedAViews = ({
  dashboard = "",
  dashboardWidget = "",
  widgetUrl = "",
  onViewSave = () => {},
  onViewSaveSuccess = () => {},
  onViewSaveError = () => {},
  onViewUpdate = () => {},
  onViewUpdateSuccess = () => {},
  onViewUpdateError = () => {},
  onViewDelete = () => {},
  onViewDeleteSuccess = () => {},
  onViewDeleteError = () => {},
}) => {
  const [isViewSaveInProgress, setIsViewSaveInProgress] = useState(false);
  const [isViewSaveError, setIsViewSaveError] = useState(false);

  const [isViewUpdateInProgress, setIsViewUpdateInProgress] = useState(false);
  const [isViewUpdateError, setIsViewUpdateError] = useState(false);

  const [isViewDeleteInProgress, setIsViewDeleteInProgress] = useState(false);
  const [isViewDeleteError, setIsViewDeleteError] = useState(false);

  const [savedViews, setSavedViews] = useState([]);

  const [isShowSavedViews, setShowSavedViewsDialog] = useState(false);
  const [isShowSaveCurrentView, setShowSaveCurrentViewDialog] = useState(false);

  const { isLoading: isFetchViewsInProgress, retry: fetchViews } =
    useFetchExtendedAViews({
      dashboard,
      dashboardWidget,
      widgetUrl,
      shouldFetch: isShowSavedViews,
      setSavedViews,
    });

  const saveNewView = async ({ name, snapshot }) => {
    setIsViewSaveInProgress(true);
    setIsViewSaveError(false);
    onViewSave();
    try {
      let result = await viewOperations.saveNewView({
        name,
        payload: snapshot,
        dashboard,
        widget: dashboardWidget,
        url: widgetUrl,
      });
      if (result.status == 200) {
        setIsViewSaveInProgress(false);
        onViewSaveSuccess();
      }
    } catch (error) {
      setIsViewSaveInProgress(false);
      setIsViewSaveError(true);
      onViewSaveError();
    }
  };

  const updateView = async ({ id, name, snapshot }) => {
    const feedback = await Swal.fire({
      icon: "question",
      title: "Do you want to overwrite this view with current one?",
      text: "This action is irreversable",
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });
    if (feedback.isConfirmed) {
      setIsViewUpdateInProgress(true);
      setIsViewUpdateError(false);
      onViewUpdate();
      try {
        let result = await viewOperations.updateView(id, {
          name,
          payload: snapshot,
          dashboard,
          widget: dashboardWidget,
          url: widgetUrl,
        });
        if (result.status == 200) {
          setIsViewUpdateInProgress(false);
          onViewUpdateSuccess();
        }
      } catch (error) {
        setIsViewUpdateInProgress(false);
        setIsViewUpdateError(true);
        onViewUpdateError();
      }
    }
  };

  const deleteView = async ({ id }) => {
    const feedback = await Swal.fire({
      icon: "question",
      title: "Are you sure you want to delete this view?",
      text: "This action is irreversable",
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });
    if (feedback.isConfirmed) {
      setIsViewDeleteInProgress(true);
      setIsViewDeleteError(false);
      onViewDelete();
      try {
        let result = await viewOperations.deleteView(id, {
          dashboard,
          widget: dashboardWidget,
          url: widgetUrl,
        });
        if (result.status == 200) {
          setIsViewDeleteInProgress(false);
          onViewDeleteSuccess();
        }
      } catch (error) {
        setIsViewDeleteError(true);
        onViewDeleteError();
      }
    }
  };

  const handleShowSavedViews = () => {
    setShowSavedViewsDialog(true);
  };

  const handleHideSavedViews = () => {
    setShowSavedViewsDialog(false);
  };

  const handleShowSaveCurrentView = () => {
    setShowSaveCurrentViewDialog(true);
  };

  const handleHideSaveCurrentView = () => {
    setShowSaveCurrentViewDialog(false);
  };

  return {
    savedViews,
    saveNewView,
    updateView,
    deleteView,
    isViewSaveInProgress,
    isViewSaveError,
    isViewUpdateInProgress,
    isViewUpdateError,
    isViewDeleteInProgress,
    isViewDeleteError,
    isFetchViewsInProgress,
    fetchViews,
    isShowSavedViews,
    isShowSaveCurrentView,
    removeDataSourceFromSnapshot: viewOperations.removeDataSourceFromSnapshot,
    mergeDataSourceToViewData: viewOperations.mergeDataSourceToViewData,
    showSavedViews: handleShowSavedViews,
    hideSavedViews: handleHideSavedViews,
    showSaveCurrentView: handleShowSaveCurrentView,
    hideSaveCurrentView: handleHideSaveCurrentView,
  };
};

export { useExtendedAViews };
