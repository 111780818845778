import { ErrorBoundary } from "react-error-boundary";
import FetchErrorView from "../fetchErrorView";

const ErrorBoundaryView = ({ children }: { children: React.ReactNode }) => {
  return (
    <ErrorBoundary
      fallbackRender={({ resetErrorBoundary }) => (
        <FetchErrorView
          errorType="report"
          refreshData={resetErrorBoundary}
        />
      )}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryView;
