import { dispatchAction } from "../../store";
import * as actions from "./slice";

const authorizeUser = (params) => dispatchAction(actions.authorizeUser(params));
const setInitiateTicker = (params) =>
  dispatchAction(actions.setInitiateTicker(params));
const setJwtData = (params) => dispatchAction(actions.setJwtData(params));
const setSessionExpired = () => dispatchAction(actions.setSessionExpired());
const toggleCompanySwitcher = (params) =>
  dispatchAction(actions.toggleCompanySwitcher(params));

export default {
  authorizeUser,
  setInitiateTicker,
  setJwtData,
  setSessionExpired,
  toggleCompanySwitcher,
};
