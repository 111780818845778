import { useContext, useLayoutEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { SubRDataContext } from "../dataProvider";
import ContentLoader from "react-content-loader";
import { useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import LoadingErrorScreen from "../loadingError";
import { OverlayTrigger, Popover } from "react-bootstrap";

export default function Order() {
  const paymentRef = useRef<HTMLElement>(null);
  const [height, setHeight] = useState(200);
  const data = useContext(SubRDataContext);
  const theme = useSelector((state: RootState) => state.theme.mode);

  useLayoutEffect(() => {
    const paymentHeight = paymentRef?.current?.scrollHeight;
    if (paymentHeight) {
      setHeight(438 - paymentHeight);
    }
  }, [paymentRef]);

  const itemsWithModifiers = useMemo(() => {
    if (data.itemsData) {
      let itemsModifierExclude = data.itemsData.filter(
        (itm) => parseInt(itm.source_order_item_parent_id) === 0
      );
      const a = itemsModifierExclude.map((item) => {
        let itemModifiers = data.itemsData.filter((itm) => {
          return itm.source_order_item_parent_id === item.source_order_item_id;
        });
        let hasModifiers = itemModifiers.length > 0;
        return { baseItem: item, itemModifiers, hasModifiers };
      });
      return a;
    }
    return null;
  }, [JSON.stringify(data.itemsData)]);

  const itemTotal = (item) => {
    let modifierTotal = item.itemModifiers.reduce(function (total, arr) {
      return total + parseFloat(arr.item_amount);
    }, 0);
    return parseFloat(
      modifierTotal + parseFloat(item.baseItem.item_amount)
    ).toFixed(2);
  };

  if (data.isOrderSummaryDataLoading && !data.itemsData) {
    return (
      <Container>
        <div className="loader-container">
          <ContentLoader
            height={470}
            speed={3}
            backgroundColor={theme === "light" ? "#f3f3f3" : "#2a2a2a"}
            foregroundColor={theme === "light" ? "#ecebeb" : "#1e1e1e"}
            style={{ width: "100%" }}
          >
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
          </ContentLoader>
        </div>
      </Container>
    );
  }

  if (data.isOrderSummaryDataLoadError && !data.itemsData) {
    return (
      <Container>
        <div className="error-screen">
          <LoadingErrorScreen />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <div className="order-details">
        <div className="details-header">
          <span>Item</span>
          <span>Price</span>
          <span>Quantity</span>
          <span>Total</span>
        </div>
        <div
          className="content-wrapper"
          style={{ height: `${height < 250 ? 250 : height}px` }}
        >
          {itemsWithModifiers
            ? itemsWithModifiers.map((orderItem, index: any) => {
                return (
                  <div className="content-row" key={index}>
                    <div className="content-item">
                      {orderItem?.baseItem?.item}
                      <ul>
                        {orderItem?.hasModifiers
                          ? orderItem?.itemModifiers?.map((m, i) => {
                              let quantity =
                                parseFloat(m.item_quantity) /
                                parseFloat(orderItem?.baseItem?.item_quantity);
                              let price =
                                parseFloat(m.item_price) *
                                parseFloat(quantity.toString()) *
                                parseFloat(orderItem?.baseItem?.item_quantity);
                              return (
                                <li className="modifier-item" key={i}>
                                  <span>
                                    <span>
                                      {parseFloat(quantity.toString()).toFixed(
                                        2
                                      )}{" "}
                                      ×
                                    </span>
                                    <span>{m.modifier_item}</span>
                                    {price > 0 ? (
                                      <span style={{ color: "#0D8A6A" }}>
                                        +{" "}
                                        {formatNumber(
                                          parseFloat(price.toString()).toFixed(
                                            decimalPrecision
                                          )
                                        )}
                                      </span>
                                    ) : (
                                      false
                                    )}
                                  </span>
                                </li>
                              );
                            })
                          : false}
                      </ul>
                    </div>
                    <div className="content-item">
                      {formatNumber(
                        parseFloat(orderItem?.baseItem?.item_price).toFixed(
                          decimalPrecision
                        )
                      )}
                    </div>
                    <div className="content-item">
                      {formatNumber(
                        parseFloat(orderItem?.baseItem?.item_quantity).toFixed(
                          decimalPrecision
                        )
                      )}
                    </div>
                    <div className="content-item">
                      {formatNumber(itemTotal(orderItem))}
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
      <div className="payments-details" ref={paymentRef}>
        {parseFloat(data.orderAmountBreakdowns.discountTotal) ? (
          <span className="details-breakdown">
            <span>Discount</span>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="top"
              overlay={
                <PopoverWrapper className="tooltip">
                  {data.popupData.discountsDetails.map((d, i) => {
                    return (
                      <div key={i}>
                        <span>{d.name}</span>:
                        <span>
                          {formatNumber(
                            parseFloat(d.value).toFixed(decimalPrecision)
                          )}
                        </span>
                      </div>
                    );
                  })}
                </PopoverWrapper>
              }>
              <span className="more-details">
                {formatNumber(
                  parseFloat(data.orderAmountBreakdowns.discountTotal).toFixed(
                    decimalPrecision
                  )
                )}
              </span>
            </OverlayTrigger>
          </span>
        ) : null}
        {parseFloat(data.orderAmountBreakdowns.surchargeTotal) ? (
          <span className="details-breakdown">
            <span>Surcharge</span>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="top"
              overlay={
                <PopoverWrapper className="tooltip">
                  {data.popupData.surchargeDetails.map((d, i) => {
                    return (
                      <div key={i}>
                        <span>{d.name}</span>:
                        <span>
                          {formatNumber(
                            parseFloat(d.value).toFixed(decimalPrecision)
                          )}
                        </span>
                      </div>
                    );
                  })}
                </PopoverWrapper>
              }>
              <span className="more-details">
                {formatNumber(
                  parseFloat(data.orderAmountBreakdowns.surchargeTotal).toFixed(
                    decimalPrecision
                  )
                )}
              </span>
            </OverlayTrigger>
          </span>
        ) : null}
        {parseFloat(data.orderAmountBreakdowns.taxTotal) ? (
          <span className="details-breakdown">
            <span>Tax</span>
            <span>
              {formatNumber(
                parseFloat(data.orderAmountBreakdowns.taxTotal).toFixed(
                  decimalPrecision
                )
              )}
            </span>
          </span>
        ) : null}
        <span className="details-breakdown">
          <span>Sub Total</span>
          <span>
            {formatNumber(
              parseFloat(data.orderAmountBreakdowns.subTotal).toFixed(
                decimalPrecision
              )
            )}
          </span>
        </span>
        <span className="details-breakdown total">
          <span>Total</span>
          <span>
            {formatNumber(
              parseFloat(data.orderAmountBreakdowns.totalAmount).toFixed(
                decimalPrecision
              )
            )}
          </span>
        </span>
      </div>
    </Container>
  );
}

const PopoverWrapper = styled(Popover)`
  color: #000;
  padding: 0.5rem;
  opacity: 1 !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    span {
      &:first-child {
        width: 60%;
        text-align: left;
      }
      &:last-child {
        width: 40%;
        text-align: right;
      }
    }
  }
`;

const Container = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  font-weight: 500;
  line-height: normal;
  background: ${({ theme }) => theme.widget.background};
  color: ${({ theme }) => theme.text};
  border-radius: 0.5rem;
  border: 1px solid rgb(255, 255, 255);
  filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 4px 4px);
  .error-screen {
    height: 470px;
  }
  .payments-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 0.25rem;
    padding: 0.5rem;
    .details-breakdown {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: right;
      gap: 2rem;
      width: 200px;
      span {
        font-size: 16px;
        &.more-details {
          color: #0094ff;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .total {
      font-weight: 700;
    }
  }
  .order-details {
    border-radius: 0.5rem 0.5rem 0 0;
    overflow: hidden;
    .details-header {
      display: flex;
      padding: 0.5rem;
      font-size: 11px;
      opacity: 0.5;
      background-color: ${({ theme }) => theme.body};
      span {
        width: 20%;
        &:first-child {
          width: 40%;
        }
        &:last-child {
          text-align: right;
        }
      }
    }
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    border-top: 1px solid #e6dfdf;
    border-bottom: 1px solid #e6dfdf;
    .content-row {
      display: flex;
      padding: 0.5rem;
      border-bottom: 1px solid rgba(130, 130, 130, 0.2);
      &:last-child {
        border-bottom: none;
      }
      .content-item {
        color: ${({ theme }) => theme.text};
        font-size: 15px;
        letter-spacing: 0;
        line-height: normal;
        text-align: left;
        width: 20%;
        &:first-child {
          width: 40%;
        }
        &:last-child {
          text-align: right;
        }
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          display: inline;
          .modifier-item {
            position: relative;
            padding-left: 20px;
            text-transform: capitalize;
            line-height: 25px;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: left;
            &::after {
              position: absolute;
              content: "";
              left: 5px;
              top: 50%;
              border-top: 2px solid ${({ theme }) => theme.text};
              width: 13px;
              height: 2px;
            }
            &:not(:last-child) {
              &::before {
                position: absolute;
                content: "";
                left: 5px;
                top: 0;
                border-left: 2px solid ${({ theme }) => theme.text};
                width: 2px;
                height: 100%;
              }
            }
            &:last-child {
              &::before {
                position: absolute;
                content: "";
                left: 5px;
                top: 0;
                border-left: 2px solid ${({ theme }) => theme.text};
                width: 2px;
                height: 50%;
              }
            }
            span {
              margin: 0 2.5px;
            }
          }
        }
      }
    }
  }
`;
