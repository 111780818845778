import { useContext, useMemo } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useElementSize } from "usehooks-ts";
import { addWidgetToFilterList } from "../../../state/features/analytics/actions";
import { WidgetContext } from "../../widgetContext";
import WidgetLoading from "../../widgetLoading";
import CandlestickVisual from "./candleStick";
import { RootState } from "../../../state/store";

interface ICandlestickWidgetProps {}

const CandleStickWidget = (props: ICandlestickWidgetProps) => {
  const [widgetData, setWidgetData] = useState<any>({});
  const [chartContainerRef, chartDimensions] = useElementSize();
  const dispatch = useDispatch();

  const { widgets: widgetFilters } = useSelector(
    (state: RootState) => state.filters
  );

  const widget = useContext(WidgetContext);

  useEffect(() => {
    if (widget.widgetData.widget_data) {
      setWidgetData(widget.widgetData);
      const widgetFilterExists = widgetFilters.find(
        (i) => i.key == widget?.widgetFilterConfig?.widgetFilterKey
      );
      if (widget.widgetFilterConfig.enableFilters && !widgetFilterExists) {
        dispatch(
          addWidgetToFilterList({
            name: widget.widgetFilterConfig.filterTitle,
            filterKey: widget.widgetFilterConfig.widgetFilterKey,
            options: widget.widgetData.widget_data.data.series.map((d) => ({
              ...d,
              name: d.category,
            })),
          })
        );
      }
    }
  }, [widget.widgetData]);

  const candleStick = useMemo(
    () => (
      <CandlestickVisual
        data={widgetData.widget_data}
        width={chartDimensions.width - 50}
        height={chartDimensions.height - 90}
      />
    ),
    [widgetData, chartDimensions]
  );

  return (
    <WidgetWrapper ref={chartContainerRef}>
      {widget.widgetLoading ? (
        <WidgetLoading />
      ) : widget.widgetLoadError ? (
        <div>Error</div>
      ) : widgetData ? (
        <>
          <div
            className="widget-title"
            onClick={() => widget.widgetOnClick(props)}>
            {widgetData.title}
          </div>
          <div className="widget-content">{candleStick}</div>
        </>
      ) : (
        <div>No Data</div>
      )}
    </WidgetWrapper>
  );
};

export default CandleStickWidget;

const WidgetWrapper = styled.div`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.widget.background};
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.05));
  width: 100%;
  height: 100%;
  padding: 25px;
  .widget-title {
    font-size: 1.3rem;
    text-decoration: underline;
  }
`;
