import styled from "styled-components";
import CustomModal from "../../customModal";
import BaseChart from "../../baseChart";
import { useMemo } from "react";
import { useElementSize } from "usehooks-ts";

const TreeViewChartPopup = ({ data, config, onClose }) => {
  const [containerRef, dims] = useElementSize();
  const handleClosePopup = () => {
    onClose();
  };

  const option = useMemo(() => {
    if (!data?.ingredients.length) {
      return {};
    }
    let ingredientsData = data?.ingredients.map((item) => ({
      ...item,
      children: item?.ingredients,
    }));
    return {
      tooltip: {
        trigger: "item",
        triggerOn: "mousemove",
        show: true,
        formatter: (params) => {
          let name = params?.data?.name ?? "";
          let qty = params?.data?.qty ?? "";
          let unit = params?.data?.measure ?? "";
          let cost = params?.data?.cost ?? "";
          return `<span>${name}</span><br/>
                  <span>Quantity - <b>${formatNumber(qty)}</b> ${unit}</span><br/>
                  <span>Cost - <b>${formatNumber(cost)}</b> ${config?.currency_formatter}</span>`;
        },
      },
      series: [
        {
          type: "tree",
          left: "5%",
          right: "20%",
          symbolSize: 7,
          lineStyle: {
            width: 3,
          },
          data: [
            {
              id: 0,
              name: data?.item,
              qty: data?.quantity,
              measure: data?.measure,
              cost: data?.cost,
              children: ingredientsData,
            },
          ],
          symbol: "emptyCircle",
          orient: "horizontal",
          expandAndCollapse: true,
          label: {
            position: "bottom",
            rotate: 0,
            verticalAlign: "top",
            align: "left",
            fontSize: 14,
            overflow: "break",
            formatter: (params) => {
              return `${
                params.data.name
              }\n${formatNumber(params.data.qty)} ${
                params.data.measure
              }, ${formatNumber(params.data.cost)} ${
                config.currency_formatter
              }`;
            },
          },
          leaves: {
            label: {
              position: "bottom",
              rotate: 0,
              verticalAlign: "top",
              align: "left",
              fontSize: 14,
              overflow: "break",
            },
          },
          animationDurationUpdate: 500,
        },
      ],
    };
  }, [data]);

  return (
    <CustomModal show={Boolean(data)} size="lg">
      <CustomModal.Content>
        <CustomModal.CloseButton onClick={handleClosePopup} />
        <ContentWrapper>
          <div className="header">
            <h2>{data?.item} Composition</h2>
          </div>
          <div className="content" ref={containerRef}>
            <BaseChart
              chartType="tree"
              options={option}
              height={dims.height}
              width={dims.width}
            />
          </div>
        </ContentWrapper>
      </CustomModal.Content>
    </CustomModal>
  );
};

export default TreeViewChartPopup;

const ContentWrapper = styled.div`
  height: 100%;
  .header {
    position: fixed;
    top: 20px;
  }
  .content {
    margin-top: 30px;
    width: 100%;
    height: 100%;
  }
`;
