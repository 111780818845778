import { TooltipHelper } from "../../tooltipHelper";

interface IWidgetSectionProps {
  sectionValue: string;
  sectionPretext: string;
  sectionText: string;
  sectionDescription: string;
  tooltipText: string;
}

const WidgetSection = ({
  sectionValue = "",
  sectionPretext = "",
  sectionText = "",
  sectionDescription = "",
  tooltipText = "",
}: IWidgetSectionProps) => {
  return (
    <div className="section">
      <TooltipHelper tooltipText={tooltipText} placement="top">
        <span
          className={"value currency currency-x" + sectionPretext}
          data-currency={sectionPretext}
          style={{
            "--currency": sectionPretext,
          }}
          data-placement="top"
        >
          {sectionValue}
        </span>
      </TooltipHelper>
      <span className="text">{sectionText}</span>
      <span className="description">{sectionDescription}</span>
    </div>
  );
};

export { WidgetSection };
