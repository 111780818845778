import { useContext, useEffect, useMemo, useRef, useState } from "react";
import CounterSelect from "../counterSelect";
import CustomMonthPicker from "../../customMonthPicker";
import { ComparisonContext } from "../../comparison/comparisonProvider";
import { COMPARISON_OPTION } from "../../../types/comparison";

const ComparisonMonthOptions = () => {
  const [showCountControls, setShowCountControls] = useState(false);
  const [count, setCount] = useState(0);

  const customDate = useRef(new Date());
  const [selectedSubRange, setSelectedSubRange] = useState("week");
  const { selectOption, option: selectedOption } =
    useContext(ComparisonContext);

  const showCustomSection = useMemo(() => {
    return selectedOption == COMPARISON_OPTION.CUSTOM;
  }, [selectedOption]);

  const getClassName = (selectionType) => {
    let isSelected = selectionType === selectedOption;
    let className = `list-item ${isSelected ? "selected" : ""}`;
    return className;
  };

  const handleCustomDateSelection = (date) => {
    customDate.current = date;
    selectOption({
      option: COMPARISON_OPTION.CUSTOM,
      config: { customDate: date },
    });
  };

  const handleSelection = (option: string) => {
    switch (option) {
      case COMPARISON_OPTION.SAME_RANGE_PREVIOUS:
        selectOption({ option: COMPARISON_OPTION.SAME_RANGE_PREVIOUS });
        break;
      case COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE:
        selectOption({
          option: COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE,
          config: { subRange: selectedSubRange },
        });
        break;
      case COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE:
        selectOption({
          option: COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE,
          config: { count },
        });
        break;
      case COMPARISON_OPTION.CUSTOM:
        selectOption({
          option: COMPARISON_OPTION.CUSTOM,
          config: { customDate: customDate.current },
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (count) {
      handleSelection(COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE);
    }
  }, [count]);

  return (
    <div className="option-list-container">
      <span
        className={getClassName(COMPARISON_OPTION.SAME_RANGE_PREVIOUS)}
        onClick={() => handleSelection(COMPARISON_OPTION.SAME_RANGE_PREVIOUS)}>
        Previous month
      </span>
      <span
        className={getClassName(COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE)}
        onClick={() =>
          handleSelection(COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE)
        }>
        Same month last year
      </span>
      <span
        className={getClassName(COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE)}
        onClick={() =>
          handleSelection(COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE)
        }
        onMouseEnter={() => setShowCountControls(true)}
        onMouseLeave={() => setShowCountControls(false)}>
        Last{" "}
        <CounterSelect
          default={2}
          min={1}
          max={3}
          showControls={showCountControls}
          setCount={setCount}
        />{" "}
        month
      </span>
      <div
        className={getClassName(COMPARISON_OPTION.CUSTOM)}
        onClick={() => handleSelection(COMPARISON_OPTION.CUSTOM)}>
        <span>Custom Range</span>
        <br />
        {showCustomSection ? (
          <div className="custom-date-select">
            <CustomMonthPicker
              date={customDate.current}
              setDate={handleCustomDateSelection}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ComparisonMonthOptions;
