import { useContext, useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import DashboardDataContext from "../../../helpers/contexts/dashboardDataContext";
import { clearWidgetFilter } from "../../../state/features/filters/actions";
import { HeaderSection } from "./headerSection";
import { DashboardStatusContainer } from "./dashboardStatusContainer";
import { DashboardWidgetsGrid } from "./dashboardWidgetsGrid";
import { DashboardWidgetsStatusContainer } from "./dashboardWidgetsStatusContainer";
import { useParams } from "react-router-dom";

const DashboardContainer = () => {
  const dispatch = useDispatch();

  const {
    dashboardWidgets,
    dashboardInfo,
    isDashboardDataLoading,
    dashboardDataLoadError,
    refreshDashboardData,
    shouldEnableDashboardFetch,
    dashboardLockedStatus,
  } = useContext(DashboardDataContext);

  useEffect(() => {
    dispatch(clearWidgetFilter());
    shouldEnableDashboardFetch(true);
  }, []);

  const dashboardName = useMemo(() => {
    if (dashboardInfo) {
      return dashboardInfo.name;
    }
    return null;
  }, [dashboardInfo]);

  return (
    <LayoutWrapper>
      <DashboardStatusContainer
        key={dashboardName}
        shouldLoadDashboard={Boolean(dashboardInfo)}
        dashboardAuthError={dashboardLockedStatus}>
        <HeaderSection
          dashboardDisplayName={dashboardInfo?.display_name}
          refreshDashboard={refreshDashboardData}
        />
        <DashboardWidgetsStatusContainer
          loadError={!dashboardLockedStatus.isEnabled && dashboardDataLoadError}
          loading={isDashboardDataLoading}
          refreshDashboard={refreshDashboardData}>
          <DashboardWidgetsGrid
            dashboard={dashboardName}
            dashboardWidgets={dashboardWidgets}
          />
        </DashboardWidgetsStatusContainer>
      </DashboardStatusContainer>
    </LayoutWrapper>
  );
};

const DashboardLayout = () => {
  const routeMatch = useParams();
  return <DashboardContainer key={routeMatch?.dashboard} />;
};

export default DashboardLayout;

const LayoutWrapper = styled.div`
  &.custom-padding {
    padding-top: 120px;
  }
  padding-bottom: 200px;
  .grid-layout {
    top: 160px;
    margin: 20px;
    .react-grid-item.react-grid-placeholder {
      background: grey;
      border-radius: 20px;
    }
  }
`;
