import { Form } from "react-bootstrap";

const ToggleSwitch = ({ name, checked, handleToggle }) => {
  const handleCheckChange = (e) => {
    handleToggle(e.target.checked);
  };

  return (
    <Form>
      <Form.Check
        type="switch"
        label={name}
        checked={checked}
        onChange={handleCheckChange}
      />
    </Form>
  );
};

export default ToggleSwitch;
