import { OverlayTrigger, Tooltip } from "react-bootstrap";
import type { Placement } from "react-bootstrap/esm/types";
import styled from "styled-components";

const renderTooltip = ({ renderProps, tooltipText }) => {
  return <ToolTipStyled {...renderProps}>{tooltipText}</ToolTipStyled>;
};

const ToolTipStyled = styled(Tooltip)`
  .tooltip-inner {
    color: ${({ theme }) => theme.tooltip.text};
    background: ${({ theme }) => theme.tooltip.background};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1px);
  }
  .tooltip-arrow {
    border-top-color: ${({ theme }) => theme.tooltip.background};
    &::before {
      border-top-color: ${({ theme }) => theme.tooltip.background};
      border-bottom-color: ${({ theme }) => theme.tooltip.background};
    }
  }
`;

interface ITooltipProps {
  placement: Placement;
  tooltipText: string;
  children: any;
}

const TooltipHelper = ({
  placement,
  tooltipText,
  children,
}: ITooltipProps) => {
  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement={placement}
      overlay={(props) =>
        renderTooltip({
          renderProps: { ...props, placement },
          tooltipText,
        })
      }>
      {children}
    </OverlayTrigger>
  );
};

export { TooltipHelper };
