import styled from "styled-components";
import ComparisonBarChart from "../../../components/comparison/barChart";
import ComparisonResultTable from "../../../components/comparison/resultsTable";
import { WidgetProvider } from "../../../components/widgetContext";
import { useMemo } from "react";
import SelectWidget from "../../../components/widgetLoader/selectWidget";
import { useElementSize } from "usehooks-ts";

const ComparisonContainer = ({ comparisonData }) => {
  const [widgetContainerRef, widgeDimensions] = useElementSize();
  const baseComparisonReport = useMemo(() => {
    if (
      comparisonData &&
      comparisonData.widgets &&
      comparisonData.widgets.length > 0
    ) {
      return comparisonData?.widgets[0];
    }
    return null;
  }, [comparisonData]);

  const comparisonWidgets = useMemo(() => {
    if (
      comparisonData &&
      comparisonData.widgets &&
      comparisonData.widgets.length > 0
    ) {
      return comparisonData.widgets.filter((d, i) => i !== 0);
    }
    return [];
  }, [comparisonData]);

  return (
    <Wrapper className="container">
      {comparisonWidgets.map((w) => {
        return (
          <div className="comparison-widget">
            <WidgetProvider widgetData={w}>
              <SelectWidget widgetType={w.type} />
            </WidgetProvider>
          </div>
        );
      })}
      <div className="row">
        {baseComparisonReport ? (
          <>
            <div>
              <div className="chart-container" ref={widgetContainerRef}>
                <p className="title">{baseComparisonReport.title}</p>
                <ComparisonBarChart
                  chartData={baseComparisonReport.widget_data}
                  width={widgeDimensions.width - 50}
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="chart-container">
                <ComparisonResultTable data={baseComparisonReport} />
              </div>
            </div>
          </>
        ) : null}
      </div>
    </Wrapper>
  );
};

export default ComparisonContainer;

const Wrapper = styled.div`
  margin: 0 2vw;
  justify-content: center;
  .comparison-widget {
    margin: 10px 0;
    width: 95vw;
    height: 600px;
  }
  .chart-container {
    border-radius: 20px;
    background-color: ${({ theme }) => theme.widget.background};
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.05));
    padding: 25px;
    margin: 10px 0;
    width: 95vw;
    > .title {
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 39px;
      text-decoration-line: underline;
      text-align: left;
    }
  }
`;
