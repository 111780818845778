import styled from "styled-components";
import { GrClose } from "react-icons/gr";
import { useContext } from "react";
import { SubRDataContext } from "../dataProvider";

interface IModalHeaderProps {
  onClose: () => void;
}

function ModalHeader(props: IModalHeaderProps) {
  const data = useContext(SubRDataContext);

  return (
    <Header>
      <div className="details">
        <div className="order-type">
          <div className="type">{data.order_type} Order</div>
          <div className="date">{data.date}</div>
        </div>
        <div className="order-number">
          <div className="title">Order Number:</div>
          <div className="number">{data.order_no}</div>
        </div>
      </div>
      <div className="close-icon" onClick={props.onClose}>
        <GrClose fontSize={"20px"} className="icon" />
      </div>
    </Header>
  );
}

export default ModalHeader;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1a1f28;
  color: #ffffff;
  border-radius: 20px 20px 0 0;
  .details {
    display: flex;
    .order-type {
      padding: 0.75rem 2rem;
      background: #252b37;
      border-radius: 20px 0 0 0;
      .type {
        font-size: 23px;
        font-weight: 600;
        line-height: normal;
      }
      .date {
        color: #958888;
        font-size: 11px;
        font-weight: 400;
        line-height: normal;
      }
    }
    .order-number {
      padding: 0.75rem 2rem;
      .title {
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        opacity: 0.7;
      }
      .number {
        font-size: 30px;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
  .close-icon {
    cursor: pointer;
    margin-right: 2rem;
    .icon path {
      stroke: #a0a0a0;
    }
  }
`;
