const BrokenSystemIcon = ({ theme }: { theme: string }) => {
  const color = theme === "light" ? "black" : "white";
  return (
    <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M55.0715 34.7416C55.587 34.7416 56.0049 34.3237 56.0049 33.8083V4.49115C56.0049 2.43256 54.3301 0.757812 52.2715 0.757812H3.73822C1.67963 0.757812 0.00488281 2.43256 0.00488281 4.49115V41.9411C0.00488281 43.9997 1.67963 45.6745 3.73822 45.6745H52.2715C54.3699 45.6745 56.0049 43.9491 56.0049 41.9686V38.9078C56.0049 38.3924 55.587 37.9745 55.0715 37.9745H7.91943C7.404 37.9745 6.9861 38.3924 6.9861 38.9078C6.9861 39.4232 7.404 39.8411 7.91943 39.8411H54.1382V41.9411C54.1382 42.9704 53.3008 43.8078 52.2715 43.8078H3.73822C2.70898 43.8078 1.87155 42.9704 1.87155 41.9411V4.49115C1.87155 3.46191 2.70898 2.62448 3.73822 2.62448H52.2715C53.3008 2.62448 54.1382 3.46191 54.1382 4.49115V33.8084C54.1382 34.3239 54.5561 34.7416 55.0715 34.7416Z" fill={color} />
      <path d="M41.3489 53.4167C39.5027 52.8423 37.5276 52.3943 35.474 52.0761L34.3868 48.2166C34.247 47.7205 33.7311 47.4316 33.2354 47.5713C32.7392 47.7111 32.4503 48.2267 32.5901 48.7227L33.4594 51.8087C29.9686 51.4223 26.2712 51.3966 22.5505 51.8084L23.4277 48.6945C23.5675 48.1984 23.2786 47.6829 22.7824 47.5431C22.2867 47.4031 21.7707 47.6922 21.631 48.1884L20.5359 52.076C18.4829 52.3941 16.5077 52.8422 14.661 53.4168C14.1688 53.57 13.894 54.093 14.0471 54.5852C14.2002 55.0774 14.7234 55.3524 15.2156 55.1991C22.9851 52.782 33.0254 52.7821 40.7943 55.1991C41.2845 55.3517 41.8091 55.0793 41.9629 54.5852C42.1158 54.093 41.8411 53.5699 41.3489 53.4167Z" fill={color} />
      <path d="M18.4365 19.1894C18.8012 19.554 19.3919 19.5539 19.7564 19.1894L20.9238 18.0221L22.0912 19.1894C22.4559 19.554 23.0466 19.5539 23.4111 19.1894C23.7756 18.825 23.7756 18.234 23.4111 17.8696L22.2438 16.7022L23.4111 15.5348C23.7756 15.1704 23.7756 14.5795 23.4111 14.215C23.0466 13.8505 22.4558 13.8505 22.0912 14.215L20.9238 15.3824L19.7566 14.2148C19.392 13.8503 18.8012 13.8503 18.4366 14.2148C18.0721 14.5792 18.0721 15.1701 18.4366 15.5346L19.604 16.702L18.4366 17.8693C18.072 18.2339 18.072 18.825 18.4365 19.1894Z" fill={color} />
      <path d="M37.5733 14.2148C37.2087 13.8503 36.6179 13.8503 36.2533 14.2148L35.086 15.3821L33.9186 14.2148C33.554 13.8503 32.9632 13.8503 32.5986 14.2148C32.2342 14.5792 32.2342 15.1701 32.5986 15.5346L33.766 16.702L32.5986 17.8693C32.2342 18.2338 32.2342 18.8247 32.5986 19.1892C32.9633 19.5538 33.554 19.5537 33.9186 19.1892L35.086 18.0218L36.2533 19.1892C36.618 19.5538 37.2087 19.5537 37.5733 19.1892C37.9378 18.8247 37.9378 18.2338 37.5733 17.8693L36.4059 16.702L37.5733 15.5346C37.9378 15.1703 37.9378 14.5792 37.5733 14.2148Z" fill={color} />
      <path d="M21.5775 30.0071C22.2055 29.391 24.5247 27.381 28.0049 27.381C28.8847 27.381 31.9483 27.5704 34.4323 30.0073C34.7992 30.3672 35.3902 30.3636 35.7521 29.9947C36.1131 29.6267 36.1075 29.0358 35.7395 28.6748C31.5449 24.5599 24.6512 24.3767 20.2702 28.6748C19.9023 29.0358 19.8967 29.6267 20.2576 29.9947C20.6188 30.3627 21.2097 30.368 21.5775 30.0071Z" fill={color} />
    </svg>
  );
};

export default BrokenSystemIcon;
