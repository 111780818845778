import { FaCircleInfo } from "react-icons/fa6";
import { IoIosGlobe, IoMdPerson } from "react-icons/io";
import { IoFastFoodOutline, IoPersonAddOutline } from "react-icons/io5";
import { LuReceipt } from "react-icons/lu";
import { MdDeliveryDining, MdOutlineTableBar } from "react-icons/md";

const EventIcon = ({ icon, iconUrl }) => {
  switch (icon) {
    case "food":
      return <IoFastFoodOutline fontSize={"16px"} />;
    case "person":
      return <IoMdPerson fontSize={"16px"} />;
    case "person-add":
      return <IoPersonAddOutline fontSize={"16px"} />;
    case "table-dine":
      return <MdOutlineTableBar fontSize={"16px"} />;
    case "receipt":
      return <LuReceipt fontSize={"16px"} />;
    case "globe":
      return <IoIosGlobe fontSize={"16px"} />;
    case "rider":
      return <MdDeliveryDining fontSize={"16px"} />;
    default:
      if (iconUrl) {
        return <img src={iconUrl} alt="event-icon" />;
      }
      return <FaCircleInfo fontSize={"16px"} />;
  }
};

export default EventIcon;
