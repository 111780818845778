import { format } from "date-fns";
import ReactDatePicker from "react-datepicker";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import styled from "styled-components";

interface Props {
  selectedDate: { year: number; month: number; day: number };
  onDateChange: (date: Date) => void;
  onDateSelect: (date: Date) => void;
  onShowMonthView: () => void;
  pickerType: "start" | "end";
}

const CalendarView = ({
  selectedDate,
  onDateChange,
  onDateSelect,
  onShowMonthView,
  pickerType,
}: Props) => {
  return (
    <Wrapper type={pickerType}>
      <ReactDatePicker
        selected={
          new Date(selectedDate.year, selectedDate.month, selectedDate.day)
        }
        inline
        isClearable
        onChange={onDateChange}
        onSelect={onDateSelect}
        calendarClassName="date-default-view"
        fixedHeight
        renderCustomHeader={({ date, increaseMonth, decreaseMonth }) => (
          <>
            <span
              className="cal-header nav nav-left"
              onClick={() => decreaseMonth()}>
              <FaChevronLeft fontSize={"20px"} />
            </span>
            <span className="cal-header title" onClick={onShowMonthView}>
              {format(date, "MMMM yyyy")}
            </span>
            <span
              className="cal-header nav nav-right"
              onClick={() => increaseMonth()}>
              <FaChevronRight fontSize={"20px"} />
            </span>
          </>
        )}
      />
    </Wrapper>
  );
};

export default CalendarView;

const Wrapper = styled.div`
  > div {
    width: calc(100%);
    > .date-default-view {
      border: none;
      width: calc(100%);
      background-color: ${({ theme }) => theme.widget.background};
      overflow: hidden;
      > .react-datepicker__month-container {
        background-color: ${({ theme }) => theme.widget.background};
        float: none;
        .react-datepicker__header--custom {
          padding: 10px 20px 0px 20px;
          border-bottom: 0;
          background-color: ${({ theme }) => theme.widget.background};
          color: ${({ theme }) => theme.text};
          padding-top: 19px;
          padding-left: 20px;
          padding-right: 20px;
          > .nav {
            display: inline;
            cursor: pointer;
            user-select: none;
          }
          & .nav-left {
            float: left;
          }
          & .nav-right {
            float: right;
          }
          > .cal-header.title {
            cursor: pointer;
            font-size: 20px;
            font-weight: 700;
            line-height: 26px;
            user-select: none;
          }
        }
        .react-datepicker__day-name {
          color: ${({ theme }) => theme.text};
        }
        .react-datepicker__day {
          color: ${({ theme }) => theme.text};
          font-weight: 500;
          font-size: 15px;
          line-height: 1.5rem;
          margin: 0.12rem;
          &.react-datepicker__day--today{
            color: red;
            background-color: transparent;
            font-weight: 800;
          }
          &.react-datepicker__day--keyboard-selected{
            background: none;
          }
          &.react-datepicker__day--selected {
            color: #fff;
            background-color: #3aa98d;
            border: 1px solid #3aa98d;
            padding-left: 5px;
            padding-right: 5px;
            width: 30px;
            border-radius: ${(props) =>
              props.type === "start" ? `30px` : `30px`};
            &:hover {
              color: #fff;
              background-color: #3aa98d;
            }
          }
          &.react-datepicker__day--today {
            font-weight: 800;
          }
          &:hover {
            color: ${({ theme }) => theme.text};
            background-color: ${({ theme }) => theme.background};
          }

          &.react-datepicker__day--outside-month{
            opacity: 0.5;
            &:hover{
              opacity: 1;
            }
          }
        }
      }
    }
  }
`;
