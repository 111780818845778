import { useMemo } from "react";
import type { EChartsOption } from "echarts";
import BaseChart from "../../baseChart";
import { numFormatter } from "../../../helpers/utils";
import { chartIcons } from "../../baseChart/utils";
import { formatDateTime } from "./utils";

export type BarLineComboChartConfiguration = {
  config: {
    mark_lines: {
      name: string;
      axis: "y-axis" | "x-axis";
      value: number | string;
      value_formatter: string;
      color?: string;
    }[];
    line: {
      name: string;
      data_point: string;
      selector: string;
      value_formatter: string;
    };
    bar: {
      name: string;
      data_point: string;
      selector: string;
      value_formatter: string;
    };
  };
  data: {
    series: {
      category: string;
      line_value: number;
      bar_value: number;
      line_id: number;
      bar_id: number;
    }[];
  };
};

interface IBarLineComboChartProps {
  width: number;
  height: number;
  data: BarLineComboChartConfiguration;
  extraConfigs?: any;
  limitBars?: number;
}

const BarLineComboChart = ({
  limitBars = 40,
  data,
  width,
  height,
  extraConfigs,
}: IBarLineComboChartProps) => {
  const option = useMemo<EChartsOption>(() => {
    let opt: EChartsOption = {
      tooltip: {},
      legend: {},
      xAxis: [],
      yAxis: [],
      series: [],
    };
    if (data) {
      opt = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            show: true,
            type: "cross",
          },
        },
        legend: {
          top: "top",
          data: [data.config.bar.name, data.config.line.name],
        },
        xAxis: [
          {
            type: "category",
            data: data.data.series.map((d) => d.category),
            axisPointer: {
              show: true,
              type: "shadow",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: data.config.bar.name,
            axisLabel: {
              formatter: (params) =>
                `${numFormatter(params)} ${data.config.bar.value_formatter}`,
            },
            axisPointer: {
              show: true,
              type: "line",
            },
            axisLine: {
              lineStyle: {
                color: "#58585E",
              },
            },
            nameTextStyle: {
              padding: [0, 0, 0, 30]
            },
          },
          {
            type: "value",
            name: data.config.line.name,
            axisLabel: {
              formatter: (value) =>
                `${numFormatter(value)} ${data.config.line.value_formatter}`,
            },
            axisLine: {
              lineStyle: {
                color: "#3ECD89",
              },
            },
            nameTextStyle: {
              padding: [0, 30, 0, 0]
            },
          },
        ],
        dataZoom:
          data.data.series.length > 1
            ? [
                {
                  type: "slider",
                  height: 20,
                  borderColor: "none",
                  startValue: 0,
                  endValue: limitBars - 1,
                  moveHandleSize: -20,
                  backgroundColor: "none",
                  fillerColor: "#F1EDED",
                  moveHandleStyle: {
                    color: "grey",
                    handleSize: 5,
                  },
                  dataBackground: {
                    areaStyle: {
                      color: "none",
                      opacity: 0,
                    },
                    lineStyle: {
                      color: "none",
                      opacity: 0,
                    },
                  },
                  z: 2,
                  handleIcon: "circle",
                  handleSize: 25,
                  moveHandleIcon: chartIcons.scrollbarMoveHandle,
                },
                {
                  type: "slider",
                  height: 5,
                  z: 0,
                  startValue: 0,
                  endValue: 40,
                  moveHandleSize: -20,
                  backgroundColor: "#F1EDED",
                  moveHandleStyle: {
                    color: "grey",
                    handleSize: 5,
                  },
                  dataBackground: {
                    areaStyle: {
                      color: "none",
                      opacity: 0,
                    },
                    lineStyle: {
                      color: "none",
                      opacity: 0,
                    },
                  },
                  handleIcon: "",
                  handleSize: 0,
                  brushSelect: false,
                },
              ]
            : [],
        series: [
          {
            name: data.config.bar.name,
            type: "bar",
            itemStyle: {
              color: "#58585E",
            },
            tooltip: {
              formatter: "{b0}",
              valueFormatter: function (value) {
                return `${formatNumber(value)} ${data.config.bar.value_formatter}`;
              },
            },
            markLine: data.config.mark_lines
              ? {
                  symbolSize: 0,
                  data: data.config.mark_lines.map((d) => ({
                    name: d.name,
                    [d.axis === "y-axis" ? "yAxis" : "xAxis"]: d.value,
                    lineStyle: {
                      color: d.color ?? "red",
                      width: 3,
                    },
                    label: {
                      show: true,
                      formatter: ({ name, value }) =>
                        `${name}: ${formatNumber(value)} ${d.value_formatter}`,
                      position: "insideMiddle",
                      backgroundColor: d.color ?? "red",
                      color: "#ababab",
                      padding: 3,
                    },
                  })),
                }
              : {},
            data: data.data.series.map((d) => d.bar_value),
          },
          {
            name: data.config.line.name,
            type: "line",
            itemStyle: {
              color: "#3ECD89",
            },
            yAxisIndex: 1,
            tooltip: {
              formatter: "{b1}",
              valueFormatter: function (value) {
                return `${formatNumber(value)} ${
                  data.config.line.value_formatter
                }`;
              },
            },
            data: data.data.series.map((d) => d.line_value),
          },
        ],
      };
      if (extraConfigs) {
        if ("trendPeriod" in extraConfigs) {
          switch (extraConfigs.trendPeriod) {
            case "trends-weekly":
              return {
                ...opt,
                dataZoom: opt?.dataZoom?.map((obj) => ({ ...obj, bottom: 4 })),
              };
            case "trends-hourly":
              return {
                ...opt,
                xAxis: [
                  {
                    type: "category",
                    data: data.data.series.map((d) => {
                      if (d.offset) {
                        return formatDateTime(
                          d.category,
                          d.offset,
                          "dd/MM - hh:mm a"
                        );
                      }
                      return d.category;
                    }),
                  },
                ],
              };
            default:
              return opt;
          }
        }
        return opt;
      }
    }
    return opt;
  }, [JSON.stringify(data)]);

  return (
    <BaseChart
      height={height}
      width={width}
      options={option}
      chartType="bar-line"
    />
  );
};

export default BarLineComboChart;
