import useSWR from "swr";
import offThreadQueryResponse from "../middleware/offThreadQueryResponse";

const useFetchComparisonReportData = (url, { onSuccess, onError }) => {
  const { data, isValidating, error, mutate } = useSWR(url, {
    use: [offThreadQueryResponse],
    onSuccess,
    onError,
  });
  return { data, isValidating, error, mutate };
};

export { useFetchComparisonReportData };
