import * as Sentry from "@sentry/react";
import { getEnvValueFor } from "../../helpers/utils";
import { ENVIRONMENT } from "../../helpers/constants";

export default function initializeSentryErrorReporting() {
  const environment = getEnvValueFor("SENTRY_ENVIRONMENT");
  if (environment == ENVIRONMENT.DEVELOPMENT) {
    return;
  }
  Sentry.init({
    dsn: getEnvValueFor("SENTRY_APP_URL"),
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: parseFloat(
      getEnvValueFor("SENTRY_TRACE_SAMPLE_RATE") || "0.5"
    ),
    environment: getEnvValueFor("SENTRY_ENVIRONMENT"),
  });
}
