import { Modal } from "react-bootstrap";
import styled from "styled-components";
import SubReportModalErrorBoundary from "../../../containers/layout/report/errorBoundary";
import ModalHeader from "./modalHeader";
import OrderDetails from "./orderDetails";
import PaymentDetails from "./paymentDetails";
import LoyaltyDetails from "./loyaltyDetails";
import OtherDetails from "./otherDetails";
import ModalFooter from "./modalFooter";
import { SubRDataProvider } from "./dataProvider";

interface IOrderDetailsModalProps {
  selectedOrder: any;
  onClose: () => void;
}

const NewOrderDetailsModal = (props: IOrderDetailsModalProps) => {
  return (
    <SubReportModalErrorBoundary>
      <SubRDataProvider selectedOrder={props.selectedOrder}>
        <Modal
          show
          dialogClassName="custom curved-wide sub-r-report-wrapper"
          onHide={props.onClose}
          size="lg"
          centered
        >
          <ModalHeader onClose={props.onClose} />
          <div className="row w-100 m-auto">
            <Container className="col-md-5">
              <LoyaltyDetails />
              <OtherDetails />
              <PaymentDetails />
            </Container>
            <Container className="col-md-7">
              <OrderDetails />
            </Container>
          </div>
        </Modal>
      </SubRDataProvider>
    </SubReportModalErrorBoundary>
  );
};
export default NewOrderDetailsModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 555px;
  padding: 1rem 0.5rem;
  &:first-child {
    padding-right: 0 !important;
  }
`;
