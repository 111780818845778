import { createContext } from "react";
import { ENTITY_LOCK_TYPE } from "../../types/visibility";

const DashboardDataContext = createContext<{
  dashboardWidgets: any[];
  dashboardInfo: any;
  isDashboardDataLoading: boolean;
  dashboardDataLoadError: any;
  refreshDashboardData: () => void;
  shouldEnableDashboardFetch: (value: boolean) => void;
  dashboardLockedStatus: {
    isEnabled: boolean;
    config: { lockType: ENTITY_LOCK_TYPE; marketingHtmlUrl: string };
  };
}>(undefined);

export default DashboardDataContext;
