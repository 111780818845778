import Pusher from "pusher-js";
import { useMemo, useState } from "react";
import { PUSHER_CONFIG } from "../../helpers/constants";
import PusherContext from "../../helpers/contexts/pusherContext";
import { getEnvValueFor } from "../../helpers/utils";

const PusherProvider = ({ children }) => {
  const [jwt, setJwt] = useState(localStorage.getItem("jwt"));

  const pusher = useMemo(() => {
    if (jwt) {
      return new Pusher(getEnvValueFor("PUSHER_KEY"), {
        appId: getEnvValueFor("PUSHER_APP_ID"),
        cluster: getEnvValueFor("PUSHER_CLUSTER"),
        encrypted: true,
        userAuthentication: {
          endpoint: PUSHER_CONFIG.ENDPOINT,
          headers: {
            Authorization: `JWT ${jwt}`,
          },
          transport: "ajax",
        },
        channelAuthorization: {
          endpoint: PUSHER_CONFIG.ENDPOINT,
          headers: {
            Authorization: `JWT ${jwt}`,
          },
          transport: "ajax",
        },
      });
    }
    return null;
  }, [jwt]);

  const updateJwtForPusher = (token) => {
    setJwt(token);
  };

  return (
    <PusherContext.Provider
      value={{ pusherInstance: pusher, updateJwtForPusher }}>
      {pusher ? <>{children}</> : null}
    </PusherContext.Provider>
  );
};

export default PusherProvider;
