import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { RootState } from "../../state/store";
import { useEffect, useMemo } from "react";
import {
  createCompanyDateSelectionPresets,
  serializeFilterQueryParams,
  validateUrlDateTimeFilters,
} from "../../helpers/utils";
import { setDateTimeFilter } from "../../state/features/filters/actions";

const DashboardDefaults = ({ companyData }) => {
  let [queryParams, setQueryParams] = useSearchParams();
  const location = useLocation();
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { defaultDashboard, dashboards } = useSelector(
    (state: RootState) => state.dashboard
  );

  const availableDashboardPaths = dashboards.map((d) => `/${d.api_path}`);

  const defaultPath = useMemo(() => {
    let path = "/";
    if (companyData && defaultDashboard) {
      `/${defaultDashboard?.api_path}`;
    }
    return path;
  }, [companyData, defaultDashboard]);

  useEffect(() => {
    if (
      (location &&
        defaultDashboard &&
        ["/", "/login/sso"].includes(location.pathname)) ||
      !availableDashboardPaths.some(path => location.pathname.startsWith(path))
    ) {
      navigateTo(defaultPath);
    }
  }, [location, defaultDashboard, availableDashboardPaths]);

  useEffect(() => {
    if (companyData) {
      // set default date-time selection
      const { today: todayPreset } = createCompanyDateSelectionPresets({
        businessEndTimeInSecs: companyData?.business_end_time,
        timezoneOffset: companyData?.time_zone_offset,
      });
      validateUrlDateTimeFilters(
        {
          defaultDateRange: todayPreset.date_range,
          defaultTimeRange: todayPreset.time_range,
          startRange: queryParams.get("from"),
          endRange: queryParams.get("to"),
        },
        ({ startDateRange, endDateRange, startTimeRange, endTimeRange }) => {
          setQueryParams(
            serializeFilterQueryParams({
              dateRange: [startDateRange, endDateRange],
              timeRange: [startTimeRange, endTimeRange],
            })
          );
          dispatch(
            setDateTimeFilter({
              dateRange: [startDateRange, endDateRange],
              timeRange: [startTimeRange, endTimeRange],
            })
          );
        }
      );
    }
  }, [companyData]);
  return null;
};

export default DashboardDefaults;
