import { WIDGET_TYPE } from "../../../types/widgets";
import { WidgetsConfigurations } from "../../../helpers/widgetsConfiguration";
import UnknownWidget from "../../widgets/unknownWidget";

interface ISelectWidgetProps {
  widgetType: WIDGET_TYPE;
}

const SelectWidget = ({ widgetType }: ISelectWidgetProps) => {
  if (!(widgetType in WidgetsConfigurations)) {
    return <UnknownWidget type={widgetType} />;
  }
    const WidgetComponent =
      WidgetsConfigurations[widgetType].component;
  return <WidgetComponent />;
};

export default SelectWidget;
