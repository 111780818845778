import useSWR from "swr";

type Params = {
  onSuccess?(data?: any, key?: string, config?: any): void;
};

const useFetchCompanyData = ({ onSuccess }: Params) => {
  const {
    data: companyData,
    isValidating: isCompanyDataLoading,
    error: companyDataLoadError,
    mutate: fetchCompany,
  } = useSWR("/api/v1/me", { revalidateOnMount: false, onSuccess: onSuccess });

  return {
    companyData,
    isCompanyDataLoading,
    companyDataLoadError,
    fetchCompany,
  };
};

export { useFetchCompanyData };
