import { ErrorBoundary } from "react-error-boundary";
import { reportExceptionToSentry } from "../../../services/sentry/utils";

const SubReportModalErrorBoundary = ({ children }) => {
  const handleOnExceptionCaught = (error: Error) => {
    reportExceptionToSentry({
      title: "Error Rendering Sub-Report",
      message: `${error.name}: ${error.message}`,
      tags: { path: window.location.href },
      stack: error.stack,
    });
  };

  return (
    <ErrorBoundary
      onError={handleOnExceptionCaught}
      FallbackComponent={({ error, resetErrorBoundary }) => (
        <div style={{ fontSize: "12px" }}>
          Error loading sub-r report <p onClick={resetErrorBoundary}>reload</p>
        </div>
      )}
      onReset={() => {}}>
      {children}
    </ErrorBoundary>
  );
};

export default SubReportModalErrorBoundary;
