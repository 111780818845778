import styled from "styled-components";
import { BsLockFill, BsUnlockFill } from "react-icons/bs";

const AuthTokenError = () => (
  <AuthErrorWrapper>
    <div className="card">
      <div className="icon">
        <div>
          <BsUnlockFill fontSize={"40px"} />
          <BsLockFill fontSize={"40px"} />
        </div>
      </div>
      <div className="text">Oops!</div>
      <div className="sub-text">
        <p>
          Oops! It looks like you don't have permission to view this page.
          Please contact your administrator and try again.
        </p>
      </div>
    </div>
  </AuthErrorWrapper>
);

export default AuthTokenError;

const AuthErrorWrapper = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  .card {
    background-color: ${({ theme }) => theme.widget.background};
    width: 576px;
    height: 324px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    text-align: center;
    justify-content: center;
    .text {
      font-style: normal;
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.2rem;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    .sub-text {
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.2rem;
      > p {
        width: 80%;
        margin: 0 auto;
        line-height: 1.2rem;
        font-size: 1rem;
      }
    }
    > .icon {
      > div {
        position: relative;
        width: 50px;
        height: 50px;
        margin: 0 auto;

        @keyframes fadeOutBounce {
          0% {
            opacity: 1;
            transform: translateY(0);
          }
          50% {
            opacity: 1;
            transform: translateY(5px);
          }
          90% {
            opacity: 1;
            transform: translateY(0);
          }
          100% {
            opacity: 0;
            transform: translateY(0);
          }
        }

        @keyframes fadeInBounce {
          0% {
            opacity: 0;
            transform: translateY(5px);
          }
          50% {
            opacity: 0.7;
            transform: translateY(5px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }

        > svg {
          position: absolute;
          left: 0;
          top: 0;
          &:first-child {
            left: 5px;
            animation-name: fadeOutBounce;
            animation-duration: 0.3s;
            animation-fill-mode: forwards;
          }
          &:last-child {
            opacity: 0;
            animation-name: fadeInBounce;
            animation-duration: 0.3s;
            animation-fill-mode: forwards;
            animation-delay: 0.2s;
          }
        }
      }
    }
  }
`;
