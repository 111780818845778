import { useEffect, useRef } from "react";
import type { SWRHook } from "swr";
import { RESULT_TYPE } from "../../types/swr";
import { getEnvValueFor } from "../utils";
import { appEventsMessaging } from "../hooks/pubSub";

const offThreadQueryResponse = (useSWRNext: SWRHook) => {
  return (key, fetcher, config) => {
    const pendingResultUrl = useRef(null);
    const pendingResultId = useRef(null);
    const pendingResultWaitSub = useRef<any>(null);

    const getThresholdValueFromEnv = () => {
      let offThreadQueryTimeout =
        getEnvValueFor("GLOBAL_OFF_THREAD_QUERY_TIMEOUT") || "100";
      let offThreadQueryWaitThreshold =
        getEnvValueFor("OFF_THREAD_QUERY_WAIT_PERIOD_THRESHOLD") || "20";
      let refetchTimeout =
        (parseInt(offThreadQueryTimeout) +
          parseInt(offThreadQueryWaitThreshold)) *
        1000;
      return refetchTimeout;
    };
    const resultWaitTimeoutValue = useRef<number>(getThresholdValueFromEnv());
    const resultWaitTimer = useRef<any>(null);

    useEffect(() => {
      if (pendingResultUrl.current !== key) {
        // Whenever the request url is changed, clean up subscriptions
        if (pendingResultWaitSub.current) {
          pendingResultWaitSub.current.unsubscribe();
        }
        if (pendingResultUrl.current && resultWaitTimer.current) {
          clearTimeout(resultWaitTimer.current);
        }
      }
      return () => {
        // Clean up, unsubscribe events to prevent memory leaks.
        if (pendingResultWaitSub.current) {
          pendingResultWaitSub.current.unsubscribe();
        }
        clearTimeout(resultWaitTimer.current);
      };
    }, [key]);

    let resultWaitPromiseResolve;
    let resultWaitPromiseReject;

    const onResultWaitSub = async (data) => {
      if (pendingResultId.current === data.result_id) {
        let pendingResultUrl = `${key}&pending_result_id=${pendingResultId.current}`;
        clearTimeout(resultWaitTimer.current);
        if (data.errorred) {
          console.error(`Request failed from server for pending result ${data?.result_id}`)
          resultWaitPromiseReject(
            new Error("Request Failed. Please try again")
          );
        } else {
          try {
            const res = await fetcher(pendingResultUrl);
            resultWaitPromiseResolve(res);
          } catch (e) {
            resultWaitPromiseReject(e);
          }
        }
      }
    };

    const modifiedFetcher = async (...args) => {
      const resultWaitPromise = new Promise((resolve, reject) => {
        resultWaitPromiseResolve = resolve;
        resultWaitPromiseReject = reject;
      });
      const response = await fetcher(...args);
      if (response.result_meta) {
        let resultType = response.result_meta.result_type;
        if (resultType === RESULT_TYPE.PENDING) {
          pendingResultId.current = response.result_meta.result_id;
          pendingResultWaitSub.current = appEventsMessaging(
            `pending_result_ready_${response.result_meta.result_id}`,
            { onSubscribe: onResultWaitSub, once: true }
          );
          pendingResultWaitSub.current.subscribe();
          resultWaitTimer.current = setTimeout(() => {
            pendingResultWaitSub.current.unsubscribe();
            console.error(
              `Request timed out from client for pending result id ${response.result_meta.result_id}`
            );
            resultWaitPromiseReject(
              new Error(`Result wait timeout after ${resultWaitTimeoutValue}s`)
            );
          }, resultWaitTimeoutValue.current);
          return await resultWaitPromise;
        }
        if (
          [
            RESULT_TYPE.ERROR,
            RESULT_TYPE.BLOCKED,
            RESULT_TYPE.TIMEOUT,
          ].includes(resultType)
        ) {
          throw new Error("Request failed. Please try again");
        }
      }
      return response;
    };
    const swr = useSWRNext(key, modifiedFetcher, config);
    return swr;
  };
};

export default offThreadQueryResponse;
