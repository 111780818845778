import { useEffect } from "react";
import usePusher from "../../helpers/hooks/usePusher";
import { usePub } from "../../helpers/hooks/pubSub";
import { store } from "../../state/store";
import { authOperations } from "../../state/features/auth";

type PusherPayload = { location_id: number; dashboard: string };

const CompanyEvents = ({ companyId }) => {
  const { pusherInstance } = usePusher();
  const publishMessage = usePub();
  const channelName = `company_updates-${companyId}`;

  const logoutCurrentUser = () => {
    localStorage.removeItem("jwt");
    localStorage.removeItem("rt");
    authOperations.authorizeUser(false);
  };

  useEffect(() => {
    let channel = pusherInstance?.subscribe(channelName);
    channel?.bind("pusher:subscription_error", () => {
      console.error(`Pusher subscription error - ${channelName}`);
    });
    // Dashboard updated events
    channel?.bind("dashboard-updated", (data: PusherPayload) => {
      let appliedLocationFilterIds: number[] =
        store.getState().filters.locations;
      if (appliedLocationFilterIds.includes(data.location_id)) {
        publishMessage(`dashboard_updated_${data.dashboard}`, undefined);
      }
    });

    // Company sync events
    channel?.bind("company_synchronized", (data) => {
      location.reload();
    });

    // Locations sync events
    channel?.bind("locations_synchronized", (data) => {
      location.reload();
    });

    // Subscription plan sync events
    channel?.bind("subscription_plan_synchronized", (data) => {
      location.reload();
    });

    // Force page refresh
    channel?.bind("refresh_browser", (data) => {
      let currentUserId = store.getState().company?.company_details?.user_id;
      if (data?.staff_id) {
        // refresh page only for current logged in user
        if (data?.staff_id == currentUserId) {
          location.reload();
        }
      } else {
        // refresh page for all users in company
        location.reload();
      }
    });

    // Force logout user(s)
    channel?.bind("logout_users", (data) => {
      let currentUserId = store.getState().company?.company_details?.user_id;
      if (data?.staff_id) {
        // logout only current logged in user.
        if (data?.staff_id == currentUserId) {
          logoutCurrentUser();
        }
      } else {
        // logout all users in company.
        logoutCurrentUser();
      }
    });

    return () => {
      channel?.unsubscribe();
    };
  }, [channelName]);

  return <></>;
};

export default CompanyEvents;
