import styled from "styled-components";
import { useElementSize } from "usehooks-ts";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import LoadingAnimation from "../loadingAnimation";

const WidgetLoading = (props) => {
  const [containerRef, dimensions] = useElementSize();
  const theme = useSelector((state: RootState) => state.theme.mode);

  return (
    <Wrapper ref={containerRef}>
      <LoadingAnimation />
      <span>Widget Loading...</span>
    </Wrapper>
  );
};

export default WidgetLoading;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  span {
    font-size: 14px;
    color: #91a1ac;
    font-weight: 500;
  }
`;
