import { TbZoomOutArea } from "react-icons/tb";
import styled from "styled-components";
import ToggleSwitch from "../../toggleSwitch";

export const TextMarkerControl = ({
  checkboxValue,
  setCheckboxValue,
  resetZoom,
}) => (
  <TextMarkerControlWrapper>
    <ToggleSwitch
      name="Labels"
      handleToggle={setCheckboxValue}
      checked={checkboxValue}
    />
    <p onClick={resetZoom} className="reset-zoom">
      <TbZoomOutArea fontSize={"19px"} />
      {"Reset Zoom"}
    </p>
  </TextMarkerControlWrapper>
);

const TextMarkerControlWrapper = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.widget.background};
  z-index: 1;
  border-radius: 0 0 0 30px;
  padding: 15px;
  top: -1;
  right: 0px;
  .toggle-control {
    margin-bottom: 10px;
  }
  .reset-zoom {
    cursor: pointer;
    text-decoration: underline;
  }
`;
