import { useContext } from "react";
import { Layer, Source } from "react-map-gl";
import { MapContext } from "react-map-gl/dist/esm/components/map";
import { END_CIRCLE_RADIUS, START_CIRCLE_RADIUS } from "../../../helpers/constants";

export const MapLayers = ({
  showMarkerLabels,
  maxCountValue,
  gradientConfig,
  geoJsonData
}) => {

  return (
    <Source id="locationSource" type="geojson" data={geoJsonData}>
      <Layer
        id="circleMarker"
        type="circle"
        paint={{
          "circle-radius": [
            "interpolate",
            ["linear"],
            ["get", "numeric_count_value"],
            0,
            START_CIRCLE_RADIUS,
            maxCountValue !== 0 ? maxCountValue : 1,
            END_CIRCLE_RADIUS,
          ],
          "circle-opacity": 0.8,
          "circle-color": [
            "interpolate",
            ["linear"],
            ["get", "numeric_amount_value"],
            ...gradientConfig,
          ],
          "circle-stroke-color": "#000000",
          "circle-stroke-width": 0.1,
        }}
      />
      {showMarkerLabels && (
        <Layer
          id="textMarker"
          type="symbol"
          paint={{ "text-color": "#ffffff" }}
          layout={{
            "text-field": ["get", "numeric_count_value"],
            "text-size": 15,
            "text-justify": "auto",
          }}
        />
      )}
    </Source>
  );
};
