import { useEffect } from "react";
import { useFilterNavigate } from "../../helpers/hooks/useFilterNavigate";

const Home = () => {
  const navigateTo = useFilterNavigate();

  useEffect(() => {
    navigateTo("/sales");
  }, []);

  return <div>🚧 Home screen</div>;
};
export default Home;
