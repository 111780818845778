import { useContext } from "react";
import { BSDashboardContextType } from "../../../types/businessSummary";
import { BSDashboardContext } from "../contexts/bsDashboardContext";

const useBSDashboardContext = () => {
  const context = useContext(BSDashboardContext) as BSDashboardContextType;
  return context;
};

export { useBSDashboardContext };
