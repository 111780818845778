import { useEffect, useState } from "react";
import {
  arrangeWidgets,
  breakpoints,
  createLayoutConfigByBreakpoint,
  getBreakpoint,
  widgetIdSplitSymbol,
} from "../gridLayoutConfigurations";

interface Params {
  /** Dashboard name */
  dashboard: string;
  /** List of dashboard widgets from data */
  dashboardWidgets: any[];
}
/**
 * React hook that determines layout configuration for widgets at D-level
 * <br/>
 * ### Logic for grid layout in D-level
 * 1. Get layout configuration from localStorage
 * 2. If the layout config does not exist or does not exist for some widgets,
 *    create a new layout based on widget's visual type
 * 3. (Optional) Override layout config for a widget based on properties from widget data
 *    (for bar/line type charts)
 */

const useDashboardGridLayout = ({ dashboard, dashboardWidgets }: Params) => {
  const [gridLayoutConfiguration, setGridLayoutConfiguration] = useState(null);
  const [dummyState, setDummyState] = useState(new Date().getTime());

  const getLayoutConfigFromLS = (name: string) => {
    let res = localStorage.getItem(name) ?? "null";
    return JSON.parse(res);
  };

  const saveLayoutToLS = (layout) => {
    // Set dummy state to forcefully trigger re-render to refresh widget contents
    setDummyState(new Date().getTime());
    let breakpoint = getBreakpoint(window.innerWidth);
    let existingLayout = JSON.parse(
      localStorage.getItem(`layout.dashboard.${dashboard}`) ?? `{}`
    );
    localStorage.setItem(
      `layout.dashboard.${dashboard}`,
      JSON.stringify({ ...existingLayout, [breakpoint]: [...layout] })
    );
  };

  useEffect(() => {
    // Reset Grid layout configuration whenever dashboard navigation is changed or dashboard widgets are updated
    let gridConfig: null = null;
    if (dashboardWidgets.length) {
      // Get layout configuration from localStorage
      gridConfig = getLayoutConfigFromLS(`layout.dashboard.${dashboard}`);
      let currentBreakpoint = getBreakpoint(window.innerWidth);
      let widgetIdentifiers = dashboardWidgets.map(
        (d) => `${d.url}${widgetIdSplitSymbol}${d.widget.type}`
      );
      let defaultGridLayout = arrangeWidgets(
        createLayoutConfigByBreakpoint(
          Object.keys(breakpoints),
          widgetIdentifiers
        )
      );
      if (!gridConfig) {
        /**
         * If no layout configuration found from localStorage,
         * fallback to default layout
         * */
        gridConfig = defaultGridLayout;
      } else {
        let identifiersFromlayout = gridConfig[currentBreakpoint].map(
          (d) => d.i
        );
        /**
         * In case if a new widget is added or an existing widget is removed,
         * fallback to default layout
         */
        if (
          JSON.stringify(identifiersFromlayout) !==
          JSON.stringify(widgetIdentifiers)
        ) {
          gridConfig = defaultGridLayout;
        }
      }
    }
    setDummyState(new Date().getTime());
    setGridLayoutConfiguration(gridConfig);
  }, [dashboard, JSON.stringify(dashboardWidgets)]);

  const shouldLoadGridLayout =
    dashboardWidgets.length && Boolean(gridLayoutConfiguration);

  return { saveLayoutToLS, gridLayoutConfiguration, shouldLoadGridLayout };
};

export { useDashboardGridLayout };
