import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useElementSize } from "usehooks-ts";
import { RootState } from "../../../state/store";
import SelectVisual from "./selectVisual";
import {
  filterWidgetUrlsBySelectedTimePeriod,
  getSectionTabTitles,
} from "./utils";
import { FaMagnifyingGlassArrowRight } from "react-icons/fa6";
import { useAnalyticsGroupWidgetContext } from "../../../helpers/hooks/useAnalyticsGroupWidgetContext";
import { GroupWidgetMeta } from "../../../types/widgets";
import WidgetLockedUnauthorized from "../../widgetLockedUnauthorized";
import AiAdvisorCta from "../../../features/ai/aiAdvisorCta";
interface WidgetTrendMeta extends GroupWidgetMeta {
  trendPeriod: string;
}

const TrendsWidget = () => {
  const [chartContainerRef, chartDimensions] = useElementSize();
  const { filters } = useSelector((state: RootState) => state);
  const [selectedTab, setSelectedTab] = useState<WidgetTrendMeta | null>(null);
  const [widgetHasNoData, setWidgetHasNoData] = useState(true);

  const {
    widgetData,
    apiError,
    fetchData,
    isLoading,
    widgetMeta,
    setWidgetUrl,
    enableExtendedAReport,
    onExtendedAClick,
    isAiSuggestionEnabled,
    isAiSuggestionLoadError,
    isAiSuggestionLoading,
    getAiSuggestion,
  } = useAnalyticsGroupWidgetContext();

  const widgetTabsMeta = widgetMeta?.sub_widgets ?? [];

  // Tabs visible in current date range selection
  const visibleTabs: WidgetTrendMeta[] = useMemo(() => {
    if (!widgetTabsMeta.length) {
      return [];
    }
    return filterWidgetUrlsBySelectedTimePeriod(filters.dates, widgetTabsMeta);
  }, [JSON.stringify(filters.dates), JSON.stringify(widgetTabsMeta)]);

  const activeTabStatus = useRef(null);

  useEffect(() => {
    if (visibleTabs.length) {
      if (!activeTabStatus.current) {
        setWidgetUrl(visibleTabs[0].url);
        setSelectedTab(visibleTabs[0]);
      } else {
        setWidgetUrl(activeTabStatus.current?.url);
        setSelectedTab(activeTabStatus.current);
      }
    }
  }, [JSON.stringify(visibleTabs)]);

  const handleTabSelection = (data) => {
    setSelectedTab(data);
    setWidgetUrl(data?.url);
  };

  return (
    <Wrapper ref={chartContainerRef}>
      <>
        <div className="section-header">
          <>
            <div className="widget-title">
              {isLoading ? "" : widgetMeta.title ?? ""}
            </div>
            {enableExtendedAReport && !isLoading && !widgetHasNoData ? (
              <div
                title="More details"
                className="extended-report-option"
                onClick={() => onExtendedAClick()}
              >
                <FaMagnifyingGlassArrowRight fontSize={"18px"} />
              </div>
            ) : null}
            {isAiSuggestionEnabled && !isLoading && !widgetHasNoData ? (
              <AiAdvisorCta
                showError={isAiSuggestionLoadError}
                showLoadingIndicator={isAiSuggestionLoading}
                onClick={() => getAiSuggestion()}
              />
            ) : null}
            <div className="tabs-section">
              {!widgetHasNoData &&
                visibleTabs.map((w, i) =>
                  isLoading ? null : (
                    <div
                      key={i}
                      className={`tab ${
                        selectedTab && selectedTab.url === w.url
                          ? `selected`
                          : ``
                      }`}
                      onClick={() => handleTabSelection(w)}
                    >
                      {getSectionTabTitles(w.url)}
                    </div>
                  )
                )}
            </div>
          </>
        </div>
        <div
          className="section-data"
          style={
            chartDimensions ? { height: chartDimensions.height - 120 } : {}
          }
        >
          {selectedTab ? (
            <SelectVisual
              width={chartDimensions.width - 50}
              height={chartDimensions.height - 120}
              trendPeriod={selectedTab?.url}
              selectedTab={selectedTab}
              error={apiError}
              isLoading={isLoading}
              refetchData={fetchData}
              setWidgetHasNoData={setWidgetHasNoData}
              widgetData={widgetData}
            />
          ) : (
            <WidgetLockedUnauthorized type="a_level" />
          )}
        </div>
      </>
    </Wrapper>
  );
};

export default TrendsWidget;

const Wrapper = styled.div`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.widget.background};
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.05));
  width: 100%;
  height: 100%;
  padding: 25px;
  .section-header {
    display: flex;
    .widget-title {
      flex: 1;
      text-decoration: underline;
    }
    .extended-report-option {
      box-sizing: border-box;
      height: 35px;
      width: 35px;
      cursor: pointer;
      position: relative;
      top: 7px;
      color: ${({ theme }) => theme.button_plain.text};
      border: 1.5px solid ${({ theme }) => theme.button_plain.border};
      &:hover {
        color: ${({ theme }) => theme.button_plain.hover_text};
        background: ${({ theme }) => theme.button_plain.hover};
      }
      border-radius: 8px;
      display: flex;
      justify-content: center;
      padding: 2px 8px;
      padding: 0px;
      align-items: center;
    }
    .ai-advisor-cta {
      position: relative;
      top: 7px;
    }
    .tabs-section {
      display: flex;
      justify-content: right;
      float: right;
      .tab {
        cursor: pointer;
        min-width: 80px;
        padding: 10px;
        margin: 5px;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
        background: ${({ theme }) => theme.body};
        border-radius: 20px;
        color: ${({ theme }) => theme.text};
        &.selected {
          background: ${({ theme }) => theme.text};
          color: ${({ theme }) => theme.body};
        }
      }
    }
  }
`;
