import { createContext, Fragment, useEffect, useState } from "react";
import type { ReactNode } from "react";
import { WidgetFilterConfig } from "../../types/widgets";

interface IWidgetContextProviderProps {
  widgetMeta?: { type: string; url: string; gridProperties: any };
  widgetState?: { loading: boolean; error: boolean; errorReason: Error | null };
  widgetType?: string;
  widgetData: any;
  widgetUrl?: string;
  widgetOnClick?: () => void;
  childWidgets?: string[];
  widgetLoading?: boolean;
  widgetLoadError?: boolean;
  widgetInputRequired?: boolean;
  widgetFilterConfig?: WidgetFilterConfig;
  getWidgetDataWithFilters?: (
    url: { key: string; value: number | string }[]
  ) => void;
  children: ReactNode;
  enableExtendedAReport?: boolean;
  onExtendedAClick?: any;
  setEnableExtendedReport: (value: boolean) => void;
  refreshData(): void;
  overrideWidgetContent: string;
  isTimelessWidget: boolean;
  lastUpdatedTime: Date;
  isAiSuggestionEnabled: boolean;
  isAiSuggestionLoading: boolean;
  isAiSuggestionLoadError: boolean;
  getAiSuggestion: () => void;
  toolTipMessage: string;
  filterUsedByWidget: string;
}

export const WidgetContext = createContext<IWidgetContextProviderProps>(
  {} as IWidgetContextProviderProps
);

export const WidgetProvider = (props: IWidgetContextProviderProps) => {
  return (
    <WidgetContext.Provider value={{ ...props }}>
      <Fragment>{props.children}</Fragment>
    </WidgetContext.Provider>
  );
};
