import useSWR from "swr";
import { configureUrlWithParams } from "../utils";
import { useMemo, useState } from "react";
import offThreadQueryResponse from "../middleware/offThreadQueryResponse";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";

type Params = {
  dashboard: string;
  dashboardWidget: string;
  widgetUrl: string;
  filters: object;
};

const useFetchAnalyticsWidgetData = ({
  dashboard,
  dashboardWidget,
  widgetUrl,
  filters,
}: Params) => {
  const { company } = useSelector((state: RootState) => state);
  const timezoneOffset = company?.company_details?.time_zone_offset;
  const [filterDataUrl, setFilterDataUrl] = useState<string | null>(null);
  const apiUrl = useMemo(() => {
    if (dashboard && dashboardWidget && widgetUrl) {
      return configureUrlWithParams(
        `/api/v1/dar/${dashboard}/${dashboardWidget}/${widgetUrl}/widget`,
        {
          ...filters,
          shouldUseEpocForTimeRange: company.shouldUseEpocForTimeRange,
          timezoneOffset,
        }
      );
    }
    return null;
  }, [dashboard, dashboardWidget, widgetUrl, JSON.stringify(filters)]);

  const { data, isValidating, error, mutate } = useSWR(apiUrl, {
    use: [offThreadQueryResponse],
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  });

  const filteredData = useSWR(filterDataUrl, {
    use: [offThreadQueryResponse],
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    onSuccess(responseData) {
      setFilterDataUrl(null);
      mutate(responseData, { revalidate: false });
    },
  });

  const isInputRequired = useMemo(() => {
    return (
      data?.result_meta?.result_type === "missing_input" ||
      filteredData.data?.result_meta?.result_type === "missing_input"
    );
  }, [data, filteredData.data]);

  const overrideMessage = useMemo(() => {
    let message = "";
    if (data?.result_meta?.override_message) {
      message = data?.result_meta?.override_message;
    }
    if (filteredData.data?.result_meta?.override_message) {
      message = filteredData.data?.result_meta?.override_message;
    }
    return message;
  }, [data, filteredData.data]);

  const filterUsedByWidget = useMemo(() => {
    if (data?.result_meta?.filtered_by) {
      return data?.result_meta?.filtered_by;
    }
    if (filteredData?.data?.result_meta?.filtered_by) {
      return filteredData.data?.result_meta?.filtered_by;
    }
    return null;
  }, [data, filteredData.data]);

  const toolTipMessage = useMemo(() => {
    let message = "";
    if (data?.result_meta?.tooltip_message) {
      message = data?.result_meta?.tooltip_message;
    }
    if (filteredData.data?.result_meta?.tooltip_message) {
      message = filteredData.data?.result_meta?.tooltip_message;
    }
    return message;
  }, [data, filteredData.data]);

  const reloadData = () => {
    mutate(undefined, true);
  };

  const getWidgetDataWithFilters = (additionalFilters) => {
    setFilterDataUrl(
      configureUrlWithParams(
        `/api/v1/dar/${dashboard}/${dashboardWidget}/${widgetUrl}/widget`,
        {
          ...filters,
          additionalFilters,
          shouldUseEpocForTimeRange: company.shouldUseEpocForTimeRange,
          timezoneOffset,
        }
      )
    );
  };

  return {
    data,
    isInputRequired,
    overrideMessage,
    toolTipMessage,
    filterUsedByWidget,
    isValidating: isValidating || filteredData.isValidating,
    error,
    reloadData,
    getWidgetDataWithFilters,
  };
};

export { useFetchAnalyticsWidgetData };
