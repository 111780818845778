import { RiBillLine } from "react-icons/ri";
import { FaMoneyBillWave } from "react-icons/fa";
import { PiCookingPot } from "react-icons/pi";
import { MdOutlineDeliveryDining, MdOutlineHome } from "react-icons/md";
import { LuPackageCheck } from "react-icons/lu";

const enum ORDER_TYPES {
  CALL_CENTER = "Call Center",
  WALK_IN = "Walk-In",
  ONLINE = "Online Order",
  DINE_IN = "Dine In",
}

const enum ORDER_STATUS_TIMESTAMP_KEY {
  CREATED_AT = "created_at",
  READY_AT = "ready_at",
  PICKED_AT = "picked_at",
  DELIVERED_AT = "delivered_at",
  CANCELLED_AT = "updated_at",
}

const orderTimelineTypes = [
  {
    orderType: ORDER_TYPES.WALK_IN,
    orderStatuses: [
      {
        title: "Paid At",
        orderStatus: ORDER_STATUS_TIMESTAMP_KEY.DELIVERED_AT,
        icon: <FaMoneyBillWave className="icon" fontSize={"25px"} />,
      },
    ],
  },
  {
    orderType: ORDER_TYPES.DINE_IN,
    orderStatuses: [
      {
        title: "Created At",
        orderStatus: ORDER_STATUS_TIMESTAMP_KEY.CREATED_AT,
        icon: <PiCookingPot className="icon" fontSize={"25px"} />,
      },
      {
        title: "Billed At",
        orderStatus: ORDER_STATUS_TIMESTAMP_KEY.PICKED_AT,
        icon: <RiBillLine className="icon" fontSize={"25px"} />,
      },
      {
        title: "Paid At",
        orderStatus: ORDER_STATUS_TIMESTAMP_KEY.DELIVERED_AT,
        icon: <FaMoneyBillWave className="icon" fontSize={"25px"} />,
      },
    ],
  },
  {
    orderType: ORDER_TYPES.CALL_CENTER,
    orderStatuses: [
      {
        title: "Created At",
        orderStatus: ORDER_STATUS_TIMESTAMP_KEY.CREATED_AT,
        icon: <PiCookingPot className="icon" fontSize={"25px"} />,
      },
      {
        title: "Ready At",
        orderStatus: ORDER_STATUS_TIMESTAMP_KEY.READY_AT,
        icon: <LuPackageCheck className="icon" fontSize={"25px"} />,
      },
      {
        title: "Picked At",
        orderStatus: ORDER_STATUS_TIMESTAMP_KEY.PICKED_AT,
        icon: <MdOutlineDeliveryDining className="icon" fontSize={"25px"} />,
      },
      {
        title: "Delivered At",
        orderStatus: ORDER_STATUS_TIMESTAMP_KEY.DELIVERED_AT,
        icon: <MdOutlineHome className="icon" fontSize={"25px"} />,
      },
    ],
  },
  {
    orderType: ORDER_TYPES.ONLINE,
    orderStatuses: [
      {
        title: "Created At",
        orderStatus: ORDER_STATUS_TIMESTAMP_KEY.CREATED_AT,
        icon: <PiCookingPot className="icon" fontSize={"25px"} />,
      },
      {
        title: "Ready At",
        orderStatus: ORDER_STATUS_TIMESTAMP_KEY.READY_AT,
        icon: <LuPackageCheck className="icon" fontSize={"25px"} />,
      },
      {
        title: "Picked At",
        orderStatus: ORDER_STATUS_TIMESTAMP_KEY.PICKED_AT,
        icon: <MdOutlineDeliveryDining className="icon" fontSize={"25px"} />,
      },
      {
        title: "Delivered At",
        orderStatus: ORDER_STATUS_TIMESTAMP_KEY.DELIVERED_AT,
        icon: <MdOutlineHome className="icon" fontSize={"25px"} />,
      },
    ],
  },
];

const createTimelineFromOrderType = ({
  timelineData,
  orderType,
  orderStatus,
}) => {
  let timelineType = orderTimelineTypes.find((d) => d.orderType === orderType);
  if (!timelineType) {
    return null;
  }
  timelineType.orderStatuses = timelineType.orderStatuses.map((d) => ({
    ...d,
    time: timelineData[d.orderStatus] ? timelineData[d.orderStatus] : null,
  }));
  if (orderStatus == "Cancelled") {
    let additionalData = {
      title: "Cancelled At",
      orderStatus: ORDER_STATUS_TIMESTAMP_KEY.CANCELLED_AT,
      icon: <MdOutlineHome className="icon" fontSize={"25px"} />,
      time: timelineData['updated_at'] ? timelineData['updated_at'] : null,
    };
    timelineType.orderStatuses.push(additionalData);
  }
  return timelineType;
};

const parseNumber = (number: any) => {
  let parsedNumber = 0;
  if (number) {
    parsedNumber = parseFloat(number);
  }
  return parsedNumber;
};

export {
  ORDER_TYPES,
  ORDER_STATUS_TIMESTAMP_KEY,
  createTimelineFromOrderType,
  orderTimelineTypes,
  parseNumber,
};
