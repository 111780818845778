import { createAction } from "@reduxjs/toolkit";
import * as types from "./types";

export const setLocationFilter = createAction(
  types.SET_LOCATION_FILTER,
  (selectedLocations) => ({ payload: selectedLocations })
);

export const setTimeRangeFilter = createAction(
  types.SET_TIME_RANGE_FILTER,
  (selectedTimeRange) => ({ payload: selectedTimeRange })
);

export const setDateTimeFilter = createAction(
  types.SET_DATE_TIME_FILTER,
  ({ dateRange, timeRange }) => ({ payload: { dateRange, timeRange } })
);

export const setWidgetFilter = createAction(
  types.SET_WIDGET_FILTER,
  (selectedDataPoint) => ({ payload: selectedDataPoint })
);

export const setDropdownFilters = createAction(
  types.SET_DROPDOWN_FILTERS,
  (filters) => ({ payload: filters })
);

export const removeWidgetFilter = createAction(
  types.REMOVE_WIDGET_FILTER,
  (selectedDataPoint) => ({ payload: selectedDataPoint })
);

export const clearWidgetFilter = createAction(types.CLEAR_WIDGET_FILTER);

export const setDateRangeWithLocations = createAction(
  types.SET_DATE_RANGE_FILTER_WITH_LOCATIONS,
  ({ locationIds, selectedDateRange, selectedTimeRange }) => ({
    payload: {
      locationIds,
      selectedDateRange,
      selectedTimeRange,
    },
  })
);
