import { parse } from "date-fns";
import { EChartsOption } from "echarts";
import { useEffect, useState } from "react";
import BaseChart from "../../baseChart";

const CUSTOM_COLOR = ["#2B7BC5", "#62B451", "#C84747"];

const BarChart = ({ data, width, height }) => {
  const [option, setOption] = useState<EChartsOption>({});

  useEffect(() => {
    setOption((o) => ({
      ...o,
      legend: {
        data: data.data.series.map((d, i) => ({
          name: d.name,
          itemStyle: {
            color: CUSTOM_COLOR[i],
          },
        })),
      },
      tooltip: {
        show: true,
      },
      grid: {
        top: 60,
      },
      xAxis: {
        type: "category",
        data: data.data.series[0].data.map((d) => d.name),
      },
      yAxis: {
        type: "value",
      },
      dataZoom: {
        type: "inside",
      },
      series: data.data.series.map((d, i) => ({
        type: "bar",
        name: d.name,
        tooltip: {
          formatter: (params) => `
          <div><h3><b>${params.value} ${data.config.value_formatter}</b></h3></div>
          <div><h6>${params.name}</h6></div>
          <div><b>${d.name}</b></div>
        `,
        },
        markLine: {
          symbolSize: 0,
          data: [
            {
              name: `Average ${data.data.series[2].name}`,
              lineStyle: {
                color: CUSTOM_COLOR[2],
              },
              label: {
                show: true,
                position: "insideMiddle",
                backgroundColor: d.color ?? "red",
                color: "#ababab",
                padding: 3,
              },
              tooltip: {
                show: true,
                formatter: (params) =>
                  `<div><b>${params.name}: </b> ${formatNumber(params.value)}</div>`,
              },
              yAxis:
                data.data.series[2].data.reduce(
                  (total, x) => total + x.value,
                  0
                ) / data.data.series[2].data.filter((d) => d.value < 0).length,
            },
          ],
          symbol: ["none", "none"],
        },
        data: d.data.map((dd) => ({
          value: dd.value,
          itemStyle: {
            color: CUSTOM_COLOR[i],
            borderRadius: dd.value < 0 ? [0, 0, 50, 50] : [50, 50, 0, 0],
          },
        })),
      })),
    }));
  }, [data]);

  const handleClickEvent = () => {
    // TODO
  };
  return (
    <BaseChart
      height={height}
      width={width}
      options={option}
      chartType="bar"
      onClick={handleClickEvent}
    />
  );
};

export default BarChart;
