import { createReducer } from "@reduxjs/toolkit";
import * as actions from "./actions";

const initialState = {
  route: "",
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(actions.setNavigation, (state, action) => {
    state.route = action.payload;
  });
});
