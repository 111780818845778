import { useEffect, useState } from "react";
import type { EChartsOption } from "echarts";
import BaseChart from "../../baseChart";
import { chartIcons } from "../../baseChart/utils";
import { convertTimestampToTimeZone } from "./utils";

export type LineChartConfiguration = {
  config: {
    selector: string;
    name: string;
    value_formatter: string;
  };
  data: {
    series: { category: string; value: number; id: number }[];
  };
};

interface ILineChartVisualProps {
  width: number;
  height: number;
  data: LineChartConfiguration;
}

const LineChartVisual = (props: ILineChartVisualProps) => {
  const [option, setOption] = useState<EChartsOption>({
    tooltip: {
      show: true,
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    xAxis: {
      data: [],
    },
    yAxis: {},
    series: [],
  });

  useEffect(() => {
    if (props.data) {
      setOption((o) => {
        let updatedOption = { ...o };
        updatedOption.xAxis = {
          type: "category",
          data: props.data.data.series.map((d) => {
            if (d.offset) {
              return convertTimestampToTimeZone(
                d.category,
                d.offset,
                "yyyy-MM-dd hh:mm:ss a"
              );
            }
            return d.category;
          }),
        };
        updatedOption.yAxis = {
          type: "value",
        };
        updatedOption.dataZoom = [
          {
           type: "slider",
           height: 20,
           borderColor: 'none',
           startValue: 0,
           endValue: 40,
           moveHandleSize: -20,
           backgroundColor: 'none',
           fillerColor: "#F1EDED",
           moveHandleStyle: {
             color: 'grey',
             handleSize: 5,
           },
            dataBackground: {
              areaStyle: {
                color: "none",
                opacity: 0,
              },
              lineStyle: {
                color: "none",
                opacity: 0,
              },
            },
            z: 2,
            handleIcon: "circle",
            handleSize: 25,
            moveHandleIcon: chartIcons.scrollbarMoveHandle,
          }, 
          {
            type: "slider",
            height: 5,
            z: 0,
            startValue: 0,
            endValue: 40,
            moveHandleSize: -20,
            backgroundColor: '#F1EDED',
            moveHandleStyle: {
              color: 'grey',
              handleSize: 5,
            },
            dataBackground: {
              areaStyle: {
                color: "none",
                opacity: 0,
              },
              lineStyle: {
                color: "none",
                opacity: 0,
              },
            },
            handleIcon: "",
            handleSize: 0,
            brushSelect: false,
          }
        ];
        updatedOption.series = [
          {
            type: "line",
            name: props.data.config.name,
            data: props.data.data.series.map((d) => d.value),
            tooltip: {
              valueFormatter: function (value) {
                return `${formatNumber(value)}  ${props.data.config.value_formatter}`;
              },
            },
            selectedMode: "multiple",
            select: {
              itemStyle: {
                borderWidth: 6,
              },
            },
          },
        ];
        return updatedOption;
      });
    }
  }, [props.data]);

  const handleClickEvent = (e) => {
    // TODO
  };

  return (
    <BaseChart
      height={props.height}
      width={props.width}
      options={option}
      chartType="line"
      onClick={handleClickEvent}
    />
  );
};

export default LineChartVisual;
