import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { useMemo } from "react";
import { configureUrlWithParams } from "../../../helpers/utils";
import useSWR from "swr";
import offThreadQueryResponse from "../../../helpers/middleware/offThreadQueryResponse";

const useFetchBSWidgetData = ({ url }) => {
  const { filters, company } = useSelector((state: RootState) => state);
  const timezoneOffset = company?.company_details?.time_zone_offset;
  const apiEndpoint = useMemo(() => {
    return configureUrlWithParams(
      `/api/v1/dar/business-summary/${url}/widget`,
      { ...filters, shouldUseEpocForTimeRange: true, timezoneOffset }
    );
  }, [url, JSON.stringify(filters)]);
  const { data, error, isLoading, mutate } = useSWR(apiEndpoint, {
    use: [offThreadQueryResponse],
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  });
  return { data, error, isLoading, mutate };
};

export { useFetchBSWidgetData };
