import { WidgetsConfigurations } from "./widgetsConfiguration";

export const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };
export const columns = { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 };

export const getBreakpoint = (innerWidth: number) => {
  if (innerWidth >= breakpoints.lg) {
    return "lg";
  } else if (innerWidth >= breakpoints.md) {
    return "md";
  } else if (innerWidth >= breakpoints.sm) {
    return "sm";
  } else if (innerWidth >= breakpoints.xs) {
    return "xs";
  } else {
    return "xxs";
  }
};

export const widgetIdSplitSymbol = "*";

export const getLayoutConfigByWidgetType = (widgetIdentifier) => {
  const [url, type] = widgetIdentifier.split(widgetIdSplitSymbol);
  if (!(type in WidgetsConfigurations)) {
    console.error(`Unknown visual type ${type} from widget ${url}`);
    return WidgetsConfigurations["UNKNOWN_TYPE"].layout;
  }
  return WidgetsConfigurations[type].layout;
};

export const arrangeWidgets = (layoutConfig, columnsConfig = columns) => {
  // Process each breakpoint
  Object.keys(breakpoints).forEach((bp) => {
    let currentRow = 0;
    let currentX = 0;
    let maxHeightInRow = 0;
    layoutConfig[bp].forEach((widget, index) => {
      // Check if widget can fit in the current row or needs to move to the next
      if (currentX + widget.w > columnsConfig[bp]) {
        // Move to the next row
        currentRow += maxHeightInRow;
        maxHeightInRow = 0;
        currentX = 0;
      }
      // Update widget position
      widget.x = currentX;
      widget.y = currentRow;
      // Prepare for the next widget
      currentX += widget.w;
      if (widget.h > maxHeightInRow) {
        maxHeightInRow = widget.h;
      }
      // If this is the last widget in the array, reset variables for the next breakpoint
      if (index === layoutConfig[bp].length - 1) {
        currentRow = 0;
        currentX = 0;
        maxHeightInRow = 0;
      }
    });
  });
  return layoutConfig;
};

export const createLayoutConfigByBreakpoint = (
  layoutBreakpoints = Object.keys(breakpoints),
  widgetIds = [] as string[]
) => {
  let config = {};
  layoutBreakpoints.forEach((b) => {
    config[b] = widgetIds.map((d) => ({
      i: d,
      x: 0,
      y: 0,
      ...getLayoutConfigByWidgetType(d)[b],
    }));
  });
  return config;
};

export const getLayoutConfigFromLS = (name: string) => {
  let res = localStorage.getItem(name) ?? "null";
  if (res == "null") {
    console.error(`layout for ${name} not found in localStorage`);
  }
  return JSON.parse(res);
};
