import { useMemo } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import styled from "styled-components";
import { useBSDashboardContext } from "../../hooks/useBSDashboardContext";
import {
  arrangeWidgets,
  breakpoints,
  createLayoutConfigByBreakpoint,
  widgetIdSplitSymbol,
} from "../../../../helpers/gridLayoutConfigurations";
import BSWidgetLoader from "../bsWidgetLoader";

const BSWidgetsGridLayout = () => {
  const { widgetsList } = useBSDashboardContext();

  const columns = { lg: 2, md: 2, sm: 2, xs: 1, xxs: 1 };

  const gridConfiguration = useMemo(() => {
    if (!widgetsList.length) {
      return null;
    }
    let widgetIds = widgetsList.map(
      (d) => `${d.url}${widgetIdSplitSymbol}${d.type}`
    );
    let gridConfig = arrangeWidgets(
      createLayoutConfigByBreakpoint(Object.keys(breakpoints), widgetIds),
      columns
    );
    return gridConfig;
  }, [JSON.stringify(widgetsList)]);

  return (
    <Wrapper>
      {gridConfiguration ? (
        <GridLayout
          breakpoints={breakpoints}
          rowHeight={25}
          allowOverlap={false}
          verticalCompact
          cols={columns}
          isResizable={false}
          isDraggable={false}
          containerPadding={{
            lg: [30, 15],
            md: [10, 15],
            sm: [15, 15],
          }}
          layouts={gridConfiguration}
          margin={[25, 25]}>
          {widgetsList.map((w, index) => (
            <div key={`${w.url}${widgetIdSplitSymbol}${w.type}`}>
              <BSWidgetLoader widget={w} />
            </div>
          ))}
        </GridLayout>
      ) : null}
    </Wrapper>
  );
};
export default BSWidgetsGridLayout;

const Wrapper = styled.div``;

const GridLayout = styled(WidthProvider(Responsive))`
  // Styles
  margin: 25px 10px 20px 10px;
  .react-grid-item.react-grid-placeholder {
    background: grey;
    border-radius: 20px;
  }
`;
