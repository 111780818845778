import { useMemo } from "react";
import useSWR from "swr";

const useFetchExtendedAViews = ({
  dashboard,
  dashboardWidget,
  widgetUrl,
  shouldFetch,
  setSavedViews,
}) => {
  const url = useMemo(() => {
    if (!shouldFetch) return null;
    return `/api/v1/dar/${dashboard}/${dashboardWidget}/${widgetUrl}/extended/views`;
  }, [shouldFetch]);

  const {
    data,
    isValidating: isLoading,
    error,
    mutate: retry,
  } = useSWR(url, {
    onSuccess(data) {
      if (typeof data !== "string") {
        setSavedViews(data);
      }
    },
  });

  return {
    data: (data && typeof data === "object") ?? [],
    isLoading,
    error,
    retry,
  };
};

export { useFetchExtendedAViews };
