import styled from "styled-components";
import LoadingAnimation from "../loadingAnimation";

const DashboardLoadingGrid = () => {
  return (
    <Container>
      <LoadingAnimation />
      <span>Loading Widgets...</span>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 400px;
  width: 100%;
  span {
    font-size: 14px;
    color: #91a1ac;
    font-weight: 500;
  }
`;

export default DashboardLoadingGrid;
