import { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useElementSize } from "usehooks-ts";
import BarChart from "./barChart";
import { WidgetContext } from "../../widgetContext";
import WidgetLoading from "../../widgetLoading";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";

const LostOpportunitiesWidget = () => {
  const [widgetData, setWidgetData] = useState({} as any);
  const [chartContainerRef, chartDimensions] = useElementSize();

  const widget = useContext(WidgetContext);

  // const { widgets: widgetFilters } = useSelector(
  //   (state: RootState) => state.filters
  // );

  useEffect(() => {
    if (widget.widgetData.widget_data) {
      setWidgetData(widget.widgetData);
      // const widgetFilterExists = widgetFilters.find(
      //   (i) => i.key == widget?.widgetFilterConfig?.widgetFilterKey
      // );
      // if (widget.widgetFilterConfig.enableFilters && !widgetFilterExists) {
      //   dispatch(
      //     addWidgetToFilterList({
      //       name: widget.widgetFilterConfig.filterTitle,
      //       filterKey: widget.widgetFilterConfig.widgetFilterKey,
      //       options: widget.widgetData.widget_data.data.series.map((d) => ({
      //         ...d,
      //         name: d.category,
      //       })),
      //     })
      //   );
      // }
    }
  }, [widget.widgetData]);

  const barChart = useMemo(
    () => (
      <BarChart
        data={widgetData?.widget_data}
        width={chartDimensions.width - 50}
        height={chartDimensions.height - 170}
      />
    ),
    [widgetData, chartDimensions]
  );

  return (
    <WidgetWrapper ref={chartContainerRef}>
      {widget.widgetLoading ? (
        <WidgetLoading />
      ) : widget.widgetLoadError ? (
        <div>Error</div>
      ) : widgetData.widget_data ? (
        <>
          <div className="widget-title">{widgetData.title}</div>
          <div className="widget-content">{barChart}</div>
          <div className="widget-information">
            <span className="heading">What is lost opportunities?</span>
            <p className="description">
              In forecasted sales vs actual sales, if the actuals sales is less
              as compared to the forecasted sales due to no inventory of the
              item, then lost opportunity is the difference between forecasted
              and actual sales.
            </p>
          </div>
        </>
      ) : (
        <div>No Data</div>
      )}
    </WidgetWrapper>
  );
};

export default LostOpportunitiesWidget;

const WidgetWrapper = styled.div`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.widget.background};
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.05));
  width: 100%;
  height: 100%;
  padding: 25px;
  .widget-title {
    font-size: 1.3rem;
    text-decoration: underline;
  }
  .widget-information {
    border: 1px solid #f0e4e4;
    border-radius: 20px;
    padding: 10px;
    .heading {
      font-size: 18px;
      display: block;
    }
    .description {
      font-size: 13px;
      line-height: 16.9px;
    }
  }
`;
