import styled, { keyframes } from "styled-components";

const LiveBadge = ({ isLive }) => {
  return (
    <Wrapper
      width="52"
      height="23"
      viewBox="0 0 52 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        width="52"
        height="23"
        rx="11.5"
        fill={isLive ? "#54A0FF" : "#ABABAB"}
      />
      <path
        d="M24.6321 15V7.72727H26.1697V13.7322H29.2876V15H24.6321ZM31.8436 7.72727V15H30.3059V7.72727H31.8436ZM34.4226 7.72727L36.1804 13.2528H36.2479L38.0092 7.72727H39.7138L37.2067 15H35.2251L32.7145 7.72727H34.4226ZM40.5891 15V7.72727H45.4897V8.99503H42.1268V10.728H45.2376V11.9957H42.1268V13.7322H45.5039V15H40.5891Z"
        fill="white"
      />

      <circle
        className={isLive ? "circle1" : "circleNotLive"}
        opacity="1"
        cx="11.5"
        cy="11.5"
        r="8.5"
        fill="white"
      />
      <circle
        className={isLive ? "circle2" : "circleNotLive"}
        opacity="1"
        cx="11.5"
        cy="11.5"
        r="8.5"
        fill="white"
      />
    </Wrapper>
  );
};

export default LiveBadge;

const glowInAnimation = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
    transform-origin: 18% 49%;
  }
  50%{
    transform: scale(0.8);
    opacity: 0.5;
    transform-origin: 18% 49%;
  }
  100%{
    transform: scale(1);
    opacity: 0;
    transform-origin: 18% 49%;
  }
`;

const Wrapper = styled.svg`
  .circle1 {
    animation: ${glowInAnimation};
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
  .circle2 {
    opacity: 0;
    animation: ${glowInAnimation};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 0.9s;
  }
`;
