import type { EChartsOption } from "echarts-for-react";
import { useMemo } from "react";
import BaseChart from "../../baseChart";

const SCALE_CONFIG = [
  { value: 0.2, color: "#3dbc69", label: "FAST" },
  { value: 0.6, color: "#efd74e", label: "MEDIUM" },
  { value: 1, color: "#e63d3c", label: "SLOW" },
];

const formatGaugeScale = (valueInMins) => {
  let scale = { min: 0, max: 60, split: 12, measure: "Mins" };
  let val = valueInMins;
  // greater than 24 hours
  if (valueInMins >= 1440) {
    val = (valueInMins / 1440).toFixed(0);
    scale = { min: 0, max: 7, split: 7, measure: "Days" };
    return { ...scale, val };
  }
  // greater than1 hour
  if (valueInMins >= 60) {
    val = (valueInMins / 60).toFixed(0);
    scale = { min: 0, max: 24, split: 6, measure: "Hours" };
    return { ...scale, val };
  }
  return { ...scale, val };
};

const GaugeChart = ({ data, width, height }) => {
  const option = useMemo<EChartsOption>(() => {
    let configScale = formatGaugeScale(data.value);
    const seriesConfig = {
      type: "gauge",
      radius: "95%",
      min: configScale.min,
      max: configScale.max,
      splitNumber: configScale.split,
      title: {
        show: true,
      },
      detail: {
        valueAnimation: true,
        formatter: "{value}",
        fontSize: 40,
        offsetCenter: [0, "50%"],
      },
    };
    if (data) {
      return {
        tooltip: {
          show: false,
        },
        legend: {
          show: false,
          selectedMode: false,
          data: SCALE_CONFIG.map((s) => ({
            name: s.label,
            itemStyle: { color: s.color },
          })),
        },
        series: {
          ...seriesConfig,
          name: "SLA",
          axisLine: {
            show: true,
            roundCaps: true,
          },
          data: [
            {
              value: configScale.val,
              name: configScale.measure,
            },
          ],
        },
      };
    }
    return {};
  }, [data]);

  return (
    <BaseChart
      height={height}
      width={width}
      chartType="gauge"
      options={option}
    />
  );
};

export default GaugeChart;
