import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useElementSize } from "usehooks-ts";
import { addWidgetToFilterList } from "../../../state/features/analytics/actions";
import WidgetHeaderSection from "../../widgetHeaderSection";
import TabularList from "./listTable";
import Widget from "../../baseWidget";
import { useWidgetContext } from "../../../helpers/hooks/useWidgetContext";
import { RootState } from "../../../state/store";

const ListWidget = () => {
  const [widgetData, setWidgetData] = useState<any>({});
  const [sortAscending, setSortAscending] = useState(false);
  const [widgetFilterOption, setWidgetFilterOption] = useState("");
  const [widgetDataCopy, setWidgetDataCopy] = useState({});
  const [widgetRef, contentDimensions] = useElementSize();

  const widget = useWidgetContext();
  const dispatch = useDispatch();

  const { widgets: widgetFilters } = useSelector(
    (state: RootState) => state.filters
  );

  useEffect(() => {
    if (widget.widgetData.widget_data) {
      setWidgetData(filterWidgetData("10", sortWidgetData(widget.widgetData)));
      setWidgetFilterOption("10");
      setWidgetDataCopy(widget.widgetData);
      const widgetFilterExists = widgetFilters.find(
        (i) => i.key == widget?.widgetFilterConfig?.widgetFilterKey
      );
      if (widget.widgetFilterConfig?.enableFilters && !widgetFilterExists) {
        dispatch(
          addWidgetToFilterList({
            name: widget.widgetFilterConfig.filterTitle,
            filterKey: widget.widgetFilterConfig.widgetFilterKey,
            options: widget.widgetData.widget_data.data.rows.map((d) => ({
              id: d[widget.widgetFilterConfig.widgetFilterKey],
              name: d[widget.widgetData.widget_data.config.filter_by_accessor],
            })),
          })
        );
      }
    }
  }, [widget.widgetData]);

  const tableRowData = useMemo(() => {
    if (widgetData?.widget_data) {
      return widgetData.widget_data.data.rows;
    }
    return [];
  }, [JSON.stringify(widgetData)]);

  const handleWidgetDataSorting = (sortAscending) => {
    setSortAscending(sortAscending);
    let sortedData = filterWidgetData(
      widgetFilterOption,
      sortWidgetData(widgetDataCopy, sortAscending)
    );
    setWidgetData(sortedData);
  };

  const sortWidgetData = (widgetData, ascending = sortAscending) => {
    let accessorSortingKey =
      widgetData.widget_data.config.sort_by_column_accessor;
    let updatedRowData = widgetData.widget_data.data.rows.sort((a, b) => {
      if (!ascending) {
        return b[accessorSortingKey] - a[accessorSortingKey];
      }
      return a[accessorSortingKey] - b[accessorSortingKey];
    });
    return {
      ...widgetData,
      widget_data: {
        ...widgetData.widget_data,
        data: { ...widgetData.widget_data.data, rows: updatedRowData },
      },
    };
  };

  const handleWidgetDataFiltering = (option) => {
    setWidgetFilterOption(option);
    setWidgetData(filterWidgetData(option, sortWidgetData(widgetDataCopy)));
  };

  const filterWidgetData = (option, widgetData) => {
    let updatedRowData = [...widgetData.widget_data.data.rows];
    if (option === "All") {
      updatedRowData = [...widgetData.widget_data.data.rows];
    } else {
      updatedRowData = widgetData.widget_data.data.rows.filter(
        (dat, idx) => idx <= parseInt(option) - 1
      );
    }
    return {
      ...widgetData,
      widget_data: {
        ...widgetData.widget_data,
        data: {
          ...widgetData.widget_data.data,
          rows: updatedRowData,
        },
      },
    };
  };

  const handleWidgetOnClick = () => {
    if (widget.widgetOnClick) {
      widget.widgetOnClick();
    }
  };

  return (
    <Widget ref={widgetRef}>
      <WidgetHeaderSection
        widgetTitle={widgetData.title}
        clickHandler={handleWidgetOnClick}
        enableFiltering={false}
        handleWidgetDataFiltering={handleWidgetDataFiltering}
        enableSorting={tableRowData.length > 0}
        handleWidgetDataSorting={handleWidgetDataSorting}
        enableExtendedAReport={
          widget.enableExtendedAReport && tableRowData.length > 0
        }
        handleExtendedAAction={() => widget.onExtendedAClick()}
        isTimelessWidget={widget?.isTimelessWidget}
        lastUpdatedTime={widget?.lastUpdatedTime}
        aiAdvisor={{
          isAiSuggestionEnabled: widget?.isAiSuggestionEnabled,
          isAiSuggestionLoading: widget?.isAiSuggestionLoading,
          isAiSuggestionLoadError: widget?.isAiSuggestionLoadError,
          getAiSuggestion: widget?.getAiSuggestion,
        }}
      />
      <Widget.Content>
        {tableRowData.length > 0 ? (
          <TabularList
            data={widgetData.widget_data}
            width={contentDimensions.width - 50}
            height={contentDimensions.height - 80}
          />
        ) : (
          <Widget.EmptyData
            width={contentDimensions.width - 50}
            height={contentDimensions.height - 75}
          />
        )}
      </Widget.Content>
    </Widget>
  );
};

export default ListWidget;
