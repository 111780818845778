import { useContext, useEffect, useRef, useState } from "react";
import { COMPARISON_OPTION } from "../../../types/comparison";
import { ComparisonContext } from "../../comparison/comparisonProvider";
import CustomQuarterPicker from "../../customquarterpicker";
import CounterSelect from "../counterSelect";

const ComparisonQuarterOptions = () => {
  const [showCountControls, setShowCountControls] = useState(false);
  const [count, setCount] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showCustomSection, setShowCustomSection] = useState(false);
  const customDate = useRef(new Date());

  const { selectOption, option: selectedOption } =
    useContext(ComparisonContext);

  const getClassName = (selectionType) => {
    let isSelected = selectionType === selectedOption;
    let className = `list-item ${isSelected ? "selected" : ""}`;
    return className;
  };

  const handleSelection = (option: string) => {
    setShowCustomSection(false);
    switch (option) {
      case COMPARISON_OPTION.SAME_RANGE_PREVIOUS:
        selectOption({ option: COMPARISON_OPTION.SAME_RANGE_PREVIOUS });
        break;
      case COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE:
        selectOption({
          option: COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE,
        });
        break;
      case COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE:
        selectOption({
          option: COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE,
          config: { count },
        });
        break;
      case COMPARISON_OPTION.CUSTOM:
        setShowCustomSection(true);
        selectOption({
          option: COMPARISON_OPTION.CUSTOM,
          config: { customDate: customDate.current },
        });
        break;
      default:
        break;
    }
  };

  const handleCustomDateSelection = (date) => {
    customDate.current = date;
    selectOption({
      option: COMPARISON_OPTION.CUSTOM,
      config: { customDate: customDate.current },
    });
  };

  useEffect(() => {
    if (count) {
      handleSelection(COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE);
    }
  }, [count]);

  return (
    <div className="option-list-container">
      <span
        className={getClassName(COMPARISON_OPTION.SAME_RANGE_PREVIOUS)}
        onClick={() => handleSelection(COMPARISON_OPTION.SAME_RANGE_PREVIOUS)}>
        Previous quarter
      </span>
      <span
        className={getClassName(COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE)}
        onClick={() =>
          handleSelection(COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE)
        }>
        Same quarter last year
      </span>
      <span
        className={getClassName(COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE)}
        onClick={() =>
          handleSelection(COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE)
        }
        onMouseEnter={() => setShowCountControls(true)}
        onMouseLeave={() => setShowCountControls(false)}>
        Last{" "}
        <CounterSelect
          default={2}
          min={1}
          max={3}
          showControls={showCountControls}
          setCount={setCount}
        />{" "}
        quarter
      </span>
      <div
        className={getClassName(COMPARISON_OPTION.CUSTOM)}
        onClick={() => handleSelection(COMPARISON_OPTION.CUSTOM)}>
        <span>Custom Range</span>
        <br />
        {showCustomSection ? (
          <div className="custom-date-select">
            <CustomQuarterPicker
              date={selectedDate}
              setDate={handleCustomDateSelection}
              onQuarterSelect={handleCustomDateSelection}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ComparisonQuarterOptions;
