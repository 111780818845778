import { useEffect, useMemo } from "react";
import styled from "styled-components";
import BarLineComboChart from "../barComboWidget/barComboChart";
import WidgetEmptyData from "../../widgetEmptyData";
import MultiSeriesComboChart from "../multiSeriesComboWidget/multiSeriesComboChart";
import { WIDGET_TYPE } from "../../../types/widgets";
import WidgetErrorFallback from "../../widgetErrorFallback";
import { patchSeriesCategories } from "../multiSeriesComboWidget/utils";
import LoadingAnimation from "../../loadingAnimation";
import { useAnalyticsGroupWidgetContext } from "../../../helpers/hooks/useAnalyticsGroupWidgetContext";

interface ISelectVisualProps {
  width: number;
  height: number;
  trendPeriod: string;
  widgetData: any;
  isLoading: boolean;
  error: any;
  refetchData: () => void;
  setWidgetHasNoData: (value: boolean) => void;
  selectedTab: any;
}

const SelectVisual = ({
  width,
  height,
  trendPeriod,
  widgetData,
  isLoading,
  error,
  refetchData,
  setWidgetHasNoData,
  selectedTab,
}: ISelectVisualProps) => {
  const { setEnableExtendedReport } = useAnalyticsGroupWidgetContext();

  useEffect(() => {
    if (widgetData && widgetData.widget.widget_data) {
      return setWidgetHasNoData(
        widgetData.widget.widget_data.data.series.length === 0
      );
    }
    return setWidgetHasNoData(true);
  }, [widgetData]);

  const widgetHasNoData = useMemo(() => {
    if (widgetData && widgetData.widget.widget_data) {
      return widgetData.widget.widget_data.data.series.length === 0;
    }
    return true;
  }, [widgetData]);

  const visualData = useMemo(() => {
    if (isLoading) {
      return null;
    }
    return widgetData?.widget?.widget_data ?? null;
  }, [widgetData, isLoading]);

  useEffect(() => {
    if (widgetData?.extended_analytics && !isLoading) {
      setEnableExtendedReport(widgetData.extended_analytics);
    }
  }, [widgetData, isLoading]);

  if (isLoading) {
    return (
      <Wrapper>
        <div className="loader-container">
          <LoadingAnimation />
          <span>Widget Loading...</span>
        </div>
      </Wrapper>
    );
  }

  if (!isLoading && widgetData && widgetHasNoData) {
    return (
      <Wrapper>
        <WidgetEmptyData width={"99%"} height={"55vh"} />
      </Wrapper>
    );
  }

  if (!isLoading && widgetData && visualData) {
    switch (selectedTab?.type) {
      case WIDGET_TYPE.BAR_LINE_COMBO_LG:
        return (
          <BarLineComboChart
            data={visualData}
            width={width}
            height={height}
            extraConfigs={{ trendPeriod }}
          />
        );
      case WIDGET_TYPE.MULTI_SERIES_COMBO_CHART:
        return (
          <MultiSeriesComboChart
            data={patchSeriesCategories(visualData)}
            width={width}
            height={height}
            extraConfigs={{ trendPeriod }}
          />
        );
      default:
        return <div>Unknown Visualization</div>;
    }
  }

  if (!isLoading && error) {
    return (
      <WidgetErrorFallback
        height={height}
        width={width}
        retry={refetchData}
        widgetType={selectedTab.type}
        widgetUrl={selectedTab.url}
      />
    );
  }

  return <></>;
};

export default SelectVisual;

const Wrapper = styled.div`
  margin: 5px;
  width: 100%;
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 400px;
    width: 100%;
    span {
      font-size: 14px;
      color: #91a1ac;
      font-weight: 500;
    }
  }
`;
