import { differenceInMonths } from "date-fns";
import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import CustomDatePicker from "../../customDatePicker";
import { useSelector } from "react-redux";

const DormantSelection = ({
  dormantDaysOptions,
  selectedOptionName,
  endDate,
  handleDormantDaysSelection,
  filterPretext,
}) => {
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [selectedCustomDate, setSelectedCustomDate] = useState(endDate);

  const handleDormantOptionSelection = (option) => {
    if (option.value !== "custom") {
      handleDormantDaysSelection(option.value);
      setShowCustomDatePicker(false);
    } else {
      setShowCustomDatePicker(true);
    }
  };

  const handleSelectCustomDate = (date) => {
    let dayDiff = differenceInMonths(endDate, date);
    setSelectedCustomDate(date);
    handleDormantDaysSelection(dayDiff);
  };

  const theme = useSelector((state: RootState) => state.theme.mode);

  return (
    <Wrapper>
      {showCustomDatePicker ? (
        <CustomDatePicker
          date={selectedCustomDate}
          setDate={handleSelectCustomDate}
          disableDatesFrom={endDate}
        />
      ) : null}
      <Dropdown className="dormant-select-dropdown">
        <Dropdown.Toggle className="dormant-select-dropdown-toggle">
          <span className="title-pretext">{`${filterPretext} ` ?? ""}</span>
          <span
            style={{
              fontWeight: "bold",
              color: theme === "dark" ? "#f3f3f3" : "#646a6f",
            }}
          >
            {selectedOptionName}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {dormantDaysOptions.map((option, i) => (
            <Dropdown.Item
              onClick={() => handleDormantOptionSelection(option)}
              key={i}
            >
              {option.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Wrapper>
  );
};

export default DormantSelection;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 7px;
  .dormant-select-dropdown {
    .dormant-select-dropdown-toggle {
      background: ${({ theme }) => theme.widget.background};
      color: #a59eac;
      border-color: #a59eac;
      border-width: 2px;
      .title-pretext {
        inline-size: 10px;
        width: 10px;
        overflow-wrap: break-word;
      }
    }
  }
  .dropdown-menu {
    transform: translate(80%, 42.2222px) !important;
  }
  .dropdown-item {
    color: ${({ theme }) => theme.text};
    &:hover {
      background: ${({ theme }) => theme.body};
    }
  }
`;
