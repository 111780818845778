import { Middleware } from "swr";
import { getEnvValueFor } from "../utils";

const enum typeName {
  STRING = "STRING",
  DATE = "DATE",
  INT = "INT",
  DECIMAL = "DECIMAL",
  LONG = "LONG",
  DOUBLE = "DOUBLE",
  TIMESTAMP = "TIMESTAMP",
  BOOLEAN = "BOOLEAN",
}

type Column = {
  name: string;
  type_text: string;
  type_name: typeName;
  type_precision?: number;
  type_scale?: number;
  position: number;
};

type ExternalLinkConfig = {
  statement_id: string;
  status: string;
  manifest: {
    schema: {
      format: string;
      columns: Column[];
      total_chunk_count: number;
      chunks: {
        chunk_index: number;
        row_offset: number;
        row_count: number;
        byte_count: number;
      }[];
      total_row_count: number;
      total_byte_count: number;
      truncated: boolean;
    };
  };
  result: {
    external_links: {
      chunk_index: number;
      row_offset: number;
      row_count: number;
      byte_count: number;
      external_link: string;
      expiration: string;
    }[];
  };
};

type ResultWithExternalLink = {
  result_meta: {
    result_type: string;
    result_id: string;
  };
  extended_data: {
    data: ExternalLinkConfig;
  };
};
type Result = {
  result_meta: {
    result_type: string;
    result_id: string;
  };
  extended_data: {
    data: any[];
  };
};

interface DownloadProgress {
  loaded: number;
  total: number | null;
  progress: number | null;
  state: "idle" | "downloading" | "parsing" | "complete" | "error";
  error?: string;
}

interface ExternalLinkOptions {
  onProgress?: (progress: DownloadProgress) => void;
}

const mapDataTypes = (data: string[][], columns: Column[]) => {
  // Create a mapping object for type conversions
  const typeConverters = {
    [typeName.STRING]: (val: string) => val,
    [typeName.DECIMAL]: (val: string) => parseFloat(val) || 0,
    [typeName.DATE]: (val: string) => new Date(val),
    [typeName.INT]: (val: string) => parseInt(val) || 0,
    [typeName.LONG]: (val: string) => parseInt(val) || 0,
    [typeName.DOUBLE]: (val: string) => parseFloat(val) || 0,
    [typeName.TIMESTAMP]: (val: string) => parseInt(val) || 0,
    [typeName.BOOLEAN]: (val: string) => val === "true",
  } as const;

  return data.map((row) => 
    row.reduce((obj, val, i) => {
      const column = columns[i];
      obj[column.name] = typeConverters[column.type_name](val);
      return obj;
    }, {} as Record<string, any>)
  );
};

const fetchDatafromExternalLink: Middleware = (useSWRNext) => {
  return (key, fetcher, config) => {
    const swr = useSWRNext(
      key,
      async (...args) => {
        try {
          let response: ResultWithExternalLink | Result | undefined =
            await fetcher?.(...args);
          if (response?.result_meta?.result_type == "result_as_ext_links") {
            if (response?.extended_data) {
              let externalLinkConfig = response.extended_data
                .data as ExternalLinkConfig;
              let chunks = externalLinkConfig.manifest.chunks;
              if (chunks.length > 1) {
                throw new Error(
                  "External link with multiple chunks is not supported"
                );
              }
              let externalLink =
                externalLinkConfig.result.external_links[0].external_link;
              let columns = externalLinkConfig.manifest.schema.columns;
              const options: ExternalLinkOptions = config?.externalLinkOptions || {};

              const result = await new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', externalLink, true);
                xhr.responseType = 'json';
                // Initial progress state
                options.onProgress?.({
                  loaded: 0,
                  total: 0,
                  progress: 0,
                  state: "idle",
                });
                xhr.onprogress = (event) => {
                  if (event.lengthComputable) {
                    options.onProgress?.({
                      loaded: event.loaded,
                      total: event.total,
                      progress: (event.loaded / event.total) * 100,
                      state: "downloading",
                    });
                  } else {
                    options.onProgress?.({
                      loaded: event.loaded,
                      total: null,
                      progress: null,
                      state: "downloading",
                    });
                  }
                };
                xhr.onload = () => {
                  if (xhr.status >= 200 && xhr.status < 300) {
                    options.onProgress?.({
                      loaded: xhr.response.length,
                      total: xhr.response.length,
                      progress: 100,
                      state: 'complete'
                    });
                    resolve(xhr.response);
                  } else {
                    const error = `HTTP ${xhr.status}: ${xhr.statusText}`;
                    options.onProgress?.({
                      loaded: 0,
                      total: 0,
                      progress: 0,
                      state: "error",
                      error,
                    });
                    reject(new Error(error));
                  }
                };
                xhr.onerror = () => {
                  const error = 'Network request failed';
                  options.onProgress?.({
                    loaded: 0,
                    total: 0,
                    progress: 0,
                    state: "error",
                    error,
                  });
                  reject(new Error(error));
                };
                xhr.send();
              });
              options.onProgress?.({
                loaded: 0,
                total: 0,
                progress: 0,
                state: "parsing",
              });
              let startParsePerf = performance.now();
              let res = mapDataTypes(result, columns);
              let endParsePerf = performance.now();
              console.log(
                `Parsing took ${endParsePerf - startParsePerf} ms`
              );
              // reset download progress to default state
              options.onProgress?.({
                loaded: 0,
                total: 0,
                progress: 0,
                state: "idle",
              });
              response = {
                ...response,
                extended_data: { ...response?.extended_data, data: res },
              };
              return response;
            }
          }
          return response;
        } catch (error) {
          throw error;
        }
      },
      config
    );

    return swr;
  };
};

export default fetchDatafromExternalLink;
