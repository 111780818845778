import styled from "styled-components";
import BSDashboardCore from "../components/bsDashboardCore";
import PrimaryFilterHelper from "../../../components/primaryFilterHelper";
import { BSContentHeader } from "../components/bsContentHeader";
import BSDashboardStatus from "../components/bsDashboardStatus";
import BSWidgetsGridLayout from "../components/bsWidgetsGridLayout";
import BSContentFooter from "../components/bsContentFooter";
import BSAiAdvisorBanner from "../../ai/components/bsAiAdvisorBanner";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { configureUrlWithParams } from "../../../helpers/utils";
import { useMemo } from "react";

const BusinessSummaryLayout = () => {
  const filters = useSelector((state:RootState)=>state.filters)
  const key = useMemo(() => {
    return configureUrlWithParams("", filters);
  }, [JSON.stringify(filters)]);
  return (
    <LayoutWrapper>
      <BSDashboardCore>
        <PrimaryFilterHelper>
          <BSContentHeader />
          <BSDashboardStatus>
            <BSAiAdvisorBanner key={key} />
            <BSWidgetsGridLayout />
          </BSDashboardStatus>
          <BSContentFooter />
        </PrimaryFilterHelper>
      </BSDashboardCore>
    </LayoutWrapper>
  );
};

export default BusinessSummaryLayout;

const LayoutWrapper = styled.div`
  background-color: ${({ theme }) => theme.body};
  margin-top: 125px;
  transition: all 0.25s linear 0s;

  .date-period {
    font-weight: normal;
    font-size: 15px;
    color: ${({ theme }) => theme.text};
    transition: all 0.25s linear 0s;
  }
`;
