import styled from "styled-components";
import { useElementSize } from "usehooks-ts";

const WidgetLockedMarketingContent = ({url}) => {
  const [ref, dims] = useElementSize()

  return (
    <Wrapper ref={ref}>
      <iframe
        title="widget locked"
        src={url}
        height={dims.height - 50}
        width={dims.width - 50}
        allowtransparency="allowtransparency"
        frameBorder="0"
        scrolling="no"
        allow="allow-modal allow-popups allow-same-origin allow-top-navigation"></iframe>
    </Wrapper>
  );
}

export default WidgetLockedMarketingContent

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.widget_group.border};
  border-radius: 20px;
  background-color: ${(props) => props.theme.widget.background};
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.05));
  width: 100%;
  height: 100%;
  padding: 25px;
`;
