import { css } from "styled-components";

export const calculatedFieldStyles = css`
  button.e-btn.e-primary {
    color: ${({ theme }) => theme.text_color} !important;
    &.e-primary {
      background: ${({ theme }) => theme.primary_color} !important;
      border-color: ${({ theme }) => theme.primary_color} !important;
    }
  }
  .e-dlg-container {
    .e-pivot-calc-dialog-div {
      &.e-dialog {
        border-radius: 25px;
        background-color: ${({ theme }) =>
          theme.pivot_table.calculated_field_props.background_color};
      }
      .e-dlg-header-content {
        border-radius: 25px;
        background-color: ${({ theme }) =>
          theme.pivot_table.calculated_field_props.background_color};
      }
      .e-dlg-content {
        background-color: ${({ theme }) =>
          theme.pivot_table.calculated_field_props.background_color};
        .e-pivot-calc-outer-div {
          .e-pivot-calc-input-div {
            .e-input-group {
              background: ${({ theme }) =>
                theme.pivot_table.calculated_field_props
                  .input_field_background_color} !important;
            }
          }
          .e-pivot-treeview-outer {
            background-color: ${({ theme }) =>
              theme.pivot_table.calculated_field_props
                .input_field_background_color} !important;
          }
          .e-textbox.e-input {
            background: ${({ theme }) =>
              theme.pivot_table.calculated_field_props
                .input_field_background_color} !important;
          }
        }
      }
      .e-input-group.e-control-wrapper {
        background: ${({ theme }) =>
          theme.pivot_table.calculated_field_props
            .input_field_background_color} !important;
      }
    }
  }
  .e-dlg-overlay {
    background-color: rgba(0, 0, 0, 0);
  }
`;
