import styled from "styled-components";

const DateRangePickerFooter = ({ onSubmit, onReset, disableSubmit }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  const handleReset = (e) => {
    e.preventDefault();
    onReset();
  };

  return (
    <Wrapper onSubmit={handleSubmit} onReset={handleReset}>
      <div className="action-buttons">
        <button type="reset" className="btn btn-reset">
          Cancel
        </button>
        <button
          type="submit"
          className="btn btn-primary btn-submit"
          disabled={disableSubmit}>
          Submit
        </button>
      </div>
    </Wrapper>
  );
};

export default DateRangePickerFooter;

const Wrapper = styled.form`
  margin-top: 10px;
  display: flex;
  height: 35px;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  width: calc(100%);
  .action-buttons {
    .btn {
      margin: 0 5px;
      padding: 5px 0;
      width: 108.81px;
      height: 40px;
      font-weight: 500;
      font-size: 14px;
      border-radius: 4px;
      border-color: transparent;
      &.btn-reset {
        background: #eceff1;
        border: 1px solid #b0bec5;
      }
      &.btn-submit {
        border: none;
        background-color: rgb(13, 138, 106);
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;
