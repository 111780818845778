import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { WidgetContext } from "../../widgetContext";

interface ISmallTabbedNumberWidgetProps {}

const SmallTabbedNumberWidget = (props: ISmallTabbedNumberWidgetProps) => {
  const [currentTab, setCurrentTab] = useState<any>(null);

  const widget = useContext(WidgetContext);

  useEffect(() => {
    if (Object.keys(widget.widgetData).length > 0) {
      setCurrentTab(widget.widgetData.data[0]);
    }
  }, [widget.widgetData]);

  const handleWidgetOnClick = () => {
    if (widget.widgetOnClick) {
      widget.widgetOnClick();
    }
  };

  return (
    <WidgetWrapper
      className="col-sm-8 col-lg-6 col-md-8 col-xl-6"
      onClick={handleWidgetOnClick}>
      {Object.keys(widget.widgetData).length > 0 && currentTab !== null && (
        <>
          <div className="section-header">
            <p className="title">{widget.widgetData.title}</p>
            <div className="tabs">
              {widget.widgetData.data.map((tab, index) => {
                return (
                  <span
                    key={index}
                    onClick={() => setCurrentTab(tab)}
                    className={`tab-label ${
                      currentTab.tab_title === tab.tab_title ? "active" : ""
                    }`}>
                    {tab.tab_title}
                  </span>
                );
              })}
            </div>
          </div>
          <div className="section-content">
            <span className="value">{currentTab.tab_content.value}</span>
            <span className="additional-value">
              {currentTab.tab_content.additional_value}
            </span>
          </div>
        </>
      )}
    </WidgetWrapper>
  );
};

export default SmallTabbedNumberWidget;

const WidgetWrapper = styled.div`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.widget.background};
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.05));
  width: 100%;
  height: 100%;
  padding: 25px;
  cursor: pointer;
  .section-header {
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 1.5rem;
      margin-right: 178px;
      text-decoration: underline;
    }
    .tabs {
      display: flex;
      .tab-label {
        margin-right: 20px;
        &:last-child {
          margin-right: 0px;
        }
        font-size: 18px;
        font-weight: 500;
        line-height: 23.4px;
        padding-bottom: 17px;
        &.active {
          color: #0d8a6a;
          border-bottom: 4px solid #0d8a6a;
        }
      }
    }
  }
  .section-content {
    margin-top: 34px;
    height: auto;
    .value {
      display: block;
      font-size: 38px;
      font-weight: 700;
      line-height: 50px;
    }
    .additional-value {
      display: block;
      font-size: 18px;
      color: #609954;
    }
  }
`;
