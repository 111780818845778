import { LngLatBounds } from "mapbox-gl";
import type { MapConfiguration } from "../../../types/widgets";

const convertToGeoJsonData = (apiData: MapConfiguration) => {
  let sourceData = {
    type: "FeatureCollection",
    features: apiData.map((loc) => ({
      geometry: {
        type: "Point",
        coordinates: [loc.location_coordinates[1], loc.location_coordinates[0]],
      },
      properties: {
        ...loc.location_data,
        location_name: loc.location_name,
        location_id: loc.location_id,
      },
    })),
  };
  return sourceData;
};

const getMaxScaleValue = (maxValue) => {
  let zeroCount = maxValue.toString().length - 1;
  zeroCount = zeroCount > 0 ? zeroCount - 1 : 1
  let maxVal = Math.ceil(maxValue / 10 ** zeroCount) * 10 ** zeroCount;
  return maxVal;
};
const getMinScaleValue = (minValue) => {
  if (minValue <= 100) {
    return 0;
  }
  return 100;
  // let zeroCount = minValue.toString().length - 1;
  // return 10 ** zeroCount;
};

const createColorGradientConfig = (colorsArray, minValue, maxValue) => {
  let startColor = colorsArray.length > 0 ? colorsArray[0] : "#ffffff";
  let endColor =
    colorsArray.length > 0 ? colorsArray[colorsArray.length - 1] : "#000000";
  let stepColors = colorsArray.filter((c) => ![endColor].includes(c));
  let stepGradientConfig = [];
  let sum = minValue;
  let steps = (maxValue - minValue) / stepColors.length - 1;
  for (let i = 0; i < stepColors.length; i++) {
    stepGradientConfig.push(sum);
    stepGradientConfig.push(stepColors[i]);
    sum += steps;
  }
  let result = [...stepGradientConfig, maxValue, endColor];
  return result;
};

const calculateBoundingBoxCoords = (coordinates) => {
  let ne = { lng: coordinates[0][1], lat: coordinates[0][0] };
  let sw = { lng: coordinates[0][1], lat: coordinates[0][0] };
  for (let i = 1; i < coordinates.length; i++) {
    let lat = coordinates[i][0];
    let lng = coordinates[i][1];
    if (lat > ne.lat) ne.lat = lat;
    if (lng > ne.lng) ne.lng = lng;
    if (lat < sw.lat) sw.lat = lat;
    if (lng < sw.lng) sw.lng = lng;
  }
  let bounds = new LngLatBounds([sw.lat, sw.lng], [ne.lat, ne.lng]);
  let center = bounds.getCenter();
  return { bounds, center };
};

const calculateCenter = (coordinates: any) => {
  const latitudeCenter =
    coordinates.reduce((total, point) => {
      return total + parseFloat(point[0]);
    }, 0)/coordinates.length ;
  const logitudeCenter =
    coordinates.reduce((total, point) => {
      return total + parseFloat(point[1]);
    }, 0) / coordinates.length;
  return { latitudeCenter, logitudeCenter };
};

export {
  createColorGradientConfig,
  getMaxScaleValue,
  getMinScaleValue,
  convertToGeoJsonData,
  calculateBoundingBoxCoords,
  calculateCenter,
};
