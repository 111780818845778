export enum COMPARISON_OPTION {
  SAME_RANGE_PREVIOUS = "COMPARISON_OPTION_SAME_RANGE_PREVIOUS",
  SAME_RANGE_LAST_RANGE_TYPE = "COMPARISON_OPTION_SAME_RANGE_LAST_RANGE_TYPE",
  SAME_RANGE_LAST_N_RANGE = "COMPARISON_OPTION_SAME_RANGE_LAST_N_RANGE",
  CUSTOM = "COMPARISON_OPTION_CUSTOM",
}

export enum COMPARISON_MODE {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  QUARTER = "quarter",
  YEAR = "year",
}

export enum COMPARISON_ACTION {
  SET_COMPARISON_MODE = "SET_COMPARISON_MODE",
  SET_COMPARISON_OPTION = "SET_COMPARISON_OPTION",
}
