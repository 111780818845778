import { useContext, useEffect, useMemo, useState } from "react";
import CounterSelect from "../counterSelect";
import CustomDatePicker from "../../customDatePicker";
import { ComparisonContext } from "../../comparison/comparisonProvider";
import { COMPARISON_OPTION } from "../../../types/comparison";

const subRangeTypes = ["week", "month", "year"] as const;

const SelectControl = ({ onSelectChange }) => {
  return (
    <select onChange={onSelectChange}>
      {subRangeTypes.map((v, i) => (
        <option key={i} value={v}>
          {v}
        </option>
      ))}
    </select>
  );
};

const ComparisonDayOptions = () => {
  const [showSelectControls, setShowSelectControls] = useState(false);
  const [selectedSubRange, setSubRange] =
    useState<typeof subRangeTypes[number]>("week");

  const [showCountControls, setShowCountControls] = useState(false);
  const [count, setCount] = useState(0);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showCustomSection, setShowCustomSection] = useState(true);

  const { selectOption, option: selectedOption } =
    useContext(ComparisonContext);

  const getClassName = (selectionType) => {
    let isSelected = selectionType === selectedOption;
    let className = `list-item ${isSelected ? "selected" : ""}`;
    return className;
  };

  useEffect(() => {
    selectOption({
      option: COMPARISON_OPTION.CUSTOM,
      config: { selectedDate },
    });
  }, [selectedDate]);

  const onSelectChange = (e: any) => {
    setSubRange(e.target.value);
    selectOption({
      option: COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE,
      config: {
        subRange: e.target.value,
      },
    });
  };

  const handleSelection = (option: string) => {
    // setShowCustomSection(false);
    switch (option) {
      case COMPARISON_OPTION.SAME_RANGE_PREVIOUS:
        selectOption({ option: COMPARISON_OPTION.SAME_RANGE_PREVIOUS });
        break;
      case COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE:
        selectOption({
          option: COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE,
          config: { subRange: selectedSubRange },
        });
        break;
      case COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE:
        selectOption({
          option: COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE,
          config: { count },
        });
        break;
      case COMPARISON_OPTION.CUSTOM:
        selectOption({
          option: COMPARISON_OPTION.CUSTOM,
          config: { selectedDate },
        });
        break;
      default:
        break;
    }
  };

  const handleCustomSectionClick = () => {
    // setShowCustomSection((show) => !show);
    handleSelection(COMPARISON_OPTION.CUSTOM);
  };

  const handleCustomDaySelection = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    if (count) {
      handleSelection(COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE);
    }
  }, [count]);

  return (
    <div className="option-list-container">
      <p
        className={getClassName(COMPARISON_OPTION.SAME_RANGE_PREVIOUS)}
        onClick={() => handleSelection(COMPARISON_OPTION.SAME_RANGE_PREVIOUS)}>
        Previous day
      </p>
      <div
        className={getClassName(COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE)}
        onClick={() =>
          handleSelection(COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE)
        }
        onMouseEnter={() => setShowSelectControls(true)}
        onMouseLeave={() => setShowSelectControls(false)}>
        Same day last &nbsp;
        {showSelectControls ? (
          <SelectControl onSelectChange={onSelectChange} />
        ) : (
          <span>{selectedSubRange}</span>
        )}
      </div>
      <div
        className={getClassName(COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE)}
        onClick={() => handleSelection(COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE)}
        onMouseEnter={() => setShowCountControls(true)}
        onMouseLeave={() => setShowCountControls(false)}>
        Last{" "}
        <CounterSelect
          default={2}
          min={1}
          max={7}
          showControls={showCountControls}
          setCount={setCount}
        />{" "}
        days
      </div>
      <div
        className={getClassName(COMPARISON_OPTION.CUSTOM)}
        onClick={handleCustomSectionClick}>
        <span>Custom Range</span>
        <br />
        {showCustomSection ? (
          <div className="custom-date-select">
            <CustomDatePicker
              date={selectedDate}
              setDate={handleCustomDaySelection}
              disableDatesFrom={new Date()}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ComparisonDayOptions;
