import { useContext } from "react";
import { ExtendedAContext } from "../contexts/extendedAContext";
import { ExtendedAContextType } from "../../../types/extendedA";

const useExtendedAContext = () => {
  const context = useContext(ExtendedAContext) as ExtendedAContextType;
  return context;
};

export { useExtendedAContext };
