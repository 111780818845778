import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import styled from "styled-components";
import { Spinner } from "react-bootstrap";
import { IoWarningOutline } from "react-icons/io5";
import { useEffect, useState } from "react";

const AiAdvisorCta = ({
  showLoadingIndicator = false,
  showError = false,
  onClick = () => {},
}) => {
  const theme = useSelector((state: RootState) => state.theme.mode);
  const [showErrorFallback, setShowErrorFallback]= useState(false);

  useEffect(() => {
    if (showError) {
      setShowErrorFallback(true);
      setTimeout(() => {
        setShowErrorFallback(false);
      }, 3000);
    }
  }, [showError]);

  const loadIcon = () => {
    if (theme == "dark") {
      return (
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          viewBox="0 0 566.9 566.9"
          style={{width: "32px", height: "32px"}}
          onClick={onClick}>
          <g id="ai-body">
            <path
              d="M478.1,2.9H88.8C41.4,2.9,2.9,41.4,2.9,88.8v389.3c0,47.5,38.5,85.9,85.9,85.9h389.3c18.6,0,35.8-5.9,49.8-15.9,12.7-9,22.8-21.5,29.1-35.9,4.5-10.5,7-22,7-34.1V88.8c0-47.5-38.5-85.9-85.9-85.9ZM88.8,544c-36.4,0-65.9-29.6-65.9-65.9v-88.4l93.1-93.1,247.5,247.4H88.8ZM541.4,496.6c-4.5,15.5-14.6,28.6-27.9,37.1L116,136.2,22.9,229.3V88.8c0-36.4,29.6-65.9,65.9-65.9h389.3c36.4,0,65.9,29.6,65.9,65.9v250.1l-202.7-202.7h-160.3l360.4,360.4Z"
              style={{ fill: "#ffffff", strokeWidth: "0px" }}
            />
          </g>
        </svg>
      );
    }
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        style={{width: "32px", height: "32px"}}
        version="1.1"
        viewBox="0 0 566.9 566.9"
        onClick={onClick}>
        <g id="ai-body">
          <path
            d="M478.1,2.9H88.8C41.4,2.9,2.9,41.4,2.9,88.8v389.3c0,47.5,38.5,85.9,85.9,85.9h389.3c18.6,0,35.8-5.9,49.8-15.9,12.7-9,22.8-21.5,29.1-35.9,4.5-10.5,7-22,7-34.1V88.8c0-47.5-38.5-85.9-85.9-85.9ZM88.8,544c-36.4,0-65.9-29.6-65.9-65.9v-88.4l93.1-93.1,247.5,247.4H88.8ZM541.4,496.6c-4.5,15.5-14.6,28.6-27.9,37.1L116,136.2,22.9,229.3V88.8c0-36.4,29.6-65.9,65.9-65.9h389.3c36.4,0,65.9,29.6,65.9,65.9v250.1l-202.7-202.7h-160.3l360.4,360.4Z"
            style={{ fill: "#1d1d1b", strokeWidth: "0px" }}
          />
        </g>
      </svg>
    );
  };

  return (
    <Wrapper className="ai-advisor-cta">
      {showLoadingIndicator ? (
        <Spinner variant="border" className="spinner" />
      ) : showErrorFallback ? (
        <IoWarningOutline onClick={onClick} color="red" />
      ) : (
        <>{loadIcon()}</>
      )}
    </Wrapper>
  );
};

export default AiAdvisorCta;

const Wrapper = styled.span`
  height: 35px;
  width: 35px;
  cursor: pointer;
  margin: 0 7px;
  color: ${({ theme }) => theme.button_plain.text};
  border: 1.5px solid ${({ theme }) => theme.button_plain.border};
  border-radius: 8px;
  &:hover {
    color: ${({ theme }) => theme.button_plain.hover_text};
    background: ${({ theme }) => theme.button_plain.hover};
  }
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 2px 8px;
  padding: 0px;
  align-items: center;
  .spinner {
    font-size: 15px;
    height: 20px;
    width: 20px;
  }
`;
