import ReactSelect, { components, MenuProps, OptionProps } from "react-select";
import { BiCheckbox, BiCheckboxSquare } from "react-icons/bi";
import { useTheme } from "styled-components";
import { Button } from "react-bootstrap";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocationFilterConfig } from "./context";
import { usePrevious } from "../../helpers/hooks/usePrevious";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";

const Option = ({ children, ...props }: OptionProps) => {
  const { selectProps, data, label } = props;

  const isOptionChecked = useMemo(() => {
    if (selectProps.value) {
      return selectProps.value.map((v) => v.id).includes(data.value);
    }
    return false;
  }, [selectProps, data]);

  return (
    <components.Option {...props}>
      <div>
        {isOptionChecked ? (
          <BiCheckboxSquare fontSize="30px" />
        ) : (
          <BiCheckbox fontSize="30px" />
        )}
        <span>{label}</span>
      </div>
    </components.Option>
  );
};

const Menu = ({ children, ...props }: MenuProps) => {
  const { selectProps } = props;

  const {
    selectedLocations,
    companyLocations,
    setClearSelection,
    handleSubmit,
    disableSubmit,
    toggleSelectAll,
    setToggleSelectAll,
    setPartialSelect,
  } = useLocationFilterConfig();

  const { locations: locationsFilter } = useSelector(
    (state: RootState) => state.filters
  );

  const handleClearSelection = () => {
    setClearSelection(true);
  };

  const handleSelectAllOptions = () => {
    setPartialSelect(false);
    setToggleSelectAll((i) => !i);
  };

  const disabledApplyButton = useMemo(() => {
    const locationDiff = selectedLocations.filter(
      (i) => !locationsFilter.includes(i.id)
    );
    return (
      !Boolean(locationDiff?.length) &&
      selectedLocations?.length == locationsFilter?.length
    );
  }, [selectedLocations, locationsFilter]);

  useEffect(() => {
    if (selectedLocations.length === companyLocations.length) {
      setToggleSelectAll(true);
    } else if (selectedLocations.length == 0) {
      setPartialSelect(false);
      setToggleSelectAll(false);
    } else {
      setPartialSelect(true);
      setToggleSelectAll(false);
    }
  }, [selectedLocations, companyLocations]);

  return (
    <components.Menu {...props}>
      <div
        className={
          selectProps?.isInFilteringState
            ? "hide-select-all"
            : "select_all_option"
        }
        onClick={handleSelectAllOptions}
      >
        <span style={{ margin: "10px 0 10px 12px" }}>
          {toggleSelectAll ? (
            <BiCheckboxSquare fontSize="30px" />
          ) : (
            <BiCheckbox fontSize="30px" />
          )}
        </span>
        Select All
      </div>
      {children}
      <div className="dropdown-actions">
        <Button
          className="btn cancel-btn"
          onClick={handleClearSelection}
          disabled={false}
        >
          Clear
        </Button>
        <Button
          className="btn submit-btn"
          onClick={handleSubmit}
          disabled={disableSubmit || disabledApplyButton}
        >
          Submit
        </Button>
      </div>
    </components.Menu>
  );
};

const MultiSelectMenu = () => {
  const theme = useTheme();
  const multiSelectRef = useRef(null);
  const [isFiltering, setIsFiltering] = useState(false);

  const {
    companyLocations,
    clearSelection,
    appliedFilters,
    handleItemSelect,
    setClearSelection,
    toggleSelectAll,
    isMenuOpen,
    partialSelect,
    selectedLocations,
  } = useLocationFilterConfig();

  useEffect(() => {
    if (isMenuOpen) {
      multiSelectRef.current.setValue(
        companyLocations
          .filter((d) => appliedFilters.includes(d.id))
          .map((v) => ({
            ...v,
            label: v.name,
            value: v.id,
          }))
      );
    }
  }, [isMenuOpen]);

  useEffect(() => {
    if (clearSelection) {
      if (multiSelectRef.current) {
        multiSelectRef.current.clearValue();
      }
    }
    setClearSelection(false);
  }, [clearSelection]);

  const prevToggleState = usePrevious(toggleSelectAll);

  useEffect(() => {
    if (toggleSelectAll) {
      multiSelectRef.current.setValue(
        companyLocations.map((v) => ({
          ...v,
          label: v.name,
          value: v.id,
        }))
      );
    } else {
      if (prevToggleState !== undefined) {
        if (partialSelect) {
          multiSelectRef.current.setValue(
            selectedLocations.map((v) => ({
              ...v,
              label: v.name,
              value: v.id,
            }))
          );
        } else {
          multiSelectRef.current.setValue([]);
        }
      }
    }
  }, [toggleSelectAll]);

  const options = useMemo(() => {
    return companyLocations.map((v) => ({
      ...v,
      label: v.name,
      value: v.id,
    }));
  }, [companyLocations]);

  const handleFilterOptions = (input) => {
    if (input) {
      setIsFiltering(true);
    } else {
      setIsFiltering(false);
    }
  };

  return (
    <ReactSelect
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          background: theme.dropdown.background_color,
        }),
        valueContainer: (baseStyles, state) => ({
          ...baseStyles,
          color: theme.widget.text,
        }),
        input: (baseStyles, state) => ({
          ...baseStyles,
          color: theme.text,
        }),
        menu: (baseStyles, state) => ({
          ...baseStyles,
          background: theme.dropdown.background_color,
          ":hover": {
            color: theme.dropdown.color,
            background: theme.dropdown.background_color,
          },
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          background: theme.dropdown.background_color,
          ":hover": {
            color: theme.dropdown.color,
            background: theme.dropdown.hover,
          },
        }),
      }}
      classNamePrefix="location-select"
      components={{
        Option,
        Menu,
        IndicatorsContainer: () => <></>,
      }}
      options={options}
      isInFilteringState={isFiltering}
      isMulti
      onChange={handleItemSelect}
      onInputChange={handleFilterOptions}
      noOptionsMessage={() => "No Locations Available"}
      ref={multiSelectRef}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      menuIsOpen
      controlShouldRenderValue={false}
      backspaceRemovesValue={false}
      isClearable={false}
      placeholder={"Search Location..."}
    />
  );
};

export default MultiSelectMenu;
