import { useState } from "react";
import TickerContext from "../../helpers/contexts/tickerContext";
import type {
  TickerContentDataType,
  TickerDataType,
} from "../../types/widgets";

const TickerProvider = ({ children }) => {
  const [tickerData, setTickerData] = useState<TickerContentDataType[]>([]);

  const addToTickerContext = (tickerContextData: TickerDataType[]) => {
    let tickerContentData: TickerContentDataType[] = [];
    if (tickerContextData.length > 0) {
      tickerContentData = tickerContextData.map((d) => {
        let currentValue = parseFloat(d?.value ?? "0");
        let previousValue = parseFloat(d?.previous_value ?? "0");
        return {
          name: d?.title ?? "",
          value: d?.value ?? "",
          value_formatter: d?.pre_text ?? "",
          indication:
            currentValue != previousValue
              ? currentValue - previousValue > 0
                ? "up"
                : "down"
              : currentValue == 0
              ? ""
              : "up",
        };
      });
    }
    setTickerData(tickerContentData);
  };

  return (
    <TickerContext.Provider
      value={{ tickerData, setTickerData, addToTickerContext }}>
      {children}
    </TickerContext.Provider>
  );
};

export default TickerProvider;
