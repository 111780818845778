import styled from "styled-components";
import Unauthorized from "./unautorized";

const WidgetLockedUnauthorized = ({ type }) => {
  return (
    <Wrapper>
      <div className={type == "a_level" ? "a_level_image" : "d_level_image"}>
        <Unauthorized />
      </div>
      <h1 className={type == "a_level" ? "a_level_heading" : "d_level_heading"}>
        Unauthorized
      </h1>
      <span className={type == "a_level" ? "a_level_para" : "d_level_para"}>
        You don't have permission to view this widget
      </span>
      <span className={type == "a_level" ? "a_level_para" : "d_level_para"}>
        If you believe this is a mistake, please contact your Administrator
      </span>
    </Wrapper>
  );
};

export default WidgetLockedUnauthorized;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .a_level_image {
    margin-bottom: 0.5rem;
    width: 54px;
    height: 52px;
  }
  .d_level_image {
    margin-bottom: 0.5rem;
    margin-top: 5rem;
    width: 90px;
    height: 90px;
  }
  .a_level_heading {
    font-size: 17px;
    font-weight: 700;
  }
  .d_level_heading {
    font-size: 25px;
    font-weight: 700;
  }
  .a_level_para {
    font-size: 14px;
    font-weight: 400;
  }
  .d_level_para {
    font-size: 16px;
    font-weight: 400;
  }
`;
