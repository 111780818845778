import { useElementSize } from "usehooks-ts";
import Widget from "../../baseWidget";
import BarChart from "./barChart";
import { useEffect, useRef, useState } from "react";
import { EChartsType } from "echarts";
import { useWidgetContext } from "../../../helpers/hooks/useWidgetContext";
import TreeViewChartPopup from "./treeViewChartPopup";

const SubRecipeWidget = () => {
  const [widgetRef, contentDimensions] = useElementSize();
  const [chartInstance, setChartinstance] = useState<EChartsType>(null);
  const [subRecipeInfo, setSubRecipeInfo] = useState(null);

  const widget = useWidgetContext();

  const isWidgetDataEmpty = !widget?.widgetData?.data?.length;

  useEffect(() => {
    if (!chartInstance) {
      setChartinstance(barChart.current?.getEchartsInstance());
    }
    if (chartInstance) {
      chartInstance.setOption({});
      chartInstance.on("click", function (event) {
        setSubRecipeInfo(event.data)
      });
    }
  }, [chartInstance]);

  const barChart = useRef(null);

  const barChartRef = (refProps) => {
    barChart.current = refProps;
    setChartinstance(barChart.current?.getEchartsInstance());
  };

  return (
    <Widget ref={widgetRef}>
      <Widget.Header>
        <Widget.Title>{widget?.widgetData?.title}</Widget.Title>
          <Widget.Controls>
            {!isWidgetDataEmpty ? <Widget.ExtendedAReportCta /> : null}
          </Widget.Controls>
      </Widget.Header>
      <Widget.Content>
        {isWidgetDataEmpty ? (
          <Widget.EmptyData />
        ) : (
          <>
            <TreeViewChartPopup
              data={subRecipeInfo}
              config={widget?.widgetData?.config}
              onClose={() => {
                setSubRecipeInfo(null);
              }}
            />
            <BarChart
              data={{
                config: widget.widgetData?.config,
                data: widget.widgetData?.data,
              }}
              height={contentDimensions.height - 90}
              width={contentDimensions.width - 50}
              chartRef={barChartRef}
            />
          </>
        )}
      </Widget.Content>
    </Widget>
  );
};

export default SubRecipeWidget;
