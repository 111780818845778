import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import * as types from "./types";
import * as navigationTypes from "../navigation/types";

const initialState = {
  showComparisonResults: false,
  comparisonData: null,
  comparisonLoading: false,
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(types.FETCH_COMPARISON_DATA_SUCCESS, (state, action) => {
    state.showComparisonResults = true;
    state.comparisonData = action.payload.data;
    state.comparisonLoading = false;
  });
  builder.addCase(types.FETCH_COMPARISON_DATA, (state, action) => {
    state.comparisonLoading = true;
  });
  builder.addCase(types.FETCH_COMPARISON_DATA_FAIL, (state) => {
    state.showComparisonResults = false;
    state.comparisonLoading = false;
  });
  builder.addCase(types.EXIT_COMPARISON_MODE, (state) => {
    state.showComparisonResults = false;
    state.comparisonData = null;
    state.comparisonLoading = false;
  });
  builder.addCase(navigationTypes.SET_NAVIGATED_ROUTE, (state) => {
    state.showComparisonResults = false;
    state.comparisonData = null;
    state.comparisonLoading = false;
  });
});
