import OverviewHeader from "../../../components/overviewHeader";

const HeaderSection = ({ dashboardDisplayName, refreshDashboard }) => {
  return (
    <OverviewHeader
      dashboardTitle={dashboardDisplayName}
      refreshData={refreshDashboard}
    />
  );
};

export { HeaderSection };
