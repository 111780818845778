import { useEffect, useMemo, useState } from "react";
import { useElementSize } from "usehooks-ts";
import { StackedBarChartType } from "../../../../types/widgets";
import { getFilterOptionsfromData, transformDataToVisualFormat } from "./utils";
import Widget from "../../../baseWidget";
import { useWidgetContext } from "../../../../helpers/hooks/useWidgetContext";
import StackedBarChart from "./stackedBarChart";
import { useDispatch, useSelector } from "react-redux";
import { addWidgetToFilterList } from "../../../../state/features/analytics/actions";
import ValueSelectDropdown from "../../../valueSelectDropdown";
import { RootState } from "../../../../state/store";

const BasicStackedBarChartWidget = () => {
  const [widgetData, setWidgetData] = useState<null | StackedBarChartType>(
    null
  );
  const [widgetContainerRef, widgetDimensions] = useElementSize();
  const [selectedOption, setSelectedOption] = useState(null);

  const { widgets: widgetFilters } = useSelector(
    (state: RootState) => state.filters
  );

  const widget = useWidgetContext();
  const dispatch = useDispatch();

  const isDataEmpty = useMemo(() => {
    if (widgetData && widgetData.data.length === 0) {
      return true;
    }
    return false;
  }, [JSON.stringify(widgetData)]);

  useEffect(() => {
    if (widget.widgetData?.data) {
      setWidgetData(widget.widgetData);
      const widgetFilterExists = widgetFilters.find(
        (i) => i.key == widget?.widgetFilterConfig?.widgetFilterKey
      );
      if (widget.widgetFilterConfig.enableFilters && !widgetFilterExists) {
        dispatch(
          addWidgetToFilterList({
            name: widget.widgetFilterConfig.filterTitle,
            filterKey: widget.widgetFilterConfig.widgetFilterKey,
            options: getFilterOptionsfromData(widget.widgetData),
          })
        );
      }
    }
  }, [widget.widgetData]);

  const valueSelectOptions = useMemo(() => {
    if (widgetData) {
      return widgetData.config.visual.value_configs.map((d) => ({
        key: d.accessor,
        name: d.name,
      }));
    }
    return [];
  }, [JSON.stringify(widgetData?.config)]);

  useEffect(() => {
    if (valueSelectOptions.length > 0) {
      setSelectedOption(valueSelectOptions[0]);
    }
  }, [valueSelectOptions.length]);

  const visualData = useMemo(() => {
    if (widgetData && selectedOption) {
      let visual_data = transformDataToVisualFormat(widgetData, selectedOption);
      let data_exist = visual_data?.data?.series?.filter(
        (i) => i?.data?.filter((j) => j)?.length
      );
      if (data_exist.length) {
        return visual_data;
      }
    }
    return null;
  }, [widgetData, selectedOption]);

  const handleDropdownSelect = (option) => {
    setSelectedOption(option);
  };

  return (
    <Widget ref={widgetContainerRef}>
      <Widget.Header>
        <Widget.Title>{widgetData?.title}</Widget.Title>
        <Widget.Controls>
          {!isDataEmpty ? <Widget.ExtendedAReportCta /> : null}
          {!isDataEmpty && valueSelectOptions.length > 1 ? (
            <ValueSelectDropdown
              options={valueSelectOptions}
              onChange={handleDropdownSelect}
              selectedOption={selectedOption}
            />
          ) : null}
        </Widget.Controls>
      </Widget.Header>
      <Widget.Content>
        {!isDataEmpty && visualData ? (
          <>
            {visualData ? (
              <StackedBarChart
                config={visualData?.config}
                data={visualData?.data}
                width={widgetDimensions.width - 50}
                height={widgetDimensions.height - 90}
              />
            ) : null}
          </>
        ) : (
          <Widget.EmptyData
            width={widgetDimensions.width - 50}
            height={widgetDimensions.height - 90}
          />
        )}
      </Widget.Content>
    </Widget>
  );
};

export default BasicStackedBarChartWidget;
