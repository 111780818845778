const CUSTOM_COLOR_SCALE = [
  "#ff9700",
  "#ffcf00",
  "#7ad305",
  "#29cb22",
  "#367700",
  "#003209",
];
const START_CIRCLE_RADIUS = 15;
const END_CIRCLE_RADIUS = 30;

const MAPBOX_MAP_STYLE_THEME_LIGHT =
  "mapbox://styles/sapaad-infra/clveroju1012101qz4g808ymy";
const MAPBOX_MAP_STYLE_THEME_DARK =
  "mapbox://styles/sapaad-infra/clvert2sy013g01oc1muq4w76";

const REPORT_MAX_ROWS = 25;

const JWT_REFRESH_TIMER_THRESHOLD_SECONDS = 300;
const PUSHER_OPTION = {
  APP_ID: "1515916",
  CLUSTER: "mt1",
  ENDPOINT: "/pusher_auth/authenticate",
};

const enum dashboard {
  sales = "vantage.sales",
  marketing = "vantage.marketing",
  operations = "vantage.operations",
  inventory = "vantage.inventory",
  business_summary = "vantage.business_summary",
}

const enum ENVIRONMENT {
  DEVELOPMENT = "development",
  REVIEW = "review",
  STAGE = "stage",
  PRODUCTION = "production",
}

let dashboardsList = [
  {
    display_name: "Sales",
    name: "sales",
    route: "/sales",
    api_endpoint: "/sales/dashboard",
  },
  {
    display_name: "Marketing",
    name: "marketing",
    route: "/marketing",
    api_endpoint: "/marketing/dashboard",
  },
  {
    display_name: "Operations",
    name: "operations",
    route: "/operations",
    api_endpoint: "/operations/dashboard",
  },
  {
    display_name: "Inventory",
    name: "inventory",
    route: "/inventory",
    api_endpoint: "/inventory/dashboard",
  },
  {
    display_name: "Business Summary",
    name: "business_summary",
    route: "/business-summary",
    api_endpoint: "/business-summary/dashboard",
  }
]


export {
  dashboard,
  dashboardsList,
  CUSTOM_COLOR_SCALE,
  START_CIRCLE_RADIUS,
  END_CIRCLE_RADIUS,
  MAPBOX_MAP_STYLE_THEME_LIGHT,
  MAPBOX_MAP_STYLE_THEME_DARK,
  REPORT_MAX_ROWS,
  JWT_REFRESH_TIMER_THRESHOLD_SECONDS,
  PUSHER_OPTION as PUSHER_CONFIG,
  ENVIRONMENT,
};
