import { FaClock } from "react-icons/fa6";
import { IoCalendar } from "react-icons/io5";
import styled from "styled-components";

const DateTimeToggle = ({ showTimePicker, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      {showTimePicker ? <FaClock /> : <IoCalendar />}
    </Wrapper>
  );
};

export default DateTimeToggle;

const Wrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.border_color};
  text-align: center;
  cursor: pointer;
  padding-bottom: 5px;
`;
