import { BiChevronLeft } from "react-icons/bi";
import { useFilterNavigate } from "../../../helpers/hooks/useFilterNavigate";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import DateRangePicker from "../../../components/dateRangePicker";

const ReportHeaderSection = ({ title }) => {
  const navigateTo = useFilterNavigate();
  const routeMatch = useParams();

  const handleBackNavigation = () => {
    navigateTo(`/${routeMatch.dashboard}/${routeMatch.widget}`);
  };

  return (
    <HeaderWrapper>
      <div className="nav-section">
        <BiChevronLeft className="icon" onClick={handleBackNavigation} />
        <span style={{ fontSize: "24px" }}>{title}</span>
      </div>
      <div className="date-picker">
        <DateRangePicker freeze={false} />
      </div>
    </HeaderWrapper>
  );
};

export default ReportHeaderSection;

const HeaderWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .nav-section {
    .icon {
      cursor: pointer;
      margin-bottom: 5px;
      font-size: 40px;
    }
  }
  .date-picker {
    margin-top: 5px;
  }
`;
