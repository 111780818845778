import { format } from "date-fns";
import { useBSDashboardContext } from "../../hooks/useBSDashboardContext";
import styled from "styled-components";

const BSContentFooter = () => {
  const { currentDateTime, userName } = useBSDashboardContext();
  return (
    <Wrapper>
      <span className="date-period">
        Generated by <strong className="user-name">{userName}</strong> on
        <span> {format(currentDateTime, "do MMMM yyyy - HH:mm:ss")}</span>
      </span>
    </Wrapper>
  );
};

export default BSContentFooter;

const Wrapper = styled.div`
  float: right;
  padding: 20px;
  .user-name {
    text-transform: capitalize;
  }
`;
