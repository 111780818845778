import { useEffect, useState } from "react";
import type { EChartsOption } from "echarts";
import BaseChart from "../../baseChart";
import { numFormatter } from "../../../helpers/utils";

export type RadialPolarBarChart = {
  config: {
    selector: string;
    name: string;
    value_formatter: string;
  };
  data: {
    polar: {
      radius: any;
    },
    angleAxis: {
      startAngle: number;
      type: string;
      data: string[];
    },
    radiusAxis: {
      max: number;
    },
    tooltip: {},
    series: {value: number; name: string}[];
  };
};

interface IRadialPolarBarVisualProps {
  width: number;
  height: number;
  data: any;
}

const RadialPolarBarChart = (props: IRadialPolarBarVisualProps) => {
  const [option, setOption] = useState<EChartsOption> ({
    tooltip: {
    },
    polar: {
      radius: [35, '80%']
    },
    radiusAxis: {},
    angleAxis: {
      type: 'category',
      data: [],
      startAngle: 105
    },
    series: {
      type: 'bar',
      data: [],
      coordinateSystem: 'polar',
    } 
  });

  useEffect(() => {
    if (props.data) {
      setOption((o) => {
        let updatedOption = { ...o };
        updatedOption.angleAxis = {
          type: 'category',
          data: props.data.data.series.map((d) => d.name),
          startAngle: 105
        };
        updatedOption.tooltip = {
          formatter: '{b} : {c} ' + props.data.config.value_formatter,
        };  
        updatedOption.radiusAxis = {
          axisLabel: {
            formatter: function (value, index) {
              return `${numFormatter(value)} ${
                props.data.config.value_formatter
              }`;
            },
          },
        };

        updatedOption.series = [
          {
            type: 'bar',
            data: props.data.data.series.map((d) => d.value),
            coordinateSystem: 'polar',
          }  
        ];
        return updatedOption;
      });
    }
  }, [props.data])

  const handleClickEvent = (e) => {
    // TODO
  };

  return (
    <BaseChart
      height={props.height}
      width={props.width}
      options={option}
      chartType="radial-polar-bar"
      onClick={handleClickEvent}
    />
  );
};

export default RadialPolarBarChart;
