import { useMemo } from "react";
import BaseChart from "../../baseChart";

const BarChart = ({ height, width, data, chartRef }) => {
  const option = useMemo(() => {
    let config = {};
    if (data.data) {
      config = {
        tooltip: {},
        legend: {
          show: false,
        },
        xAxis: {
          type: "category",
        },
        yAxis: [
          {
            type: "value",
          },
        ],
        dataset: {
          source: data.data,
          dimensions: [
            {
              type: "ordinal",
              name: "item",
              displayName: "Item",
            },
            {
              type: "int",
              name: "cost",
              displayName: "Item Cost",
            },
          ],
        },
        series: [
          {
            name: "Sub Recipe",
            type: "bar",
            itemStyle: {
              color: '#58585E'
            },
            label: {
              show: true,
              position: "inside",
            },
            tooltip: {
              enterable: true,
              hideDelay: 30000,
              formatter: (params) => {
                const { marker, data: seriesData } = params;
                const itemRow = `<span>${seriesData.item}</span><br />Quantity - <b>${formatNumber(seriesData.quantity)} ${seriesData.measure}</b>`;
                const otherRow = `<br/><p>Cost - ${formatNumber(seriesData.cost)} ${data?.config?.currency_formatter}</p>`;
                return `${marker}` + itemRow + otherRow;
              },
            },
            encode: {
              x: "item",
              y: "quantity",
            },
          },
        ],
      };
    }
    return config;
  }, [JSON.stringify(data)]);

  return (
    <BaseChart
      ref={chartRef}
      chartType="bar"
      options={option}
      height={height}
      width={width}
    />
  );
};

export default BarChart;
