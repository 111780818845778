import {
  COMPARISON_ACTION,
  COMPARISON_MODE,
  COMPARISON_OPTION,
} from "../../../types/comparison";
import { createDateFormatter } from "../../comparison/utils";
import { buildCompareDateRangeFromOption } from "../utils";

export const initialState = {
  comparisonMode: null as null | COMPARISON_MODE,
  option: null as null | COMPARISON_OPTION,
  compareDateRange: [],
};

export const comparisonReducer = (
  state: any,
  action: { type: COMPARISON_ACTION; payload: any }
) => {
  switch (action.type) {
    case COMPARISON_ACTION.SET_COMPARISON_MODE:
      return { ...state, comparisonMode: action.payload };
    case COMPARISON_ACTION.SET_COMPARISON_OPTION:
      let formatDate = createDateFormatter("dd-MM-yyyy");
      let compareDateRange = [formatDate(new Date()), formatDate(new Date())];
      if (state.comparisonMode) {
        compareDateRange = buildCompareDateRangeFromOption({
          baseDateRange: action.payload.baseDateRange,
          mode: state.comparisonMode,
          option: action.payload.option,
          config: action.payload.config,
        });
        return { ...state, option: action.payload.option, compareDateRange };
      }
      return { ...state, option: action.payload.option };
    default:
      return state;
  }
};
