import Draggable from "react-draggable";
import { useExtendedAContext } from "../../hooks/useExtendedAContext";
import styled, { keyframes } from "styled-components";
import { useRef } from "react";
import CloseButton from "../modalCloseButton";

const SaveCurrentViewPopup = () => {
  const { views, saveCurrentView } = useExtendedAContext();
  const { hideSaveCurrentView, isViewSaveInProgress} = views;
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    saveCurrentView({ name: inputRef.current?.value });
  };

  return (
    <PopupWrapper>
      <Draggable
        defaultClassName="current-view-save-popup"
        defaultClassNameDragging="current-view-save-popup-dragging"
        defaultClassNameDragged="current-view-save-popup-dragged"
        positionOffset={{ x: "5vw", y: "15vh" }}>
        <div className="popup-content">
          <div className="current-view-save-draggable-handle"></div>
          <CloseButton onClick={hideSaveCurrentView} />
          <div className="header">
            <span className="title">Save Current View</span>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="content">
              <input
                type="text"
                ref={inputRef}
                name="view-name-field"
                placeholder="What do you want to call this view?"
                required
              />
            </div>
            <div className="footer">
              <div className="action-buttons">
                <button
                  disabled={isViewSaveInProgress}
                  className="btn btn-outline action action-cancel"
                  onClick={hideSaveCurrentView}>
                  Cancel
                </button>
                <button
                  disabled={isViewSaveInProgress}
                  className="btn btn-secondary action action-save">
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </Draggable>
    </PopupWrapper>
  );
};

export default SaveCurrentViewPopup;

const contentShow = keyframes({
  "0%": {
    opacity: 0,
    transform: "translate(5vw, 14vh) scale(0.95)",
  },
  "100%": {
    opacity: 1,
    transform: "translate(5vw, 15vh) scale(1)",
  },
});

const PopupWrapper = styled.div`
  .popup-content {
    position: fixed;
    top: 10%;
    left: 25%;
    transform: translate(5vw, 15vh);
    z-index: 1610;
    background-color: ${({ theme }) => theme.pivot_table.background_color};
    border: 1px solid
      ${({ theme }) => theme.pivot_table.table_props.border_color};
    border-radius: 25px;
    width: 630px;
    height: 363px;
    padding: 25px;
    animation: ${contentShow} 600ms cubic-bezier(0.16, 1, 0.3, 1);
    .current-view-save-draggable-handle {
      cursor: move;
      position: absolute;
      width: 100%;
      top: 1px;
      left: 1px;
      height: 30px;
      border-radius: 20px 20px 0 0;
    }
    .header {
      margin-bottom: 20px;
      .title {
        font-size: 25px;
        font-weight: 600;
        line-height: 39px;
        text-align: left;
      }
    }
    .content {
      input[name="view-name-field"] {
        background: #fbfafa;
        padding: 10px;
        font-size: 18px;
        font-weight: 400;
        width: 100%;
        height: 64px;
        border-radius: 8px;
        border: 1px solid #b1b1b1;
        &::placeholder {
          line-height: 23.4px;
          text-align: left;
        }
      }
    }
    .footer {
      .action-buttons {
        position: absolute;
        bottom: 30px;
        right: 25px;
        display: flex;
        justify-content: end;
        .action {
          width: 124px;
          height: 45px;
          border-radius: 4px;
          border: 1px solid #000;
          margin: 0px 5px 0px 5px;
        }
      }
    }
  }
`;
