import styled from "styled-components";
import { SubRDataContext } from "../dataProvider";
import { useContext } from "react";
import ContentLoader from "react-content-loader";
import { useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import LoadingErrorScreen from "../loadingError";
import { generateRandomId } from "../../../../helpers/utils";

export default function PaymentDetails() {
  const data = useContext(SubRDataContext);
  const theme = useSelector((state: RootState) => state.theme.mode);

  const paymentsData = (data?.paymentsData || []).map((i) => ({
    ...i,
    key: generateRandomId(),
  }));

  if (data.isOrderSummaryDataLoading && !data.paymentsData) {
    return (
      <Container>
        <span>Payments</span>
        <div className="loader-container">
          <ContentLoader
            height={120}
            speed={3}
            backgroundColor={theme === "light" ? "#f3f3f3" : "#2a2a2a"}
            foregroundColor={theme === "light" ? "#ecebeb" : "#1e1e1e"}
            style={{ width: "100%" }}
          >
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
          </ContentLoader>
        </div>
      </Container>
    );
  }

  if (data.isOrderSummaryDataLoadError && !data.paymentsData) {
    return (
      <Container style={{ height: "100%" }}>
        <span>Payments</span>
        <LoadingErrorScreen />
      </Container>
    );
  }

  return (
    <Container>
      <span>Payments</span>
      {paymentsData && paymentsData.length ? (
        <div>
          <table>
            <thead>
              <tr>
                <th>Paid By</th>
                <th>Amount</th>
                <th>Collected</th>
                <th>Returned</th>
                <th>Tips</th>
              </tr>
            </thead>
            <tbody>
              {paymentsData?.map((data) => {
                return (
                  <tr key={data.key}>
                    <td>{data.payment_type}</td>
                    <td>{formatNumber(parseFloat(data.amount).toFixed(decimalPrecision))}</td>
                    <td>{formatNumber(parseFloat(data.amount_collected).toFixed(decimalPrecision))}</td>
                    <td>0.00</td>
                    <td>{formatNumber(parseFloat(data.tips).toFixed(decimalPrecision))}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="no-data">No data available</div>
      )}
    </Container>
  );
}

const Container = styled.div`
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.text};
  padding: 0.5rem;
  background: ${({ theme }) => theme.widget.background};
  color: ${({ theme }) => theme.text};
  filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 4px 4px);
  border-radius: 0.5rem;
  border: 1px solid rgb(255, 255, 255);
  span {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    margin-left: 0.5rem;
  }
  div {
    margin-top: 0.5rem;
    max-height: 120px;
    overflow: scroll;
    table {
      width: 100%;
      border-radius: 0.5rem;
      overflow: hidden;
      thead {
        background-color: ${({ theme }) => theme.body};
        opacity: 0.5;
      }
      tbody {
        border-top: 1px solid #e6dfdf;
      }
      tr {
        th,
        td {
          padding: 0.5rem;
          font-size: 12px;
          font-weight: 400;
          line-height: normal;
          text-align: center;
        }
        td {
          padding: 1rem;
          font-weight: 500;
        }
      }
    }
  }
  .no-data {
    font-size: 16px;
    opacity: 0.5;
    height: 120px;
    margin-top: 0.5rem;
    display: flex;
    border: 1px solid #e6dfdf;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
  }
`;
