import DashboardLoadingGrid from "../../../components/dashboardLoader";
import DashboardWidgetsFetchError from "../../../components/dashboardWidgetsFetchError";

const DashboardWidgetsStatusContainer = ({
  children,
  loadError,
  loading,
  refreshDashboard,
}) => {
  if (loadError) {
    return <DashboardWidgetsFetchError retryRequest={refreshDashboard} />;
  }
  if (loading) {
    return <DashboardLoadingGrid />;
  }
  return <>{children}</>;
};

export { DashboardWidgetsStatusContainer };
