import DashboardWidgetLoader from "../../../components/widgetLoader/dashboardWidgetLoader";
import { useMemo } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { MdControlCamera } from "react-icons/md";
import DashboardLoadingGrid from "../../../components/dashboardLoader";
import Resizer from "./resizer";
import { useDashboardGridLayout } from "../../../helpers/hooks/useDashboardGridLayout";
import { breakpoints, columns, widgetIdSplitSymbol } from "../../../helpers/gridLayoutConfigurations";

const DashboardWidgetsGrid = ({ dashboard, dashboardWidgets }) => {
  const GridLayout = useMemo(() => WidthProvider(Responsive), []);
  const { gridLayoutConfiguration, saveLayoutToLS, shouldLoadGridLayout } =
    useDashboardGridLayout({
      dashboard,
      dashboardWidgets,
    });

  const handleOnDragStop = (layout) => {
    saveLayoutToLS(layout);
  };

  const handleResizeStop = (layout) => {
    saveLayoutToLS(layout);
  };

  return gridLayoutConfiguration ? (
    <GridLayout
      breakpoints={breakpoints}
      rowHeight={25}
      verticalCompact
      cols={columns}
      layouts={gridLayoutConfiguration}
      containerPadding={{ lg: [30, 15], md: [10, 15], sm: [15, 15] }}
      margin={[20, 20]}
      onDragStop={handleOnDragStop}
      onResizeStop={handleResizeStop}
      draggableHandle=".drag-handle"
      className="grid-layout">
      {dashboardWidgets.map((widget) => {
        return (
          <div key={`${widget.url}${widgetIdSplitSymbol}${widget.widget.type}`}>
            <DashboardWidgetLoader
              url={widget.url}
              type={widget.widget.type}
              childWidgets={[...widget.widgets, widget.groups]}
              widgetData={widget.widget}
              isTimelessWidget={widget.is_timeless_widget}
            />
            <div className="resizer">
              <Resizer />
            </div>
            <div className="drag-handle">
              <MdControlCamera />
            </div>
          </div>
        );
      })}
    </GridLayout>
  ) : (
    <DashboardLoadingGrid />
  );
};

export { DashboardWidgetsGrid };
