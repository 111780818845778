import styled from "styled-components";

interface ISwitchToggleProps {
  widgetData: any
  handleFlipData(): void;
}

const SwitchToggle = ({widgetData, handleFlipData}: ISwitchToggleProps) => {
  return (
    <Wrapper>
      <span>
        <label className="switch">
          <input type="checkbox" onClick={handleFlipData} />
          <span className="slider round">
            <span
              className={
                widgetData[0]?.config?.name?.length > 6
                  ? "option-1-long"
                  : "option-1"
              }>
              {widgetData[0]?.config?.name}
            </span>
            <span className="option-2">
              {widgetData[1]?.config?.name}
            </span>
          </span>
        </label>
      </span>
    </Wrapper>
  );
}

export default SwitchToggle;

const Wrapper = styled.div`
  .switch, .switch-long {
    position: relative;
    display: inline-block;
    height: 34px;
    min-width: 112px;
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    .slider {
      position: absolute;
      border: 2px solid;
      border-color: #42454b;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${({ theme }) => theme.widget.background};
      -webkit-transition: 0.4s;
      transition: 0.4s;
      &::before {
        position: absolute;
        content: "";
        height: 26px;
        width: 52px;
        left: 2px;
        bottom: 2px;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }
    .option-1 {
      position: relative;
      text-align: center;
      font-size: 12px;
      color: #ffffff;
      left: 8px;
      bottom: 7px;
    }
    .option-2 {
      position: absolute;
      text-align: center;
      font-size: 12px;
      color: ${({ theme }) => theme.text};
      right: 13px;
      bottom: 7px;
    }
    input:checked + .slider {
      background-color: ${({ theme }) => theme.widget.background};
      .option-2 {
        color: #ffffff;
      }
      .option-1 {
        color: ${({ theme }) => theme.text};
      }
    }
    input:checked + .slider:before {
      -webkit-transform: translateX(55px);
      -ms-transform: translateX(55px);
      transform: translateX(52px);
    }
  }

  .switch-long {
    position: relative;
    width: 145px;
    .slider::before{
      width: 84px;
    }
    .option-1 {
      left: 10px;
    }
    input:checked + .slider::before {
        transform: translateX(83px);
        width: 54px;
    }
  }
  .slider {
    &.round {
      border-radius: 24px;
    }
    &.round::before {
      border-radius: 24px;
      background: #42454b;
    }
  }
`;
