import { createReducer } from "@reduxjs/toolkit";
import * as actions from "./actions";
import * as locationSlice from "../locations/slice";
import { format } from "date-fns";

const initialState = {
  locations: [],
  dates: [format(new Date(), "yyyy-MM-dd"), format(new Date(), "yyyy-MM-dd")],
  timeRange: [[0, 0], [0, 0]], // [startTime, endTime]
  widgets: [] as any[],
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(locationSlice.setLocations, (state, action) => {
    state.locations = action.payload.map((d) => d.id);
  });
  builder.addCase(actions.setLocationFilter, (state, action) => {
    state.locations = action.payload;
  });
  builder.addCase(actions.setTimeRangeFilter, (state, action) => {
    state.dates = action.payload;
  });
  builder.addCase(actions.setWidgetFilter, (state, action) => {
    let index = state.widgets.findIndex(
      (widget) => widget.key === action.payload.key
    );
    if (index === -1) {
      state.widgets.push(action.payload);
    } else {
      state.widgets[index] = action.payload;
    }
  });
  builder.addCase(actions.removeWidgetFilter, (state, action) => {
    let widgetFilters = [...state.widgets];
    let categoryIndex = widgetFilters.findIndex((w)=>w.key === action.payload.key);
    if(categoryIndex !== -1) {
      let widgetCategory = { ...widgetFilters[categoryIndex] };
      widgetCategory.data = widgetFilters[categoryIndex].data.filter(
        (f) => f.id !== action.payload.id
      );
      widgetFilters[categoryIndex] = widgetCategory;
    }
    let filterEmptyData = widgetFilters.filter((d) => d.data.length !== 0); 
    return { ...state, widgets: filterEmptyData };
  });
  builder.addCase(actions.clearWidgetFilter, (state, action) => {
    state.widgets = [];
  });
  builder.addCase(actions.setDropdownFilters, (state, action) => {
    state.widgets = action.payload;
  });
  builder.addCase(actions.setDateRangeWithLocations, (state, action) => {
    state.locations = action.payload.locationIds;
    state.dates = action.payload.selectedDateRange;
    state.timeRange = action.payload.selectedTimeRange;
  });
  builder.addCase(actions.setDateTimeFilter, (state, action) => {
    state.dates = action.payload.dateRange;
    state.timeRange = action.payload.timeRange;
  });
});
