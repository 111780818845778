import styled from "styled-components";

const UnknownWidget = ({ type = "" }) => {
  return (
    <WidgetWrapper className="col-sm-4 col-lg-3 col-md-3">
      <div className="widget-content">
        <span>⚠️</span>
        <span>Unknown widget type:</span>
        <code>{type}</code>
      </div>
    </WidgetWrapper>
  );
};

export default UnknownWidget;

export const WidgetWrapper = styled.div`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.widget.background};
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.05));
  height: 100%;
  width: 100%;
  > .widget-content {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;
