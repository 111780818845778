import { useEffect, useMemo, useState } from "react";
import { useElementSize } from "usehooks-ts";
import DonutChart from "./donutChart";
import { useDispatch, useSelector } from "react-redux";
import { CustomDispatch, RootState } from "../../../state/store";
import { addWidgetToFilterList } from "../../../state/features/analytics/actions";
import Widget from "../../baseWidget";
import { useWidgetContext } from "../../../helpers/hooks/useWidgetContext";
import ValueSelectDropdown from "../../valueSelectDropdown";

const DonutSwitchWidget = () => {
  const [widgetTitle, setWidgetTitle] = useState("");
  const [widgetData, setWidgetData] = useState<any>(null);
  const [chartContainerRef, chartDimensions] = useElementSize();
  const [selectedOption, setSelectedOption] = useState(null);

  const widget = useWidgetContext();
  const dispatch: CustomDispatch = useDispatch();

  const { widgets: widgetFilters } = useSelector(
    (state: RootState) => state.filters
  );

  const valueSelectOptions = useMemo(() => {
    let options = [];
    if (widget.widgetData?.widget_data) {
      options = widget.widgetData.widget_data.map((d) => {
        let key = d?.config?.name;
        let name = d?.config?.name;
        return { key, name };
      });
    }
    return options;
  }, [JSON.stringify(widget.widgetData)]);

  const showKpiSelectDropdown = useMemo(() => {
    if (widgetData && widgetData.length && widgetData.length > 0) {
      if (
        widgetData[0]?.data?.series?.length ||
        widgetData[1]?.data?.series?.length
      ) {
        return true;
      }
      return false
    }
    return false;
  }, [JSON.stringify(widgetData)]);

  const visualData = useMemo(() => {
    let data = null;
    if (widgetData && selectedOption) {
      data = widgetData.find((d) => d.config.name === selectedOption?.key);
    }
    if (!data) return null;
    return { data };
  }, [widgetData, selectedOption]);

  const isWidgetEmptyData = useMemo(() => {
    if (visualData?.data) {
      let seriesData = visualData?.data?.data?.series ?? [];
      if (seriesData.length > 0) {
        return false;
      }
      return true;
    }
    return true;
  }, [visualData]);

  const handleDropdownSelect = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    if (valueSelectOptions.length > 0 && !selectedOption) {
      setSelectedOption(valueSelectOptions[0]);
    }
  }, [valueSelectOptions.length]);

  useEffect(() => {
    if (widget.widgetData.widget_data) {
      setWidgetTitle(widget.widgetData.title);
      setWidgetData(widget.widgetData.widget_data);
      const widgetFilterExists = widgetFilters.find(
        (i) => i.key == widget?.widgetFilterConfig?.widgetFilterKey
      );
      if (widget.widgetFilterConfig.enableFilters && !widgetFilterExists) {
        dispatch(
          addWidgetToFilterList({
            name: widget.widgetFilterConfig.filterTitle,
            filterKey: widget.widgetFilterConfig.widgetFilterKey,
            options: widget.widgetData.widget_data[0].data.series.map((d) => ({
              id: d.id,
              name: d.category,
            })),
          })
        );
      }
    }
  }, [widget.widgetData]);

  return (
    <Widget ref={chartContainerRef}>
      <Widget.Header>
        <Widget.Title>{widgetTitle}</Widget.Title>
        <Widget.Controls>
          {visualData ? (
            <>
              {!isWidgetEmptyData && <Widget.ExtendedAReportCta />}
              {showKpiSelectDropdown ? (
                <ValueSelectDropdown
                  options={valueSelectOptions}
                  onChange={handleDropdownSelect}
                  selectedOption={selectedOption}
                />
              ) : null}
            </>
          ) : null}
        </Widget.Controls>
      </Widget.Header>
      <Widget.Content>
        {!isWidgetEmptyData && visualData ? (
          <DonutChart
            width={chartDimensions.width - 50}
            height={chartDimensions.height - 90}
            data={visualData.data}
          />
        ) : (
          <Widget.EmptyData
            width={chartDimensions.width - 50}
            height={chartDimensions.height - 90}
          />
        )}
      </Widget.Content>
    </Widget>
  );
};

export default DonutSwitchWidget;
