import { createReducer } from "@reduxjs/toolkit";
import * as actions from "./actions";

const initialState = {
  type: "",
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(actions.invalidJwt, (state, action) => {
    state.type = "invalid_token";
  });
});
