import styled from "styled-components";
import { formatValue, numberFormatting } from "../../helpers/utils";
import { Fragment } from "react";
import { RootState } from "../../../../state/store";
import { useSelector } from "react-redux";

const DataGridView = ({ data }) => {
  const decimalPrecision = useSelector((state: RootState) => state.company.company_details?.currency_precision);
  return (
    <DataGridWrapper>
      <div
        className="table-area"
        style={{
          height:
            data &&
            Object.keys(data).length &&
            data?.footer_data[0]?.row_values?.length
              ? "75%"
              : "100%",
        }}>
        <table>
          <thead className="widget-header">
            <tr>
              {data &&
                data.header_data?.map((item, i) => {
                  return (
                    <Fragment key={i}>
                      {item.row_values.map((values, i) => {
                        return <th key={i}>{values.value}</th>;
                      })}
                    </Fragment>
                  );
                })}
            </tr>
          </thead>
          <tbody className="widget-data">
            <>
              {data &&
                data.content_data?.map((item, i) => {
                  return (
                    <tr key={i} className="content-text">
                      {item.row_values.map((values, i) => {
                        return (
                          <td
                            key={i}
                            className={
                              values.highlight
                                ? "content-text highlight-text"
                                : "content-text"
                            }>
                            {isNaN(values.value % 1) || values.value === ""
                              ? values.value
                              : numberFormatting(values.value, false)}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </>
          </tbody>
          {data &&
          Object.keys(data).length > 0 &&
          data.summary_data[0].row_values.length > 0 ? (
            <tfoot className="summary">
              <>
                {data.summary_data?.map((item, i) => {
                  return (
                    <tr key={i} style={{ fontWeight: item.emphasize ? 700 : 400 }}>
                      {item.row_values.map((valCol, i) => {
                        return (
                          <td
                            key={i}
                            className={
                              valCol.highlight ? "highlight-text" : ""
                            }>
                            {formatValue({
                              valueColumn: valCol,
                              decimalPrecision: decimalPrecision || 2,
                            })}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </>
            </tfoot>
          ) : (
            false
          )}
        </table>
      </div>
      {data &&
      Object.keys(data).length > 0 &&
      data.footer_data[0]?.row_values?.length > 0 ? (
        <div className="footer-data">
          <div className="footer-table">
            <table>
              {data.footer_data?.map((item, i) => {
                return (
                  <tr key={i} style={{ fontWeight: item.emphasize ? 700 : 400 }}>
                    {item.row_values?.map((values, i) => {
                      return (
                        <td
                          key={i}
                          className={values.highlight ? "highlight-text" : ""}>
                          {formatValue({
                            valueColumn: values,
                            decimalPrecision: decimalPrecision || 2,
                          })}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      ) : (
        false
      )}
    </DataGridWrapper>
  );
};

export default DataGridView;

const DataGridWrapper = styled.div`
  .table-area {
    position: fixed;
    border: 1px solid ${({ theme }) => theme.table_cell_border};
    width: 100%;
    font-size: 16px;
    background-color: ${({ theme }) => theme.widget.background};
    overflow-y: scroll;
    border-radius: 25px;
    table {
      width: calc(100%);
      .widget-header {
        background-color: ${({ theme }) => theme.bs_title};
        position: sticky;
        top: 0;
        .title-text {
          padding: 0.25rem 1.5rem;
          font-weight: 700;
          display: flex;
          justify-content: space-between;
        }
      }
      .widget-data {
        padding: 0.5rem;
        width: 100%;
        .content {
          font-weight: 400;
          font-size: 16px;
          .content-text {
            padding: 0 0.5rem;
            margin: 0 1rem;
            font-weight: 400;
            font-size: 16px;
            border-bottom: 1px solid ${({ theme }) => theme.text};
            display: flex;
            justify-content: space-between;
            padding: 0.5rem;
          }
        }
      }
      .summary {
        position: sticky;
        bottom: 0;
        border-top: 3px solid ${({ theme }) => theme.text};
        background-color: ${({ theme }) => theme.widget.background};
        transition: all 0.25s linear 0s;
        tr {
          border-top: 3px solid ${({ theme }) => theme.text};
          td {
            border-top: 3px solid ${({ theme }) => theme.text};
          }
        }
        .summary-text {
          margin: 0 1rem;
          font-size: 16px;
          display: flex;
          padding: 0.5em;
          justify-content: space-between;
          &:first-child {
            border-top: 3px solid ${({ theme }) => theme.text};
          }
        }
      }
      thead tr th,
      tbody tr td,
      tfoot tr td {
        word-break: normal;
        text-align: right;
        padding: 20px;
        word-break: break-all;
        min-width: 150px;
        white-space: nowrap;
      }
      thead tr th:first-child,
      tbody tr td:first-child,
      tfoot tr td:first-child {
        text-align: left;
        word-break: break-word;
      }
      thead tr th {
        height: 60px;
        font-size: 22px;
        vertical-align: top;
      }
      tfoot tr td {
        font-size: 20px;
        padding: 10px 20px;
      }
      tbody tr td {
        padding: 5px 20px;
        border-bottom: 1px solid ${({ theme }) => theme.table_cell_border};
        font-size: 16px;
      }
    }
  }

  .footer-data {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-top: 10px;
    background-color: ${({ theme }) => theme.body};
    font-size: 20px;
    .footer-table {
      border-radius: 25px;
      width: 100%;
      background-color: ${({ theme }) => theme.widget.background};
      border: 1px solid ${({ theme }) => theme.table_cell_border};
      padding: 8px 10px;
      table {
        width: calc(100%);
        tr td {
          word-break: normal;
          text-align: right;
          padding: 8px 10px;
          word-break: break-all;
          min-width: 150px;
          white-space: nowrap;
        }
        tr td:first-child {
          text-align: left;
          word-break: break-word;
        }
        tr td:last-child {
          width: 30%;
        }
      }
    }
  }
  .highlight-text {
    color: red;
  }
  .highlight-text::before {
    content: "(";
  }
  .highlight-text::after {
    content: ")";
  }
`;
