import { useContext, useMemo, useEffect, useState } from "react";
import styled from "styled-components";
import { useElementSize } from "usehooks-ts";
import { WidgetContext } from "../../widgetContext";
import WidgetLoading from "../../widgetLoading";
import SunburstChart from "./sunburstChart";

interface ISunburstChartWidgetProps {}

const SunburstChartWidget = (props: ISunburstChartWidgetProps) => {
  const [widgetData, setWidgetData] = useState<any>({});
  const [chartContainerRef, chartDimensions] = useElementSize();

  const widget = useContext(WidgetContext);

  useEffect(() => {
    if (widget.widgetData.widget_data) {
      setWidgetData(widget.widgetData);
    }
  }, [widget.widgetData]);

  const sunburstChart = useMemo(
    () => (
      <SunburstChart
        data={widgetData.widget_data}
        width={chartDimensions.width - 50}
        height={chartDimensions.height - 90}
      />
    ),
    [widgetData, chartDimensions]
  );

  const handleWidgetOnClick = () => {
    if (widget.widgetOnClick) {
      widget.widgetOnClick();
    }
  };

  return (
    <WidgetWrapper ref={chartContainerRef}>
      {widget.widgetLoading ? (
        <WidgetLoading />
      ) : widget.widgetLoadError ? (
        <div>Error</div>
      ) : widgetData ? (
        <>
          <div className="widget-title" onClick={handleWidgetOnClick}>
            {widgetData.title}
          </div>
          <div className="widget-content">{sunburstChart}</div>
        </>
      ) : (
        <div>No Data</div>
      )}
    </WidgetWrapper>
  );
};

export default SunburstChartWidget;

const WidgetWrapper = styled.div`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.widget.background};
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.05));
  height: 100%;
  width: 100%;
  padding: 25px;
  .widget-title {
    font-size: 1.3rem;
    text-decoration: underline;
  }
`;
