import styled from "styled-components";
import { useBSDashboardContext } from "../../hooks/useBSDashboardContext";
import BSDashboardLoading from "../bsDashboardLoading";

const BSDashboardStatus = ({ children }) => {
  const { dataFetchError, isDataLoading } = useBSDashboardContext();
  if (dataFetchError) {
    return (
      <Wrapper>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <span>Oops! Unable to load widgets..! Please try again</span>
        </div>
      </Wrapper>
    );
  }
  if (isDataLoading) {
    <Wrapper>
      <BSDashboardLoading />;
    </Wrapper>;
  }
  return <Wrapper>{children}</Wrapper>;
};

export default BSDashboardStatus;

const Wrapper = styled.div``;
