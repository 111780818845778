import { useCallback } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../state/store";
import { createCompanyDateSelectionPresets } from "../../helpers/utils";

const DatePresetSection = ({ applyFilters }) => {
  const { dates: selectedDate } = useSelector(
    (state: RootState) => state.filters
  );
  const { company } = useSelector((state: RootState) => state);

  const getClassName = useCallback(
    (presetDates) => {
      return `preset ${
        presetDates[0] === selectedDate[0] && presetDates[1] === selectedDate[1]
          ? "selected"
          : ""
      }`;
    },
    [selectedDate]
  );

  const selectPresetDate = (presetDates, presetTime) => {
    applyFilters(presetDates, presetTime);
  };

  const dateRangePresets = createCompanyDateSelectionPresets({
    businessEndTimeInSecs: company?.company_details?.business_end_time,
    timezoneOffset: company?.company_details?.time_zone_offset,
  });

  return (
    <Wrapper>
      <span className="heading">Quick Selection: </span>
      {Object.entries(dateRangePresets)
        .map((a) => a[1])
        .map((d, i) => (
          <span
            key={i}
            className={getClassName(d.date_range)}
            onClick={() => selectPresetDate(d.date_range, d.time_range)}>
            {d.title}
          </span>
        ))}
    </Wrapper>
  );
};

export default DatePresetSection;

const Wrapper = styled.div`
  .heading {
    font-size: 16px;
    font-weight: bold;
  }
  .preset {
    font-size: 16px;
    font-weight: 400;
    margin: 0 10px;
    cursor: pointer;
    user-select: none;
    &:hover {
      text-decoration: underline;
    }
    &.selected {
      font-weight: 700;
      color: #14785f;
      pointer-events: none;
      &:hover {
        text-decoration: none;
      }
    }
  }
  padding-bottom: 11px;
  border-bottom: 1px solid ${({ theme }) => theme.border_color};
`;
