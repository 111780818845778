import { useContext } from "react";
import { BSWidgetContext } from "../contexts/bsWidgetContext";
import { BSWidgetContextType } from "../../../types/businessSummary";

const useBSWidgetContext = () => {
  const context = useContext(BSWidgetContext) as BSWidgetContextType;
  return context;
};

export { useBSWidgetContext };
