import { removeDuplicateArrayItems } from "../../../helpers/utils";

export const patchSeriesCategories = (selectedWidgetData) => {
  let allCategoryData = removeDuplicateArrayItems(
    selectedWidgetData.data.series
      .map((d) => d.series_data)
      .flat()
      .map((d) => ({ ...d, value: 0 }))
  );
  let mainSeriesData = selectedWidgetData.data.series.map((d) => {
    let existingCatSeries = d.series_data;
    let normalizeCatData = allCategoryData.map((c) => {
      let existCat = existingCatSeries.find((e) => e.id === c.id);
      if (existCat) {
        return existCat;
      }
      return c;
    });
    return { ...d, series_data: normalizeCatData };
  });
  let modifiedWidgetsData = {
    ...selectedWidgetData,
    data: { ...selectedWidgetData.data, series: mainSeriesData },
  };
  return modifiedWidgetsData;
};
