import { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useElementSize } from "usehooks-ts";
import { WidgetContext } from "../../widgetContext";
import MultiSetBarChart from "./multiSetBarChart";
import { useDispatch, useSelector } from "react-redux";
import { addWidgetToFilterList } from "../../../state/features/analytics/actions";
import Widget from "../../baseWidget";
import { patchSeriesCategories } from "./utils";
import { RootState } from "../../../state/store";

const MultiSetBarChartWidget = () => {
  const [widgetData, setWidgetData] = useState<any>(null);
  const [chartContainerRef, chartDimensions] = useElementSize();

  const widget = useContext(WidgetContext);
  const dispatch = useDispatch();

  const { widgets: widgetFilters } = useSelector(
    (state: RootState) => state.filters
  );

  const widgetSeriesData = useMemo(() => {
    if (widgetData) {
      return widgetData.widget_data.data.series;
    }
    return [];
  }, [widgetData]);

  useEffect(() => {
    if (widget.widgetData.widget_data) {
      setWidgetData(widget.widgetData);
      const widgetFilterExists = widgetFilters.find(
        (i) => i.key == widget?.widgetFilterConfig?.widgetFilterKey
      );
      if (widget?.widgetFilterConfig?.enableFilters && !widgetFilterExists) {
        dispatch(
          addWidgetToFilterList({
            name: widget.widgetFilterConfig.filterTitle,
            filterKey: widget.widgetFilterConfig.widgetFilterKey,
            options:
              widget.widgetData.widget_data.data.series[0].series_data.map(
                (d) => ({
                  id: d.id,
                  name: d.category,
                })
              ),
          })
        );
      }
    }
  }, [widget.widgetData]);

  return (
    <Widget ref={chartContainerRef}>
      <Widget.Header>
        <Widget.Title>{widgetData?.title}</Widget.Title>
      </Widget.Header>
      <Widget.Content>
        {widgetSeriesData.length > 0 ? (
          <MultiSetBarChart
            data={patchSeriesCategories(widgetData.widget_data)}
            width={chartDimensions.width - 50}
            height={chartDimensions.height - 90}
          />
        ) : (
          <Widget.EmptyData
            width={chartDimensions.width - 50}
            height={chartDimensions.height - 90}
          />
        )}
      </Widget.Content>
    </Widget>
  );
};

export default MultiSetBarChartWidget;
