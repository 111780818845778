import styled from "styled-components";
import LoadingAnimation from "../../../components/loadingAnimation";

const ReportsLoaderModal = () => {
  return (
    <LoaderWrapper>
      <div className="content">
        <LoadingAnimation />
        <span>Loading Data</span>
      </div>
    </LoaderWrapper>
  );
};

export default ReportsLoaderModal;

const LoaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.background};
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    position: absolute;
    margin-top: -100px;
    width: 576px;
    height: 324px;
    background-color: ${({ theme }) => theme.widget.background};
    padding: 6rem 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
      font-size: 14px;
      color: #91a1ac;
      font-weight: 500;
    }
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
  }
`;
