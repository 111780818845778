import { numFormatter } from "../../../helpers/utils";
import styled from "styled-components";
import { useContext } from "react";
import { WidgetContext } from "../../widgetContext";

interface ISmallNumberCostWidgetProps {}

const SmallNumberCostWidget = (props: ISmallNumberCostWidgetProps) => {
  const widget = useContext(WidgetContext);

  const handleWidgetOnClick = () => {
    if (widget.widgetOnClick) {
      widget.widgetOnClick();
    }
  };

  return (
    <Wrapper onClick={handleWidgetOnClick}>
      <div className="widget-content"> 
        <span className="title">
        {widget.widgetData?.data?.currency}
        </span>
        <span
          className="value"
          data-toggle="tooltip"
          data-placement="top"
          title={widget.widgetData?.data?.value}>
            {numFormatter(widget.widgetData?.data?.value)}
        </span>
        <span className="description">
          {widget.widgetData?.data?.description}
        </span>
      </div>
    </Wrapper>
  );
};

export default SmallNumberCostWidget;

const Wrapper = styled.div`
border-radius: 20px;
background-color: ${({ theme }) => theme.widget.background};
filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.05));
width: 100%;
height: 100%;
padding: 25px;
display: flex;
cursor: pointer;
justify-content: center;

.widget-content {
  display: flex;
  flex-direction: column;
  justify-content: left;
  position: relative;
  width: 100%;

  .title {
    position: relative;
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    top: 15px;
    padding: 10px;
  }
  .value {
    display: block;
    padding: 10px;
    line-height: 80px;
    font-size: 4.5rem;
    line-height: 4.5rem;
    font-weight: 700;
    letter-spacing: -2px;
  }
  .description {
    display: block;
    font-size: 26px;
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 10px;
    padding: 10px;
  }
}  
`;

