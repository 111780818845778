import { useEffect, useState } from "react";
import type { EChartsOption } from "echarts";
import BaseChart from "../../baseChart";

export type SunburstChartConfiguration = {
  config: {
    selector: string;
    name: string;
    value_formatter: string;
  };
  data: {
    series: {
      type: string;
      radius: any;
      label: {
        rotate: string;
      };
      data: any;
    };
  };
};

interface ISunburstChartProps {
  width: number;
  height: number;
  data: SunburstChartConfiguration;
}
const SunburstChart = (props: ISunburstChartProps) => {
  const [option, setOption] = useState<EChartsOption>({
    tooltip: {},
    series: {
      type: 'sunburst',
      emphasis: {
          focus: 'ancestor'
      },
      data: [],
      radius: [0, '125%'],
      label: {
        rotate: 'radial'
      }
    },
  });

  useEffect(() => {
    if (props.data) {
      setOption((o) => {
        let updatedOption = { ...o };
        updatedOption.tooltip = {
          formatter: '{b} : {c} ' + props.data.config.value_formatter,
        };
        updatedOption.series = [
          {
            type: 'sunburst',
            emphasis: {
              focus: 'ancestor'
            },
            radius: [0, '90%'],
            label: {
              rotate: 'radial'
            },
            data: props.data.data.series,
          }  
        ];
        return updatedOption;
      });
    }
  }, [props.data]);

  const handleClickEvent = (e) => {
    // TODO
  };

  return (
    <BaseChart
      height={props.height}
      width={props.width}
      options={option}
      chartType="sunburst"
      onClick={handleClickEvent}
    />
  );
};

export default SunburstChart;
