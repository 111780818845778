import { ReactElement, useContext, useMemo } from "react";
import styled from "styled-components";
import { SubRDataContext } from "../dataProvider";
import { useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import ContentLoader from "react-content-loader";
import { format } from "date-fns";

export default function History() {
  const data = useContext(SubRDataContext);
  const theme = useSelector((state: RootState) => state.theme.mode);

  return (
    <Container>
      <span className="history-header">Order Status History</span>
      {data.isOrderSummaryDataLoading && !data.orderHistory ? (
        <div className="loader-container">
          <ContentLoader
            height={150}
            speed={3}
            backgroundColor={theme === "light" ? "#f3f3f3" : "#2a2a2a"}
            foregroundColor={theme === "light" ? "#ecebeb" : "#1e1e1e"}
            style={{ width: "100%" }}
          >
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
          </ContentLoader>
        </div>
      ) : (
        <>
          {data.orderHistory && data.orderHistory.length ? (
            <div className="order-details">
              <div className="details-header">
                <span>Status</span>
                <span>Staff</span>
                <span>Time</span>
              </div>
              <div className="details-container">
                {data.orderHistory
                  .sort((a, b) => a.created_at - b.created_at)
                  .map((i, index) => {
                    return (
                      <div className="details-body" key={index}>
                        <span>{i.status}</span>
                        <span>{i.staff_name}</span>
                        <span>
                          {format(
                            new Date(i.created_at * 1000),
                            "dd-LLL-yyyy pp"
                          )}
                        </span>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            <div className="no-data">No data available</div>
          )}
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  padding: 0.5rem;
  width: 100%;
  background: ${({ theme }) => theme.widget.background};
  color: ${({ theme }) => theme.text};
  border: 1px solid rgb(255, 255, 255);
  border-radius: 0.5rem;
  filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 4px 4px);
  .history-header {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0.5rem;
    width: 100%;
    text-align: left;
    margin-left: 0.5rem;
  }
  .order-details {
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    overflow: hidden;
    .details-header,
    .details-body {
      display: flex;
      padding: 0.25rem 0.5rem;
      text-align: left;
      span {
        width: 25%;
        &:last-child {
          width: 50%;
        }
      }
    }
    .details-header {
      font-size: 11px;
      opacity: 0.5;
      background-color: ${({ theme }) => theme.body};
    }
    .details-body {
      font-size: 13px;
    }
    .details-container {
      display: flex;
      flex-direction: column;
      overflow: scroll;
      height: 115px;
      border-top: 1px solid #e6dfdf;
    }
  }
  .no-data {
    font-size: 16px;
    opacity: 0.5;
    height: 125px;
    margin-top: 0.5rem;
    border: 1px solid #e6dfdf;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
  }
`;
