import styled, { keyframes } from "styled-components";

interface IActiveFilterSectionProps {
  columnFilters: any[];
  setAppliedColumnFilters: (filters: any) => void;
  removeColumnFilter: ({
    filterCategory: string,
    widgetFilterData: any,
  }) => void;
  appliedFtsFilters: any[];
  removeFtsFilter: (data: any) => void;
  resetFilters: () => void;
}

const ActiveFiltersSection = (props: IActiveFilterSectionProps) => {

  const handleResetFilters = () => {
    props.resetFilters()
  }

  return (
    <Container>
      <div className="active-column-filters">
        <div className="data-filter-section">
          <div className="header-section">
            <p className="header">Filtering data by:</p>
            <button className="reset-button" onClick={handleResetFilters}>
              Reset
            </button>
          </div>
          <div className="widget-filter-container">
            {props.columnFilters.map((category, idx) => (
              <div className="widget-filter" key={idx}>
                <p className="widget-filter_name">{category.name}:</p>
                <div className="filter-list">
                  {category.options.map((data, idx) => (
                    <div className="filter-option" key={idx}>
                      <p className="option-name">{data.label}</p>
                      <div
                        className="close-button"
                        onClick={() =>
                          props.removeColumnFilter({
                            filterCategory: category,
                            widgetFilterData: data,
                          })
                        }>
                        &#215;
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            {props.appliedFtsFilters.map((category, idx) => (
              <div className="widget-filter" key={idx}>
                <p className="widget-filter_name">{category.key}</p>
                <div className="filter-list">
                  <div className="filter-option">
                    <p className="option-name">{category.query}</p>
                    <div
                      className="close-button"
                      onClick={() => props.removeFtsFilter(category)}>
                      {" "}
                      &#215;
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ActiveFiltersSection;

const fadeInTop = keyframes`
  0% {
    -webkit-transform: translateZ(-80px);
            transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
`;

const Container = styled.div`
  animation: ${fadeInTop} 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  margin-bottom: 2rem;
  span {
    font-size: 18px;
  }
  p {
    margin: 0;
  }
  .data-filter-section {
    background-color: #ffffff;
    border-radius: 20px;
    overflow-x: scroll;
    .header-section {
      display: flex;
      justify-content: space-between;
      padding: 22px 16px 10px;
      position: sticky;
      top: 0;
      left: 0;
      .header {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        color: #000000;
      }
      .reset-button {
        background-color: #a2d198;
        color: #fff;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        border: none;
        border-radius: 20px;
        padding: 10px 15px;
      }
    }
    .widget-filter-container {
      display: flex;
      border-top: 1px solid #f7f7f7;
      margin: 0 16px 22px;
      width: max-content;
      min-width: -webkit-fill-available;
      .widget-filter {
        padding: 10px 15px;
        :not(:last-child) {
          border-right: 1px solid #f7f7f7;
        }
        .widget-filter_name {
          font-weight: 500;
          font-size: 11px;
          line-height: 15px;
          color: #000000;
        }
        .filter-list {
          .filter-option {
            display: flex;
            background-color: #464646;
            border-radius: 20px;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #ffffff;
            margin: 6px 0;
            overflow: hidden;
            .option-name {
              padding: 0.5rem 1.2rem;
              flex: 1;
            }
            .close-button {
              background: #333333;
              display: flex;
              justify-content: center;
              align-items: baseline;
              padding: 4px 7px;
              color: #bab4b4;
              font-size: 25px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
`;
