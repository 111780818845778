import styled, { keyframes } from "styled-components";

const StreamIcon = () => {
  return (
    <Wrapper
      xmlns="http://www.w3.org/2000/Wrapper"
      version="1.1"
      viewBox="0 0 144.3 144.3">
      <g>
        <g id="Layer_1">
          <path
            id="wh-tower"
            d="M88,46.6c.4,3.6-.5,7.2-2.6,10.2-.9.9-1.8,1.7-2.8,2.4l35.9,80.6-9.6,4.2-8-18h-57.7l-8,18-9.6-4.2L61.4,59.2c-2.2-2.2-3.6-5-4.2-8-.6-3-.3-6.1.8-9,1.2-2.9,3.2-5.3,5.8-7.1,2.2-1.5,4.8-2.4,7.5-2.6,2.7-.2,5.4.3,7.8,1.5,2.4,1.2,4.5,2.9,6.1,5.1s2.5,4.8,2.8,7.5ZM71.4,43c-1,.2-1.9.7-2.6,1.5-.6.7-1,1.4-1.1,2.3-.3,1-.3,2,0,3,.4.9,1.1,1.8,1.9,2.4.9.5,1.9.8,2.9.8,1.4,0,2.7-.6,3.7-1.6,1-1,1.5-2.3,1.6-3.7,0-1-.3-2-.8-2.9-.6-.8-1.5-1.5-2.4-1.9-1-.3-2-.3-3,0ZM73.8,64.4h-2.8l-9.1,20.2h20.8l-9-20.2ZM96.7,115.9l-9.3-20.8h-30l-9.3,20.8h48.6Z"
            style={{ fill: "#7abf7d", fillRule: "evenodd" }}
          />
          <path
            id="wh-right-one"
            data-name="right-one"
            d="M105.9,81.6l-7.4-7.4c3.4-3.3,6.2-7.3,7.9-11.8,1.8-4.4,2.8-9.1,2.8-13.9s-1-9.5-2.8-13.9c-1.8-4.4-4.5-8.5-7.9-11.8l7.4-7.3c4.4,4.3,7.8,9.5,10.2,15.1,2.4,5.7,3.6,11.8,3.6,17.9s-1.2,12.2-3.6,17.9c-2.4,5.7-5.8,10.8-10.2,15.1Z"
            style={{ fill: "#7abf7d", fillRule: "evenodd" }}
          />
          <path
            id="wh-right-two"
            d="M120,.4l-7.4,7.4c5.4,5.3,9.6,11.5,12.5,18.5,2.9,6.9,4.4,14.4,4.4,21.9s-1.5,14.9-4.4,21.9c-2.9,6.9-7.2,13.2-12.5,18.5l7.4,7.4c6.3-6.3,11.3-13.7,14.7-21.9s5.2-17,5.2-25.9-1.8-17.7-5.2-25.9c-3.4-8.2-8.4-15.6-14.7-21.9Z"
            style={{ fill: "#7abf7d", fillRule: "evenodd" }}
          />
          <path
            id="wh-left-one"
            d="M26.1,48.1c0,6.1,1.2,12.2,3.5,17.9,2.4,5.7,5.8,10.8,10.2,15.1l7.4-7.4c-3.4-3.3-6.1-7.3-7.9-11.7-1.8-4.4-2.8-9.1-2.8-13.9s1-9.5,2.8-13.9c1.8-4.4,4.5-8.5,7.9-11.8l-7.4-7.4c-4.4,4.3-7.8,9.5-10.2,15.1-2.4,5.7-3.6,11.8-3.6,17.9Z"
            style={{ fill: "#7abf7d", fillRule: "evenodd" }}
          />
          <path
            id="wh-left-two"
            d="M15.1,48.1c.1-15.2,6.2-29.7,16.9-40.4L24.6.4c-6.3,6.3-11.3,13.7-14.7,21.9-3.4,8.2-5.2,17-5.2,25.9s1.8,17.7,5.2,25.9c3.4,8.2,8.4,15.6,14.7,21.9l7.4-7.3c-10.7-10.7-16.8-25.3-16.9-40.4Z"
            style={{ fill: "#7abf7d", fillRule: "evenodd" }}
          />
        </g>
      </g>
    </Wrapper>
  );
};

export default StreamIcon;

const fadeInOut = keyframes`
  0%{
      opacity: 0;
    }
    50%{
      opacity: 1;
    }
    100%{
      opacity: 0;
    }
`;

const Wrapper = styled.svg`
  height: 37px;
  width: 35px;
  #wh-right-one,
  #wh-left-one {
    opacity: 0;
    animation-name: ${fadeInOut};
    animation-duration: 2.1s;
    animation-iteration-count: infinite;
  }

  #wh-right-two,
  #wh-left-two {
    opacity: 0;
    animation-name: ${fadeInOut};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 0.6s;
  }
`;
