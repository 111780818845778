import styled from "styled-components";
import BrokenLink from "../../images/icons/brokenLink.svg";
import { useWidgetContext } from "../../helpers/hooks/useWidgetContext";

const WidgetLoadError = () => {
  const { refreshData } = useWidgetContext();
  return (
    <Wrapper>
      <div className="icon">
        <img src={BrokenLink} width="61px" height="59.75px" />
      </div>
      <div className="error-title">Widget Error - Unable to fetch Data</div>
      <p className="error-retry-cta" onClick={refreshData}>
        Reload Widget
      </p>
    </Wrapper>
  );
};

export default WidgetLoadError;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  .icon {
  }
  .error-title {
    font-size: 18px;
    text-align: center;
    padding: 20px;
    font-weight: bold;
  }
  .error-retry-cta {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #2b7bc5;
    cursor: pointer;
  }
`;
