import { useEffect, useState } from "react";

interface TickerParms {
  /** Threshold value in seconds */
  thresholdValue: number;
  /** Callback to execute when timer reaches threshold value */
  onThresholdTrigger: () => void;
}
/** Custom hook for creating a timer to trigger token refresh */
const useTicker = ({ thresholdValue, onThresholdTrigger }: TickerParms) => {
  const [timerStatus, setTimerStatus] = useState(false);
  const [expiry, setExpiry] = useState<number | null>(null);

  const handleSetTimerStatus = (value: boolean) => {
    setTimerStatus(value);
  };

  const handleSetTokenExpiry = (value: number) => {
    setExpiry(value);
  };

  useEffect(() => {
    let timer: any = undefined;
    if (timerStatus && expiry) {
      timer = setInterval(() => {
        let currentTimeInSecs = new Date().getTime() / 1000;
        let remainingTime = expiry - currentTimeInSecs;
        if (remainingTime <= thresholdValue) {
          onThresholdTrigger();
          setTimerStatus(false);
        }
      }, 1000);
    }
    if (timer) {
      return () => clearInterval(timer);
    }
    return () => {};
  }, [timerStatus]);

  return [timerStatus, handleSetTimerStatus, handleSetTokenExpiry];
};

export { useTicker };
