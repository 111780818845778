import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import { SiBookstack } from "react-icons/si"
import { TiTick } from "react-icons/ti";
import { BsCheckCircleFill } from "react-icons/bs";

interface IComparisonAdditionalFiltersProps {
  filterList: any;
  context: any
}

const ComparisonAdditionalFilters = (props: IComparisonAdditionalFiltersProps) => {

  const handleSelect = (option: string) => {
    if(props.context) {
      props.context.applyDimensionFilter(option);
    }
  }
  
  return (
    <Wrapper>
      <Dropdown onSelect={handleSelect}>
        <Dropdown.Toggle>
          <span className="icon">
            <SiBookstack size={"24px"} />
            {props.context.appliedDimension ? (
              <BsCheckCircleFill className="selected-indicator" />
            ) : null}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Header>Compare Categories</Dropdown.Header>
          {props.context
            ? props.filterList[props.context.comparisonMode].map((c) => {
                return (
                  <Dropdown.Item eventKey={c.dimension_key}>
                    <span
                      className={`item ${
                        c.dimension_key === props.context.appliedDimension
                          ? "selected"
                          : ""
                      }`}>
                      {c.dimension_label}
                    </span>
                    {c.dimension_key === props.context.appliedDimension ? (
                      <TiTick className="tick" />
                    ) : null}
                  </Dropdown.Item>
                );
              })
            : null}
        </Dropdown.Menu>
      </Dropdown>
    </Wrapper>
  );
}

export default ComparisonAdditionalFilters;

const Wrapper = styled.div`
  margin: 0px 0 0 12px;
  .dropdown {
    .dropdown-toggle::after {
      content: none;
    }
    .dropdown-toggle {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      > img {
        position: relative;
        width: 25px;
      }
      > p {
        margin-bottom: 0px;
        margin-left: 10px;
        margin-right: 1rem;
      }
      .icon {
        .selected-indicator {
          position: absolute;
          color: #c62828;
          border: 1px solid #ffffff;
          top: -8px;
          right: -5px;
        }
      }
      &.btn-primary {
        background-color: #c62828;
        border-color: #c62828;
        color: #ffffff;
        border-radius: 4px;
        height: 44px;
        &:focus {
          box-shadow: none;
        }
      }
    }
    .dropdown-menu {
      border-radius: 25px;
      padding: 11px;
      .dropdown-header {
        font-weight: 800;
        color: ${({ theme }) => theme.text};
        font-size: 18px;
      }
      .dropdown-item {
        color: ${({ theme }) => theme.text};
        &:hover {
          background-color: ${({ theme }) => theme.bs_title};
        }
        padding: 11px;
        border-bottom: 1px solid #f2f2f2;
        .tick {
          float: right;
        }
      }
    }
  }
`;
