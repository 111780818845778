import { useEffect, useRef, useState } from "react";

const DashboardLockedMarketingContent = ({ url }) => {
  const [contentDims, setContentDims] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 80,
  });

  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      setContentDims({
        height: containerRef?.current?.clientHeight,
        width: containerRef?.current?.clientWidth,
      });
    }
  }, []);

  return (
    <div style={{ height: "100%", width: "100%" }} ref={containerRef}>
      <iframe src={url} width={contentDims.width} height={contentDims.height} />
    </div>
  );
};

export default DashboardLockedMarketingContent;
