import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import calendarAltIcon from "../../images/icons/calendar-alt.svg";
import arrowRight from "../../images/icons/arrow-right.svg";
import arrowLeft from "../../images/icons/arrow-left.svg";
import styled, { keyframes } from "styled-components";

const CustomDatePicker = ({
  date,
  setDate,
  disableDatesFrom,
}: {
  date: Date;
  setDate: any;
  disableDatesFrom?: Date;
}) => {
  const [month, setMonth] = useState(new Date().getMonth());
  const [day, setDay] = useState(new Date().getDate());
  const [year, setYear] = useState(new Date().getFullYear());
  const [showMonthsView, setShowMonthsView] = useState(false);
  const [showYearsView, setShowYearsView] = useState(false);

  const datePickerRef = useRef(null);
  const dropdownRef: React.RefObject<HTMLButtonElement> = useRef(null);

  useEffect(() => {
    if (date) {
      setDay(new Date(date).getDate());
      setMonth(new Date(date).getMonth());
      setYear(new Date(date).getFullYear());
    }
  }, [date]);

  const onDateSelect = (date) => {
    setDay(new Date(date).getDate());
    setMonth(new Date(date).getMonth());
    setYear(new Date(date).getFullYear());
    setDate(new Date(date));
  };

  const onMonthSelect = (date) => {
    setMonth(new Date(date).getMonth());
    setYear(new Date(date).getFullYear());
    setShowMonthsView(false);
  };

  const onYearSelect = (date) => {
    setYear(new Date(date).getFullYear());
    setShowYearsView(false);
  };

  const handleDateSelection = (date) => {
    setDate(new Date(date));
    if (dropdownRef.current) {
      dropdownRef.current.click();
    }
  };

  const handleShowMonthPicker = () => {
    setShowMonthsView(true);
  };

  const handleShowYearPicker = () => {
    setShowMonthsView(false);
    setShowYearsView(true);
  };

  return (
    <Wrapper>
      <Dropdown>
        <Dropdown.Toggle className="date-select-btn" ref={dropdownRef}>
          {format(new Date(year, month, day), "dd MMMM yyyy")}
          <img src={calendarAltIcon} alt="calendar-icon" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="date-select-menu">
          {!showMonthsView && !showYearsView && (
            <ReactDatePicker
              className="date-default-view"
              selected={new Date(year, month, day)}
              inline
              isClearable
              onChange={onDateSelect}
              ref={datePickerRef}
              onSelect={handleDateSelection}
              maxDate={disableDatesFrom}
              renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                <>
                  <span
                    className="cal-header nav nav-left"
                    onClick={() => decreaseMonth()}
                  >
                    <img src={arrowLeft} alt="left-arrow" />
                  </span>
                  <span
                    className="cal-header title"
                    onClick={handleShowMonthPicker}
                  >
                    {format(date, "MMMM yyyy")}
                  </span>
                  <span
                    className="cal-header nav nav-right"
                    onClick={() => increaseMonth()}
                  >
                    <img src={arrowRight} alt="right-arrow" />
                  </span>
                </>
              )}
            />
          )}
          {showMonthsView && (
            <ReactDatePicker
              inline
              showMonthYearPicker
              selected={new Date(year, month, day)}
              onChange={onMonthSelect}
              renderCustomHeader={({ date, decreaseYear, increaseYear }) => (
                <>
                  <span
                    className="cal-header nav nav-left"
                    onClick={() => decreaseYear()}
                  >
                    <img src={arrowLeft} alt="left-arrow" />
                  </span>
                  <span
                    className="cal-header title"
                    onClick={handleShowYearPicker}
                  >
                    {format(date, "yyyy")}
                  </span>
                  <span
                    className="cal-header nav nav-right"
                    onClick={() => increaseYear()}
                  >
                    <img src={arrowRight} alt="right-arrow" />
                  </span>
                </>
              )}
            />
          )}
          {showYearsView && (
            <ReactDatePicker
              inline
              selected={new Date(year, month, day)}
              showYearPicker
              onChange={onYearSelect}
            />
          )}
        </Dropdown.Menu>
      </Dropdown>
    </Wrapper>
  );
};

export default CustomDatePicker;

const fadeInTop = keyframes`
  0% {
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-50);
            transform: translateY(-50);
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  .date-select-btn {
    width: 100%;
    height: 43px;
    border: 1px solid #000;
    outline: none;
    background-color: white;
    color: #000;
    text-align: left;
    > img {
      float: right;
    }
    &.btn-primary {
      color: #000;
      background-color: white;
      border: 1px solid #000;
    }
  }
  .date-select-menu {
    padding: 0;
    animation: ${fadeInTop} 0.2s;
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border: 1px solid #dedede;
    > div {
      > .react-datepicker {
        border-radius: 10px;
        width: 269px;
        background-color: ${({ theme }) => theme.widget.background};
        > .react-datepicker__month-container {
          /* width: 266px; */
          background-color: ${({ theme }) => theme.widget.background};
          float: none;
          border-radius: 10px;
          .react-datepicker__header--custom {
            border-bottom: 0;
            background-color: ${({ theme }) => theme.widget.background};
            color: ${({ theme }) => theme.text};
            padding-left: 20px;
            padding-right: 20px;
            border-radius: 10px;
            > .nav {
              display: inline;
              cursor: pointer;
            }
            & .nav-left {
              float: left;
            }
            & .nav-right {
              float: right;
            }
            > .cal-header.title {
              cursor: pointer;
              font-size: 20px;
              font-weight: 700;
              line-height: 26px;
            }
          }
          .react-datepicker__day-name {
            color: ${({ theme }) => theme.text};
          }
          .react-datepicker__day {
            &.react-datepicker__day--keyboard-selected {
              background: none;
            }
            color: ${({ theme }) => theme.text};
            border-radius: 50px;
            &.react-datepicker__day--selected {
              background-color: rgb(13, 138, 106) !important;
              color: #fff;
            }
            &:hover {
              background-color: ${({ theme }) => theme.background};
            }
          }
          .react-datepicker__monthPicker {
            height: 160px;
            display: grid;
            margin: 0;
            margin-bottom: 0.4rem;
            .react-datepicker__header--custom {
              background-color: ${({ theme }) => theme.widget.background};
            }
            width: 100%;
            .react-datepicker__month-text {
              width: 75px;
              height: 33px;
              padding: 7px;
              margin: 2px;
              color: ${({ theme }) => theme.text};
              background-color: ${({ theme }) => theme.body};
              border-radius: 10px;
              &.react-datepicker__month-text--keyboard-selected {
                background-color: rgb(13, 138, 106);
                color: #fff;
              }
            }
          }
        }
        .react-datepicker__navigation {
          font-size: 18px;
          .react-datepicker__navigation-icon {
            &::before {
              border-color: ${({ theme }) => theme.text};
              border-style: solid;
              border-width: 3px 3px 0 0;
              border-radius: 2px;
              content: "";
              display: block;
              height: 13px;
              position: absolute;
              top: 13px;
              width: 13px;
            }
          }
        }
        .react-datepicker__year--container {
          border-radius: 10px;
          .react-datepicker__header {
            font-size: 20px;
            font-weight: 700;
            line-height: 26px;
            color: ${({ theme }) => theme.text};
            background-color: ${({ theme }) => theme.widget.background};
            border-bottom: none;
            border-radius: 10px;
          }
          .react-datepicker__year {
            .react-datepicker__year-wrapper {
              width: 100%;
              max-width: 100%;
              justify-content: space-evenly;
              .react-datepicker__year-text {
                padding: 5px;
                height: 33px;
                color: ${({ theme }) => theme.text};
                background-color: ${({ theme }) => theme.body};
                border: ${({ theme }) => theme.widget.background};
                border-radius: 10px;
                &.react-datepicker__year-text--selected {
                  background-color: rgb(13, 138, 106);
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
`;
