import { createContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { formatDateWithTimezoneOffset } from "../../../containers/layout/report/utils";
import { useFetchSubRComponentData } from "../../../helpers/hooks/useFetchSubRComponentData";
import { createDecimalNumberFormatter } from "../../../helpers/utils";
import { parseNumber } from "./utils";
import { format } from "date-fns";

export const SubRDataContext = createContext(null);

export const SubRDataProvider = ({ children, selectedOrder }) => {
  const routeMatch = useParams();
  const formatDecimal = createDecimalNumberFormatter(2);

  const {
    order_date,
    order_no,
    order_id,
    offset,
    order_type,
    location_id,
    staff,
    order_status,
  } = selectedOrder.row.original;
  const is_tax_inclusive =
    selectedOrder?.row?.original?.is_tax_inclusive ?? false;
  const date = formatDateWithTimezoneOffset(order_date, offset, "yyyy-MM-dd");

  const apiEndpoint = useMemo(
    () => ({
      orderItems: `/api/v1/dar/${routeMatch.dashboard}/${routeMatch.widget}/sub-r/${order_id}/order-items?order_date=${order_date}&location=${location_id}`,
      orderPayments: `/api/v1/dar/${routeMatch.dashboard}/${routeMatch.widget}/sub-r/${order_id}/order-payments?order_date=${order_date}&location=${location_id}`,
      orderDiscounts: `/api/v1/dar/${routeMatch.dashboard}/${routeMatch.widget}/sub-r/${order_id}/order-discounts?order_date=${order_date}&location=${location_id}`,
      orderSurcharges: `/api/v1/dar/${routeMatch.dashboard}/${routeMatch.widget}/sub-r/${order_id}/order-surcharges?order_date=${order_date}&location=${location_id}`,
      orderTimeline: `/api/v1/dar/${routeMatch.dashboard}/${routeMatch.widget}/sub-r/${order_id}/order-timeline?order_date=${order_date}&location=${location_id}`,
      orderHistory: `/api/v1/dar/${routeMatch.dashboard}/${routeMatch.widget}/sub-r/${order_id}/order-history?order_date=${order_date}&location=${location_id}`,
      modification: `/api/v1/dar/${routeMatch.dashboard}/${routeMatch.widget}/sub-r/${order_id}/modifications?order_date=${order_date}&location=${location_id}`,
    }),
    [routeMatch, order_id, date, location_id]
  );

  const {
    data: itemsData,
    error: itemsDataError,
    isValidating: isItemsDataLoading,
    mutate: reloadItemsData,
  } = useFetchSubRComponentData(apiEndpoint.orderItems);

  const {
    data: paymentsData,
    error: paymentsDataError,
    isValidating: isPaymentsDataLoading,
    mutate: reloadPaymentsData,
  } = useFetchSubRComponentData(apiEndpoint.orderPayments);

  const {
    data: discountsData,
    error: discountDataError,
    isValidating: isDiscountsDataLoading,
    mutate: reloadDiscountData,
  } = useFetchSubRComponentData(apiEndpoint.orderDiscounts);

  const {
    data: surchargeData,
    error: surchargeError,
    isValidating: isSurchargeDataLoading,
    mutate: reloadSurchargeData,
  } = useFetchSubRComponentData(apiEndpoint.orderSurcharges);

  const {
    data: timelineData,
    error: timelineDataError,
    isValidating: isTimelineDataLoading,
    mutate: reloadTimelineData,
  } = useFetchSubRComponentData(apiEndpoint.orderTimeline);

  const {
    data: orderHistory,
    error: orderHistoryDataError,
    isValidating: isOrderHistoryDataLoading,
    mutate: reloadOrderHistoryData,
  } = useFetchSubRComponentData(apiEndpoint.orderHistory);

  const {
    data: modification,
    error: modificationDataError,
    isValidating: isModificationDataLoading,
    mutate: reloadModificationData,
  } = useFetchSubRComponentData(apiEndpoint.modification);

  const isOrderSummaryDataLoading = useMemo(() => {
    let isOrderItemsDataLoading = !itemsData && isItemsDataLoading;
    let isDiscountsLoading = !discountsData && isDiscountsDataLoading;
    let isSurchargeLoading = !surchargeData && isSurchargeDataLoading;
    let isPaymentsLoading = !paymentsData && isPaymentsDataLoading;
    let isOrderHistoryLoading = !orderHistory && isOrderHistoryDataLoading;
    let isModificationLoading = !modification && isModificationDataLoading;
    let isTimelineLoading = !timelineData && isTimelineDataLoading;
    return (
      isOrderItemsDataLoading ||
      isDiscountsLoading ||
      isSurchargeLoading ||
      isPaymentsLoading ||
      isOrderHistoryLoading ||
      isModificationLoading ||
      isTimelineLoading
    );
  }, [
    itemsData,
    isItemsDataLoading,
    discountsData,
    isDiscountsDataLoading,
    surchargeData,
    isSurchargeDataLoading,
    paymentsData,
    isPaymentsDataLoading,
    orderHistory,
    isOrderHistoryDataLoading,
    modification,
    isModificationDataLoading,
    timelineData,
    isTimelineDataLoading,
  ]);

  const isOrderSummaryDataLoadError = useMemo(() => {
    return (
      itemsDataError ||
      discountDataError ||
      surchargeError ||
      paymentsDataError ||
      orderHistoryDataError ||
      modificationDataError ||
      timelineDataError
    );
  }, [
    itemsDataError,
    discountDataError,
    surchargeError,
    paymentsDataError,
    orderHistoryDataError,
    modificationDataError,
    timelineDataError,
  ]);

  const orderAmountBreakdowns = useMemo(() => {
    let subTotal = 0;
    let discountTotal = 0;
    let surchargeTotal = 0;
    let totalAmount = 0;
    let taxTotal = 0;
    if (itemsData) {
      subTotal = formatDecimal(
        itemsData.reduce((total, d) => {
          return total + parseNumber(d.item_amount);
        }, 0)
      );
      if (is_tax_inclusive) {
        taxTotal = 0;
      } else {
        taxTotal = formatDecimal(
          itemsData.reduce((total, d) => {
            return total + parseNumber(d.item_tax);
          }, 0) + parseNumber(itemsData[0]?.surcharge_tax || 0)
        );
      }
    }
    if (discountsData) {
      discountTotal = formatDecimal(
        discountsData.reduce((total, d) => {
          return total + parseNumber(d.total_discounts);
        }, 0)
      );
    }
    if (surchargeData) {
      surchargeTotal = formatDecimal(
        surchargeData.reduce((total, d) => {
          return total + parseNumber(d.surcharge_amount);
        }, 0)
      );
    }
    if (paymentsData) {
      totalAmount = formatDecimal(
        parseNumber(subTotal) +
          parseNumber(taxTotal) -
          parseNumber(discountTotal) +
          parseNumber(surchargeTotal)
      );
    }
    return { subTotal, discountTotal, surchargeTotal, taxTotal, totalAmount };
  }, [
    itemsData,
    isItemsDataLoading,
    discountsData,
    isDiscountsDataLoading,
    paymentsData,
    isPaymentsDataLoading,
    surchargeData,
    isSurchargeDataLoading,
  ]);

  const popupData = useMemo(() => {
    type PopupData = { name: string; value: number }[];
    let discountsDetails: PopupData = [];
    let surchargeDetails: PopupData = [];
    if (discountsData) {
      discountsDetails = discountsData.map((d) => ({
        name: d.discount,
        value: d.total_discounts,
      }));
    }
    if (surchargeData) {
      surchargeDetails = surchargeData.map((d) => ({
        name: d.surcharge,
        value: d.surcharge_amount,
      }));
    }
    return { discountsDetails, surchargeDetails };
  }, [discountsData, surchargeData]);

  const reloadData = () => {
    reloadItemsData();
    reloadPaymentsData();
    reloadDiscountData();
    reloadSurchargeData();
    reloadOrderHistoryData();
    reloadModificationData();
    reloadTimelineData();
  };

  const { customer, customer_contact, table_no, cover } = useMemo(() => {
    let customer = null;
    let customer_contact = null;
    let table_no = null;
    let cover = null;
    if (itemsData) {
      customer = itemsData[0].customer_name || null;
      customer_contact = itemsData[0].customer_phone || null;
      table_no = itemsData[0].table_no || null;
      cover = itemsData[0].covers || null;
    }
    return { customer, customer_contact, table_no, cover };
  }, [itemsData]);

  const providerData: any = useMemo(() => {
    return {
      itemsData,
      paymentsData,
      discountsData,
      surchargeData,
      timelineData,
      orderAmountBreakdowns,
      popupData,
      isOrderSummaryDataLoading,
      isOrderSummaryDataLoadError,
      reloadData,
      orderHistory,
      modification,
      order_no,
      order_type,
      order_status,
      staff,
      date: format(new Date(order_date * 1000), "dd-LLL-yyyy pp"),
      table_no,
      cover,
      customer,
      customer_contact,
    };
  }, [
    paymentsData,
    isPaymentsDataLoading,
    discountsData,
    itemsData,
    surchargeData,
    timelineData,
    orderAmountBreakdowns,
    popupData,
    isOrderSummaryDataLoading,
    isOrderSummaryDataLoadError,
    orderHistory,
    modification,
    order_no,
    order_type,
    staff,
    order_date,
  ]);

  return (
    <SubRDataContext.Provider value={providerData}>
      {children}
    </SubRDataContext.Provider>
  );
};
