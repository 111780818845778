export const enum FILTER_LIST {
  ADD_WIDGET = "filters/ADD_WIDGET_TO_FILTER_LIST",
  CLEAR_FILTERS = "filters/CLEAR_FILTER_LIST",
}
export const enum FILTER_FOCUS {
  SECONDARY = "analytics/SET_SECONDARY_FILTER_FOCUS",
  LOCATION = "analytics/SET_LOCATION_FILTER_FOCUS",
}
export const enum CLEAR_FILTER_FOCUS {
  SECONDARY = "analytics/CLEAR_SECONDARY_FILTER_FOCUS",
  LOCATION = "analytics/CLEAR_LOCATION_FILTER_FOCUS",
}
