import styled from "styled-components";
import LocationFilterDropdown from "../../../../components/locationFilterDropdown";
import { useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import { BsPrinterFill } from "react-icons/bs";
import { useBSDashboardContext } from "../../hooks/useBSDashboardContext";
import DateRangePicker from "../../../../components/dateRangePicker";

const BSContentHeader = () => {
  const companyLocations = useSelector((state: RootState) => state.locations);
  const { disablePrint, handlePrint, selectedDateTimeString } = useBSDashboardContext();
  return (
    <Wrapper>
      <div className="row">
        <div className="col">
          <h1>Business Summary</h1>
        </div>
        <div className="col d-flex justify-content-end align-items-center">
          {companyLocations.length > 0 && <LocationFilterDropdown />}
          <DateRangePicker />
          <button
            title={
              disablePrint
                ? "Please wait until all widgets are loaded to print this document"
                : "Print Report"
            }
            className="btn btn-primary bs-print-btn"
            disabled={disablePrint}
            onClick={handlePrint}>
            <BsPrinterFill fontSize={"20px"} />
          </button>
        </div>
      </div>
      <div className="period">
        <span className="date-period">
          <strong>Period:{" "}</strong>
          {selectedDateTimeString}
        </span>
      </div>
    </Wrapper>
  );
};
export { BSContentHeader };

const Wrapper = styled.div`
  padding-left: 50px;
  top: 70px;
  margin: 5px;
  background-color: ${({ theme }) => theme.body};
  transition: all 0.25s linear 0s;

  margin: 50px 0 5px;
  width: 100%;
  padding-right: 50px;
  h1 {
    font-weight: 800;
    margin-bottom: 0;
  }
  .dashboard-link {
    cursor: pointer;
    .navigation-arrow {
      position: absolute;
      left: 30px;
    }
    h3 {
      margin-bottom: 0;
      font-weight: 500;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .bs-print-btn {
    margin-left: 10px;
    border: none;
    &:disabled {
      background-color: grey;
    }
  }
`;
