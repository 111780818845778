import {
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from "date-fns";
import { createDateParser } from "../../comparison/utils";

let trendPeriodKeys = [
  "trends-yearly",
  "trends-quarterly",
  "trends-monthly",
  "trends-weekly",
  "trends-daily",
  "trends-hourly",
];

const getTrendPeriod = (key: string) => {
  return trendPeriodKeys.find((d) => key.includes(d)) ?? "none";
};

const getSectionTabTitles = (key: string) => {
  return sectionTabTitles[getTrendPeriod(key)];
};

const sectionTabTitles = {
  "trends-yearly": "Yearly",
  "trends-quarterly": "Quarterly",
  "trends-monthly": "Monthly",
  "trends-weekly": "Weekly",
  "trends-daily": "Daily",
  "trends-hourly": "Hourly",
  none: " ",
};
const urlSortOrder = {
  "trends-yearly": 1,
  "trends-quarterly": 2,
  "trends-monthly": 3,
  "trends-weekly": 4,
  "trends-daily": 5,
  "trends-hourly": 6,
};
const filterWidgetUrlsBySelectedTimePeriod = (
  selectedTimeRange,
  childWidgets
) => {
  let parseDate = createDateParser("yyyy-MM-dd");
  let startTime = parseDate(selectedTimeRange[0]);
  let endTime = parseDate(selectedTimeRange[1]);
  let dayDiff = differenceInDays(endTime, startTime);
  let monthDiff = differenceInMonths(endTime, startTime);
  let yearDiff = differenceInYears(endTime, startTime);

  let childWidgetsWTrendPeriod = childWidgets.map((d) => ({
    ...d,
    trendPeriod: getTrendPeriod(d.url),
  }));
  childWidgetsWTrendPeriod.sort(
    (a, b) => urlSortOrder[a.trendPeriod] - urlSortOrder[b.trendPeriod]
  );

  if (yearDiff > 1) {
    return childWidgetsWTrendPeriod.filter((d) =>
      ["trends-yearly", "trends-quarterly", "trends-monthly"].includes(
        d.trendPeriod
      )
    );
  } else if (yearDiff === 1 || monthDiff > 6) {
    return childWidgetsWTrendPeriod.filter((d) =>
      ["trends-quarterly", "trends-monthly", "trends-weekly"].includes(
        d.trendPeriod
      )
    );
  } else if (monthDiff < 6 && monthDiff > 1) {
    return childWidgetsWTrendPeriod.filter((d) =>
      ["trends-monthly", "trends-weekly"].includes(d.trendPeriod)
    );
  } else if (monthDiff <= 1 && dayDiff > 15) {
    return childWidgetsWTrendPeriod.filter((d) =>
      ["trends-weekly", "trends-daily"].includes(d.trendPeriod)
    );
  } else {
    return childWidgetsWTrendPeriod.filter((d) =>
      ["trends-daily", "trends-hourly"].includes(d.trendPeriod)
    );
  }
};
export {
  sectionTabTitles,
  filterWidgetUrlsBySelectedTimePeriod,
  getSectionTabTitles,
};
