import { useEffect, useState } from "react";
import type { EChartsOption } from "echarts";
import BaseChart from "../../baseChart";

export type FunnelChart = {
  config: {
    selector: string;
    name: string;
    value_formatter: any;
  };
  data: {
    series: { value: number; name: string }[];
  };
};

interface IFunnelChartProps {
  width: number;
  height: number;
  data: any;
}

const FunnelChart = (props: IFunnelChartProps) => {
  const [option, setOption] = useState<EChartsOption>({
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c}%'
    },
    series: {}
  });

  useEffect(() => {
    if (props.data) {
      setOption((o) => {
        let updatedOption = { ...o };
        updatedOption.series = [
          {
            name: props.data.config.name,
            type: 'funnel',
            left: '10%',
            top: 60,
            bottom: 60,
            width: '80%',
            min: 0,
            max: 100,
            minSize: '0%',
            maxSize: '100%',
            sort: 'descending',
            gap: 2,
            label: {
              show: true,
              position: 'inside'
            },
            labelLine: {
              length: 10,
              lineStyle: {
                width: 1,
                type: 'solid'
              }
            },
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 1
            },
            emphasis: {
              label: {
                fontSize: 20
              }
            },
            data: props.data.data.series.map((d) => (
                { value: d.value, name: d.name }
              ))
          }
        ];
        return updatedOption;
      });
    }
  }, [props.data]);

  const handleClickEvent = (e) => {
    // TODO
  };

  return (
    <BaseChart
      height={props.height}
      width={props.width}
      options={option}
      chartType="funnel"
      onClick={handleClickEvent}
    />
  );
};

export default FunnelChart;
