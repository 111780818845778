import { createSlice } from "@reduxjs/toolkit";
import { getEnvValueFor } from "../../../helpers/utils";

const fallbackDashboard = {
  label: "Sales",
  identifier: "vantage.sales",
  api_path: "sales",
};

const initialState = {
  dashboards: [],
  defaultDashboard: null,
  shouldDisableDashboardUpdatedEvents: JSON.parse(
    getEnvValueFor("DISABLE_DASHBOARD_AUTO_PUSH") || false
  ),
  pollingInterval:
    parseInt(getEnvValueFor("DASHBOARD_POLL_INTERVAL") || 9) * 1000,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardsList(state, action) {
      state.dashboards = action.payload;
      state.defaultDashboard =
        action.payload.find((d) => d.visibility == "visible") ??
        fallbackDashboard;
    },
  },
});

export const { setDashboardsList } = dashboardSlice.actions;
export const reducer = dashboardSlice.reducer;
