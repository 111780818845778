import { numFormatter } from "../../../helpers/utils";
import styled from "styled-components";
import { useContext } from "react";
import { WidgetContext } from "../../widgetContext";
import { TooltipHelper } from "../../tooltipHelper";

interface ISmallNumberMultiStatsWidgetProps {}

const SmallNumberMultiStatsWidget = (
  props: ISmallNumberMultiStatsWidgetProps
) => {
  const widget = useContext(WidgetContext);

  const handleWidgetOnClick = () => {
    if (widget.widgetOnClick) {
      widget.widgetOnClick();
    }
  };

  return (
    <Wrapper onClick={handleWidgetOnClick}>
      <div className="widget-content">
        <span className="title">{widget.widgetData?.data?.title}</span>
        <TooltipHelper
          tooltipText={`${widget.widgetData?.data?.value}`}
          placement="bottom"
        >
          <span className="value" data-toggle="tooltip" data-placement="top">
            {numFormatter(widget.widgetData?.data?.value)}
          </span>
        </TooltipHelper>
        <span className="description">
          {widget.widgetData?.data?.dataArray.map((dataArray) => {
            return (
              <div className="categories">
                <b>{dataArray.count}</b> {dataArray.name}
              </div>
            );
          })}
        </span>
      </div>
    </Wrapper>
  );
};

export default SmallNumberMultiStatsWidget;

const Wrapper = styled.div`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.widget.background};
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.05));
  width: 100%;
  height: 100%;
  padding: 25px;
  display: flex;
  cursor: pointer;
  justify-content: center;

  .widget-content {
    display: flex;
    flex-direction: column;
    justify-content: left;
    position: relative;
    width: 100%;

    .title {
      position: relative;
      font-size: 26px;
      font-weight: 500;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: left;
      top: 15px;
      padding: 10px;
      opacity: 0.8;
    }
    .value {
      display: block;
      padding: 10px;
      line-height: 80px;
      font-size: 4rem;
      line-height: 4.5rem;
      font-weight: 800;
      letter-spacing: -2px;
      color: ${({ theme }) => theme.tooltip.text};
    }
    .description {
      display: block;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.2rem;
      margin-bottom: 10px;
      padding: 0 10px;
      color: ${({ theme }) => theme.tooltip.text};
      .categories {
        padding: 5px;
      }
    }
  }
`;
