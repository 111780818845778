import styled, { keyframes } from "styled-components";

const StatelessLoader = () => {
  return (
    <LoadingViewWrapper className="report-loader">
      <div className="report-loader--container">
        <div className="report-loader--loader loading">
          <span>xxx%</span>
        </div>
      </div>
    </LoadingViewWrapper>
  );
};

export default StatelessLoader;

const loadingAnimation = keyframes`
  0%{
    transform: translateX(-75px);
  }
  50%{
    transform: translateX(250px);
  }
  100%{
    transform: translateX(-75px);
  }
  `;

const LoadingViewWrapper = styled.figure`
  margin: 0;
  padding: 0;
  .report-loader--container {
    width: 338px;
    height: 14px;
    border: 1px solid #f3eeee;
    background-color: #fff;
    border-radius: 100px;
    position: relative;
    overflow: hidden;
    padding: 2px;
    .report-loader--loader {
      position: absolute;
      top: 1px;
      left: 0;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 12px;
      font-weight: 600;
      &.loading-start {
        background-color: #6faf90;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        color: #fff;
        width: 0%;
        transition: all 500ms cubic-bezier(0.195, 0.92, 0.355, 1);
        display: flex;
        gap: 5px;
      }
      &.loading {
        width: 150px;
        background: linear-gradient(
          to right,
          rgba(245, 245, 245, 0) 0%,
          rgba(193, 207, 214, 1) 50%,
          rgba(245, 245, 245, 0) 100%
        );
        transform: translateX(-75px);
        animation-name: ${loadingAnimation};
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        > span {
          display: none;
        }
      }
    }
  }
`;
