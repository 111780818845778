import styled from "styled-components";
import LoadingAnimation from "../../../../components/loadingAnimation";

const BSDashboardLoading = () => {
  return (
    <LoaderWrapper>
      <div className="content">
        <LoadingAnimation />
        <span>Loading Business Summary</span>
      </div>
    </LoaderWrapper>
  );
};

export default BSDashboardLoading;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
  .content {
    width: 576px;
    height: 324px;
    background-color: ${({ theme }) => theme.widget.background};
    padding: 6rem 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
      font-size: 24px;
      color: ${({ theme }) => theme.text};
      font-weight: 700;
    }
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
  }
`;
