import type { EChartsOption } from "echarts-for-react";
import { Fragment, useMemo } from "react";
import BaseChart from "../../baseChart";

const SCALE_CONFIG = [
  { value: 0.2, color: "#3dbc69", label: "FAST" },
  { value: 0.6, color: "#efd74e", label: "MEDIUM" },
  { value: 1, color: "#e63d3c", label: "SLOW" },
];

const GaugeChart = ({ data, width, height }) => {
  const option = useMemo<EChartsOption>(() => {
    const seriesConfig = {
      type: "gauge",
      radius: "95%",
      min: 0,
      max: 60,
      splitNumber: 12,
      title: {
        show: true,
      },
      detail: {
        valueAnimation: true,
        formatter: "{value}",
        fontSize: 40,
        offsetCenter: [0, "50%"],
      },
    };
    if (data) {
      return {
        tooltip: {
          show: false,
        },
        legend: {
          show: false,
          selectedMode: false,
          data: SCALE_CONFIG.map((s) => ({
            name: s.label,
            itemStyle: { color: s.color },
          })),
        },
        series: {
          ...seriesConfig,
          name: "SLA",
          axisLine: {
            show: true,
            roundCaps: true,
            // lineStyle: {
            //   width: 6,
            //   color: SCALE_CONFIG.map((s) => [s.value, s.color]),
            // },
          },
          data: [
            {
              value: data.value,
              name: "Mins",
            },
          ],
        },
      };
    }
    return {};
  }, [data]);

  return (
    <BaseChart
      height={height}
      width={width}
      chartType="gauge"
      options={option}
    />
  );
};

export default GaugeChart;
