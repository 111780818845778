import * as Sentry from "@sentry/react";

export const reportExceptionToSentry = ({
  title = "Error",
  message,
  contexts = {},
  tags = {},
  stack = null,
}) => {
  let error = new Error(message);
  error.name = title;
  if (stack) {
    error.stack = stack;
  }
  Object.entries(tags).forEach((tagEntry) => {
    Sentry.setTag(tagEntry[0], tagEntry[1]);
  });
  Sentry.captureException(error, contexts);
};

export const addToSentryContext = ({ name, contexts = {} }) => {
  Sentry.setContext(name, contexts);
};

export const setSentryTags = (tags = {}) => {
  Object.entries(tags).forEach((tagEntry) => {
    Sentry.setTag(tagEntry[0], tagEntry[1]);
  });
};
