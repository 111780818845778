import styled from "styled-components";
import LoadingAnimation from "../../../../components/loadingAnimation";
import { ProgressBar } from "react-bootstrap";
import { formatBytes } from "../../../../helpers/utils";
import type { DownloadProgress } from "../../../../types/swr";
import StatelessLoader from "./statelessLoader";

interface Props {
  loadingText?: string;
  downloadProgress?: DownloadProgress;
}

const LoadingView = ({ loadingText = "loading", downloadProgress }: Props) => {

  const loadingTextContent = () => {
    if (downloadProgress?.state === "downloading") {
      return "Downloading...";
    } else if (downloadProgress?.state === "parsing") {
      return "Processing data...";
    } else if (downloadProgress?.state === "complete") {
      return "Complete";
    } else {
      return loadingText;
    }
  };

  const showProgressBar = ["downloading", "parsing", "complete"].includes(downloadProgress?.state);
  const progressValue = parseInt((downloadProgress?.progressValue || 0).toFixed(0));

  return (
    <LoadingViewWrapper>
      <LoadingAnimation />
      <span className="subheading">{loadingTextContent()}</span>
      <br />
      {showProgressBar ? (
        <span className="subheading" style={{ textAlign: "center" }}>
          <ProgressBar now={progressValue} label={`${progressValue}%`} />
        </span>
      ):(
        <StatelessLoader />
      )}
    </LoadingViewWrapper>
  );
};

export default LoadingView;

const LoadingViewWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .subheading {
    font-size: 14px;
    color: #91a1ac;
    font-weight: 500;
  }
  .progress {
    width: 250px;
    padding: 2px;
    .progress-bar {
      font-size: 10px;
      padding-right: 5px;
      text-align: right;
      border-radius: 10px;
      background-color: #6FAF90;
    }
  }
`;
