import ReactPaginate from "react-paginate";
import styled from "styled-components";

interface IReportPaginateSection {
  totalPages: number;
  onPageChange: (selectedPage) => void;
}

const ReportPaginateSection = (props: IReportPaginateSection) => {
  return (
    <Container>
      <ReactPaginate
        pageCount={props.totalPages}
        marginPagesDisplayed={4}
        pageRangeDisplayed={3}
        breakLabel={"..."}
        onPageChange={props.onPageChange}
        containerClassName={"pagination my-5 justify-content-center"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}></ReactPaginate>
    </Container>
  );
};

export default ReportPaginateSection;

const Container = styled.div`
  .pagination {
    font-size: 16px;
    > .page-item,
    .break {
      &.active {
        .page-link {
          background: ${({ theme }) => theme.body};
        }
      }
      .page-link {
        background: ${({ theme }) => theme.background};
        color: ${({ theme }) => theme.text};
        border: 1px solid ${({ theme }) => theme.background};
      }
    }
  }
`;
