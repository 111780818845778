import { useState } from "react";

const useDownloadProgress = () => {
  const [downloadState, setDownloadState] = useState<string>("idle");
  const [progressValue, setProgressValue] = useState(0);
  const [loadedSize, setLoadedSize] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [error, setError] = useState<string | null>(null);

  const handleDownloadProgress = ({
    loaded,
    total,
    progress,
    state,
    error: downloadError,
  }) => {
    if (downloadError) {
      setError(downloadError);
    }
    setDownloadState(state);
    if (progress !== null) {
      setProgressValue(progress);
    }
    setLoadedSize(loaded);
    setTotalSize(total);
  };

  const downloadProgress = {
    state: downloadState,
    progressValue,
    loadedSize,
    totalSize,
    error,
  };

  return {
    downloadProgress,
    handleDownloadProgress,
  };
};

export { useDownloadProgress };
