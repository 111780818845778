import useSWR from "swr";
import offThreadQueryResponse from "../middleware/offThreadQueryResponse";

const useFetchSubRComponentData = (url) => {
  const { data, isValidating, error, mutate } = useSWR(url, {
    use: [offThreadQueryResponse],
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  });
  return {
    data: data?.sub_r_data,
    isValidating: isValidating,
    error,
    mutate,
  };
};

export { useFetchSubRComponentData };
