import { useSelector } from "react-redux";
import { ThemeProvider as SCThemeProvider } from "styled-components";
import { RootState } from "../../state/store";
import { darkTheme, lightTheme } from "../../helpers/theme";
import { GlobalStyles } from "../../helpers/globalStyles";
import { useEffect } from "react";

const ThemeProvider = ({ children }) => {
  const { theme } = useSelector((state: RootState) => state);

  useEffect(() => {
    document.documentElement.setAttribute("data-bs-theme", theme.mode);
  }, [theme.mode]);

  return (
    <SCThemeProvider theme={theme.mode === "light" ? lightTheme : darkTheme}>
      <GlobalStyles />
      {children}
    </SCThemeProvider>
  );
};

export { ThemeProvider };
