import { useElementSize } from "usehooks-ts";
import Widget from "../../baseWidget";
import { useWidgetContext } from "../../../helpers/hooks/useWidgetContext";
import { useMemo } from "react";
import GaugeChart from "./gaugeChart";
import styled from "styled-components";

const GaugeGroupWidget = () => {
  const [widgetRef, containerDims] = useElementSize();
  const widget = useWidgetContext();
  const dims = useMemo(() => {
    let length = widget.widgetData.widget_data.widgets.length;
    let hMarginOffset = 40 * length; // 10px each
    let vMarginOffset = 80; // 10px each
    let hPadding = 50 + hMarginOffset;
    let vPadding = 90 + vMarginOffset;
    let remSpace = containerDims.width - hPadding;
    let widgetDims = { width: 0, height: 0 };
    widgetDims = {
      width: remSpace / length,
      height: containerDims.height - vPadding,
    };
    return { widgetDims };
  }, [JSON.stringify(containerDims), widget]);

  return (
    <Widget ref={widgetRef} type="group">
      <Widget.Header>
        <Widget.Title>{widget.widgetData.widget_data.title}</Widget.Title>
      </Widget.Header>
      <Widget.Content
        style={{
          display: "flex",
          justifyContent: "center",
        }}>
        {widget.widgetData.widget_data.widgets.map((widgetData, i) => (
          <WidgetItem>
            <div className="title">{widgetData.widget_name}</div>
            <GaugeChart
              key={i}
              data={widgetData.widget.data}
              width={dims.widgetDims.width}
              height={dims.widgetDims.height}
            />
          </WidgetItem>
        ))}
      </Widget.Content>
    </Widget>
  );
};

export default GaugeGroupWidget;

const WidgetItem = styled.div`
  display: inline-block;
  background-color: ${({ theme }) => theme.widget.background};
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.05));
  border-radius: 20px;
  margin: 10px;
  padding: 20px;
`;
