import styled from "styled-components";
import { useWidgetContext } from "../../../helpers/hooks/useWidgetContext";
import { StockMovementsIcon } from "./stockMovementsIcon";

const SimpleIconWidget = () => {
  const widget = useWidgetContext();

  const handleWidgetOnClick = () => {
    if (widget.widgetOnClick) {
      widget.widgetOnClick();
    }
  };

  const selectIcon = (icon) => {
    switch (icon) {
      case "stock_movements":
          return <StockMovementsIcon />;
        default:
          return null
    }
  }

  return (
    <Wrapper onClick={handleWidgetOnClick}>
      <div
        className="widget-content"
        data-toggle="tooltip"
        data-placement="top">
        <div className="icon-container">
          {selectIcon(widget.widgetData?.icon)}
        </div>
        <div className="widget-title">{widget.widgetData.title}</div>
      </div>
    </Wrapper>
  );
};

export default SimpleIconWidget;

const Wrapper = styled.div`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.widget.background};
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.05));
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 25px;
  &.danger {
    background-color: #cb2e2e;
    color: #fff;
    border-radius: 20px;
    > .widget-content {
      .value {
        color: #fff !important;
      }
    }
  }
  &.outlined {
    border-radius: 20px;
    border: 10px solid #acd5ca;
    > .widget-content {
      color: #0d7459;
      .value {
        color: #0d7459 !important;
      }
    }
  }

  .tooltip-container {
    width: calc(100%);
    height: calc(100%);
    position: unset;
  }
  .icon-container {
    text-align: center;
  }
  .widget-title {
    font-size: 26px;
    line-height: 2rem;
    font-size: 26px;
    text-align: center;
    font-weight: 500;
    opacity: 0.8;
  }
  .widget-content {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    .pre-text {
      position: relative;
      top: 30px;
      display: block;
    }
    .value {
      display: block;
      text-align: center;
      font-size: 4rem;
      font-weight: 800;
      letter-spacing: -2px;
      position: relative;
      color: ${({ theme }) => theme.tooltip.text};
      &.currency:before {
        position: absolute;
        content: attr(data-currency);
        font-size: 20px;
        top: 7px;
        left: 0;
        letter-spacing: 0px;
        font-weight: 500;
        line-height: 1rem;
      }
    }
  }
`;
