import { useEffect } from "react";
import { EventEmitter } from "eventemitter3";

const emitter = new EventEmitter();

export const useSub = (event, callback) => {
  const unsubscribe = () => {
    emitter.off(event, callback);
  };

  useEffect(() => {
    emitter.on(event, callback);
    return unsubscribe;
  }, []);

  return unsubscribe;
};

export const appEventsMessaging = (
  event,
  { onSubscribe = (data) => {}, onUnsubscribe = () => {}, once = false }
) => {
  const subscribe = () => {
    if (once) {
      emitter.once(event, onSubscribe);
    } else {
      emitter.on(event, onSubscribe);
    }
  };
  const unsubscribe = () => {
    emitter.off(event, onUnsubscribe);
  };

  return Object.freeze({ subscribe, unsubscribe });
};

export const usePub = () => {
  return (event, data) => {
    emitter.emit(event, data);
  };
};
