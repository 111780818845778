import useSWR from "swr";

const useFetchCompanies = () => {
  const { data, isValidating, error, mutate } = useSWR(
    "/api/v1/companies/list",
    {
      shouldRetryOnError: false,
    }
  );

  return {
    data,
    isValidating,
    error,
    mutate,
  };
};

export { useFetchCompanies };
