import { useContext, useEffect, useState } from "react";
import { useElementSize } from "usehooks-ts";
import { WidgetContext } from "../../widgetContext";
import DemandForecastChart from "./demandForecastChart";
import Widget from "../../baseWidget";

const DemandForecastWidget = () => {
  const [widgetData, setWidgetData] = useState<any>({});
  const [chartContainerRef, chartDimensions] = useElementSize();

  const widget = useContext(WidgetContext);

  useEffect(() => {
    if (widget.widgetData.widget_data) {
      setWidgetData(widget.widgetData);
    }
  }, [widget.widgetData]);

  return (
    <Widget ref={chartContainerRef}>
      <Widget.Header>
        <Widget.Title>{widgetData?.title}</Widget.Title>
      </Widget.Header>
      <Widget.Content>
        {widgetData?.widget_data?.data?.series.length ? (
          <DemandForecastChart
            data={widgetData.widget_data}
            width={chartDimensions.width - 50}
            height={chartDimensions.height - 90}
          />
        ) : (
          <Widget.EmptyData
            width={chartDimensions.width - 50}
            height={chartDimensions.height - 90}
          />
        )}
      </Widget.Content>
    </Widget>
  );
};

export default DemandForecastWidget;
