import { useEffect, useRef, useState } from "react";
import Markdown from "react-markdown";

const RenderMarkdownContent = ({ content }) => {
  const [markdownContent, setMarkdownContent] = useState("");

  let { current: interval } = useRef<NodeJS.Timer>(null);

  const { current: latency } = useRef<number>(50); // in milliseconds
  const { current: splitChar } = useRef(" ");

  useEffect(() => {
    if (content) {
      const splitByChar = content.split(splitChar);
      let currentIndex = 0;
      const processedWords = [];
      interval = setInterval(() => {
        if (currentIndex < splitByChar.length) {
          processedWords.push(splitByChar[currentIndex]);
          setMarkdownContent(processedWords.join(splitChar));
          currentIndex++;
        }
      }, latency);
    }
    return () => {
      // Cleanup
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [content]);

  return <Markdown>{markdownContent}</Markdown>;
};

export default RenderMarkdownContent;
