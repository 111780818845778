import { MutableRefObject, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { updateTheme } from "../../state/features/theme/operations";
import { RootState } from "../../state/store";
import { ToggleSwitch } from "./style";
import { IoIosMoon, IoIosSunny} from "react-icons/io";

const ThemeToggle = () => {
  const { theme } = useSelector((state: RootState) => state);
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    if (theme.mode === "dark") {
      inputRef.current.checked = true;
    }
  }, []);

  return (
    <ToggleSwitch>
      <label className="switch">
        <input
          ref={inputRef}
          type="checkbox"
          onChange={(e) => updateTheme(e.target.checked ? "dark" : "light")}
        />
        <span className="slider round">
          <IoIosSunny fontSize="19px" className="theme-icon sun" />
          <IoIosMoon fontSize="19px" className="theme-icon moon" />
        </span>
      </label>
    </ToggleSwitch>
  );
};

export default ThemeToggle;
