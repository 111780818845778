import { useSelector } from "react-redux";
import { BrokenLinkIcon } from "./brokenLinkIcon";
import styled from "styled-components";
import { RootState } from "../../../../state/store";
import BrokenSystemIcon from "./brokenSystemIcon";
import { ExceedLimitIcon } from "./exceedLimitIcon";

const FetchErrorView = ({
  refreshData,
  errorType = "fetch",
}: {
  refreshData: () => void;
  errorType?: "fetch" | "report" | "chunkError";
}) => {
  const theme = useSelector((state: RootState) => state.theme.mode);
  const errorTitle =
    errorType === "fetch"
      ? "Oops! Unable to retrieve data at the moment."
      : errorType === "chunkError"
      ? "Date range is too broad to display data"
      : "Oops! Unable to load the report at the moment.";
  const errorSubTitle =
    errorType === "fetch"
      ? "We're working on it! Please try again later."
      : errorType === "chunkError"
      ? "Please select a shorter date range to view the report."
      : "We're working on it! Please try again later.";
  return (
    <Wrapper>
      <div className="icon">
        {errorType === "fetch" ? (
          <BrokenLinkIcon theme={theme} />
        ) : errorType === "chunkError" ? (
          <ExceedLimitIcon />
        ) : (
          <BrokenSystemIcon theme={theme} />
        )}
      </div>
      <div className="error-title">{errorTitle}</div>
      <div className="error-subtitle">{errorSubTitle}</div>
      {errorType !== "chunkError" ? (
        <p className="error-retry-cta" onClick={refreshData}>
          Refresh report
        </p>
      ) : null}
    </Wrapper>
  );
};

export default FetchErrorView;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 23px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  .error-title {
    font-size: 18px;
    text-align: center;
    padding: 20px 20px 1px 20px;
    font-weight: bold;
  }
  .error-subtitle {
    font-size: 18px;
    text-align: center;
  }
  .error-retry-cta {
    padding-top: 20px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #2b7bc5;
    cursor: pointer;
  }
`;
