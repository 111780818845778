import FileExclamation from "../../images/icons/fileExclamation.svg";
import { RiFolderInfoLine } from "react-icons/ri";

const WidgetEmptyData = ({ height, width }) => {
  return (
    <div
      style={{
        height,
        width,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}>
        <div>
          <div>
            <RiFolderInfoLine style={{ fontSize: "70px" }}  />
          </div>
          <div style={{
            padding: "20px",
          }}>
            No Data Available. Choose another date range.
          </div>
        </div>
    </div>
  );
};

export default WidgetEmptyData;

