import styled from "styled-components";

const ApplicationErrorScreen = ({ error, resetErrorBoundary }) => {
  return (
    <Wrapper>
      <div className="content">
        <h4>Application Error</h4>
        <p className="error-text">
          Something's broken. Please reload UI or refresh this page
        </p>
        <div className="retry-actions">
          <button className="btn btn-primary" onClick={resetErrorBoundary}>
            Reload
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default ApplicationErrorScreen;

const Wrapper = styled.div`
  background-color: #e5e5e5;
  width: 100vw;
  height: 100vh;
  .content {
    position: absolute;
    top: 25%;
    left: 30%;
    width: 576px;
    height: 324px;
    background-color: white;
    padding: 6rem 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
  }
`;
