const currencyChecker = (currencyString) => {
  const currencyStringParts = currencyString.split(" ");
  if (typeof currencyString != "number" && currencyStringParts.length != 2) {
    return false;
  }
  return currencyStringParts;
};

const roundOff = (value, isRoundOffNeeded) => {
  return isRoundOffNeeded ? Math.round(value) : value;
};

const formatValue = ({ valueColumn, decimalPrecision = 2 }) => {
  let value = valueColumn.value;
  let prefix = valueColumn?.cell_prefix || "";
  if (valueColumn.type === "string" || valueColumn.value === "") {
    return `${prefix}${valueColumn.value}`;
  }
  if (valueColumn.type === "number") {
    return `${prefix} ${formatNumber(
      parseFloat(value).toFixed(decimalPrecision)
    )}`;
  }
  return value;
};

const numberFormatting = (number, isGrandTotal: boolean) => {
  let numberStringParts: any = currencyChecker(number);
  if (numberStringParts) {
    // currency string checking
    const value = numberStringParts[1] || 0;
    const currencyName = numberStringParts[0];
    const currencyValue = roundOff(parseFloat(value).toFixed(2), isGrandTotal);
    let formattedValue = formatNumber(currencyValue);
    let formattedString = `${currencyName} ${formattedValue}`;
    return formattedString;
  } else {
    //non currency number strings
    if (number % 1 === 0) {
      // whole number
      return formatNumber(Math.floor(number));
    } else {
      // decimal number
      return formatNumber(
        roundOff(parseFloat(number).toFixed(2), isGrandTotal)
      );
    }
  }
};

export { numberFormatting, formatValue };
